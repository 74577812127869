import { MachineLearning } from '@erp_core/erp-icons/icons/machine-learning';
import { Recat } from '@erp_core/erp-icons/icons/recat';
import { SwitchShiftScheduleType } from '@erp_core/erp-types/dist/modules/planning';
import {
  CardBody,
  CardHeader,
  MonthSelector,
  MultiSelect,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedSwitchShift } from '../../../../hooks/hrd/switch-shift-schedule/use-switch-shift';
import { SwitchShiftSchduleInterface } from '../../../../models/interfaces/hrd/switch-shift-schedule';
import { UserRendererInterface } from '../../../common/fragments/user';

type RenderEmployeesSwitchShift = {
  useCombinedSwitchShift: UseCombinedSwitchShift;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  userRendererService: UserRendererInterface;
  switchShiftService: SwitchShiftSchduleInterface;
};

export const renderEmployeeSwitchShifts = ({
  useCombinedSwitchShift,
  useCombinedEmployeeProfile,
  userRendererService,
  switchShiftService,
}: RenderEmployeesSwitchShift) => {
  return function EmployeeSwitchShiftsPage(): JSX.Element {
    const {
      list: switchScheduleData,
      getAll: getAllSwitchShiftScheduleData,
      loading,
    } = useCombinedSwitchShift();

    const { user: currentUser } = useContext(UserContext);

    const { syncSet: setSwitchShift } = useCombinedSwitchShift();

    const {
      list: employees,
      getAll: getAllEmployees,
    } = useCombinedEmployeeProfile();

    const [month, setMonth] = useState(
      localStorage.getItem('switch-shift-month') ||
        moment.utc().format('YYYY-MM')
    );
    useEffect(() => {
      getAllSwitchShiftScheduleData({
        date: month,
      });
      localStorage.setItem('switch-shift-month', month);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month]);

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Employee Switch Shifts',
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select Month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={month}
                onChange={(m) => setMonth(m)}
              />
            </div>
          ),
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Date' },
        { name: 'Current Employee' },
        { name: 'Shift Id' },
        { name: 'Replace Shift With Employee' },
        { name: 'Shift Id' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: 'Reason',
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
        { name: 'Status' },
      ],
    ];

    const filteredData = (switchScheduleData || []).filter((x) => {
      const emp = employees?.find((y) => x.currentEmployee.id === y.id);
      if (
        emp &&
        emp.details?.authorizations?.attendanceAuthorizer?.id === currentUser.id
      ) {
        return true;
      }
      return false;
    });

    const filteredTableBody: TableBody =
      filteredData.map((r) => ({
        rowData: { x: r },
        cells: [
          { value: r.currentShiftDay.date },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.currentEmployee?.id}
                name={r.currentEmployee?.name}
              />
            ),
          },
          { value: r.currentShiftDay.details.shiftId?.name || '' },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.replaceEmployee.id}
                name={r.replaceEmployee.name}
              />
            ),
          },
          {
            value:
              r.replaceShiftDay.id === r.currentShiftDay.id &&
              r.status === 'pending'
                ? r.currentShiftDay.details?.newShiftId?.name || ''
                : r.currentEmployee.id === r.replaceEmployee.id
                ? r.replaceShiftDay?.details?.shiftSwitches &&
                  (r.replaceShiftDay.details.shiftSwitches[0] as any)?.oldShift
                    ?.name
                : r.replaceShiftDay.details.shiftId?.name || '',
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <div title={r.details?.type}>
                {r.details?.type === 'company-mandated' ? (
                  <MachineLearning className='w-5 inline text-blue-500 mr-1' />
                ) : (
                  <UserIcon className='w-5 inline text-blue-500 mr-1' />
                )}
                {r.details?.reason}
              </div>
            ),
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
          { value: r.status },
        ],
      })) || [];

    const MultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Approve',
          show: () => true,
          behaviour: 'confirm',
          onConfirm: (entries, b) => {
            const selectedEntries = filteredData.filter((x, idx) =>
              entries.includes(idx)
            );
            if (selectedEntries.find((x) => x.status !== 'pending')) {
              return {
                title:
                  'Some of your selection are not pending switch-shift requests?',
                message: 'Select only pending records',
                type: 'warning',
                onConfirm: async () => {},
              };
            }

            return {
              title: 'Are you sure you want to approve switch-shift?',
              message: `Selected Employees: ${selectedEntries
                .map(
                  (x) =>
                    `${x.currentEmployee.name}-${x.replaceEmployee.name} [${x.currentShiftDay.date}-${x.replaceShiftDay.date}]`
                )
                .join()}`,
              type: 'warning',
              onConfirm: async () => {
                for (const entry of selectedEntries) {
                  await switchShiftService.approveSwitchShift(entry.id);
                }
                toast('Switch Shifts approved successfully');
                await sleep(2000);
                getAllSwitchShiftScheduleData({
                  date: month,
                });
              },
            };
          },
        },
      ],
    };

    const tableBody: TableBody = (switchScheduleData || []).map((r) => {
      return {
        rowData: {
          x: r,
        },
        cells: [
          { value: r.currentShiftDay.date },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.currentEmployee?.id}
                name={r.currentEmployee?.name}
              />
            ),
          },
          { value: r.currentShiftDay.details.shiftId?.name || '' },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.replaceEmployee.id}
                name={r.replaceEmployee.name}
              />
            ),
          },
          {
            value:
              r.replaceShiftDay.id === r.currentShiftDay.id &&
              r.status === 'pending'
                ? r.currentShiftDay.details?.newShiftId?.name || ''
                : r.currentEmployee.id === r.replaceEmployee.id
                ? r.replaceShiftDay?.details?.shiftSwitches &&
                  (r.replaceShiftDay.details.shiftSwitches[0] as any)?.oldShift
                    ?.name
                : r.replaceShiftDay.details.shiftId?.name || '',
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <div title={r.details?.type}>
                {r.details?.type === 'company-mandated' ? (
                  <MachineLearning className='w-5 inline text-blue-500 mr-1' />
                ) : (
                  <UserIcon className='w-5 inline text-blue-500 mr-1' />
                )}
                {r.details?.reason}
              </div>
            ),
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
          { value: r.status },
        ],
      };
    });

    const tableActions: TableActionsType[] = [
      {
        name: 'Approve',
        auth: 'UI:BTN-APRV-SHFT-SWCH:VIEW',
        show: ({ x }: { x: SwitchShiftScheduleType }) => {
          const emp = employees?.find((y) => x.currentEmployee.id === y.id);
          if (
            x.status === 'pending' &&
            emp &&
            emp.details?.authorizations?.attendanceAuthorizer?.id ===
              currentUser.id
          ) {
            return true;
          }

          return false;
        },
        behaviour: 'confirm',
        onConfirm: ({ x }) => {
          return {
            message: `Are you sure, accept switch schedule request for ${x.currentEmployee.name} & ${x.replaceEmployee.name}.`,
            onConfirm: async () => {
              // const final: Partial<SwitchShiftScheduleType> = {
              //   id: r.id,
              //   status: 'approved',
              // };
              // await setRotationalShiftDayData(r);
              // await setSwitchShift(final as SwitchShiftScheduleType);
              await switchShiftService.approveSwitchShift(x.id);
              getAllSwitchShiftScheduleData({
                date: month,
              });
            },
          };
        },
      },
      {
        name: 'Cancel',
        show: ({ x }: { x: SwitchShiftScheduleType }) => {
          if (x.status === 'pending') {
            return true;
          }

          return false;
        },
        behaviour: 'confirm',
        onConfirm: ({ x }) => {
          return {
            message: `Are you sure, cancelling switch shift request for ${x.currentEmployee.name}.`,
            onConfirm: async () => {
              const final: Partial<SwitchShiftScheduleType> = {
                id: x.id,
                status: 'cancelled',
              };
              await setSwitchShift(final as SwitchShiftScheduleType);

              toast('Employee Switch Schedule Request Cancelled');
              getAllSwitchShiftScheduleData({
                date: month,
              });
            },
          };
        },
      },
    ];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: loading ? (
        <div className='flex my-24 justify-center'>
          <Recat className='h-5 inline animate-pulse mx-4' />
        </div>
      ) : (
        <div>
          <div className='my-5'>
            <Card
              header={{ title: 'Requests for you' }}
              body={{
                type: 'jsx-component',
                body: (
                  <Table
                    header={tableHeader}
                    body={filteredTableBody}
                    multiSelect={MultiSelect}
                    actions={tableActions}
                    auth={currentUser.authorization}
                  />
                ),
              }}
            />
          </div>
          <div className='my-5'>
            <Card
              header={{ title: 'All Requests' }}
              body={{
                type: 'jsx-component',
                body: (
                  <Table
                    header={tableHeader}
                    body={tableBody}
                    actions={tableActions}
                    auth={currentUser.authorization}
                  />
                ),
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
};
