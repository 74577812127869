import { BatchItemGodown } from '@erp_core/erp-types/dist/modules/inventory';
import {
  BOMRes,
  PurchaseRequisition,
} from '@erp_core/erp-types/dist/modules/order';
import {
  BatchAllocatedPayload,
  PurchaseItemAllocatePayload,
} from '@erp_core/erp-types/dist/types/modules/inventory/batch-item-godown';
import { BOMStatus } from '@erp_core/erp-types/dist/types/modules/order/work-order/bom';
import {
  LoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { CubeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedBatchItemGodown } from '../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseCombinedPurchaseRequisition } from '../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-resource';
import { UseCombinedBom } from '../../../../hooks/order/work-order/bom/use-bom';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';

export function renderNewCommonAllocationForm({
  useCombinedBatchItemGodown,
  useCombinedBom,
  itemService,
  useCombinedPurchaseRequisition,
}: {
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useCombinedBom: UseCombinedBom;
  itemService: ItemInterface;
  useCombinedPurchaseRequisition: UseCombinedPurchaseRequisition;
}): ({ bom, onClose }: { bom: BOMRes; onClose: () => void }) => JSX.Element {
  return function NewCommonAllocationForm({
    bom,
    onClose,
  }: {
    bom: BOMRes;
    onClose: () => void;
  }): JSX.Element {
    const {
      syncSet: setBatchItemGodown,
      getListSync: getBatches,
    } = useCombinedBatchItemGodown();
    const { syncSet: setBom, getListSync: getBoms } = useCombinedBom();
    const [realBatches, setRealBatches] = useState<BatchItemGodown[]>([]);
    const [virtualBatches, setVirtualBatches] = useState<BatchItemGodown[]>([]);
    const [openBoms, setOpenBoms] = useState<BOMRes[]>([]);
    const [selectedBom, setSelectedBom] = useState<BOMRes | null>(null);
    const [showPurchaseReq, setShowPurchaseReq] = useState<boolean>(false);
    const [prData, setPrData] = useState<any>({
      id: bom.rawItem.id,
      name: bom.rawItem.name,
    });
    const { syncSet: setPR } = useCombinedPurchaseRequisition();

    useEffect(() => {
      getBoms({
        getAll: true,
        rawItemId: bom.rawItem.id,
      } as any).then((res) => {
        const newBoms: BOMRes[] = [];
        res.forEach((bom) => {
          if (
            [
              'new',
              'partially-allocated',
              'allocated',
              'pr-open',
              'purchase-order',
              'grn',
              'inspection',
            ].includes(bom.details?.status)
          ) {
            newBoms.push(bom);
          }
        });
        if (newBoms.length) {
          setOpenBoms(newBoms);
        }
      });

      getBatches({
        grade_id: bom.rawItem?.id,
        getAll: true,
        active: true,
        crossLocations: true,
      }).then((res) => {
        const newResult = (res as unknown) as Array<BatchItemGodown>;
        setRealBatches(newResult.filter((x) => x.active && x.state === 'real'));
        setVirtualBatches(
          newResult.filter((x) => x.active && x.state === 'virtual')
        );
      });

      if (bom.rawItem?.item?.id) {
        itemService.getProperties(bom.rawItem.item.id).then((res) => {
          setPrData((pd) => {
            const msl: any = res?.find((x) => x.name === 'minimum-stock-level');
            const moq: any = res?.find(
              (x) => x.name === 'minimum-order-quantity'
            );
            return {
              ...pd,
              ...{
                minimumStockLimit: msl?.value?.data
                  ? parseFloat(msl?.value?.data || '0')
                  : 0,
                minumumOrderQuantity: moq?.value?.data?.value
                  ? parseFloat(moq?.value?.data?.value || '0')
                  : 0,
              },
            };
          });
        });
      }
      // eslint-disable-next-line
    }, []);

    return (
      <div>
        <div>
          <AllocationTable
            boms={openBoms}
            realBatches={[...realBatches, ...virtualBatches]}
            setSelectedBom={setSelectedBom}
            setParamsForPr={(qty, bomIds, date) => {
              setPrData((pd) => {
                return {
                  ...pd,
                  ...{
                    purchaseBalance: qty,
                    bomIds: bomIds,
                    date: date,
                  },
                };
              });
              setShowPurchaseReq(true);
            }}
          />
        </div>
        {showPurchaseReq ? (
          <PurchaseSummary
            onClose={onClose}
            setPR={setPR}
            setShowPurchaseReq={setShowPurchaseReq}
            prData={prData}
            uom={openBoms[0].details?.rawMaterial?.uom}
          />
        ) : null}

        {selectedBom ? (
          <Cubes
            setBom={setBom}
            setBatchItemGodown={setBatchItemGodown}
            batches={realBatches}
            virtualBatches={virtualBatches}
            selectedBom={selectedBom}
            setSelectedBom={setSelectedBom}
          />
        ) : null}
      </div>
    );
  };
}

const Cubes = ({
  selectedBom,
  batches,
  virtualBatches,
  setSelectedBom,
  setBatchItemGodown,
  setBom,
}: {
  selectedBom: BOMRes;
  batches: BatchItemGodown[];
  virtualBatches: Array<BatchItemGodown>;
  setSelectedBom: React.Dispatch<React.SetStateAction<BOMRes | null>>;
  setBatchItemGodown: (s: any) => Promise<any>;
  setBom: (s: BOMRes) => Promise<BOMRes>;
}) => {
  const [balanceUnallocated, setBalanceUnallocated] = useState<number>(
    selectedBom.details.rawMaterial.quantity -
      [...batches, ...virtualBatches].reduce((p, c) => {
        if (
          ((c.referenceType === 'bill-of-material' &&
            c.referenceId === selectedBom.id) ||
            // c.details.bomIds?.find((y) => y === selectedBom.id)
            c.details.bomId === selectedBom.id) &&
          c.action === 'allocated' &&
          c.active
        ) {
          return p + c.quantity;
        }

        return p;
      }, 0)
  );

  useEffect(() => {
    setBalanceUnallocated(
      selectedBom.details.rawMaterial.quantity -
        [...batches, ...virtualBatches].reduce((p, c) => {
          if (
            ((c.referenceType === 'bill-of-material' &&
              c.referenceId === selectedBom.id) ||
              // c.details.bomIds?.find((y) => y === selectedBom.id)
              c.details.bomId === selectedBom.id) &&
            c.action === 'allocated' &&
            c.active
          ) {
            return p + c.quantity;
          }

          return p;
        }, 0)
    );
  }, [selectedBom, batches, virtualBatches]);

  return (
    <div className='p-1 border border-gray-200 rounded-md relative'>
      <XMarkIcon
        className='absolute top-1 right-1 w-5 text-red-500 cursor-pointer'
        onClick={() => {
          setSelectedBom(null);
        }}
      />
      <div className='text-center text-lg my-1'>
        Allocate for {selectedBom.name} [{balanceUnallocated} Unallocated]
      </div>

      <Batches
        saveAllocation={async ({
          selectedBatch,
          quantity,
          allAllocated,
        }: {
          selectedBatch: BatchItemGodown;
          quantity: number;
          allAllocated: boolean;
        }) => {
          const payload = {
            unallocatedBatchId: selectedBatch.id,
            allocateQuantity: quantity,
            referenceType: 'bill-of-material',
            referenceId: selectedBom.id,
            bomIds: [selectedBom.id],
            reason: 'Physically Allocated',
          } as BatchAllocatedPayload;
          await setBatchItemGodown({
            payload,
            operation: 'batch-allocated',
          });
          if (
            ![
              'purchase-order',
              'allocated',
              'pr-open',
              'grn',
              'inspection',
            ].includes(selectedBom.details?.status)
          ) {
            // Update the status to partially-allocated or allocated
            await setBom({
              id: selectedBom.id,
              status: allAllocated ? 'allocated' : 'partially-allocated',
              details: {
                status: allAllocated ? 'allocated' : 'partially-allocated',
              },
            } as BOMRes);
            toast.success('Allocation Successful');
          }
        }}
        // reference={{ type: 'bill-of-material', id: () => selectedBom.id }} bomReferences={[selectedBom.id]} setBatchItemGodown={setBatchItemGodown}
        batches={batches}
        heading='Real Batches'
        balanceUnallocated={balanceUnallocated}
      />

      {virtualBatches.length ? (
        <Batches
          saveAllocation={async ({
            selectedBatch,
            quantity,
          }: {
            selectedBatch: BatchItemGodown;
            quantity: number;
          }) => {
            console.log(selectedBatch, quantity);
            const payload = {
              bom: {
                id: selectedBom.id,
                name: selectedBom.name,
              },
              unallocatedBatchId: selectedBatch.id,
              allocateQuantity: quantity,
            } as PurchaseItemAllocatePayload;
            await setBatchItemGodown({
              payload,
              operation: 'purchase-order-allocate',
            });
            toast.success('Virtual Allocation Successful');
          }}
          // reference={{ type: 'purchase-order', id: (batch) => batch.referenceId  }} bomReferences={[selectedBom.id]} setBatchItemGodown={setBatchItemGodown}
          heading='Open Purchase Orders'
          batches={virtualBatches}
          balanceUnallocated={balanceUnallocated}
        />
      ) : null}
    </div>
  );
};

function Batches({
  // reference,
  // bomReferences,
  heading,
  batches,
  balanceUnallocated,
  // setBatchItemGodown,
  saveAllocation,
}: {
  // reference calculation depends on the operation type. in case of phy allocation we use the selected bomId,
  // in case of virtual allocation we want to preserve the original purchase order.
  // reference: {
  //   type: 'purchase-order' | 'bill-of-material',
  //   id: (selectedbatch: any) => string;
  // };
  // bomReferences: string[];
  heading: string;
  batches: BatchItemGodown[];
  balanceUnallocated: number;
  // setBatchItemGodown: (s: any) => Promise<any>;
  saveAllocation: (props: {
    selectedBatch: BatchItemGodown;
    quantity: number;
    allAllocated: boolean;
  }) => Promise<void>;
}): JSX.Element {
  const [allocateBom, setAllocateBom] = useState<{ [id: string]: number }>({});
  const [errorBom, setErrorBom] = useState<{ [id: string]: string }>({});

  useEffect(() => {
    const newError: { [id: string]: string } = {};

    let totalAllocations = 0;
    _.keys(allocateBom).forEach((key) => {
      if (allocateBom[key]) {
        totalAllocations += allocateBom[key];
        const bat = batches.find((x) => x.id === key);
        if (bat && allocateBom[key] > bat.quantity) {
          newError[key] = `Allocated Quantity exceeds ${bat.quantity}. `;
        }
      }
    });

    if (totalAllocations > balanceUnallocated) {
      _.keys(allocateBom).forEach((key) => {
        if (allocateBom[key]) {
          newError[key] = `${
            newError[key] || ''
          }Total Allocated exceeds ${balanceUnallocated}`;
        }
      });
    }
    setErrorBom(newError);
  }, [allocateBom, balanceUnallocated, batches]);

  return (
    <div className='my-1'>
      <div className='text-center'>{heading}</div>
      <div className='flex flex-row flex-wrap'>
        {batches?.map((i, index) => (
          <div key={index} className='border p-1 w-1/4'>
            <div
              className={`p-1 mx-auto text-center m-1 ${
                i.action === 'unallocated' ? 'bg-green-100' : 'bg-gray-100'
              } ${errorBom[i.id] ? 'border-2 border-red-600' : ''}`}
            >
              <CubeIcon className='inline-block w-8 h-8 text-blue-500' />
              <sup className='bg-green-200 text-green-800 rounded sups text-lg'>
                {i.quantity}
              </sup>
              {i.action === 'unallocated' ? (
                <input
                  className='mb-3 ml-1 shadow appearance-none border rounded w-14  text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  id='alloted'
                  min='0'
                  max={i.quantity}
                  onChange={(e) => {
                    const { value } = e.target;
                    setAllocateBom((val) => {
                      val[i.id] = parseInt(value || '0', 10);
                      return { ...val };
                    });
                  }}
                  type='number'
                  value={allocateBom[i.id] || 0}
                />
              ) : (
                i.quantity
              )}

              {errorBom[i.id] ? (
                <label className='block text-red-700 text-sm font-bold mb-2'>
                  {errorBom[i.id]}
                </label>
              ) : null}
            </div>

            <div className='mx-auto text-center'>
              <label className='block text-blue-600 text-md font-bold'>
                {i?.name}
              </label>
              <label className='block text-gray-800 text-md font-bold mb-2'>
                {i?.godown?.name}
              </label>
              {allocateBom[i.id] ? (
                <LoadingButton
                  behaviorFn={async () => {
                    const selectedBatch = batches.find((x) => x.id === i.id);
                    if (selectedBatch) {
                      await saveAllocation({
                        selectedBatch,
                        quantity: allocateBom[i.id],
                        allAllocated: balanceUnallocated === allocateBom[i.id],
                      });
                      // const payload = {
                      //   unallocatedBatchId: i.id,
                      //   allocateQuantity: allocateBom[i.id],
                      //   referenceType: reference.type,
                      //   referenceId: reference.id(selectedBatch),
                      //   bomIds: bomReferences,
                      //   reason: 'Physically Allocated',
                      // } as BatchAllocatedPayload;
                      // await setBatchItemGodown({
                      //   payload,
                      //   operation: 'batch-allocated'
                      // });
                    }
                  }}
                  defaultStyle='border border-green-500 text-green-500 p-1 rounded-md'
                  text='Allocate'
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

type BatchPO = {
  name: string;
  unallocated: number;
  allocated: number;
};

type CalculatedDetails = {
  [id: string]: {
    name: string;
    status: BOMStatus;
    quantity: number;
    balance: number;
    balanceToPurchase: number;
    batchPO: Array<BatchPO>;
    allocateAction: boolean;
  };
};

function AllocationTable({
  boms,
  realBatches,
  setSelectedBom,
  setParamsForPr,
}: {
  boms: BOMRes[];
  realBatches: Array<BatchItemGodown>;
  setSelectedBom: React.Dispatch<React.SetStateAction<BOMRes | null>>;
  setParamsForPr: (qty, bomIds, date) => void;
}): JSX.Element {
  const [calculatedDetails, setCalculatedDetails] = useState<CalculatedDetails>(
    {}
  );
  const [totalPurchaseBalance, setTotalPurchaseBalance] = useState<{
    bomIds: string[];
    quantity: number;
    date: string;
  }>({
    bomIds: [],
    quantity: 0,
    date: '',
  });

  const [uom, setUom] = useState<string>('');

  useEffect(() => {
    const cd: CalculatedDetails = {};
    const tpb: {
      bomIds: string[];
      quantity: number;
      date: string;
    } = {
      bomIds: [],
      quantity: 0,
      date: '',
    };
    let uom = '';
    for (const bom of boms) {
      if (bom.details?.rawMaterial?.uom && !uom) {
        uom = bom.details?.rawMaterial?.uom;
      }
      cd[bom.id] = {
        name: bom.name,
        status: bom.details.status,
        quantity: bom.details.rawMaterial.quantity,
        balance: bom.details.rawMaterial.quantity,
        balanceToPurchase: 0,
        allocateAction: false,
        batchPO: [
          {
            name: '',
            unallocated: 0,
            allocated: 0,
          },
        ],
      };

      // Batches belonging to current BOM
      const batches = realBatches.filter((x) => {
        if (
          x.referenceType === 'bill-of-material' &&
          x.referenceId === bom.id
        ) {
          return true;
        }

        // if (x.details.bomIds?.find((y) => y === bom.id)) {
        //   return true;
        // }
        if (x.details.bomId === bom.id) {
          return true;
        }

        return false;
      });

      batches.forEach((b, idx) => {
        let selected: BatchPO | null = null;
        if (idx === 0) {
          cd[bom.id].batchPO[0].name = b.name;
          selected = cd[bom.id].batchPO[0];
        } else {
          let similarBatch = cd[bom.id].batchPO.find((x) => x.name === b.name);
          if (similarBatch) {
            selected = similarBatch;
          } else {
            let newBatch: BatchPO = {
              name: b.name,
              unallocated: 0,
              allocated: 0,
            };
            cd[bom.id].batchPO.push(newBatch);
            selected = newBatch;
          }
        }

        if (selected) {
          selected.unallocated += b.action === 'unallocated' ? b.quantity : 0;
          selected.allocated += b.action === 'allocated' ? b.quantity : 0;
          if (b.action === 'allocated') {
            cd[bom.id].balance -= b.quantity;
          }
        }
      });

      if (['new', 'partially-allocated'].includes(bom.details.status)) {
        cd[bom.id].balanceToPurchase = cd[bom.id].balance;
        tpb.bomIds.push(bom.id);
        tpb.quantity += cd[bom.id].balanceToPurchase;
        tpb.date =
          !tpb.date || moment(tpb.date).isAfter(bom.targetDate)
            ? bom.targetDate
            : tpb.date;

        if (cd[bom.id].balance > 0) {
          cd[bom.id].allocateAction = true;
        }
      }
    }

    setCalculatedDetails(cd);
    setTotalPurchaseBalance(tpb);
    setUom(uom);
    // eslint-disable-next-line
  }, [boms]);

  const Table = renderTableComponent();
  const rows: TableBody = [];

  for (const key of _.keys(calculatedDetails)) {
    const entry = calculatedDetails[key];
    entry.batchPO.forEach((x, idx) => {
      if (idx === 0) {
        rows.push({
          cells: [
            { value: entry.name, rowSpan: entry.batchPO.length },
            { value: entry.status, rowSpan: entry.batchPO.length },
            { value: uom, rowSpan: entry.batchPO.length },
            { value: entry.quantity, rowSpan: entry.batchPO.length },
            { value: x.name },
            { value: x.unallocated },
            { value: x.allocated },
            { value: entry.balance, rowSpan: entry.batchPO.length },
            { value: entry.balanceToPurchase, rowSpan: entry.batchPO.length },
            {
              value: entry.allocateAction ? (
                <div className='inline-block align-middle'>
                  <LoadingButton
                    behaviorFn={async () => {
                      const bom = boms.find((x) => x.id === key);
                      if (bom) {
                        setSelectedBom(bom);
                      }
                    }}
                    defaultStyle='border border-green-500 text-green-500 p-1 rounded-md'
                    text='Allocate'
                  />
                </div>
              ) : (
                <></>
              ),
              rowSpan: entry.batchPO.length,
            },
          ],
        });
      } else {
        rows.push({
          cells: [
            { value: x.name },
            { value: x.unallocated },
            { value: x.allocated },
          ],
        });
      }
    });
  }

  // let purchaseBomQuantity = 0;
  // let bomIds: Array<string> = [];
  // let date = '';

  // for (const bom of boms) {
  //   let balanceUnallocated = bom.details.rawMaterial.quantity;

  //   const batches = realBatches.filter((x) => {
  //     if (x.referenceType === 'bill-of-material' && x.referenceId === bom.id) {
  //       return true;
  //     }

  //     if (x.details.bomIds?.find((y) => y === bom.id)) {
  //       return true;
  //     }

  //     return false;
  //   });

  //   //

  //   if (batches.length) {
  //     // eslint-disable-next-line
  //     batches.forEach((b, idx) => {
  //       balanceUnallocated =
  //         balanceUnallocated - (b.action === 'allocated' ? b.quantity : 0);
  //       if (idx === 0) {
  //         const purchaseBalance = ['new', 'partially-allocated'].includes(
  //           bom.details.status
  //         )
  //           ? bom.details.rawMaterial.quantity -
  //             batches.reduce((p, c) => {
  //               return p + (c.action === 'allocated' ? c.quantity : 0);
  //             }, 0)
  //           : 0;
  //         if (purchaseBalance) {
  //           purchaseBomQuantity += purchaseBalance;
  //           bomIds.push(bom.id);
  //           if (!date) {
  //             date = bom.targetDate;
  //           } else if (moment(date).isAfter(bom.targetDate)) {
  //             date = bom.targetDate;
  //           }
  //         }
  //         rows.push({
  //           cells: [
  //             { value: bom.name, rowSpan: batches.length },
  //             { value: bom.details?.status, rowSpan: batches.length },
  //             {
  //               value: calculatedDetails[bom.id] && calculatedDetails[bom.id].quantity,  //bom.details.rawMaterial.quantity,
  //               rowSpan: batches.length,
  //             },
  //             { value: b.name },
  //             { value: b.action === 'unallocated' ? b.quantity : 0 },
  //             { value: b.action === 'allocated' ? b.quantity : 0 },
  //             { value: balanceUnallocated },
  //             { value: purchaseBalance, rowSpan: batches.length },
  //             {
  //               value: (
  //                 <div className='inline-block align-middle'>
  //                   <button
  //                     onClick={() => {
  //                       setSelectedBom(bom);
  //                     }}
  //                     className='border border-green-500 text-green-500 p-1 rounded-md'
  //                   >
  //                     Allocate
  //                   </button>
  //                 </div>
  //               ),
  //               rowSpan: batches.length,
  //             },
  //           ],
  //         });
  //       } else {
  //         rows.push({
  //           cells: [
  //             { value: b.name },
  //             { value: b.action === 'unallocated' ? b.quantity : 0 },
  //             { value: b.action === 'allocated' ? b.quantity : 0 },
  //             { value: balanceUnallocated },
  //           ],
  //         });
  //       }
  //     });
  //   } else {
  //     purchaseBomQuantity += balanceUnallocated;
  //     bomIds.push(bom.id);
  //     if (!date) {
  //       date = bom.targetDate;
  //     } else if (moment(date).isAfter(bom.targetDate)) {
  //       date = bom.targetDate;
  //     }
  //     rows.push({
  //       cells: [
  //         { value: bom.name },
  //         { value: bom.details?.status },
  //         { value: bom.details.rawMaterial.quantity },
  //         { value: '' },
  //         { value: '' },
  //         { value: '' },
  //         { value: balanceUnallocated },
  //         { value: balanceUnallocated },
  //         {
  //           value: ['new', 'partially-allocated'].includes(
  //             bom.details?.status
  //           ) ? (
  //             <div className='inline-block align-middle'>
  //               <button
  //                 onClick={() => {
  //                   setSelectedBom(bom);
  //                 }}
  //                 className='border border-green-500 text-green-500 p-1 rounded-md'
  //               >
  //                 Allocate
  //               </button>
  //             </div>
  //           ) : (
  //             <></>
  //           ),
  //           rowSpan: batches.length,
  //         },
  //       ],
  //     });
  //   }
  // }

  rows.push({
    cells: [
      {
        value: (
          <div className='grid justify-items-center'>
            <LoadingButton
              behaviorFn={async () => {
                setParamsForPr(
                  totalPurchaseBalance.quantity,
                  totalPurchaseBalance.bomIds,
                  totalPurchaseBalance.date
                );
              }}
              defaultStyle='border border-purple-500 text-purple-500 p-1 rounded-md'
              text='Raise Purchase Requisition'
            />
          </div>
        ),
        colSpan: 8,
      },
    ],
  });

  return (
    <div>
      <Table
        header={[
          [
            { name: 'BOM' },
            { name: 'Status' },
            { name: 'UOM' },
            { name: 'BOM Qty' },
            { name: 'Batch / PO #' },
            { name: 'Unallocated Qty' },
            { name: 'Allocated Qty' },
            { name: 'Balance Unallocated' },
            { name: 'Balance To be Purchased' },
            { name: 'Actions' },
          ],
        ]}
        body={rows}
      />
    </div>
  );
}

function PurchaseSummary({
  uom,
  setShowPurchaseReq,
  prData,
  setPR,
  onClose,
}: {
  uom: string;
  setShowPurchaseReq: React.Dispatch<React.SetStateAction<boolean>>;
  prData: any;
  setPR: (s: PurchaseRequisition) => Promise<PurchaseRequisition>;
  onClose: () => void;
}): JSX.Element {
  const Table = renderTableComponent();
  const purchaseBalance: string = prData.purchaseBalance;
  const total = parseInt(purchaseBalance) + (prData?.minimumStockLimit || 0); // Add spl req by store
  const prQty =
    prData?.minumumOrderQuantity > total ? prData?.minumumOrderQuantity : total;

  return (
    <div className='relative p-1 border border-gray-200 rounded-md'>
      <XMarkIcon
        className='absolute top-1 right-1 w-5 text-red-500 cursor-pointer'
        onClick={() => {
          setShowPurchaseReq(false);
        }}
      />
      <div className='text-center text-lg my-1'>Raise Purchase Requisition</div>
      <Table
        header={[]}
        body={[
          {
            cells: [
              { value: 'Bal to be Purchased' },
              { value: purchaseBalance || '-' },
            ],
          },
          { cells: [{ value: 'MSL' }, { value: prData?.minimumStockLimit }] },
          { cells: [{ value: 'Spl Req by Store' }, { value: '-' }] },
          { cells: [{ value: 'Total' }, { value: total }] },
          {
            cells: [{ value: 'MOQ' }, { value: prData?.minumumOrderQuantity }],
          },
          {
            cells: [
              { value: 'PR Qty [Max(Total,MOQ)]' },
              { value: `${prQty} ${uom}` },
            ],
          },
        ]}
      />
      <div className='flex justify-center'>
        <LoadingButton
          text='Submit'
          behaviorFn={async () => {
            await setPR({
              name: `PR-${moment().format('YY-MM-DD')}`,
              bomIds: prData.bomIds,
              itemDetails: {
                id: prData.id,
                name: prData.name,
                uom: uom,
              },
              grade: {
                id: prData.id,
                name: prData.name,
              },
              details: {
                status: 'open',
                activity: [],
                remarks: '',
                quantity: prQty,
                targetDate: prData.date,
                priority: 'medium',
              },
            } as any);

            toast.success('Purchase requisition created successfully');
            onClose();
          }}
          behaviourParams={{}}
        />
      </div>
    </div>
  );
}
