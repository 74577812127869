import {
  DashboardFilter,
  DashboardType,
} from '@erp_core/erp-types/dist/modules/analytics';
import { AxiosResponse } from 'axios';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpDashbaordRepo extends GenericHttpWithLogsRepo<
  DashboardType,
  DashboardFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/dashboard',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getReportData(url, data): Promise<AxiosResponse<any>> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: url,
    });

    return httpInstance.post('', data);
  }

  async syncDatabase(url, data): Promise<AxiosResponse<any>> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: url,
    });

    return httpInstance.post('', data);
  }
}
