import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { AdvancedLoadingButton } from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import {
  BoltIcon,
  CogIcon,
  EyeIcon,
  InformationCircleIcon,
  LockClosedIcon,
  PencilIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

export function LHSRenderer({
  setRevision,
  currentUser,
  seq,
  x,
  entityId,
  renderEditProp,
  EditProperty,
  properties,
  SoftEditProperty,
  AuthEditProperty,
  RuleUpdateProperty,
  renderAuth,
  classifier,
  auth,
}: {
  setRevision: (data: Revision) => Promise<void>;
  currentUser: CurrentUserType;
  seq: number;
  x: ItemPropertyValue;
  entityId: string;
  renderEditProp: boolean;
  EditProperty?: (p: { idx: number; onClose: () => void }) => JSX.Element;
  properties: Array<ItemPropertyValue>;
  SoftEditProperty?: ({
    idx,
    onClose,
  }: {
    idx: number;
    onClose: () => void;
  }) => JSX.Element;
  AuthEditProperty?: ({
    idx,
    onClose,
  }: {
    idx: number;
    onClose: () => void;
  }) => JSX.Element;
  RuleUpdateProperty?: (p: { idx: number; onClose: () => void }) => JSX.Element;
  renderAuth: boolean;
  classifier?: string;
  auth: {
    type: 'role' | 'user';
    actions: Array<'view' | 'edit'>;
    id: string;
    name: string;
  }[];
}): JSX.Element {
  const owner = x.owner?.name || '';

  // async function updateProp(x: any) {
  //   if (editProperty) {
  //     const index = properties.findIndex((y) => y.name === x.name);
  //     editProperty(index);
  //   }
  // }

  // async function softUpdateProp(x: any) {
  //   if (softEditProperty) {
  //     const index = properties.findIndex((y) => y.name === x.name);
  //     softEditProperty(index);
  //   }
  // }

  // async function ruleUpdateProp(x: any) {
  //   if (ruleUpdateProperty) {
  //     const index = properties.findIndex((y) => y.name === x.name);
  //     ruleUpdateProperty(index);
  //   }
  // }

  // async function authUpdateProp(x: any) {
  //   if (authEditProperty) {
  //     const index = properties.findIndex((y) => y.name === x.name);
  //     authEditProperty(index);
  //   }
  // }

  return (
    <div className='w-full'>
      <div className='flex font-semibold text-lg'>
        {/* <div className='mr-1 flex-none'>
          {x.sequenceNumber ? `${x.sequenceNumber}.` : ''}
        </div> */}
        <div
          className={`grow ${
            x.owner?.id === entityId ? 'text-green-500' : 'text-black'
          }`}
        >
          <span className='mr-1'>{seq + 1}.</span>
          {x.displayName}{' '}
          {x.rules?.length ? (
            <BoltIcon className='text-yellow-500 animate-pulse w-5 inline' />
          ) : null}
        </div>
        <div className='flex-none'>
          <div className='flex'>
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <InformationCircleIcon className='h-5 w-5 inline text-gray-800' />
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <div className='border border-gray-200 rounded-md'>
                      <div className='text-gray-700 italic text-sm'>
                        Description: {x.description}
                      </div>
                      <div className='italic text-right text-xs'>
                        Owned by{' '}
                        <a
                          href={`/inventory/masters/stock-groups/${
                            x.owner?.id || ''
                          }/profile`}
                        >
                          {owner}
                        </a>
                      </div>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
            {renderEditProp && x.owner?.id === entityId ? (
              <>
                <AdvancedLoadingButton
                  text={
                    <TrashIcon className='inline w-5 h-5 text-red-500 cursor-pointer' />
                  }
                  defaultStyle='bg-none p-0'
                  behaviour='confirm'
                  onConfirm={() => {
                    return {
                      title: `Are you sure you want to delete this Property ${x.name}? This cannot be undone.`,
                      message:
                        'A revision request will be created if you proceed.',
                      type: 'warning',
                      showInput: true,
                      onConfirm: async (inputReason) => {
                        try {
                          const finalRevRequest = {
                            resource: entityId,
                            name: x.name,
                            reason: inputReason,
                            type: 'stock-group',
                            isLatest: false, // TODO: Unsure about this field
                            status: 'pending',
                            requestedBy: {
                              id: currentUser.id,
                              name: currentUser.name,
                            },
                            operation: 'property-deleted',
                            changeRequest: {},
                            details: {
                              property: x.name,
                              itemProperty: x,
                            },
                          } as Revision;

                          console.log('finalData', finalRevRequest);
                          await setRevision(finalRevRequest);
                          toast('Change request submitted successfully');
                        } catch (e) {
                          console.log('Something went wrong');
                        }
                      },
                    };
                  }}
                />

                {EditProperty ? (
                  <AdvancedLoadingButton
                    text={
                      <PencilIcon className='inline w-5 h-5 text-gray-400 cursor-pointer' />
                    }
                    defaultStyle='bg-none p-0'
                    behaviour='modal'
                    modal={{
                      size: 'large',
                      title: 'Edit Property',
                      content: ({ onClose }) => {
                        const index = properties.findIndex(
                          (y) => y.name === x.name
                        );
                        return <EditProperty idx={index} onClose={onClose} />;
                      },
                    }}
                  />
                ) : null}
                {/* <PencilIcon
                  className='inline ml-2 w-5 h-5 text-gray-400 cursor-pointer'
                  onClick={() => {
                    updateProp(x);
                  }}
                /> */}
                {SoftEditProperty ? (
                  <AdvancedLoadingButton
                    text={
                      <EyeIcon className='inline ml-2 w-5 h-5 text-gray-400 cursor-pointer' />
                    }
                    defaultStyle='bg-none p-0'
                    behaviour='modal'
                    modal={{
                      size: 'normal',
                      title: 'Soft Edit',
                      content: ({ onClose }) => {
                        const index = properties.findIndex(
                          (y) => y.name === x.name
                        );
                        return (
                          <SoftEditProperty idx={index} onClose={onClose} />
                        );
                      },
                    }}
                  />
                ) : null}
                {/* <EyeIcon
                  className='inline ml-2 w-5 h-5 text-gray-400 cursor-pointer'
                  onClick={() => {
                    softUpdateProp(x);
                  }}
                /> */}
                {RuleUpdateProperty ? (
                  <AdvancedLoadingButton
                    text={
                      <BoltIcon className='inline w-5 h-5 text-gray-400 cursor-pointer' />
                    }
                    defaultStyle='bg-none p-0'
                    behaviour='modal'
                    modal={{
                      size: 'normal',
                      title: 'Rule Update for Property',
                      content: ({ onClose }) => {
                        const index = properties.findIndex(
                          (y) => y.name === x.name
                        );
                        return (
                          <RuleUpdateProperty idx={index} onClose={onClose} />
                        );
                      },
                    }}
                  />
                ) : null}
                {/* <BoltIcon
                  className='inline ml-2 w-5 h-5 text-gray-400 cursor-pointer'
                  onClick={() => {
                    ruleUpdateProp(x);
                  }}
                /> */}
                {AuthEditProperty ? (
                  <AdvancedLoadingButton
                    text={
                      <LockClosedIcon className='inline ml-2 w-5 h-5 text-gray-400 cursor-pointer' />
                    }
                    defaultStyle='bg-none p-0'
                    behaviour='modal'
                    modal={{
                      size: 'normal',
                      title: 'Edit Authorization',
                      content: ({ onClose }) => {
                        const index = properties.findIndex(
                          (y) => y.name === x.name
                        );
                        return (
                          <AuthEditProperty idx={index} onClose={onClose} />
                        );
                      },
                    }}
                  />
                ) : null}
                {/* <LockClosedIcon
                  className='inline ml-2 w-5 h-5 text-gray-400 cursor-pointer'
                  onClick={() => {
                    authUpdateProp(x);
                  }}
                /> */}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {!classifier ? (
        <div>
          <span className='italic text-gray-700 text-sm'>Classifier</span>
          {x.classifiers?.map((c, cIdx) => (
            <span
              key={cIdx}
              className='mx-1 border border-gray-200 rounded-full px-2'
            >
              {c.name}
            </span>
          ))}
        </div>
      ) : null}
      {x.auth && renderAuth && auth.length ? (
        <>
          <div>
            <span className='italic text-sm font-semibold'>Authorization</span>
          </div>
          <div className='flex'>
            {auth.map((a) => (
              <div
                key={a.id}
                className='px-1 py-0.5  border border-gray-200 rounded-md'
              >
                {a.type === 'role' ? (
                  <CogIcon title='Role' className='inline w-5' />
                ) : (
                  <UserIcon title='User' className='inline w-5' />
                )}
                {a.name}
                {a.actions.map((ac, idx) => {
                  return ac === 'edit' ? (
                    <PencilIcon key={idx} title='edit' className='w-5 inline' />
                  ) : (
                    <EyeIcon key={idx} title='view' className='w-5 inline' />
                  );
                })}
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}
