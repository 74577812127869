import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { renderAddEnquiryForm } from '../form/new-enquiry';

export function renderEnquiryInfoForm({
  useCombinedClient,
  useCombinedGrade,
}: {
  useCombinedClient;
  useCombinedGrade;
}) {
  return function EnquiryDetailsForm(): JSX.Element {
    const EnquiryInfoCard = renderCardComponent();

    const EnquiryQuantityInfoCard = renderCardComponent();

    const data = {
      id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
      sirNo: '9890',
      productGrade: 'standard',
      productCode: '123',
      endUse: 'aaa',
      modeOfApp: 'customer',
      location: 'office',
      address: 'Lodash Kafka MongoDb, Thane, Maharashtra India 466234',
      modeOfEnq: 'email',
      remarks: 'aaab',
      casno: '12354',
      destination: 'Mumbai',
      alias: '1-Ethyl piperazine',
      contactPerson: 'Suraj',
      quantity: [
        {
          productGrade: {
            id: 'C51E8109-00A4-4735-8127-2C5F6220A786',
            name: '1-Amino-4-Cyclopentyl Piperazine (C-Grade)',
            casNo: '543321',
            alias: 'AMP - Crude',
            endUse: '-',
            productName: '1-Amino-4-Cyclopentyl Piperazine',
            productCode: '',
          },
          quantity: '75',
          unit: 'kg',
          date: '12/12/2024',
          deliveryPer: '20',
          qtySchedule: [
            {
              qty: '50',
              leadTime: '20 Days',
            },
            {
              qty: '25',
              leadTime: '50 Days',
            },
          ],
        },
        {
          productGrade: {
            id: '643132134654',
            name: 'SFG - NMP (Standard)',
            casNo: '546546',
            alias: '1-Amino-4-Methyl Piperazine',
            endUse: '-',
            productName: 'SFG - NMP',
            productCode: '2352',
          },
          quantity: '150',
          unit: 'kg',
          date: '12/12/2024',
          deliveryPer: '20',
          qtySchedule: [
            {
              qty: '100',
              date: '12/12/2024',
            },
            {
              qty: '50',
              leadTime: '20/12/2024',
            },
          ],
        },
      ],
      offerNo: '24-25/9890',
      date: '31/12/2024',
      customer: { id: '2222', name: 'Dr. Reddy' },
      product: { id: '22222', name: 'N-Methyl Piperazine' },
      status: 'Inquiry',
      refNo: '5566516',
    };

    const EnquiryQuantityInfoCardHeader: CardHeader = {
      title: 'Quantity Details',
    };

    const EnquiryInfoCardHeader: CardHeader = {
      title: 'Enquiry',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              size: 'normal',
              title: 'Edit',
              content: ({ onClose }) => {
                const Form1 = renderAddEnquiryForm({
                  useCombinedClient,
                  useCombinedGrade,
                });
                return (
                  <Form1
                    data={{
                      id: data.id,
                      sirNo: data.sirNo,
                      refNo: data.refNo,
                      offerNo: data.offerNo,
                      status: data.status,
                      location: data.location,
                      address: data.address,
                      product: {
                        id: data.product?.id,
                        name: data.product?.name,
                      },
                      productCode: data.productCode,
                      productGrade: data.productGrade,
                      endUse: data.endUse,
                      modeOfApp: data.modeOfApp,
                      modeOfEnq: data.modeOfEnq,
                      customer: {
                        id: data.customer?.id,
                        name: data.customer?.name,
                      },
                      remarks: data.remarks,
                      casno: data.casno,
                      date: data.date,
                      destination: data.destination,
                      alias: data.alias,
                      contactPerson: data.contactPerson,
                      quantity: data.quantity,
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const EnquiryInfoData: ColumnizedContent = [
      [
        {
          key: 'Customer:',
          value: data.customer?.name,
          type: 'text',
        },
        {
          key: 'Contact Person   :',
          value: data.contactPerson,
          type: 'text',
        },
        {
          key: 'Mode of Inquiry :',
          value: data.modeOfEnq || '',
          type: 'text',
        },
      ],
      [
        {
          key: 'Mode of Approach :',
          value: data.modeOfApp || '',
          type: 'text',
        },
        {
          key: 'Ref No:',
          value: data.refNo || '',
          type: 'text',
        },
        {
          key: 'Date:',
          value: data.date,
          type: 'text',
        },
        // {
        //   key: 'Product :',
        //   value: data.product.name || '',
        //   type: 'text',
        // },
        // {
        //   key: 'CAS No. :',
        //   value: data.casno || '',
        //   type: 'text',
        // },
        // {
        //   key: 'Product Code:',
        //   value: data.productCode || '',
        //   type: 'text',
        // },
      ],
      [
        // {
        //   key: 'Product Grade :',
        //   value: data.productGrade || '',
        //   type: 'text',
        // },
        {
          key: 'Destination  :',
          value: data.destination || '',
          type: 'text',
        },
        // {
        //   key: 'Alias  :',
        //   value: data.alias || '',
        //   type: 'text',
        // },
        {
          key: 'Remark   :',
          value: data.remarks || '',
          type: 'text',
        },
      ],
    ];

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Product Grade', rowSpan: 2 },
        { name: 'Quantity', rowSpan: 2 },
        { name: 'Description', rowSpan: 2 },
        { name: 'Schedule', colSpan: 2, style: 'text-center' },
        { name: 'Delivery Period', rowSpan: 2 },
      ],
      [{ name: 'Quantity' }, { name: 'Date/leadTime' }],
    ];
    const tableBody: TableBody =
      data.quantity.map((r) => ({
        rowData: { enquiry: r },
        cells: [
          { value: r.productGrade.name },
          { value: r.quantity + r.unit },
          {
            value: (
              <>
                <tr>
                  <b>Name:</b> {r.productGrade.productName}
                </tr>
                <tr>
                  <b>Code:</b> {r.productGrade.productCode}
                </tr>
                <tr>
                  <b>Cas No:</b> {r.productGrade.casNo}
                </tr>
                <tr>
                  <b>Alias:</b> {r.productGrade.alias}
                </tr>
                <tr>
                  <b>End Use:</b> {r.productGrade.endUse}
                </tr>
              </>
            ),
          },
          {
            value: (
              <>
                <table>
                  {r.qtySchedule.map((a, i) => (
                    <tr className='border border-blue-500'>
                      <td>{a.qty}</td>
                    </tr>
                  ))}
                </table>
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.qtySchedule.map((a, i) => (
                    <tr className='border border-blue-500'>
                      <td>{a.date || a.leadTime}</td>
                    </tr>
                  ))}
                </div>
              </>
            ),
          },
          { value: r.deliveryPer },
        ],
      })) || [];

    const EnquiryInfoCardBody: CardBody = {
      type: 'columns',
      body: EnquiryInfoData,
    };

    const EnquiryQuantityCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <EnquiryInfoCard
          body={EnquiryInfoCardBody}
          header={EnquiryInfoCardHeader}
        />

        <EnquiryQuantityInfoCard
          body={EnquiryQuantityCardBody}
          header={EnquiryQuantityInfoCardHeader}
        />
      </div>
    );
  };
}
