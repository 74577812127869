import {
  AttendanceDeviceFilter,
  AttendanceDeviceType,
  AttendanceType,
  DocumentsFilter,
  DocumentsType,
  EmployeeEducationFilter,
  EmployeeEducationType,
  EmployeeExitType,
  EmployeeFamilyFilter,
  EmployeeFamilyType,
  EmployeeFilterType,
  EmployeeProfileType,
  EmployeeRegistrationType,
  LeaveAppType,
  LeaveFilterType,
  OffSiteWorkHoursFilterType,
  OffSiteWorkHoursType,
  PfReturnsType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeExitFilterType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-exit/fandf-settlement';
import {
  EmployeeLettersFilterType,
  EmployeeLettersType,
} from '@erp_core/erp-types/dist/types/modules/hrd/employee-letters';
import { EmployeeRegistrationFilter } from '@erp_core/erp-types/dist/types/modules/hrd/employee-registration';
import {
  GatePassFilterType,
  GatePassType,
} from '@erp_core/erp-types/dist/types/modules/hrd/gatepass';
import {
  OvertimeFilterType,
  OvertimeType,
} from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import {
  StatutoryReturnFilterType,
  StatutoryReturnType,
} from '@erp_core/erp-types/dist/types/modules/hrd/statutory-returns/statutory-returns';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { AttendanceHttpRepo } from '../datalayer/repository/http/hrd/attendance';
import { HttpEmployeeProfileRepo } from '../datalayer/repository/http/hrd/employee/profile';
import { LeavesHttpRepo } from '../datalayer/repository/http/hrd/leaves';
import { createUseAttendanceMonthlySummary } from '../hooks/hrd/attendance/use-monthly-summary';
import {
  AttendanceFilter,
  AttendanceInterface,
} from '../models/interfaces/hrd/attendance';
import { AttendanceDeviceInterface } from '../models/interfaces/hrd/attendance-interface';
import { EmployeeEducationInterface } from '../models/interfaces/hrd/employee-education-details';
import { EmployeeFamilyInterface } from '../models/interfaces/hrd/employee-family-details';
import { EmployeeLettersInterface } from '../models/interfaces/hrd/employee-letters';
import { EmployeeRegistrationInterface } from '../models/interfaces/hrd/employee-registration-details';
import { DocumentsInterface } from '../models/interfaces/hrd/employee-registration-doc';
import { EmployeeProfileInterface } from '../models/interfaces/hrd/employee/empProfile';
import { GatePassInterface } from '../models/interfaces/hrd/employee/gate-pass';
import { OvertimeInterface } from '../models/interfaces/hrd/employee/overtime';
import { ESICReturnInterface } from '../models/interfaces/hrd/esic-return/esic-return';
import { LeaveInterface } from '../models/interfaces/hrd/leave';
import { MinimumWagesInterface } from '../models/interfaces/hrd/minimumWage/minimumWage';
import { OffSiteWorkHoursInterface } from '../models/interfaces/hrd/off-site-work-hours';
import { PFReturnInterface } from '../models/interfaces/hrd/pf-returns/pf-returns';
import { PTReturnInterface } from '../models/interfaces/hrd/pt-returns/pt-returns';
import { StatutoryReturnInterface } from '../models/interfaces/hrd/statutory-return/statutory-return';
import { erpV2Api } from './admin';
import { HRD_SERVER_URI } from './server-uri';

export const employeeProfileService = new HttpEmployeeProfileRepo(
  HRD_SERVER_URI,
  erpV2Api
);

export const employeeLettersService = new GenericHttpWithLogsRepo<
  EmployeeLettersType,
  EmployeeLettersFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employees/letters',
});

export const useCombinedEmployeeLetter = createUseCombinedResource<
  EmployeeLettersType,
  EmployeeLettersFilterType,
  EmployeeLettersInterface
>({
  useService: () => employeeLettersService,
});

export const useCombinedEmployeeProfile = createUseCombinedResource<
  EmployeeProfileType,
  EmployeeFilterType,
  EmployeeProfileInterface
>({
  useService: () => employeeProfileService,
});

export const statutoryReturnService = new GenericHttpWithLogsRepo<
  StatutoryReturnType,
  StatutoryReturnFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employees/statutory-return',
});

export const useCombinedStatutoryReturn = createUseCombinedResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  StatutoryReturnInterface
>({
  useService: () => statutoryReturnService,
});

export const attendanceService = new AttendanceHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useCombinedAttendance = createUseCombinedResource<
  AttendanceType,
  AttendanceFilter,
  AttendanceInterface
>({ useService: () => attendanceService });

export const useAttendanceMonthlySummary = createUseAttendanceMonthlySummary({
  useAttendanceService: () => attendanceService,
});

export const gatePassService = new GenericHttpWithLogsRepo<
  GatePassType,
  GatePassFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/gate-passes',
});

export const useCombinedGatePass = createUseCombinedResource<
  GatePassType,
  GatePassFilterType,
  GatePassInterface
>({ useService: () => gatePassService });

export const overtimeService = new GenericHttpWithLogsRepo<
  OvertimeType,
  OvertimeFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/overtime',
});

export const useCombinedOvertime = createUseCombinedResource<
  OvertimeType,
  OvertimeFilterType,
  OvertimeInterface
>({ useService: () => overtimeService });

export const attendanceDeviceService = new GenericHttpWithLogsRepo<
  AttendanceDeviceType,
  AttendanceDeviceFilter
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/attendance/devices',
});

export const useCombinedAttendanceDevice = createUseCombinedResource<
  AttendanceDeviceType,
  AttendanceDeviceFilter,
  AttendanceDeviceInterface
>({ useService: () => attendanceDeviceService });

export const leaveService = new LeavesHttpRepo(HRD_SERVER_URI, erpV2Api);

export const useCombinedLeave = createUseCombinedResource<
  LeaveAppType,
  LeaveFilterType,
  LeaveInterface
>({ useService: () => leaveService });

export const offSiteWorkHoursService = new GenericHttpWithLogsRepo<
  OffSiteWorkHoursType,
  OffSiteWorkHoursFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/off-site-work-hours',
});

export const useCombinedOffSiteWorkHour = createUseCombinedResource<
  OffSiteWorkHoursType,
  OffSiteWorkHoursFilterType,
  OffSiteWorkHoursInterface
>({ useService: () => offSiteWorkHoursService });

export const pfReturnService = new GenericHttpWithLogsRepo<
  PfReturnsType,
  StatutoryReturnFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employees/pf-returns',
});

export const useCombinedPFReturn = createUseCombinedResource<
  PfReturnsType,
  StatutoryReturnFilterType,
  PFReturnInterface
>({
  useService: () => pfReturnService,
});

export const minimumWagesService = new GenericHttpWithLogsRepo<
  StatutoryReturnType,
  StatutoryReturnFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employees/minimum-wages',
});

export const useCombinedMinimumWage = createUseCombinedResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  MinimumWagesInterface
>({
  useService: () => minimumWagesService,
});

export const ptReturnService = new GenericHttpWithLogsRepo<
  StatutoryReturnType,
  StatutoryReturnFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employees/pt-returns',
});

export const useCombinedPTReturn = createUseCombinedResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  PTReturnInterface
>({
  useService: () => ptReturnService,
});

export const esicReturnService = new GenericHttpWithLogsRepo<
  StatutoryReturnType,
  StatutoryReturnFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employees/esic-returns',
});

export const useCombinedESICReturn = createUseCombinedResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  ESICReturnInterface
>({
  useService: () => esicReturnService,
});

export const employeeExitService = new GenericHttpWithLogsRepo<
  EmployeeExitType,
  EmployeeExitFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employees/fandF-Settlement',
});

export const employeeRegistrationService = new GenericHttpWithLogsRepo<
  EmployeeRegistrationType,
  EmployeeRegistrationFilter
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employee-registration-details',
});

export const useCombinedEmployeeRegistration = createUseCombinedResource<
  EmployeeRegistrationType,
  EmployeeRegistrationFilter,
  EmployeeRegistrationInterface
>({
  useService: () => employeeRegistrationService,
});

export const employeeDocRegistrationService = new GenericHttpWithLogsRepo<
  DocumentsType,
  DocumentsFilter
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/documents',
});

export const useCombinedDocument = createUseCombinedResource<
  DocumentsType,
  DocumentsFilter,
  DocumentsInterface
>({
  useService: () => employeeDocRegistrationService,
});

export const employeeEducationService = new GenericHttpWithLogsRepo<
  EmployeeEducationType,
  EmployeeEducationFilter
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employee-education-details',
});

export const useCombinedEmployeeEducation = createUseCombinedResource<
  EmployeeEducationType,
  EmployeeEducationFilter,
  EmployeeEducationInterface
>({
  useService: () => employeeEducationService,
});

export const employeeFamilyService = new GenericHttpWithLogsRepo<
  EmployeeFamilyType,
  EmployeeFamilyFilter
>({
  erpv2Api: erpV2Api,
  baseURI: HRD_SERVER_URI,
  resourceEndpoint: '/api/v1/employee-family-details',
});

export const useCombinedEmployeeFamily = createUseCombinedResource<
  EmployeeFamilyType,
  EmployeeFamilyFilter,
  EmployeeFamilyInterface
>({
  useService: () => employeeFamilyService,
});
