import {
  LicenseContract,
  LicenseContractDocType,
} from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedItem } from 'hooks/inventory/item/use-combined-item';
import { UseCombinedVendor } from 'hooks/inventory/purchase/vendors/use-vendor';
import { UseCombinedClient } from 'hooks/order/sales/client/use-client';
import { UseCombinedLicenseContractDocType } from 'hooks/order/work-order/license-contract-doc-type/use-license-contract-doc-type';

export type LicenseContractFormType = {
  name: string;
  shortName: string;
  description: string;
  refNo: string;
  docType: LicenseContractDocType;
  addonType: string;
  validFrom: string;
  expiresAt: string;
  partyName: string;
  partyType: string;
  item: IdName;
  client: IdName;
  vendor: IdName;
};

export const renderLicenseContractorm = ({
  useCombinedItem,
  useCombinedLicenseContractDocType,
  useCombinedClient,
  useCombinedVendor,
}: {
  useCombinedItem: UseCombinedItem;
  useCombinedLicenseContractDocType: UseCombinedLicenseContractDocType;
  useCombinedClient: UseCombinedClient;
  useCombinedVendor: UseCombinedVendor;
}) => {
  return function LicenseContractForm({
    onSave,
    data,
    isEdit,
  }: {
    onSave: any;
    data?: LicenseContract;
    isEdit: boolean;
  }) {
    const formProps: FormProps<LicenseContractFormType> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Name',
        },
        {
          property: 'shortName',
          type: 'input',
          required: true,
          label: 'Short Name',
        },
        {
          property: 'description',
          type: 'text-area',
          rows: 3,
          required: true,
          label: 'Description',
        },
        {
          property: 'refNo',
          type: 'input',
          label: 'Refference No',
        },
        {
          property: 'addonType',
          type: 'select',
          label: 'License Contract Type',
          options: [
            { text: 'Select', value: '' },
            { text: 'lic', value: 'lic' },
            { text: 'contract', value: 'contract' },
          ],
        },
        {
          property: 'docType',
          label: 'Document Type',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedLicenseContractDocType,
            onSearchValueSelect: (u: any) => {},
          },
        },
        {
          property: 'partyType',
          type: 'select',
          required: true,
          label: 'Party Type',
          options: [
            { text: 'Select', value: '' },
            { text: 'client', value: 'client' },
            { text: 'vendor', value: 'vendor' },
            { text: 'regulatory body', value: 'regulatorybody' },
          ],
        },
        {
          property: 'client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedClient,
            onSearchValueSelect: (u: any) => {},
          },
          dependentOn: (formData) => {
            return formData.partyType === 'client';
          },
        },
        {
          property: 'vendor',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedVendor,
            onSearchValueSelect: (u: any) => {},
          },
          dependentOn: (formData) => {
            return formData.partyType === 'vendor';
          },
        },
        {
          property: 'partyName',
          type: 'input',
          label: 'Party Name',
          dependentOn: (formData) => {
            return formData.partyType === 'regulatorybody';
          },
        },
        {
          property: 'validFrom',
          type: 'date',
          required: true,
          label: 'Valid From',
        },
        {
          property: 'expiresAt',
          type: 'date',
          required: true,
          label: 'Expires At',
        },

        {
          property: 'item',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedItem,
            onSearchValueSelect: (u: any) => {},
          },
        },
      ],
      initialFormState: {
        name: data?.name || '',
        description: data?.description || '',
        addonType: data?.addonType || '',
        item: data?.item || { id: '', name: '' },
        shortName: data?.shortName || '',
        refNo: data?.refNo || '',
        docType: data?.docType || { id: '', name: '' },
        validFrom: data?.validFrom || '',
        expiresAt: data?.expiresAt || '',
        partyName: data?.partyName || '',
        partyType: data?.partyType || '',
        client: data?.details?.client || { id: '', name: '' },
        vendor: data?.details?.vendor || { id: '', name: '' },
      },
      onSubmit: async (f) => {
        console.log('formdata', data);
        await onSave(({
          name: f.name || data?.name,
          description: f.description || data?.description,
          addonType: f.addonType || data?.addonType,
          item: f.item || data?.item,
          shortName: f.shortName || data?.shortName,
          refNo: f.refNo || data?.refNo,
          docType: f.docType || data?.docType,
          validFrom: f.validFrom || data?.validFrom,
          expiresAt: f.expiresAt || data?.expiresAt,
          partyName: f.partyName || data?.partyName,
          partyType: f.partyType || data?.partyType,
          details: {
            client: f.client
              ? f.client
              : data?.details?.client || { id: '', name: '' },
            vendor: f.vendor
              ? f.vendor
              : data?.details?.vendor || { id: '', name: '' },
          },
        } as unknown) as LicenseContract);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
    };

    const Form = renderFormV2<LicenseContractFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      p: FormDataType<LicenseContractFormType>
    ): LicenseContractFormType {
      return (p as unknown) as LicenseContractFormType;
    }
  };
};
