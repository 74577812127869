/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import {
  EmployeeFilterType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  GetSurveyAnswerReq,
  SurveyAnsewrsDataResponse,
} from '../../../../../models/interfaces/hrd/employee/empProfile';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpEmployeeProfileRepo extends GenericHttpWithLogsRepo<
  EmployeeProfileType,
  EmployeeFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/employees/employee-profile',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async get(id: string): Promise<EmployeeProfileType> {
    try {
      const emp = await super.get(id);
      return emp;
    } catch (e) {
      return null as any;
    }
  }

  async getSurveyAnswersReport(
    p: GetSurveyAnswerReq
  ): Promise<SurveyAnsewrsDataResponse[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/survey-ans-report/?month=${p.month}&employeeId=${p.employeeId}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }
}
