import {
  ReportsFilterType,
  ReportsType,
} from '@erp_core/erp-types/dist/modules/utility';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { ReportsInterface } from '../models/interfaces/reporting/reports';
import { erpV2Api } from './admin';
import { REPORTING_SERVER_URI } from './server-uri';

export const reportsService = new GenericHttpWithLogsRepo<
  ReportsType,
  ReportsFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: REPORTING_SERVER_URI,
  resourceEndpoint: 'api/v1/reports',
});

export const useCombinedReport = createUseCombinedResource<
  ReportsType,
  ReportsFilterType,
  ReportsInterface
>({
  useService: () => reportsService,
});
