import { Fire } from '@erp_core/erp-icons/icons/fire';
import {
  HazardClass,
  HazardClassFilterType,
} from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { CubeIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import { UseCombinedHazardGhsClass } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UsePublicTransfer } from '../../../../hooks/file-transfer/use-public-transfer';
import { renderAddHazardGhsClassForm } from './forms/ghs-class-add-form';

export const SignalWordColor: { [key: string]: string } = {
  Danger: 'bg-red-100',
  Warning: 'bg-yellow-100',
  'No Signal Word': 'bg-green-100',
};

export type RenderHazardGhsClassProps = {
  usePublicTransfer: UsePublicTransfer;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
};

export function renderHazardGhsClass({
  usePublicTransfer,
  useCombinedHazardGhsClass,
}: RenderHazardGhsClassProps): () => JSX.Element {
  return function HazardGhsClass(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      HazardClass,
      HazardClassFilterType
    >();
    const { syncSet: setHazardGhsClass } = useCombinedHazardGhsClass();

    // useEffect(() => {
    //   getHazardGhsClasses();
    //   // eslint-disable-next-line
    // }, []);

    // eslint-disable-next-line

    const handleHazardGhsClassAdd = async (data: HazardClass) => {
      await setHazardGhsClass(data);
      toast('Added successfully');
    };

    const HazardGhsClassCard = renderCardComponent();
    const HazardGhsClassCardHeader: CardHeader = {
      title: 'GHS Class',
      icon: (
        <Fire className='h-8 w-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add GHS Class',
            style: 'float-right bg-green-500 mx-1',
            behaviour: 'modal',
            modal: {
              title: 'Add GHS Class',
              content: ({ onClose }) => {
                const handleHazardGhsClassAdd = async (data: HazardClass) => {
                  await setHazardGhsClass(data);
                  toast('Added successfully');
                  onClose();
                };

                const Form = renderAddHazardGhsClassForm({
                  onSave: handleHazardGhsClassAdd,
                  usePublicTransfer,
                });
                return <Form />;
              },
            },
          },
        },
      ],
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Chapter No-Category Name' },
        { name: 'Hazard Type' },
        { name: 'GHS Chapter Name' },
        { name: 'Hazard Category' },
        { name: 'Description' },
        { name: 'Signal Word' },
      ],
    ];

    const bodyMapper = (r: HazardClass) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <div className='flex space-x-4'>
              {r.details?.symbol ? (
                <img
                  className='w-6 h-6'
                  src={r.details?.symbol}
                  alt={r.chapterNo.toString() || r.name}
                />
              ) : (
                <CubeIcon className='w-5 h-5' />
              )}
              <div className='font-bold text-blue-700 hover:text-blue-800'>
                {r.chapterNo} - {r.categoryName}
              </div>
            </div>
          ),
          link: `/system/group-settings/hazard-regulations/hazard-class/${r.id}`,
        },
        { value: r.type },
        { value: r.name },
        { value: r.categoryName || '' },
        { value: r.details?.description || '' },
        {
          value: r.details?.signalWord || '',
          style: SignalWordColor[r.details?.signalWord || ''],
        },
      ];
      return {
        rowData: { r },
        cells,
      };
    };

    const filter: Filter<HazardClassFilterType> = {
      version: 'v2',
      sortFields: [
        {
          key: 'ChapterName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'type',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'physical', value: 'physical' },
            { text: 'health', value: 'health' },
            { text: 'environment', value: 'environment' },
          ],
        },
      ],
      filterMapper: (filterSelection: HazardClassFilterType) => {
        const filterData: HazardClassFilterType = {};

        if (filterSelection.type !== 'all') {
          filterData.type = filterSelection.type;
        }

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        return filterData as HazardClassFilterType;
      },
    };

    const HazardGhsClassCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          actions={[
            {
              name: 'Edit',
              behaviour: 'modal',
              show: () => true,
              modal: {
                title: 'Edit GHS Class',
                content: ({ data: { r }, onClose }) => {
                  const Form = renderAddHazardGhsClassForm({
                    onSave: async (form) => {
                      await handleHazardGhsClassAdd(form);
                      onClose();
                    },
                    usePublicTransfer,
                  });
                  return <Form defaultData={r} />;
                },
              },
            },
          ]}
          type={{
            defaultPageSize: 10,
            type: 'paginated',
            usePaginatedResources: useCombinedHazardGhsClass,
          }}
          filter={filter}
        />
      ),
    };

    return (
      <>
        <HazardGhsClassCard
          header={HazardGhsClassCardHeader}
          body={HazardGhsClassCardBody}
        />
      </>
    );
  };
}
