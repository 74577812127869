import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';

export function renderFullNFinal({
  useCombinedEmployeeProfile,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function FullNFinal() {
    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >();
    const [properties, setProperties] = useState<
      Array<{ name: string; value: string }>
    >([]);
    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useCombinedEmployeeProfile,
      extraFilter: {
        crossGroup: 'true',
      },
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
        setProperties([
          {
            name: 'Date Of Joining',
            value:
              u.details?.workTimeLines?.find((x) => x.activity === 'joined')
                ?.date || 'not-available',
          },
          { name: 'Date of Resignation', value: '' },
          { name: 'Date of Exit', value: '' },
          { name: 'Reason of Exit', value: '' },
          { name: 'Company', value: u.company?.name || 'not-available' },
          { name: 'Gender', value: u.details?.gender || 'not-available' },
          { name: 'Cost Center', value: '' },
          { name: 'Type Of Wage', value: '' },
          { name: 'Category', value: '' },
          { name: 'Is Notice Period allowed to Serve?', value: '' },
          { name: 'Fixed Term Completed?', value: '' },
          {
            name: 'Is the Service interrupted during Service Period?',
            value: '',
          },
          { name: 'Reason of interrupted service?', value: '' },
          { name: 'Start date of No service?', value: '' },
          { name: 'End date of No service?', value: '' },
          {
            name: 'Has this employee resumed duty after a break in service?',
            value: '',
          },
          { name: 'Required notice period', value: '' },
          { name: 'Attended days of notice period', value: '' },
          { name: 'Notice period pending to be served', value: '' },
          { name: 'Fixed Basic Salary', value: '' },
          { name: 'Fixed Gross Earning', value: '' },
          { name: 'Salary Month', value: '' },
          { name: 'Salary Payable Days', value: '' },
          { name: 'Salary payable in last month', value: '' },
          { name: 'PL allowed', value: '' },
          { name: 'PL Taken', value: '' },
          { name: 'SL allowed', value: '' },
          { name: 'SL Taken', value: '' },
          { name: 'CL allowed', value: '' },
          { name: 'CL Taken', value: '' },
          { name: 'CO allowed', value: '' },
          { name: 'CO Taken', value: '' },
          { name: 'Previous Years paid bonus', value: '' },
          { name: 'Forfeited Amount from Previous Years Bonus', value: '' },
          { name: 'Forfeited Amount from Current Years Bonus', value: '' },
          { name: 'Performance Bonus', value: '' },
          { name: 'Other Recoveries From Salary', value: '' },
          { name: 'Salary Advance', value: '' },
          { name: 'Claims of other employees', value: '' },
          { name: 'Gratuity Nominee # 1', value: '' },
          {
            name:
              'Gratuity Nominee # 1 Proportion by which gratuity will be shared',
            value: '',
          },
          { name: 'Gratuity Nominee # 2', value: '' },
          {
            name:
              'Gratuity Nominee # 2 Proportion by which gratuity will be shared',
            value: '',
          },
          { name: 'Gratuity Nominee # 3', value: '' },
          {
            name:
              'Gratuity Nominee # 3 Proportion by which gratuity will be shared',
            value: '',
          },
          { name: 'Gratuity Nominee # 4', value: '' },
          {
            name:
              'Gratuity Nominee # 4 Proportion by which gratuity will be shared',
            value: '',
          },
        ]);
      },
    });

    return (
      <div>
        <Card
          header={{
            title: 'Full N Final',
            subheading: `Selected Employee: ${
              selectedEmployee?.name || 'None Selected'
            }`,
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='w-96'>
                    <SearchBox />
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {selectedEmployee ? (
                  <Table
                    header={[
                      [
                        {
                          name: 'Particulars',
                        },
                        {
                          name: 'Value',
                        },
                      ],
                    ]}
                    body={properties.map((x) => {
                      return {
                        cells: [{ value: x.name }, { value: x.value }],
                      };
                    })}
                  />
                ) : (
                  <div className='text-center'>Select Employee</div>
                )}
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
