import {
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';

export function renderAddEnquiryScheduleForm() {
  return function AddEnquiryScheduleForm({
    data,
    onClose,
  }: {
    data: any;
    onClose: () => any;
  }): JSX.Element {
    console.log('data', data);
    const Form = renderFormV2({
      onSubmit: async (form) => {
        // onSave(form);
      },
      style: 'md:w-1/2',
      mapTToU: (f) => f,
      initialFormState: {
        qtySchedule: data?.qtySchedule ? data?.qtySchedule : ([] as any),
      },
      fieldsData: [
        {
          width: 'w-full',
          property: 'qtySchedule',
          type: 'jsx',
          // dependentOn: (formData) => {
          //   return formData.documents === ;
          // },
          renderObject: ({ formData, field, onSave }) => {
            console.log('property2', formData);
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                />
              </div>
            );
          },
        },
      ],
    });

    return <Form />;
  };
}

function ObjectRender({ formData, field, onSave }): JSX.Element {
  console.log('field', field);
  const [props, setProps] = useState<any>(formData[field] || []);
  console.log('props', props);
  // const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const Table = renderTableComponent();
  // const Form = renderAddDocImplementationCompletionForm();

  const cardHeader: CardHeader = {
    title: 'Quantity',
    actions: [
      {
        type: 'button',
        button: {
          behaviour: 'modal',
          name: 'Add',
          modal: {
            title: 'Add Quantity',
            content: ({ onClose }) => {
              const Form1 = renderFormV2({
                mapTToU: (f) => f,
                button: {
                  avoidSubmitBehavior: true,
                },
                initialFormState: {
                  quantity: '',
                  date: '',
                  leadTime: '',
                  dateOrLeadTime: '',
                },
                style: 'w-1/4',
                fieldsData: [
                  {
                    property: 'quantity',
                    label: 'Quantity',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'dateOrLeadTime',
                    label: 'Date',
                    type: 'select',
                    options: [
                      { text: 'Select', value: '' },
                      { text: 'date', value: 'date' },
                      { text: 'leadTime', value: 'leadTime' },
                    ],
                    required: true,
                  },
                  {
                    property: 'date',
                    label: 'Date',
                    type: 'date',
                    dependentOn: (formData) => {
                      return formData.dateOrLeadTime === 'date';
                    },
                  },
                  {
                    property: 'leadTime',
                    label: 'Lead Time(in days)',
                    type: 'input',
                    dependentOn: (formData) => {
                      return formData.dateOrLeadTime === 'leadTime';
                    },
                  },
                ],
                onSubmit: async (form) => {
                  console.log('props', props);
                  const newProps = [...props];
                  newProps.push(form);
                  setProps(newProps);
                  onClose();
                },
              });
              return <Form1 />;
            },
          },
        },
      },
    ],
  };
  const Card = renderCardComponent();
  return (
    <div>
      <div>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </span>
        <Card
          header={cardHeader}
          body={{
            type: 'columns',
            body: [],
          }}
        />
      </div>

      <Table
        header={[[{ name: 'Quantity' }, { name: 'Date/LeadTime' }]]}
        body={(props || []).map((x) => {
          return {
            cells: [
              { value: x.quantity },
              { value: x.date || x.leadTime + ' days' },
            ],
          };
        })}
      />
    </div>
  );
}
