import { AttendanceType } from '@erp_core/erp-types/dist/modules/hrd';
import { GatePassType } from '@erp_core/erp-types/dist/types/modules/hrd/gatepass';
import { Popover } from '@headlessui/react';
import { BanknotesIcon } from '@heroicons/react/24/outline';

export function GatePassIcon({
  d,
  gatePasses,
}: {
  d: AttendanceType;
  gatePasses: GatePassType[];
}): JSX.Element {
  const employeeGatePasses = gatePasses?.filter(
    (p) => p.employee.id === d.employee.id && p.status !== 'cancelled'
  );

  return (
    <>
      {employeeGatePasses?.map((gp) => {
        return (
          <Popover className='relative' key={gp.id}>
            <Popover.Button>
              <BanknotesIcon
                className={`w-5 h-5 ${
                  gp.status === 'issued' ? 'text-blue-500' : 'text-green-500'
                }  inline`}
              />
            </Popover.Button>
            <Popover.Panel className='absolute bg-white w-64 left-1/2 z-10 mt-3  transform p-2'>
              {({ open }) => (
                <div className='flex flex-col justify-center border border-gray-100 p-2 capitalize'>
                  {gp.type} Gate Pass issued by {gp?.issuedBy.name}
                  <span>Reason: {gp?.reason}</span>
                </div>
              )}
            </Popover.Panel>
          </Popover>
        );
      })}
    </>
  );
}
