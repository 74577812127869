import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { BankDetailsType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableBody,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../../contexts/current';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { renderAddBankForm } from '../../profile-form/bank/bank-add-form';
import { renderEditBankForm } from '../../profile-form/bank/bank-edit-form';

export function renderBankDetails(
  useFileTransfer: UseFileTransfer
): ({
  id,
  employee,
  setEmployee,
}: {
  id: string;
  employee: EmployeeProfileType;
  setEmployee: (s: EmployeeProfileType) => void;
  isHr: boolean;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
}) => JSX.Element {
  const FileViewerUploader = renderFileViewerUploader();

  return function BankDetails({
    id,
    employee,
    setEmployee,
    actions,
    isHr,
  }: {
    id: string;
    employee: EmployeeProfileType;
    setEmployee: (s: EmployeeProfileType) => void;
    isHr: boolean;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }) {
    // const { get: getPrivateFile } = useFileTransfer();
    const { company, companyGroup } = useContext(CurrentContext);

    useEffect(() => {}, [employee]);

    const handleSaveEdit = async (
      idx: number,
      data: Partial<BankDetailsType>
    ) => {
      // we need to find the bank record in employee which is being edited.
      const activeBank = employee.details.bankDetails[idx];
      if (activeBank) {
        if (data.bank) {
          activeBank.bank = data.bank;
        }
        if (data.accountStatus) {
          activeBank.accountStatus = data.accountStatus;
        }
        if (data.accountNo) {
          activeBank.accountNo = data.accountNo;
        }
        if (data.branch) {
          activeBank.branch = data.branch;
        }
        if (data.ifsc) {
          activeBank.ifsc = data.ifsc;
        }
        if (data.branchCode) {
          activeBank.branchCode = data.branchCode;
        }
        if (data.micrCode) {
          activeBank.micrCode = data.micrCode;
        }
        if (data.swiftCode) {
          activeBank.swiftCode = data.swiftCode;
        }
        if (data.uploadDocument) {
          activeBank.uploadDocument = data.uploadDocument;
        }
        if (data.active) {
          activeBank.active = data.active;
        }
      }

      // Send only properties which are being affected apart from employee-id
      // ALso we can send details object partially as the backend will take care of
      // fetching the remainder unaffected properties.
      const finalData = {
        id: employee.id,
        details: {
          bankDetails: employee.details.bankDetails,
        },
      };

      try {
        setEmployee((finalData as unknown) as EmployeeProfileType);
      } catch (error) {
        toast((error as any).message);
      }
    };

    // eslint-disable-next-line
    const handleBankAdd = async (data: Partial<BankDetailsType>) => {
      const finalData = {
        id: employee.id,
        details: {
          bankDetails: [...(employee.details.bankDetails || []), data],
        },
      };

      setEmployee((finalData as unknown) as EmployeeProfileType);
    };

    const BankDetailsCard = renderCardComponent();
    const BankDetailsCardHeader: CardHeader = {
      title: 'Bank Details',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Bank',
              content: ({ onClose }) => {
                const Form = renderAddBankForm({
                  useFileTransfer,
                  employeeId: id,
                });

                return (
                  <Form
                    onSave={async (form) => {
                      handleBankAdd(form);
                      onClose();
                    }}
                    data={{
                      bank: '',
                      branch: '',
                      accountNo: '',
                      ifsc: '',
                      micrCode: '',
                      swiftCode: '',
                      accountStatus: 'pending',
                      branchCode: '',
                      uploadDocument: '',
                      active: 'no',
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const BankDetailsTable = renderTableComponent();
    const bankDetailsTableHeader: TableHeader = [
      [
        { name: 'Bank Name' },
        { name: 'Branch' },
        { name: 'Branch Code' },
        { name: 'A/C Number' },
        { name: 'IFSC' },
        { name: 'Swift Code' },
        { name: 'Micr Code' },
        { name: 'Active' },
        { name: 'Status' },
        { name: 'Document' },
        // { name: 'Actions', colSpan: 2 },
      ],
    ];
    const bankDetails = Array.isArray(employee?.details?.bankDetails)
      ? employee.details?.bankDetails
      : employee?.details?.bankDetails
      ? [employee.details?.bankDetails]
      : [];
    const BankDetailsTableBody: TableBody = bankDetails.map((f, idx) => {
      const cells: Array<TableCell> = [];
      cells.push(
        { value: f.bank },
        { value: f.branch },
        { value: f.branchCode },
        { value: f.accountNo },
        { value: f.ifsc },
        { value: f.swiftCode || '-' },
        { value: f.micrCode || '-' },
        { value: f.active },
        { value: f.accountStatus },
        {
          value:
            f.bank && f.accountNo ? (
              <FileViewerUploader
                mode={isHr ? 'upload' : 'view'}
                url={f.uploadDocument}
                useFileTransfer={useFileTransfer}
                path={`${companyGroup.id}/${company.id}/employees/bank-details/${employee.id}/${f.bank}-${f.accountNo}.pdf`.replaceAll(
                  ' ',
                  '-'
                )}
                type='private'
                onUpload={async (path) => {
                  const bankDetails = employee.details.bankDetails;
                  bankDetails[idx].uploadDocument = path.url;
                  setEmployee({
                    id: employee.id,
                    details: {
                      bankDetails: bankDetails,
                    },
                  } as any);
                }}
              />
            ) : (
              ''
            ),
          // <ArrowDownCircleIcon
          //   className="inline w-5 h-5 m-1 cursor-pointer"
          //   onClick={
          //     async () => {
          //       const res = await getPrivateFile(
          //         `${companyGroup.id}/${company.id}/employees/education-details/${id}/${f.bank}-${f.accountNo}`,true);
          //       const fName = `${f.bank}-${f.accountNo}.pdf`;

          //       downloadPdfFile({result: res, fileName: fName})
          //     }
          //   }
          // /> : ''
        }
      );

      return {
        cells,
        rowData: {
          bank: f,
          idx: idx,
        },
      };
    });
    const BankDetailsCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          {BankDetailsTableBody ? (
            <div className='w-full'>
              <BankDetailsTable
                header={bankDetailsTableHeader}
                body={BankDetailsTableBody}
                actions={[
                  {
                    auth: 'HRD:EMP-PROFILE:EDIT',
                    name: 'Edit',
                    show: () => true,
                    behaviour: 'modal',
                    modal: {
                      title: 'Edit Bank Details',
                      content: ({ data: { bank, idx }, onClose }) => {
                        const Form1 = renderEditBankForm();
                        return (
                          <Form1
                            data={bank}
                            onSave={(bankData) => handleSaveEdit(idx, bankData)}
                            onClose={onClose}
                          />
                        );
                      },
                    },
                  },
                ]}
              />{' '}
            </div>
          ) : null}
        </div>
      ),
    };

    return (
      <>
        <BankDetailsCard
          header={BankDetailsCardHeader}
          body={BankDetailsCardBody}
          auth={{ actions }}
        />
      </>
    );
  };
}
