import {
  HsnFilterType,
  HsnType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseCombinedHsn } from '../../../hooks/admin/constants/hsn/use-hsn';
import { renderAddHsnForm } from './form/add-hsn-form';

export type RenderHsnProps = {
  useCombinedHsn: UseCombinedHsn;
};

export function renderHsn({
  useCombinedHsn,
}: RenderHsnProps): () => JSX.Element {
  return function Hsn(): JSX.Element {
    const Table = renderTableWithMapperComponent<HsnType, HsnFilterType>();
    const { syncSet: setHsn } = useCombinedHsn();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Hsn',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Hsn',
            behaviour: 'modal',
            modal: {
              title: 'Add Hsn',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveHsn(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddHsnForm();

    const saveHsn = async (form: Partial<HsnType>) => {
      try {
        const finalData = {
          ...form,
        } as HsnType;
        await setHsn(finalData as HsnType);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },
        { name: 'HSN Code' },
        { name: 'GST Rate' },
        { name: 'Chapter' },
        { name: 'Cess' },
        { name: 'Effective From' },
      ],
    ];

    const bodyMapper = (r: HsnType) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.description },
        { value: r.hsnCode },
        { value: r.gstRate },
        { value: r.chapter },
        { value: r.cess },
        { value: r.effectiveFrom },
      ];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='h-full w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                defaultPageSize: 10,
                type: 'paginated',
                usePaginatedResources: useCombinedHsn,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
