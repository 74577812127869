import { Approval } from '@erp_core/erp-icons/icons/approval';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
  ValidPageSize,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCombinedActionAdmin } from '../../../hooks/admin/action-admin/use-action-admin';
import { UseCombinedAuthorization } from '../../../hooks/admin/authorization/use-authorization';
import { UseCombinedModuleAdmin } from '../../../hooks/admin/module-admin/use-modules-admin';
import { UseCombinedResourcesAdmin } from '../../../hooks/admin/use-resource-admin/use-resources-admin';
import { PaginatedData } from '../../../models/interfaces/generic-resource';
import { renderEditAuthorizationForm } from './edit-module-form';

export type RenderAuthorizationAdminProps = {
  useCombinedResourcesAdmin: UseCombinedResourcesAdmin;
  useCombinedActionAdmin: UseCombinedActionAdmin;
  useCombinedModuleAdmin: UseCombinedModuleAdmin;
  useCombinedAuthorization: UseCombinedAuthorization;
};

export function renderAuthorizationAdmin({
  useCombinedResourcesAdmin,
  useCombinedActionAdmin,
  useCombinedModuleAdmin,
  useCombinedAuthorization,
}: RenderAuthorizationAdminProps): () => JSX.Element {
  return function AuthorizationAdmin(): JSX.Element {
    const [pageState, setPageState] = useState<{
      pageNumber: number;
      pageSize: ValidPageSize;
    }>({ pageNumber: 1, pageSize: 10 });

    const {
      paginatedList: paginatedAuthorizations,
      getWithPagination,
    } = useCombinedAuthorization();

    useEffect(() => {
      getWithPagination(pageState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      getWithPagination({ ...pageState });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState]);

    const EditAuthorizationForm = renderEditAuthorizationForm({
      useCombinedAuthorization,
      closeEditModal: () => {},
      useCombinedResourcesAdmin,
      useCombinedActionAdmin,
      useCombinedModuleAdmin,
    });

    const { delete: deleteAuthorizationAdmin } = useCombinedAuthorization();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Authorization Admin',
      icon: (
        <Approval className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Authorization',
            behaviour: 'modal',
            modal: {
              title: 'Add Authorization',
              content: ({ onClose }) => {
                const auth: any = {
                  id: '',
                  name: '',
                  resource: '',
                  action: '',
                  module: '',
                };

                return <EditAuthorizationForm r={auth} onClose={onClose} />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Module' },
        { name: 'Resource' },
        { name: 'Action' },
      ],
    ];

    if (!paginatedAuthorizations?.data) return <div>LOADING</div>;

    const tableBody: TableBody =
      paginatedAuthorizations?.data.map((r) => ({
        rowData: { authorization: r },
        cells: [
          { value: r.name },
          { value: r.module.name },
          { value: r.resource.name },
          { value: r.action.name },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: (r) => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => {
                      return (
                        <EditAuthorizationForm
                          r={data.authorization}
                          onClose={onClose}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: (r) => true,
                  behaviour: 'confirm',
                  onConfirm: ({ authorization }) => {
                    return {
                      title: `Are you sure you want to delete this Authorization ${authorization.name}? This cannot be undone.`,
                      type: 'warning',
                      onConfirm: async () => {
                        deleteAuthorizationAdmin(authorization.id);
                      },
                    };
                  },
                },
              ]}
              pagination={{
                enabled: true,
                data: paginatedAuthorizations as PaginatedData<any>,
                activePage: pageState.pageNumber,
                activePageSize: pageState.pageSize,
                handlePageChange(e) {
                  getWithPagination({
                    pageNumber: e.currentPage,
                    pageSize: e.pageSize,
                  });
                  setPageState({
                    pageSize: e.pageSize,
                    pageNumber: e.currentPage,
                  });
                },
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
