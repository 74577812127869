import { Recat } from '@erp_core/erp-icons/icons/recat';
import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { CubeIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UseCombinedBatchItemGodown } from '../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseCombinedPurchaseOrder } from '../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';

type AllocateData = {
  id: string;
  referenceId: string;
  referenceType: 'bom' | 'sales-enquiry' | 'sales-order';
  quantity: number;
  currentUser: CurrentUserType;
  allocationComplete: boolean; // Send this true when no further allocation should be made for the targeted reference type.
};

export type UseAllocate = () => {
  set(data: AllocateData): Promise<boolean>;
};

export type CommonAllocateFormRequestType = {
  id: string;
  name: string;
  rawItem: {
    id: string;
    name: string;
    quantity: number;
    uom: string;
  };
};

export function CommonAllocationForm({
  bomData,
  onSave,
  onCancel,
  useCombinedPurchaseOrder,
  useAllocate,
  useCombinedBatchItemGodown,
  useAllocationResource,
  allocationResourceValueFn,
  allocationResourceQtyFn,
  allocationType,
  allocationResourceFilter,
}: {
  bomData: CommonAllocateFormRequestType;
  onSave: any;
  onCancel: any;
  useCombinedPurchaseOrder: UseCombinedPurchaseOrder;
  useAllocate: UseAllocate;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useAllocationResource: any;

  allocationResourceFilter: any;
  allocationResourceValueFn: (
    resourceArray: Array<any>,
    i: any
  ) => { id: string; quantity: number };
  allocationResourceQtyFn: (resourceArray: Array<any>, i: any) => number;

  allocationType: 'physical' | 'virtual';
}): JSX.Element {
  const {
    data: allocationResources,
    getAll,
    loading: loadingAR,
  } = useAllocationResource();
  const {
    list: allocatedBatchItems,
    getAll: allocatedGetAll,
  } = useCombinedBatchItemGodown();
  const {
    list: virtuallyAllocatedItems,
    getAll: getAllVirtuallyAllocatedItems,
  } = useCombinedPurchaseOrder();

  const [userAllotedValues, setUserAllotedValues] = useState<
    Array<{
      id: string;
      quantity: number;
    }>
  >([]);
  const [userAllotedErrors, setUserAllotedErrors] = useState<
    Array<{
      error: boolean;
      reason: string;
    }>
  >([]);

  // eslint-disable-next-line
  const { set: setAllocate } = useAllocate();

  useEffect(() => {
    getAll(allocationResourceFilter);
    allocatedGetAll({
      item_id: bomData.rawItem.id,
      reference_id: bomData.id,
    });
    getAllVirtuallyAllocatedItems({
      itemId: bomData.rawItem.id,
      referenceId: `Equal::${bomData.id}`,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allocationResources && allocatedBatchItems && virtuallyAllocatedItems) {
      const values: Array<{
        quantity: number;
        id: string;
      }> = _.times(allocationResources.length).map((i, idx) => {
        return allocationResourceValueFn(allocationResources, idx);
      });

      setUserAllotedValues(values);

      const errors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(allocationResources.length).map((i) => ({
        error: false,
        reason: '',
      }));
      setUserAllotedErrors(errors);
    }
    // eslint-disable-next-line
  }, [allocationResources, allocatedBatchItems, virtuallyAllocatedItems]);

  useEffect(() => {
    if (userAllotedValues.length && allocationResources?.length) {
      const updatedErrors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(allocationResources.length).map((i) => ({
        error: false,
        reason: '',
      }));
      // eslint-disable-next-line
      let updated: boolean = false;

      userAllotedValues.forEach((val, idx) => {
        if (
          allocationResources &&
          val.quantity > allocationResourceQtyFn(allocationResources, idx)
        ) {
          updated = true;
          updatedErrors[idx].error = true;
          updatedErrors[idx].reason = `can't exceed ${allocationResourceQtyFn(
            allocationResources,
            idx
          )}`;
        }
      });
      setUserAllotedErrors(updatedErrors);
    }
    // eslint-disable-next-line
  }, [userAllotedValues]);

  const handleSaveClick = async () => {
    console.log('save called');
    // const list = userAllotedValues.filter((u) => u.quantity !== 0);

    // for (let i = 0; i < list.length; i++) {
    //   const v = list[i];
    //   await setAllocate({
    //     ...v,
    //     referenceId: bomData.id,
    //     currentUser,
    //     referenceType: 'bom',
    //     allocationComplete:
    //       i === list.length - 1 && balanceRequiredQuantity === 0,
    //   });
    // }
    // onSave();
  };

  const availableQuantity = allocationResources?.reduce(
    (prev, curr, idx) =>
      prev + allocationResourceQtyFn(allocationResources, idx),
    0
  );
  const physicallyAllocated =
    allocatedBatchItems?.reduce((prev, curr) => prev + curr.quantity, 0) || 0;
  const virtuallyAllocatedQnt =
    virtuallyAllocatedItems?.reduce(
      (prev, curr) => prev + curr.details?.quantity || 0,
      0
    ) || 0;

  const totalAllocatedQuantity =
    userAllotedValues?.reduce((prev, curr) => prev + curr.quantity, 0) +
    physicallyAllocated +
    virtuallyAllocatedQnt;
  const balanceRequiredQuantity = userAllotedValues?.reduce(
    (prev, curr) => prev - curr.quantity,
    bomData.rawItem.quantity - physicallyAllocated - virtuallyAllocatedQnt
  );

  return (
    <div className='p-5 flex flex-col space-y-4'>
      <div className='flex'>
        <Chip
          isError={balanceRequiredQuantity < 0}
          name='Physically Allocated:'
          value={physicallyAllocated}
        />
        <Chip
          isError={balanceRequiredQuantity < 0}
          name='Virtually Allocated:'
          value={virtuallyAllocatedQnt}
        />
        <Chip
          isError={balanceRequiredQuantity < 0}
          name='Total Allocated:'
          value={totalAllocatedQuantity}
        />
      </div>

      <div className='flex flex-row'>
        <Chip
          isError={false}
          name='Total Required:'
          value={bomData.rawItem.quantity}
        />
        <Chip
          isError={false}
          name='Available:'
          value={availableQuantity || '--'}
        />
        <Chip
          isError={balanceRequiredQuantity < 0}
          name='Balance Required:'
          value={balanceRequiredQuantity}
        />
      </div>

      {balanceRequiredQuantity === 0 ? <div>Fully Allocated</div> : null}
      {balanceRequiredQuantity < 0 ? <div>Over Allocated</div> : null}

      {!loadingAR ? (
        <form>
          <Cubes
            allocationResources={allocationResources}
            userAllotedErrors={userAllotedErrors}
            userAllotedValues={userAllotedValues}
            setUserAllotedValues={setUserAllotedValues}
            allocationResourceQtyFn={allocationResourceQtyFn}
          />
          {balanceRequiredQuantity < 0 ? (
            <div className='mx-auto w-1/2 text-center'>
              <label className='block text-red-700 text-sm font-bold mb-2'>
                Total {allocationType} allocation cannot exceed{' '}
                {bomData.rawItem.quantity}
              </label>
            </div>
          ) : null}
          <div className='flex items-center mt-12 justify-end'>
            <LoadingButton
              defaultStyle='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              behaviorFn={onCancel}
              text='Cancel'
            />
            {balanceRequiredQuantity >= 0 ? (
              <button
                className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                type='button'
                disabled={!!userAllotedErrors.find((a) => a.error)}
                onClick={handleSaveClick}
              >
                {allocationType}ly Allocate
              </button>
            ) : null}
          </div>
        </form>
      ) : (
        <Recat className='h-5 inline animate-pulse mx-4' />
      )}
    </div>
  );
}

const Chip = ({
  name,
  value,
  isError,
}: {
  isError: boolean;
  value: string | number;
  name: string;
}) => {
  return (
    <div
      className={`p-1 m-1 ${isError ? 'border-2 border-red-600' : 'border'}`}
    >
      <label className='text-gray-800 text-md font-bold mb-2'>
        {name}:&nbsp;
      </label>
      <span className='text-gray-500'>{value}</span>
    </div>
  );
};

const Cubes = ({
  allocationResources,
  userAllotedErrors,
  setUserAllotedValues,
  userAllotedValues,
  allocationResourceQtyFn,
}: {
  allocationResources: any;
  userAllotedErrors: any;
  setUserAllotedValues: any;
  userAllotedValues: any;
  allocationResourceQtyFn: (resourceArray: Array<any>, i: any) => number;
}) => {
  return (
    <div className='flex flex-row flex-wrap'>
      {allocationResources?.map((i, index) => (
        <div key={index} className='border p-1 w-1/4'>
          <div
            className={`p-1 mx-auto text-center m-1 bg-blue-100 ${
              userAllotedErrors[index]?.error ? 'border-2 border-red-600' : ''
            }`}
          >
            <CubeIcon className='inline-block w-8 h-8 text-blue-500' />
            <sup className='bg-green-200 text-green-800 rounded sups text-lg'>
              {allocationResourceQtyFn(allocationResources, index)}
            </sup>
            <input
              className='mb-3 ml-1 shadow appearance-none border rounded w-14  text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='alloted'
              min='0'
              onChange={(e) => {
                const { value } = e.target;
                setUserAllotedValues((val) => {
                  const res = [...val];
                  res[index].quantity = parseInt(value || '0', 10);
                  return res;
                });
              }}
              type='number'
              value={userAllotedValues[index]?.quantity || 0}
            />
            {userAllotedErrors[index]?.error ? (
              <label className='block text-red-700 text-sm font-bold mb-2'>
                {userAllotedErrors[index]?.reason}
              </label>
            ) : null}
          </div>

          <div className='mx-auto text-center'>
            <label className='block text-blue-600 text-md font-bold'>
              {i?.name}
            </label>
            <label className='block text-gray-800 text-md font-bold mb-2'>
              {i?.godown?.name}
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};
