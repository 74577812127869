import {
  PayrollReport,
  PayrollReportFilter,
} from '@erp_core/erp-types/dist/modules/utility';
import {
  SalaryFilterType,
  SalaryType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import {
  SalaryParamFilterType,
  SalaryParamType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import {
  SalaryRevisionFilterType,
  SalaryRevisionType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { SalaryHttpRepo } from '../datalayer/repository/http/hrd/salary';
import { SalaryRevisionHttpRepo } from '../datalayer/repository/http/hrd/salary-revision';
import { PayrollReportsInterface } from '../models/interfaces/hrd/payroll-reports';
import { SalaryInterface } from '../models/interfaces/hrd/salary';
import { SalaryParamInterface } from '../models/interfaces/hrd/salary-param';
import { SalaryRevisionInterface } from '../models/interfaces/hrd/salary-revision';
import { erpV2Api } from './admin';
import { PAYROLL_SERVER_URI } from './server-uri';

export const salaryService = new SalaryHttpRepo(PAYROLL_SERVER_URI, erpV2Api);

export const useCombinedSalary = createUseCombinedResource<
  SalaryType,
  SalaryFilterType,
  SalaryInterface
>({
  useService: () => salaryService,
});

export const salaryParamService = new GenericHttpWithLogsRepo<
  SalaryParamType,
  SalaryParamFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: PAYROLL_SERVER_URI,
  resourceEndpoint: '/api/v1/salary-params',
});

export const useCombinedSalaryParam = createUseCombinedResource<
  SalaryParamType,
  SalaryParamFilterType,
  SalaryParamInterface
>({
  useService: () => salaryParamService,
});

export const salaryRevisionService = new SalaryRevisionHttpRepo(
  PAYROLL_SERVER_URI,
  erpV2Api
);

export const useCombinedSalaryRevision = createUseCombinedResource<
  SalaryRevisionType,
  SalaryRevisionFilterType,
  SalaryRevisionInterface
>({
  useService: () => salaryRevisionService,
});

export const payrollReportsService = new GenericHttpWithLogsRepo<
  PayrollReport,
  PayrollReportFilter
>({
  erpv2Api: erpV2Api,
  baseURI: PAYROLL_SERVER_URI,
  resourceEndpoint: '/api/v1/reports',
});

export const useCombinedPayrollReport = createUseCombinedResource<
  PayrollReport,
  PayrollReportFilter,
  PayrollReportsInterface
>({
  useService: () => payrollReportsService,
});
