// import { Approval } from '@erp_core/erp-icons/icons/approval';
// import { Unapproval } from '@erp_core/erp-icons/icons/unapproval';
import {
  CombinedAttDataType,
  SegregatePunchReturnType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  ArrowRightStartOnRectangleIcon,
  BoltIcon,
  CalculatorIcon,
  CheckBadgeIcon,
  ClockIcon,
  CurrencyRupeeIcon,
  TicketIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
// import {
//   BanknotesIcon,
//   BoltIcon,
//   ClockIcon,
//   CurrencyRupeeIcon,
//   UserCircleIcon,
// } from '@heroicons/react/24/outline';
// import moment from 'moment';
// import { renderAuthWrapper } from '../../../../../components/auth';
import { Approval } from '@erp_core/erp-icons/icons/approval';
// import { EmployeeAttendanceStatusMapper } from '../../../../../utils/common';
// import { formatTime } from '../../util/format-time';
// import { Chip } from '../../util/segregate-punches';

export type renderSummaryProps = {
  d: CombinedAttDataType;
  punchResult: SegregatePunchReturnType;
  punchRemarks: string;
  autoFinalizeFilter: string;
  pr:
    | {
        attendance: CombinedAttDataType;
        reason: string;
      }
    | {
        attendance: CombinedAttDataType;
      };
};

export function renderSummary(): (props: renderSummaryProps) => JSX.Element {
  return function Summary({
    punchRemarks,
    punchResult,
    d,
    pr,
    autoFinalizeFilter,
  }: renderSummaryProps): JSX.Element {
    // const empOffSiteWorkHours = d?.offSiteWorkHours?.filter(
    //   (el) => el.employee.id === d.employee.id
    // );

    return (
      <>
        <div>
          {d.summary?.map((x, idx) => (
            <div key={idx}>
              <div className='font-semibold'>
                ‣ <SummaryIcon summary={x} /> {x.topic}
              </div>
              {x.description ? (
                <div className='italic'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{x.description}
                </div>
              ) : null}
            </div>
          ))}
          {/* <AuthWrapper
            action='UI:BTN-ATTEN-FINALIZE:VIEW'
            children={
              <div className='p-1 border border-gray-200 rounded-md '>
                <div className='text-center font-semibold'>
                  Finalization Summary
                </div>
                <div className='flex flex-wrap'>
                  <EmployeeAttendanceStatusMapper
                    status={pr.attendance?.status}
                    leaves={pr.attendance?.empLeaves}
                  />
                  {!pr.attendance?.finalized &&
                  pr.attendance?.safeToAutoFinallize ? (
                    <div className='px-3 rounded-md mb-1 border bg-red-50 w-fit font-semibold border-amber-500 text-black'>
                      Auto-Finalizable{' '}
                      <BoltIcon className='text-amber-500 animate-pulse inline h-5' />
                    </div>
                  ) : null}
                  {pr.attendance.finalized &&
                  pr.attendance.details?.summary?.autofinalized ? (
                    <span className='px-3 rounded-md mb-1 border border-gray-200'>
                      <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
                      <span className='mx-1'>
                        {pr.attendance.details?.summary?.autofinalizeReason}
                      </span>
                    </span>
                  ) : null}
                  {pr.attendance.finalized ? (
                    <span className='px-3 border border-gray-200 rounded-md mb-1'>
                      <CurrencyRupeeIcon className='text-green-500 animate-ping w-4 inline' />
                      <span className='mx-1'>
                        {pr.attendance.details?.summary?.payableWorkHours} Hours
                      </span>
                    </span>
                  ) : null}
                  {pr.attendance?.details?.summary?.layoffRule?.type ? (
                    <span className='px-3 border border-gray-200 rounded-md mb-1'>
                      <span className='mx-1'>
                        Layoff Rule:{' '}
                        {pr.attendance?.details?.summary?.layoffRule?.value} (
                        {pr.attendance?.details?.summary?.layoffRule?.type})
                      </span>
                    </span>
                  ) : null}
                </div>
              </div>
            }
          />
          <div className='p-1 border border-gray-200 rounded-md '>
            <div className='text-center font-semibold'>General Summary</div>
            <div className='flex flex-wrap'>
              {d.punchResult?.ignoredPunches?.map((p) => (
                <span key={p.timeStamp} title={p.ignoreRequest?.reason}>
                  <Chip p={p} date={d.date} />
                </span>
              ))}
              {pr.attendance?.verified?.verified === 'yes' ? (
                <span
                  title={`Verified at ${moment(
                    pr.attendance?.verified?.verifiedAt
                  ).format('LLL')}`}
                  className='inline px-3 border bg-green-50 w-fit border-green-300 rounded-md text-green-800'
                >
                  <Approval className='inline w-5 h-5 stroke-green-800 stroke-0' />{' '}
                  {pr.attendance?.verified?.verifiedBy?.name}
                </span>
              ) : (
                <div
                  title='Verification pending'
                  className='px-1 border bg-red-50 w-fit border-red-300 text-red-800'
                >
                  <Unapproval className='inline w-5 h-5 stroke-red-800 stroke-0' />{' '}
                  {pr.attendance?.shiftDay?.supervisor?.name ||
                    pr.attendance?.employeeProfile?.details?.authorizations
                      ?.attendanceSupervisor?.name ||
                    'unknown'}
                </div>
              )}

              {d?.details?.summary?.ignoreOvertime ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  <div className='font-semibold text-xs'>Ignored Overtime</div>
                </div>
              ) : null}

              {d?.details?.summary?.ignorePunches ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  <div className='font-semibold text-xs'>Ignored Punches</div>
                </div>
              ) : null}

              {d?.details?.summary?.ignoreCheckinProximity ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  <div className='font-semibold text-xs'>
                    Ignored Checkin Proximity
                  </div>
                </div>
              ) : null}

              {d?.details?.summary?.payableDayPenalty ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  <div className='font-semibold text-xs'>
                    Penalty{' '}
                    <BanknotesIcon className='text-red-500 w-5 inline' />
                  </div>
                </div>
              ) : null}

              {d?.shiftDay?.details?.expectedOvertimeHours ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  <UserCircleIcon className='inline h-4 animate-pulse text-fuchsia-500' />{' '}
                  Expected to do overtime of{' '}
                  {d?.shiftDay?.details?.expectedOvertimeHours}hrs as per
                  schedule
                </div>
              ) : null}
              {d?.isLateForShift ? (
                <div className='px-3 rounded-md mb-1 border bg-red-50 w-fit font-semibold border-red-300'>
                  <ClockIcon
                    className={`text-red-800 ${
                      !d.details?.lateMark ? 'animate-ping' : ''
                    } inline h-4 mx-2`}
                  />{' '}
                  LateMark Penalty{' '}
                  {d?.details?.lateMark ? (
                    <span
                      className={
                        d.details.lateMark.status === 'approved'
                          ? 'text-red-500'
                          : 'text-green-500'
                      }
                    >
                      {d?.details.lateMark.status} by &nbsp;
                      {d?.details.lateMark.approvedBy?.name ||
                        d?.details.lateMark.rejectedBy?.name}
                    </span>
                  ) : null}
                </div>
              ) : null}
              {punchRemarks ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  <span className='font-semibold'>Punch Remarks: </span>
                  <span className='italic'>{punchRemarks}</span>
                </div>
              ) : null}
              {punchResult?.totalWorkHours ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  Total work:{' '}
                  <span className='font-bold'>
                    {formatTime(punchResult?.totalWorkHours)}
                  </span>
                </div>
              ) : null}

              {punchResult?.totalOutHours ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  Total Out:{' '}
                  <span className='font-bold'>
                    {formatTime(punchResult?.totalOutHours)}
                  </span>
                </div>
              ) : null}

              {punchResult?.overtimeHours > 0 ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1'>
                  Overtime:{' '}
                  <span className='font-bold'>
                    {formatTime(punchResult?.overtimeHours)}
                  </span>
                </div>
              ) : null}

              {d?.gatePasses && d?.gatePasses?.length > 0 ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1 flex'>
                  <div className='font-semibold text-xs mx-2'>
                    Gate Passes
                    <sup className='font-bold rounded-full aspect-square'>
                      {d?.gatePasses.length}
                    </sup>
                  </div>
                  {d?.gatePasses?.map((egp) => {
                    let outDuration = moment(
                      moment().format('YYYY-MM-DD') + 'T' + egp.entryTime
                    ).diff(
                      moment().format('YYYY-MM-DD') + 'T' + egp.exitTime,
                      'minutes'
                    );
                    let unit = 'min';
                    if (outDuration >= 60) {
                      outDuration = outDuration / 60;
                      unit = 'h';
                    }
                    return (
                      <div key={egp.id} className='border bg-green-50 px-2'>
                        <BanknotesIcon
                          title={`Gate Pass issued by ${egp?.issuedBy.name}`}
                          className='w-5 h-5 text-blue-500 inline-block'
                        />
                        <span className='bolder'>
                          {egp?.exitTime} to {egp?.entryTime}{' '}
                          <b className='font-semibold'>
                            [{outDuration.toFixed(1)}
                            {unit}]
                          </b>
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {d?.issuedOvertimeInHours ? (
                <div className='px-3 border border-gray-200 rounded-md mb-1 flex'>
                  <div className='font-semibold text-xs'>Approved Overtime</div>
                  <div className='border bg-orange-50 px-1 rounded'>
                    <ClockIcon
                      title={`Overtime issued for ${d?.issuedOvertimeInHours.toFixed(
                        1
                      )}`}
                      className='w-5 h-5 text-blue-500 inline-block'
                    />
                    {d?.issuedOvertimeInHours.toFixed(1)} hours
                  </div>
                </div>
              ) : null}
              {d?.empLeaves
                ?.filter((l) => l.leave.employee.id === d.employee.id)
                .map((l) => {
                  return (
                    <div
                      key={l.leave.id}
                      className='px-3 border border-gray-200 rounded-md mb-1'
                    >
                      {l.leave.status.includes('pending')
                        ? `${l?.leave.type} waiting for approval from ${
                            l?.leave.dateStart
                          } to ${l?.leave.dateEnd} - [${l?.count / 2} day(s)]`
                        : `${l?.leave.type} approved from ${
                            l?.leave.dateStart
                          } to ${l?.leave.dateEnd} - [${l?.count / 2} day(s)]`}
                    </div>
                  );
                })}
              {punchResult?.totalWorkHours > 0 &&
              punchResult?.totalWorkHours <= 4
                ? 'Remark: Worked less than half day'
                : null}
              {empOffSiteWorkHours
                ?.filter(
                  (l) =>
                    l.employee.id === d.employee.id && l.status !== 'cancelled'
                )
                .map((eos) => {
                  return (
                    <div
                      key={eos.id}
                      className='px-3 border border-gray-200 rounded-md mb-1'
                    >
                      {eos.status.includes('pending')
                        ? 'Waiting for approval to work off-site'
                        : `Working Off-Site from ${eos?.startTime} to ${eos?.endTime}`}
                    </div>
                  );
                })}
            </div>
          </div> */}
        </div>
      </>
    );
  };
}

function SummaryIcon({
  summary,
}: {
  summary: {
    topic: string;
    description?: string;
    icon: string;
    action?: string;
    iconColor: string;
    authorization?: {
      show: boolean;
      role: string;
      action: string;
    };
  };
}) {
  if (summary.topic === 'Supervisor Verification Pending') {
    return (
      <UserCircleIcon className='text-red-500 w-5 h-5 inline animate-pulse' />
    );
  }

  if (summary.topic.startsWith('Late mark: rejected by')) {
    return <CheckBadgeIcon className='text-green-500 w-4 h-4 inline' />;
  }

  if (summary.topic.endsWith(' arrived late for the shift')) {
    return <ClockIcon className='text-red-500 w-4 h-4 inline animate-pulse' />;
  }

  if (summary.topic.startsWith('Verified by ')) {
    return (
      <Approval className='inline w-4 h-4 text-green-500 stroke-green-500 stroke-0' />
    );
  }

  if (summary.topic.startsWith('Total Work Hours:')) {
    return <CalculatorIcon className='inline w-4 h-4' />;
  }

  if (summary.topic.startsWith('Payable Hours:')) {
    return <CurrencyRupeeIcon className='inline w-4 h-4 text-green-500' />;
  }

  if (summary.topic.startsWith('Finalized By')) {
    return <BoltIcon className='inline w-4 h-4 text-amber-500' />;
  }

  if (summary.topic.startsWith('Total Out Hours')) {
    return (
      <ArrowRightStartOnRectangleIcon className='inline w-4 h-4 text-red-500' />
    );
  }

  if (summary.topic.includes('Gatepass')) {
    return <TicketIcon className='inline w-4 h-4' />;
  }

  return <></>;
}
