import { QuestionsType } from '@erp_core/erp-types/dist/modules/utility';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { parseCronExpression } from 'cron-schedule';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseCombinedQuestionnaireQuestion } from '../../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { customQuestionRenderer, QuestionRendererProps } from '../store';
import { AnswerComponent } from './component/answer-type';

export type RenderSurveyAnswerProps = {
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
};

export type SurveyAnswerType = Array<{
  questionId: string;
  answerValue: string;
  reason?: string;
}>;
// eslint-disable-next-line
export function renderSurveyAnswerForm({
  useCombinedQuestionnaireQuestion,
}: RenderSurveyAnswerProps): (props: {
  id: string;
  date?: string;
  dynamicData?: any;
  onSave: (p: {
    topic: { id: string };
    details: {
      surveyAns: SurveyAnswerType;
    };
  }) => Promise<void>;
}) => JSX.Element {
  return function SurveyAnswers({
    id,
    dynamicData,
    onSave,
    date,
  }: {
    id: string;
    dynamicData?: any;
    onSave: (props: {
      topic: { id: string };
      details: { surveyAns: SurveyAnswerType };
    }) => Promise<void>;
    date?: string;
  }): JSX.Element {
    const {
      list: ques,
      getAll: getQuestions,
    } = useCombinedQuestionnaireQuestion();

    const [surveyAns, setSurveyAns] = useState<SurveyAnswerType>([]);
    const [questions, setQuestions] = useState<QuestionsType[]>([]);

    useEffect(() => {
      const qList: QuestionsType[] = [];
      if (ques?.length) {
        ques.forEach((q) => {
          if (q.cronExpression) {
            const finalDate = moment(
              `${moment().format('YYYY-MM-DD')}T00:00:00Z`
            ).toDate();
            const cron = parseCronExpression(q.cronExpression);
            const dates = [
              moment(cron.getNextDate(finalDate)).format('YYYY-MM-DD'),
              ...cron
                .getPrevDates(5, finalDate)
                .map((x) => moment(x).format('YYYY-MM-DD')),
            ];
            const date = moment().format('YYYY-MM-DD');
            // console.log(date, q.cronExpression,
            //   cronjsMatcher.getFutureMatches(q.cronExpression), cronjsMatcher.isTimeMatches(q.cronExpression, date))
            if (dates.includes(date)) {
              qList.push(q);
            }
          } else {
            qList.push(q);
          }
        });
      }

      setQuestions(qList);
    }, [ques]);

    useEffect(() => {
      setSurveyAns(
        (questions || []).map((x) => {
          return { questionId: x.id, answerValue: '' };
        })
      );
    }, [questions]);

    useEffect(() => {
      getQuestions({ topicId: id, order: 'asc', sortBy: 'orderNo' });
      // eslint-disable-next-line
    }, []);

    async function save() {
      onSave({
        topic: {
          id: id,
        },
        details: {
          surveyAns: surveyAns,
        },
      });
    }

    let i = 1;
    function dependentQuestionRender() {
      return (
        <div>
          {questions?.map((r, index) => {
            if (r.details.isdependentOn === 'no' || !r.details?.isdependentOn) {
              return (
                <QABlock
                  key={index}
                  r={r}
                  incrementer={i++}
                  index={index}
                  setSurveyAns={setSurveyAns}
                  surveyAns={surveyAns}
                  dynamicData={dynamicData}
                />
              );
            }
            //if the code reaches means isdependenton is yes
            const dependentQId = r.details.dependentQuestionId;
            const exAns = r.details.dependentQuestionsAnswer;
            if (!dependentQId || !exAns) {
              return <div key={index}></div>;
            }
            const currentAnsObj = surveyAns.find(
              (x) => x.questionId === dependentQId
            );
            if (currentAnsObj && currentAnsObj.answerValue === exAns) {
              return (
                <QABlock
                  key={index}
                  surveyAns={surveyAns}
                  r={r}
                  index={index}
                  dynamicData={dynamicData}
                  setSurveyAns={setSurveyAns}
                  incrementer={i++}
                />
              );
            }

            return <div key={index}></div>;
          })}
        </div>
      );
    }

    return (
      <>
        <div className='p-2 border border-gray-200 rounded-lg'>
          <div className='block tracking-wide text-gray-700 text-2xl text-center font-bold mb-2'>
            {_.first(questions)?.topic?.name} {date ? <>for {date}</> : null}
            <br />
          </div>
          <div>{dependentQuestionRender()}</div>
          <div>
            <br />
            {surveyAns.filter((x) => {
              const ques = questions?.find((y) => y.id === x.questionId);
              if (
                ques &&
                ques.details?.hasIdealAnswer &&
                x.answerValue &&
                ques.details?.idealAnswer &&
                ques.details?.idealAnswer !== x.answerValue
              ) {
                return true;
              }

              return false;
            }).length ? (
              <>
                {' '}
                <label className='italic block text-center tracking-wide text-red-700 text-xm mb-2'>
                  Seems like some of the answers are not ideal.
                  <br /> You may require to re-submit your answer later once you
                  have correct answer.
                </label>
                <br />
              </>
            ) : null}

            <div className='flex justify-center'>
              <LoadingButton
                text='Submit'
                defaultStyle='bg-green-500 text-white p-2'
                behaviourParams={{}}
                behaviorFn={save}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
}

function computeDynamicString(question: string, dynamicData) {
  return question.replace(/{(.*?)}/g, (match, key) => {
    return _.get(dynamicData, key.trim()) || match;
  });
}

// function computeDynamicAnswer(answer: string, dynamicData) {
//   return answer.replace(
//     /{(.*?)}/g,
//     (match, key) => {
//       return _.get(dynamicData, key.trim()) || match;
//     } // dynamicData[key]
//   );
// }

function QABlock({
  surveyAns,
  r,
  index,
  dynamicData,
  setSurveyAns,
  incrementer,
}: {
  surveyAns: SurveyAnswerType;
  r: QuestionsType;
  index: number;
  dynamicData: any;
  setSurveyAns: React.Dispatch<React.SetStateAction<SurveyAnswerType>>;
  incrementer: number;
}): JSX.Element {
  let bgColor = answerValidity(r, surveyAns, dynamicData);
  return (
    <div
      key={index}
      className={`p-1 border border-gray-100 rounded my-0.5 ${bgColor}`}
    >
      <label className='block tracking-wide text-black-700 text-xl capitalize mb-2 bg-slate-100'>
        <div>
          {incrementer}.{' '}
          {r.details?.isDynamic
            ? computeDynamicString(r.name, dynamicData || {})
            : r.name}
        </div>
        <div>
          {r.details?.hasIdealAnswer === 'yes' ? (
            <label className='italic block tracking-wide text-orange-700 text-sm mb-2 pl-6'>
              {' '}
              {'Ideal Answer:-  '}
              {r.details?.isDynamic
                ? computeDynamicString(
                    r.details.idealAnswer || '',
                    dynamicData || {}
                  )
                : r.details.idealAnswer}
            </label>
          ) : null}
        </div>
      </label>
      {r.details?.hasSubQuestions === 'yes' ? (
        <SubQuestionsBlock
          r={r}
          setSurveyAns={setSurveyAns}
          dynamicData={dynamicData}
          surveyAns={surveyAns}
        />
      ) : (
        <AnswerComponent
          question={r}
          surveyAns={surveyAns}
          setSurveyAns={setSurveyAns}
        />
      )}
      <br />
    </div>
  );
}

function NoRenderer(props: QuestionRendererProps) {
  return (
    <div className='text-center animate-bounce'>
      No renderer registered for {props.r.details.customQuestionRenderer}
    </div>
  );
}

function SubQuestionsBlock({
  surveyAns,
  setSurveyAns,
  dynamicData,
  r,
}: QuestionRendererProps): JSX.Element {
  const companyGroup = (r.topic as any).company_group_id; // TODO: This may go away in future
  const QuestionRenderer =
    customQuestionRenderer[companyGroup]?.find(
      (x) => x.functionName === r.details?.customQuestionRenderer
    )?.renderer || NoRenderer;
  return (
    <div>
      <QuestionRenderer
        surveyAns={surveyAns}
        setSurveyAns={setSurveyAns}
        r={r}
        dynamicData={dynamicData}
      />
    </div>
  );
}

function answerValidity(
  r: QuestionsType,
  surveyAns: SurveyAnswerType,
  dynamicData: any
) {
  if (!r.details?.hasIdealAnswer) {
    return '';
  }
  const ans = surveyAns.find((x) => r.id === x.questionId)?.answerValue;
  const answer = r.details.isDynamic
    ? computeDynamicString(r.details?.idealAnswer || '', dynamicData || {})
    : ans;
  if (r.details?.hasIdealAnswer === 'yes') {
    if (answer && answer === ans) {
      return 'bg-green-50';
    } else {
      return 'bg-red-50';
    }
  } else if (
    ['binaryWithText', 'binary'].includes(r.answerType) &&
    answer === 'yes'
  ) {
    return 'bg-green-50';
  }
  return '';
}
