import { Building } from '@erp_core/erp-icons/icons/building';
import { Company } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedCompanyAdmin } from '../../../hooks/admin/company-admin/use-company-admin';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { renderEditCompanyForm } from './edit-company-form';

export type RenderCompanyAdminProps = {
  useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
};

export function renderCompanyAdmin({
  useCombinedCompanyAdmin,
  usePublicTransfer,
}: RenderCompanyAdminProps): () => JSX.Element {
  return function CompanyAdmin(): JSX.Element {
    const { list: data, getAll: getCompanies } = useCombinedCompanyAdmin();

    useEffect(() => {
      getCompanies();
      // eslint-disable-next-line
    }, []);

    const EditCompanyForm = renderEditCompanyForm({
      useCombinedCompanyAdmin,
      usePublicTransfer,
    });

    const { delete: deleteCompanyAdmin } = useCombinedCompanyAdmin();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Company Admin Screen',
      icon: (
        <Building className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Company',
            behaviour: 'modal',
            modal: {
              title: 'Add Company',
              content: ({ onClose }) => {
                const al: Partial<Company> = {
                  id: '',
                  name: '',
                  shortName: '',
                  locations: [],
                  createdAt: '',
                  createdBy: { id: '', name: '' },
                  description: '',
                  logo: '',
                };
                return <EditCompanyForm r={al} onClose={onClose} />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Company Group' },
        { name: 'Name' },
        { name: 'Short Name' },
        { name: 'Logo' },
      ],
    ];

    const tableBody: TableBody = data
      ? data.map((r, index) => {
          const cells: Array<TableCell> = [];
          if (index === 0) {
            cells.push({ value: r.companyGroup.name, rowSpan: data.length });
          }

          cells.push(
            {
              value: r.name,
              link: `/system/admin/company/${r.id}/company-profile`,
            },
            { value: r.shortName },
            {
              value: (
                <img alt={`${r.shortName} logo`} src={r.logo} className='h-8' />
              ),
            }
          );

          return { cells, rowData: { company: r } };
        })
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: (p) => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => {
                      return (
                        <EditCompanyForm r={data.company} onClose={onClose} />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: (p) => true,
                  behaviour: 'confirm',
                  onConfirm: ({ company }) => {
                    return {
                      title: `Are you sure you want to delete this Company '${company.name}'? This cannot be undone.`,
                      type: 'warning',
                      onConfirm: async () => {
                        deleteCompanyAdmin(company.id);
                        getCompanies();
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
