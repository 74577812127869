import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { renderAddOfferForm } from '../form/create-offer';

export function renderOfferDetailsForm({
  useCombinedGrade,
}: {
  useCombinedGrade: UseCombinedGrade;
}) {
  return function OfferDetailsForm(): JSX.Element {
    const Card = renderCardComponent();

    const OfferQuantityInfoCard = renderCardComponent();

    const Form = renderAddOfferForm({ useCombinedGrade });

    const data = {
      id: '45545862131213',
      sirNo: '9890',
      company: {
        id: '534534534',
        name: 'Recat',
      },
      validityPeriod: '50',
      offerDate: '30/12/2024',
      creditPeriod: '60',
      deliveryMode: 'road',
      deliveryTerms: { id: '', name: 'CIF' },
      frieght: 'To pay basis',
      paymentTerm: { id: '', name: 'CIF' },
      hsnCode: '6464',
      insurance: 'To your accunt',
      remark: 'lmmkj',
      typeOfState: 'GST-MS-18%',
      quantityDeliverySchl: [
        {
          productGrade: {
            id: 'C51E8109-00A4-4735-8127-2C5F6220A786',
            name: '1-Amino-4-Cyclopentyl Piperazine (C-Grade)',
            casNo: '543321',
            alias: 'AMP - Crude',
            endUse: '-',
            productName: '1-Amino-4-Cyclopentyl Piperazine',
            productCode: '',
          },
          quantity: '75',
          unit: 'kg',
          price: '75',
          priceUnit: 'Rs',
          exchangeRate: '1',
          quantityPriceDetails: [
            {
              leadDays: '7',
              date: '12/01/2025',
              qty: '50',
              packingDetails: [
                {
                  packingType: {
                    id: '6546546',
                    name: 'Drum-HM-HDPE,Narrow Mouth,55.00,2.50',
                  },
                  wtPerPkg: '25.00',
                  noOfPkg: '1.00 ',
                  qty: '25.00',
                },
                {
                  packingType: {
                    id: '6546546',
                    name: 'Drum-HM-HDPE,Narrow Mouth,55.00,2.50',
                  },
                  wtPerPkg: '25.00',
                  noOfPkg: '1.00 ',
                  qty: '25.00',
                },
              ],
            },
            {
              leadDays: '7',
              date: '12/01/2025',
              qty: '25',
              packingDetails: [
                {
                  packingType: {
                    id: '6546546',
                    name: 'Drum-HM-HDPE,Narrow Mouth,55.00,2.50',
                  },
                  wtPerPkg: '25.00',
                  noOfPkg: '1.00 ',
                  qty: '25.00',
                },
              ],
            },
          ],
        },
      ],
    };

    const OfferInfoData: ColumnizedContent = [
      [
        {
          key: 'Company:',
          value: data.company?.name,
          type: 'text',
        },
        {
          key: 'Type of state:',
          value: data.typeOfState,
          type: 'text',
        },
        {
          key: 'Offer Date:',
          value: data.offerDate || '',
          type: 'text',
        },
        {
          key: 'Delivery Terms:',
          value: data.deliveryTerms.name || '',
          type: 'text',
        },
        {
          key: 'Delivery Mode:',
          value: data.deliveryMode,
          type: 'text',
        },
        {
          key: 'Insurance',
          value: data.insurance,
        },
      ],
      [
        {
          key: 'Frieght:',
          value: data.frieght || '',
          type: 'text',
        },
        {
          key: 'Payment Term:',
          value: data.paymentTerm.name || '',
          type: 'text',
        },
        {
          key: 'Credit Period:',
          value: data.creditPeriod || '',
          type: 'text',
        },
        {
          key: 'HSN Code:',
          value: data.hsnCode || '',
          type: 'text',
        },
      ],
      [
        {
          key: 'Remark:',
          value: data.remark || '',
          type: 'text',
        },
      ],
    ];

    const OfferQuantityInfoCardHeader: CardHeader = {
      title: 'Quantity Details',
    };

    const cardHeader: CardHeader = {
      title: 'Offer',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Offer',
              content: ({ onClose }) => {
                return (
                  <Form
                    onClose={onClose}
                    data={{
                      id: '',
                      company: {
                        id: '',
                        name: '',
                      },
                      sirNo: '',
                      typeOfState: '',
                      offerDate: '',
                      validityPeriod: '',
                      deliveryTerms: { id: '', name: '' },
                      deliveryMode: '',
                      insurance: '',
                      frieght: '',
                      paymentTerm: { id: '', name: '' },
                      creditPeriod: '',
                      remark: '',
                      hsnCode: '',
                      quantityDeliverySchl: [],
                    }}
                  />
                );
              },
            },
          },
        },
        {
          type: 'button',
          button: {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              size: 'normal',
              title: 'Edit',
              content: ({ onClose }) => {
                return (
                  <Form
                    data={{
                      id: data.id,
                      sirNo: data.sirNo,
                      company: { id: data.company.id, name: data.company.name },
                      typeOfState: data.typeOfState,
                      offerDate: data.offerDate,
                      validityPeriod: data.validityPeriod,
                      deliveryTerms: {
                        id: data.deliveryTerms.id,
                        name: data.deliveryTerms.name,
                      },
                      deliveryMode: data.deliveryMode,
                      insurance: data.insurance,
                      frieght: data.frieght,
                      paymentTerm: {
                        id: data.paymentTerm.id,
                        name: data.paymentTerm.name,
                      },
                      creditPeriod: data.creditPeriod,
                      remark: data.remark,
                      hsnCode: data.hsnCode,
                      quantityDeliverySchl: data.quantityDeliverySchl,
                    }}
                    onClose={onClose}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Product Grade', rowSpan: 3 },
        { name: 'Quantity', rowSpan: 3 },
        { name: 'Unit', rowSpan: 3 },
        { name: 'Price', rowSpan: 3 },
        { name: 'Price/Unit', rowSpan: 3 },
        { name: 'Exchange Rate', rowSpan: 3 },
        {
          name: 'Delivery Schedule',
          colSpan: 7,
          style: 'text-center',
        },
      ],
      [
        { name: 'LeadTime,days	', rowSpan: 2 },
        { name: 'Date', rowSpan: 2 },
        { name: 'Quantity', rowSpan: 2 },
        {
          name: 'Schedule',
          colSpan: 4,
          style: 'text-center',
        },
      ],
      [
        { name: 'Packing' },
        { name: 'Weight/Package' },
        { name: 'No of Package' },
        { name: 'Quantity' },
      ],
    ];

    const tableBody: TableBody =
      data.quantityDeliverySchl.map((r) => ({
        rowData: { authorization: r },
        cells: [
          { value: r.productGrade.name },
          { value: r.quantity },
          { value: r.unit },
          { value: r.price },
          { value: r.priceUnit },
          { value: r.exchangeRate },
          {
            value: (
              <>
                {r.quantityPriceDetails.map((a, i) => (
                  <div className='border border-blue-500'>{a.leadDays}</div>
                ))}
              </>
            ),
          },
          {
            value: (
              <>
                {r.quantityPriceDetails.map((a, i) => (
                  <div className='border border-blue-500'>{a.date}</div>
                ))}
              </>
            ),
          },
          {
            value: (
              <>
                {r.quantityPriceDetails.map((a, i) => (
                  <div className='border border-blue-500'>{a.qty}</div>
                ))}
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-800'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          <tr className='border border-blue-500'>
                            {b.packingType.name}
                          </tr>
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-500'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          {b.wtPerPkg}
                          <br />
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-500'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          {b.noOfPkg}
                          <br />
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-500'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          {b.qty}
                          <br />
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
        ],
      })) || [];

    // const OfferInfoCardBody: CardBody = {
    //   type: 'columns',
    //   body: OfferInfoData,
    // };

    const OfferQunatityCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Close Order',
                  show: () => true,
                  behaviour: 'click',
                  onClick: async () => {
                    toast('Order Closed.');
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    const cardBody: CardBody = {
      type: 'columns',
      body: OfferInfoData,
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
        <OfferQuantityInfoCard
          body={OfferQunatityCardBody}
          header={OfferQuantityInfoCardHeader}
        />
      </>
    );
  };
}
