// import { BOM } from '@erp_core/erp-icons/icons/bom';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useContext, useMemo } from 'react';
import { UserContext } from '../../../contexts/user';
import { UseCombinedUser } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedGodown } from '../../../hooks/inventory/godown-inventory/use-godown';
import { UseCombinedGodownTransfer } from '../../../hooks/inventory/godown-transfer/use-godown-transfer';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedBatchItemGodown } from '../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UsePhysicalStock } from '../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualStock } from '../../../hooks/inventory/item/stock/use-virtual-stock';
import { UseMRS } from '../../../hooks/inventory/mrs/use-mrs';
import { UseCombinedPurchaseRequisition } from '../../../hooks/inventory/purchase/purchase-requisition/use-purchase-resource';
import { UseCombinedBomProject } from '../../../hooks/order/work-order/bom-project/use-bom-project';
import { UseCombinedBom } from '../../../hooks/order/work-order/bom/use-bom';
import { BomFlowInterface } from '../../../models/interfaces/inventory/bom-flow';
import { GradeInterface } from '../../../models/interfaces/inventory/grade';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { PurchaseRequisitionInterface } from '../../../models/interfaces/order/purchase/purchase-requisition';
import { renderBOMFormV2 } from './bom-form-v2';
import { renderBOMProjectRegister } from './bom-project-register';
import { renderCommonBomTable } from './bom-register/fragments/table/table';
import { renderMRSRegister } from './mrs-register';

type RenderRequisitionListProps = {
  hooks: {
    useCombinedGrade: UseCombinedGrade;
    useCombinedGodown: UseCombinedGodown;
    useCombinedUser: UseCombinedUser;
    usePhysicalStock: UsePhysicalStock;
    useVirtualStock: UseVirtualStock;
    useCombinedPurchaseRequisition: UseCombinedPurchaseRequisition;
    useMRS: UseMRS;
    useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
    useCombinedGodownTransfer: UseCombinedGodownTransfer;
    useCombinedBom: UseCombinedBom;
    useCombinedBomProject: UseCombinedBomProject;
  };
  services: {
    purchaseRequisitionService: PurchaseRequisitionInterface;
    itemV2Service: ItemInterface;
    bomFlowService: BomFlowInterface;
    gradeService: GradeInterface;
  };
};

export function renderRequisitionList({
  hooks: {
    useCombinedGrade,
    useCombinedGodown,
    useCombinedUser,
    usePhysicalStock,
    useVirtualStock,
    useCombinedPurchaseRequisition,
    useMRS,
    useCombinedBatchItemGodown,
    useCombinedGodownTransfer,
    useCombinedBom,
    useCombinedBomProject,
  },
  services: { itemV2Service, gradeService, bomFlowService },
}: RenderRequisitionListProps): () => JSX.Element {
  const NewTabs = renderTabsV2Component();

  const NewBomRegister = renderCommonBomTable({
    type: 'approved',
    useCombinedBatchItemGodown,
    itemService: itemV2Service,
    useCombinedBomProject,
    useCombinedGrade,
    useCombinedBom,
    useCombinedPurchaseRequisition,
    useCombinedUser,
    useCombinedGodown,
  });

  const NewBomDraftRegister = renderCommonBomTable({
    type: 'draft',
    useCombinedBom,
    useCombinedGrade,
    useCombinedBomProject,
    useCombinedBatchItemGodown,
    useCombinedUser,
    useCombinedGodown,
    itemService: itemV2Service,
    useCombinedPurchaseRequisition,
  });

  // const BOMDraft = renderBOMDraft({
  //   hooks: {
  //     useBatchItemGodown,
  //     useBatchItemGodowns,
  //     usePurchaseRequisition,
  //     useGrades,
  //     useBomProjects,
  //     useGodowns,
  //     useBom,
  //     usePhysicalStock,
  //     useVirtualStock,
  //     useCurrentUser,
  //     useCombinedBom,
  //     useUsers,
  //   },
  //   service: {
  //     itemService: itemV2Service
  //   },
  // });

  const MRSRegister = renderMRSRegister({
    useMRS,
    useCombinedBatchItemGodown,
    useCombinedGodownTransfer,
    useCombinedBom,
  });

  const BOMProjectRegister = renderBOMProjectRegister({
    useCombinedBomProject,
    useCombinedUser,
  });

  const categories: DataArray = [
    { name: 'bom-project-register', Fn: BOMProjectRegister },
    { name: 'bom-register', Fn: NewBomRegister },
    { name: 'bom-draft', Fn: NewBomDraftRegister },
    { name: 'mrs-register', Fn: MRSRegister },
  ];

  return function RequisitionList(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const Card = renderCardComponent();
    const header: CardHeader = useMemo(() => {
      return {
        title: 'Material Requisitions',
        actions: [
          {
            type: 'button',
            button: {
              name: 'Add BOM',
              behaviour: 'modal',
              modal: {
                title: 'Add Bom',
                content: ({ onClose }) => {
                  const Form = renderBOMFormV2({
                    useCombinedGrade,
                    useCombinedUser,
                    useCombinedGodown,
                    useCombinedBomProject,
                    bomFlowService,
                    useCombinedBom,
                    currentUser,
                    onClose,
                    gradeService,
                  });
                  return <Form />;
                },
              },
            },
          },
        ],
      };
      // eslint-disable-next-line
    }, []);

    const body: CardBody = useMemo(() => {
      return {
        type: 'jsx-component',
        body: <NewTabs data={categories} manipulateLocation={true} />,
      };
      // eslint-disable-next-line
    }, []);

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
