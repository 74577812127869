import { Recat } from '@erp_core/erp-icons/icons/recat';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import {
  DateSelector,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedSalaryAdvance } from '../../../../hooks/hrd/salary-advance/use-salary-advance';
import { SalaryInterface } from '../../../../models/interfaces/hrd/salary';
import { findParamType, formatParam } from '../utils/generate-salary';

export function renderSimpleSalaryView({
  title,
  employee,
  salaryRevision,
  availableSalaryRevisions,
  selectedParam,
  salaryService,
  persist,
  mode,
  useCombinedSalaryAdvance,
}: {
  title: string;
  employee: EmployeeProfileType;
  salaryRevision: SalaryRevisionType;
  availableSalaryRevisions: SalaryRevisionType[];
  selectedParam: SalaryParamType;
  salaryService: SalaryInterface;
  persist: boolean;
  mode: 'real' | 'simulate' | 'recalculate' | 'salary-advance';
  useCombinedSalaryAdvance: UseCombinedSalaryAdvance;
}): (props: { date: string }) => JSX.Element {
  const Table = renderTableComponent();
  const Card = renderCardComponent();
  return function SalaryView({ date }: { date: string }): JSX.Element {
    const [finalSalaryParams, setFinalSalaryParams] = useState<{
      other: any;
      earning: any;
      deduction: any;
    }>({ other: {}, earning: {}, deduction: {} });
    const [currTab, setCurrTab] = useState<string>('earning');
    const [loading, setLoading] = useState<boolean>(false);
    const [duration, setDuration] = useState<'Monthly' | 'Annual'>('Monthly');
    const { cgSetting } = useContext(CurrentContext);
    const [salaryUntil, setSalaryUntil] = useState<string>('');
    const { syncSet: setSalaryAdvance } = useCombinedSalaryAdvance();
    const { user: currentUser } = useContext(UserContext);

    const [selectedSalRev, setSelectedSalRev] = useState<SalaryRevisionType>(
      salaryRevision
    );

    useEffect(() => {
      setLoading(true);
      if (employee.id && date && selectedSalRev?.id && selectedParam?.id) {
        salaryService
          .calculateSalary({
            employeeId: employee.id,
            month: date,
            salaryRevisionId: selectedSalRev.id,
            salaryParamId: selectedParam.id,
            mode: mode,
            persist,
            companyGroupSetting: cgSetting,
            duration,
            salaryUntil: salaryUntil ? salaryUntil : null,
          })
          .then((res) => {
            if (res.salary?.salaryParams) {
              setFinalSalaryParams(res.salary?.salaryParams);
            }

            setLoading(false);
          });
      } else {
        setLoading(false);
      }
      // eslint-disable-next-line
    }, [selectedSalRev, duration, salaryUntil]);

    return (
      <div>
        <Card
          header={{
            title: title,
            actions: [
              {
                type: 'button',
                show: () =>
                  mode === 'salary-advance' &&
                  finalSalaryParams?.[currTab]?.['employeeTakeHome'],
                button: {
                  name: 'Request Advance',
                  behaviour: 'modal',
                  modal: {
                    title: 'Request Advance',
                    content: ({ onClose }) => {
                      const value = formatParam({
                        value:
                          finalSalaryParams[currTab]['employeeTakeHome'] / 3,
                        metric: findParamType(
                          'employeeTakeHome',
                          selectedParam.details.inner,
                          'metric'
                        ),
                        scale: findParamType(
                          'employeeTakeHome',
                          selectedParam.details.inner,
                          'scale'
                        ),
                      });
                      const Form = renderFormV2({
                        initialFormState: {
                          amount: parseInt(
                            `${
                              finalSalaryParams[currTab]['employeeTakeHome'] / 3
                            }`
                          ),
                          reason: '',
                        },
                        fieldsData: [
                          {
                            property: 'amount',
                            subHeading: (
                              <div>
                                {`Note Advance Salary ${value} can be around 1/3rd of the Employee Take Home ${finalSalaryParams[currTab]['employeeTakeHome']}`}
                                ,
                              </div>
                            ),
                            type: 'number',
                            required: true,
                          },
                          {
                            property: 'reason',
                            required: true,
                            type: 'text-area',
                            rows: 3,
                          },
                        ],
                        mapTToU: (x) => x,
                        onSubmit: async (form) => {
                          console.log(form);
                          setSalaryAdvance({
                            name: '',
                            transactionId: '',
                            details: {
                              approvalRejectionReason: undefined,
                              releasedDate: undefined,
                              relasedAmount: undefined,
                            },
                            employee: employee,
                            authorizer: currentUser,
                            date: moment().format('YYYY-MM-DD'),
                            amount: parseInt(`${form.amount}`),
                            feasibleAmount: parseInt(
                              `${
                                finalSalaryParams[currTab]['employeeTakeHome'] /
                                3
                              }`
                            ),
                            reason: form.reason || '',
                            status: 'pending',
                          } as any);
                          onClose();
                          toast.success('Created Salary Advance');
                        },
                      });

                      return <Form />;
                    },
                  },
                },
              },
            ],
            subheading: (
              <div className='flex'>
                <div className='border border-gray-200 mx-2 w-1/4'>
                  <div>Duration:</div>
                  {mode === 'simulate' ? (
                    <select
                      value={duration}
                      onChange={(evt) => {
                        setDuration(evt.target.value as any);
                      }}
                    >
                      <option value='Monthly'>Monthly</option>
                      <option value='Annual'>Annual</option>
                    </select>
                  ) : (
                    <>{duration}</>
                  )}
                </div>
                <div className='border border-gray-200 mx-2 w-1/2'>
                  <div>Salary Revision:</div>
                  {!persist && availableSalaryRevisions.length ? (
                    <select
                      className='w-full'
                      value={selectedSalRev.id}
                      onChange={(evt) => {
                        const newSelected = availableSalaryRevisions.find(
                          (x) => x.id === evt.target.value
                        );

                        if (newSelected) {
                          setSelectedSalRev(newSelected);
                        }
                      }}
                    >
                      {availableSalaryRevisions.map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
                {mode === 'salary-advance' ? (
                  <div className='border border-gray-200 mx-2 text-center w-1/4'>
                    <div>Salary Until:</div>
                    <DateSelector
                      initialState={salaryUntil}
                      max={moment().format('YYYY-MM-DD')}
                      format='YYYY-MM-DD'
                      onChange={(date) => setSalaryUntil(date)}
                    />
                  </div>
                ) : null}
              </div>
            ),
          }}
          body={{
            type: 'jsx-component',
            body: (
              <>
                {!selectedSalRev ? (
                  <div>No Salary revision for {employee.name}</div>
                ) : (
                  <>
                    {loading ? (
                      <div className='flex justify-center'>
                        <Recat className='h-3 z-50' />
                      </div>
                    ) : (
                      <>
                        <div className='w-full'>
                          <div className='flex w-full'>
                            <div
                              onClick={() => setCurrTab('earning')}
                              className={`text-center border border-gray-200 p-1 basis-1/3 ${
                                currTab === 'earning'
                                  ? 'bg-blue-300 text-white'
                                  : 'bg-white text-black'
                              } cursor-pointer`}
                            >
                              Earning
                            </div>
                            <div
                              onClick={() => setCurrTab('deduction')}
                              className={`text-center border border-gray-200 p-1 basis-1/3 ${
                                currTab === 'deduction'
                                  ? 'bg-blue-300 text-white'
                                  : 'bg-white text-black'
                              } cursor-pointer`}
                            >
                              Deduction
                            </div>
                            <div
                              onClick={() => setCurrTab('other')}
                              className={`text-center border border-gray-200 p-1 basis-1/3 ${
                                currTab === 'other'
                                  ? 'bg-blue-300 text-white'
                                  : 'bg-white text-black'
                              } cursor-pointer`}
                            >
                              Hidden
                            </div>
                          </div>
                          <div className='mx-auto w-1/2'>
                            <Table
                              header={[
                                [{ name: 'Particular' }, { name: 'Value' }],
                              ]}
                              body={_.keys(finalSalaryParams[currTab]).map(
                                (key) => {
                                  const value = formatParam({
                                    value: finalSalaryParams[currTab][key],
                                    metric: findParamType(
                                      key,
                                      selectedParam.details.inner,
                                      'metric'
                                    ),
                                    scale: findParamType(
                                      key,
                                      selectedParam.details.inner,
                                      'scale'
                                    ),
                                  });
                                  return {
                                    cells: [
                                      { value: key },
                                      {
                                        value: (
                                          <>
                                            <div className='text-right'>
                                              {[
                                                'string',
                                                'number',
                                                'boolean',
                                              ].includes(typeof value)
                                                ? value
                                                : 'invalid-data'}
                                            </div>
                                          </>
                                        ),
                                      },
                                    ],
                                  };
                                }
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ),
          }}
        />
      </div>
    );
  };
}
