import {
  LeaveAppType,
  LeaveFilterType,
  LeaveType,
} from '@erp_core/erp-types/dist/types/modules/hrd/leave';
import moment from 'moment';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  ApplyLeaveResponse,
  LeaveInterface,
  RefillLeaveBankReq,
  RefillLeaveResponse,
} from '../../../../../models/interfaces/hrd/leave';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class LeavesHttpRepo
  extends GenericHttpWithLogsRepo<LeaveAppType, LeaveFilterType>
  implements LeaveInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/leaves',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getMonthLeaves(month: string): Promise<LeaveAppType[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const year = moment(month, 'YYYY-MM').format('YYYY');

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/month-leaves`,
      {
        params: {
          date: month,
          expiryDate: `${year}-12-31`,
          getAll: 'true',
          crossGroup: 'true',
        },
      }
    );

    return res.data as LeaveAppType[];
  }

  async applyLeave(data: LeaveAppType): Promise<ApplyLeaveResponse> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/apply-leave`,
      data
    );

    return res.data as ApplyLeaveResponse;
  }

  async cancelLeaves(data: {
    ids: string[];
    type: LeaveType;
    reason: string;
  }): Promise<{ success: boolean; message: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/cancel-leaves`,
      data
    );

    return res.data;
  }

  async approveLeaves(data: {
    ids: string[];
    type: LeaveType;
  }): Promise<{ success: boolean; message: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/approve-leaves`,
      data
    );

    return res.data;
  }

  async bulkWithdraw(data: {
    type: LeaveType;
    cancelLeaveIds: Array<string>;
    dateEditLeaves: Array<{
      id: string;
      dateStart: string;
      dateEnd: string;
    }>;
    reason: string;
  }): Promise<{ success: boolean; message: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/bulk-withdraw`,
      data
    );

    return res.data;
  }

  async refillLeaveBank(
    data: RefillLeaveBankReq
  ): Promise<RefillLeaveResponse> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/refill-leave-bank`,
      data
    );

    if (res.status === 200) {
      return {
        success: res.data?.success,
      } as RefillLeaveResponse;
    }
    return {
      success: false,
    } as RefillLeaveResponse;
  }

  async expireLeaves(data: {
    year: string;
    justification: string;
  }): Promise<{ success: boolean }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/expire-leaves/${data.year}`,
      {
        justification: data.justification,
      }
    );

    if (res.status === 200) {
      return {
        success: res.data.success,
      };
    }
    return {
      success: false,
    };
  }
}
