import { Recat } from '@erp_core/erp-icons/icons/recat';
import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UseCombinedItem } from '../../../../../hooks/inventory/item/use-combined-item';
import { renderEditItemEndUse } from './forms/add-and-edit-enduse';

type RenderEndUseTab = {
  setItemEnduse: (s: ItemRes) => Promise<ItemRes>;
  loading: boolean;
  itemEnduse: ItemRes;
  useCombinedItem: UseCombinedItem;
};

export function renderEndUseTab({
  loading,
  setItemEnduse,
  itemEnduse,
  useCombinedItem,
}: RenderEndUseTab) {
  return function EndUseTab() {
    type EnduseFormType = {
      id: string;
      name: string;
      description: string;
      ratio: string;
      isSystemItem: string;
      item: {
        id: string;
        name: string;
      };
    };

    const addItemEnduse = async (form: EnduseFormType) => {
      const finalData = {
        id: itemEnduse.id,
        details: {
          endUse: [
            ...(itemEnduse.details?.endUse || []),
            {
              id: form.isSystemItem === 'Yes' ? form.item.id : '',
              name: form.isSystemItem === 'Yes' ? form.item.name : form.name,
              description: form.description,
              ratio: form.ratio,
            },
          ],
        },
      };
      await setItemEnduse((finalData as unknown) as ItemRes);
    };

    const editItemEndUse = async (data, index: number) => {
      const enduse = itemEnduse.details.endUse || [];
      enduse[index] = data;
      const finalData = {
        id: itemEnduse.id,
        details: {
          endUse: enduse,
        },
      } as ItemRes;
      await setItemEnduse((finalData as unknown) as ItemRes);
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Item End Use',
      actions: [
        {
          auth: 'UI:BTN-EDIT-ITEM-ENDUSE:VIEW',
          type: 'button',
          show: () => Boolean(itemEnduse.id),
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add',
              content: ({ onClose }) => {
                const AddItemEnduseForm = renderEditItemEndUse({
                  useCombinedItem,
                });
                return (
                  <AddItemEnduseForm
                    onSubmit={async (form) => {
                      await addItemEnduse(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [{ name: 'Item Name' }, { name: 'Description ' }, { name: 'Ratio' }],
    ];

    const tableBody: TableBody =
      itemEnduse?.details?.endUse?.map((i, index) => {
        return {
          rowData: {
            endUse: i,
            index,
          },
          cells: [
            { value: i?.name || '' },
            { value: i?.description || '' },
            { value: i?.ratio || '' },
          ],
        };
      }) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: ({ endUse }) => Boolean(endUse?.id),
                  behaviour: 'modal',
                  auth: 'UI:BTN-ADD-VENDOR-ITEM:VIEW',
                  modal: {
                    title: 'Edit',
                    content: ({ data: { endUse, index }, onClose }) => {
                      const EditForm = renderEditItemEndUse({
                        useCombinedItem,
                      });

                      return (
                        <EditForm
                          data={endUse}
                          onSubmit={async (d) => {
                            await editItemEndUse(d, index);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: ({ endUse }) => Boolean(endUse?.id),
                  auth: 'UI:BTN-ADD-VENDOR-ITEM:VIEW',
                  behaviour: 'confirm',
                  onConfirm: ({ index }) => {
                    const a = itemEnduse.details?.endUse || [];
                    const enduse = [
                      ...a.slice(0, index),
                      ...a.slice(index + 1, a.length),
                    ];
                    return {
                      type: 'warning',
                      onConfirm: async () => {
                        const finalData = {
                          id: itemEnduse.id,
                          details: {
                            endUse: enduse,
                          },
                        };
                        setItemEnduse(finalData as ItemRes);
                      },
                      message: 'This action can not be reverted.',
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        {loading ? (
          <Recat className='h-5 inline animate-pulse mx-4' />
        ) : (
          <Card header={cardHeader} body={cardBody} />
        )}
      </div>
    );
  };
}
