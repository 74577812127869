import {
  PolicyFilter,
  PolicyType,
  PremiumsFilter,
  PremiumsType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { PolicyInterface } from '../models/interfaces/hrd/insurance/policy';
import { PremiumsInterface } from '../models/interfaces/hrd/insurance/premiums';
import { erpV2Api } from './admin';
import { INSURANCE_SERVER_URI } from './server-uri';

export const insurancePolicyService = new GenericHttpWithLogsRepo<
  PolicyType,
  PolicyFilter
>({
  erpv2Api: erpV2Api,
  baseURI: INSURANCE_SERVER_URI,
  resourceEndpoint: '/policies',
});

export const useCombinedPolicy = createUseCombinedResource<
  PolicyType,
  PolicyFilter,
  PolicyInterface
>({
  useService: () => insurancePolicyService,
});

export const insurancePremiumService = new GenericHttpWithLogsRepo<
  PremiumsType,
  PremiumsFilter
>({
  erpv2Api: erpV2Api,
  baseURI: INSURANCE_SERVER_URI,
  resourceEndpoint: '/premiums',
});

export const useCombinedPremium = createUseCombinedResource<
  PremiumsType,
  PremiumsFilter,
  PremiumsInterface
>({
  useService: () => insurancePremiumService,
});
