import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';

export function renderDispatchScheduleForm(): () => JSX.Element {
  return function DispatchSchedule(): JSX.Element {
    const Card = renderCardComponent();

    const Table = renderTableComponent();

    const data = [
      {
        id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
        pono: '4100023370',
        scheduleDate: '12 Jan 2025',
        quantity: '50',
        customer: { id: '2222', name: 'Dr. Reddy' },
        productGrade: {
          id: '22222',
          name: '1-Amino-4-Cyclopentyl Piperazine (C-Grade)',
        },
        status: 'Scheduled',
        company: { id: '1321321', name: 'Recat' },
      },
    ];
    const tableHeader: TableHeader = [
      [
        { name: 'Schedule Date' },
        { name: 'Po No' },
        { name: 'Customer' },
        { name: 'Product Grade' },
        { name: 'Quantity' },
        { name: 'Status' },
        { name: 'Company' },
      ],
    ];

    const tableData = (data || []).map((item) => ({
      rowData: {
        inqDtls: item,
      },
      cells: [
        { value: item.scheduleDate },
        {
          value: item.pono,
          link: `/sales/dispatch-schedules/${item.id}/oa-details`,
        },
        { value: item.customer.name },
        { value: item.productGrade.name },
        { value: item.quantity },
        { value: item.status },
        { value: item.company.name },
      ],
    }));

    const tableBody: TableBody = tableData;

    const cardHeader: CardHeader = {
      title: 'Dispatch Schedule',
      subheading: 'this page for display the list of scheduled products',
      actions: [],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} actions={[]} />,
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
