import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { EmployeeCategory } from '@erp_core/erp-types/dist/modules/constants';
import { renderTableComponent, TableCell } from '@erp_core/erp-ui-components';
import { PencilIcon } from '@heroicons/react/24/outline';
import { UseCombinedEmployeeCategory } from '../../../../../hooks/admin/constants/employee-category/use-employee-category';
import { UseCombinedEmployeeDesignation } from '../../../../../hooks/admin/constants/employee-designation/use-employee-designation';
import { renderAttendanceRuleForm3 } from './forms/rule-form-advance';
import { renderAttendanceRuleSimpleForm } from './forms/rule-form-simple';

export const renderAttendanceRules = ({
  updateAttendanceRule,
  useCombinedEmployeeCategory,
  updateAttendanceRule2,
  useCombinedEmployeeDesignation,
}: {
  updateAttendanceRule: (
    ruleName: string,
    value: { categories: UserIdName[]; designations: UserIdName[] }
  ) => void;
  updateAttendanceRule2: (
    ruleName: string,
    value: { categories: UserIdName[]; designations: UserIdName[] }
  ) => Promise<void>;
  useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
  useCombinedEmployeeDesignation: UseCombinedEmployeeDesignation;
}) => {
  return ({
    categories,
    comGrpSet,
  }: {
    categories: EmployeeCategory[];
    comGrpSet: CompanyGroupSetting;
  }) => {
    type Configs = {
      settingName: string;
      settingValue?: string;
      ruleName: string;
      level: 'simple' | 'advance';
      valueType?: 'number' | 'string';
    };

    const formatSettingCategory = (
      value: { id: string; name: string; value?: boolean | number }[],
      type: 'category' | 'designation'
    ): JSX.Element => {
      const result: Array<{
        lhs: string;
        rhs: string;
      }> = value.map((c) => {
        if (typeof c.value === 'boolean') {
          return {
            lhs:
              type === 'category'
                ? `${c.name
                    .replace('-', ' ')
                    .split(' ')
                    .splice(0, 2)
                    .map((t) => t?.toUpperCase())
                    .join('')}`
                : `${c.name
                    .replace('-', ' ')
                    .split(' ')
                    .map((t) => t[0]?.toUpperCase())
                    .join('')}`,
            rhs: '',
          };
        }
        return {
          lhs: `${c.name
            .replace('-', ' ')
            .split(' ')
            .map((t) => t[0]?.toUpperCase())
            .join('')}`,
          rhs: `${Number(c.value)}`,
        };
      });

      return (
        <>
          {result.map((x, idx) => (
            <span
              className='mx-0.5 px-0.5 border border-gray-300 rounded-md'
              key={idx}
            >
              <span className='font-semibold'>{x.lhs}</span>
              {x.rhs ? (
                <span className='italic font-light'>: {x.rhs}</span>
              ) : null}
            </span>
          ))}
        </>
      );
    };

    const format = (ruleName: string) => {
      const categoriesList = formatSettingCategory(
        comGrpSet.details.hrd?.attendanceRules?.[ruleName]?.categories || [],
        'category'
      );
      const designationList = formatSettingCategory(
        comGrpSet.details.hrd?.attendanceRules?.[ruleName]?.designations || [],
        'designation'
      );
      return ((
        <div>
          <div>
            <span className='font-semibold'>Grade: </span>
            {categoriesList}
          </div>
          <div>
            <span className='font-semibold'>Designations: </span>
            {designationList}
          </div>
        </div>
      ) as unknown) as string;
    };

    const attendanceRulesConfig: Configs[] = [
      {
        settingName: 'Track punches',
        settingValue: format('trackPunches'),
        ruleName: 'trackPunches',
        level: 'simple',
      },
      {
        settingName: 'Auto-Ignore Break Punches',
        settingValue: format('autoIgnoreBreakPunches'),
        ruleName: 'autoIgnoreBreakPunches',
        level: 'simple',
      },
      {
        settingName: 'Minimum Punches Per Day (For Tracked Employees)',
        settingValue: format('minimumPunchesPerDay'),
        ruleName: 'minimumPunchesPerDay',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Consider Lunch Break as Work Hours',
        settingValue: format('considerLunchBreakAsWorkHours'),
        ruleName: 'considerLunchBreakAsWorkHours',
        level: 'simple',
      },
      {
        settingName: 'Follow Shift time (Not flexi-hours)',
        settingValue: format('followShiftTime'),
        ruleName: 'followShiftTime',
        level: 'simple',
      },
      {
        settingName: 'Require gate pass to go out',
        settingValue: format('requireGatePassToCheckOut'),
        ruleName: 'requireGatePassToCheckOut',
        level: 'simple',
      },
      {
        settingName: 'Allow Half day',
        settingValue: format('allowHalfDay'),
        ruleName: 'allowHalfDay',
        level: 'simple',
      },
      {
        settingName: 'Count overtime',
        settingValue: format('trackOvertime'),
        ruleName: 'trackOvertime',
        level: 'simple',
      },
      {
        settingName: 'Overtime policy (Comp off)',
        settingValue: format('overTimeToCompOff'),
        ruleName: 'overTimeToCompOff',
        level: 'simple',
      },
      {
        settingName: 'Overtime Policy (add to salary)',
        settingValue: format('overTimeToSalary'),
        ruleName: 'overTimeToSalary',
        level: 'simple',
      },
      {
        settingName: 'Maximum allowed overtime in hours per months',
        settingValue: format('maxAllowedOvertimeHoursPerMonth'),
        ruleName: 'maxAllowedOvertimeHoursPerMonth',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Default General-Type Shift',
        settingValue: format('defaultGeneralTypeShift'),
        ruleName: 'defaultGeneralTypeShift',
        level: 'advance',
        valueType: 'string',
      },
      {
        settingName: 'Default Rotational-Type Shift',
        settingValue: format('defaultRotationalTypeShift'),
        ruleName: 'defaultRotationalTypeShift',
        level: 'advance',
        valueType: 'string',
      },
      {
        settingName: 'Enable Fake Punch',
        settingValue: format('enableFakePunch'),
        ruleName: 'enableFakePunch',
        level: 'simple',
      },
      {
        settingName: 'Punch Gap',
        settingValue: format('punchGap'),
        ruleName: 'punchGap',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Max Salary OT Hours',
        settingValue: format('maxSalaryOTHours'),
        ruleName: 'maxSalaryOTHours',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Max Comp-Off OT Hours',
        settingValue: format('maxCompoffOTHours'),
        ruleName: 'maxCompoffOTHours',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Worked On Location Off',
        settingValue: format('workedOnLocationOff'),
        ruleName: 'workedOnLocationOff',
        level: 'advance',
        valueType: 'string',
      },
      {
        settingName: 'Worked On Week Off',
        settingValue: format('workedOnWeekOff'),
        ruleName: 'workedOnWeekOff',
        level: 'advance',
        valueType: 'string',
      },
      {
        settingName: 'WorkHours In Hours Threshold',
        settingValue: format('workHoursInHoursThreshold'),
        ruleName: 'workHoursInHoursThreshold',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'LateMark In Minutes Threshold',
        settingValue: format('lateMarkInMinutesThreshold'),
        ruleName: 'lateMarkInMinutesThreshold',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Incomplete Shift In Minutes Threshold',
        settingValue: format('incompleteShiftInMinutesThreshold'),
        ruleName: 'incompleteShiftInMinutesThreshold',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Autofinalize Incomplete Shift Hours',
        settingValue: format('autofinalizeIncompleteShiftHours'),
        ruleName: 'autofinalizeIncompleteShiftHours',
        level: 'advance',
        valueType: 'number',
      },
      {
        settingName: 'Allow Multiple Punches Without Gatepass',
        settingValue: format('allowMultiplePunchesWithoutGatepass'),
        ruleName: 'allowMultiplePunchesWithoutGatepass',
        level: 'simple',
      },
      {
        settingName: 'Enable Break Time Punches Without Gatepass',
        settingValue: format('enableBreakTimePunchesWithoutGatepass'),
        ruleName: 'enableBreakTimePunchesWithoutGatepass',
        level: 'simple',
      },
      {
        settingName: 'Allow Ignore OT',
        settingValue: format('allowIgnoreOT'),
        ruleName: 'allowIgnoreOT',
        level: 'simple',
      },
      {
        settingName: 'Allow Ignore Checkin Proximity',
        settingValue: format('allowIgnoreCheckinProximity'),
        ruleName: 'allowIgnoreCheckinProximity',
        level: 'simple',
      },
      {
        settingName: 'Penalize Uninformed Absent',
        settingValue: format('penalizeUninformedAbsent'),
        ruleName: 'penalizeUninformedAbsent',
        level: 'simple',
      },
      {
        settingName: 'Flexy Worked On Week-Off',
        settingValue: format('flexyWorkedOnWeekOff'),
        ruleName: 'flexyWorkedOnWeekOff',
        level: 'advance',
        valueType: 'string',
      },
      {
        settingName: 'Flexy Worked On Location Off',
        settingValue: format('flexyWorkedOnLocationOff'),
        ruleName: 'flexyWorkedOnLocationOff',
        level: 'advance',
        valueType: 'string',
      },
      {
        settingName: 'Half Day Behaviour',
        settingValue: format('halfDayBehaviour'),
        ruleName: 'halfDayBehaviour',
        level: 'advance',
        valueType: 'string',
      },
    ];

    const Table = renderTableComponent();

    return (
      <div>
        <Table
          header={[[{ name: 'Name' }, { name: 'Applicable To' }]]}
          body={attendanceRulesConfig.map((x) => {
            return {
              cells: [
                { value: x.settingName },
                {
                  value: x.settingValue ? x.settingValue : '-',
                  button: {
                    show: () => true,
                    behaviour: 'modal',
                    icon: PencilIcon,
                    style: 'w-4 h-4 text-gray-500 cursor-pointer',
                    modal: {
                      title: x.ruleName
                        .replace(/([a-z])([A-Z])/g, '$1 $2')
                        .toUpperCase(),
                      onClose: () => {},
                      content: () => {
                        const Form =
                          x.level === 'advance' && x.valueType
                            ? renderAttendanceRuleForm3({
                                onSave: (form) =>
                                  updateAttendanceRule(x.ruleName, form),
                                useCombinedEmployeeCategory,
                                valueType: x.valueType,
                              })
                            : renderAttendanceRuleSimpleForm({
                                onSave: (form) =>
                                  updateAttendanceRule2(x.ruleName, form),
                                useCombinedEmployeeCategory,
                                useCombinedEmployeeDesignation,
                              });
                        return (
                          <Form
                            comGrpSet={comGrpSet}
                            ruleName={x.ruleName}
                            categories={categories || []}
                          />
                        );
                      },
                    },
                  },
                } as TableCell,
              ],
            };
          })}
        />
        {/* <CGConfigRenderer configs={attendanceRulesConfig} loading={false} /> */}
      </div>
    );
  };
};
