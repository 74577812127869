import { OrderAcceptanceDetails } from '@erp_core/erp-types/dist/types/modules/order/sales/sales-register/order-acceptance-details';
import {
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableActionsType,
} from '@erp_core/erp-ui-components';
import { useCombinedCompanyAdmin } from 'config/admin';
import { useState } from 'react';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { renderAddOAScheduleForm } from '../component/schedule-form/order-acceptance-schedule';

// eslint-disable-next-line
export function renderAddOrderAcceptanceForm({
  useCombinedGrade,
}: {
  useCombinedGrade: UseCombinedGrade;
}) {
  return function AddOrderAcceptanceForm({
    data,
    onClose,
  }: {
    data: OrderAcceptanceDetails;
    onClose: () => any;
  }): JSX.Element {
    console.log('data', data);
    const Form = renderFormV2({
      onSubmit: async (form) => {
        // onSave(form);
      },
      style: 'md:w-1/2',
      mapTToU: (f) => f,
      initialFormState: {
        company: data?.company || '',
        date: data?.date || '',
        deliverTo: data?.deliverTo || '',
        remark: data?.remark || '',
        deliverToAdd: data?.deliverToAdd || '',
        invoiceTo: data?.invoiceTo || '',
        invoiceToAdd: data?.invoiceToAdd || '',
        poNo: data?.poNo || '',
        quantityDeliverySchl: data?.quantityDeliverySchl
          ? data?.quantityDeliverySchl
          : ([] as any),
      },
      fieldsData: [
        {
          property: 'poNo',
          label: 'PO No',
          type: 'input',
        },
        {
          property: 'date',
          label: 'Date',
          type: 'input',
        },
        {
          property: 'company',
          label: 'Company',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedCompanyAdmin,
            onSearchValueSelect: () => null,
          },
        },
        {
          property: 'date',
          label: 'Date',
          type: 'input',
        },
        {
          property: 'deliverTo',
          label: 'Deliver To (fetch from company master)',
          type: 'input',
        },
        {
          property: 'deliverToAdd',
          label: 'Deliver To Address',
          type: 'input',
        },
        {
          property: 'invoiceTo',
          label: 'Invoice To (fetch from company master)',
          type: 'input',
        },
        {
          property: 'invoiceToAdd',
          label: 'Invoice To Address',
          type: 'input',
        },
        {
          property: 'remark',
          label: 'Remark',
          type: 'input',
        },
        {
          width: 'w-full',
          property: 'quantityDeliverySchl',
          type: 'jsx',
          // dependentOn: (formData) => {
          //   return formData.documents === ;
          // },
          renderObject: ({ formData, field, onSave }) => {
            console.log('property2', formData);
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                  useCombinedGrade={useCombinedGrade}
                />
              </div>
            );
          },
        },
      ],
    });

    return <Form />;
  };
}

function ObjectRender({
  formData,
  field,
  onSave,
  useCombinedGrade,
}): JSX.Element {
  console.log('field', field);
  const [props, setProps] = useState<any>(formData[field] || []);
  console.log('props', props);
  // const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const Table = renderTableComponent();
  // const Form = renderAddDocImplementationCompletionForm();

  const cardHeader: CardHeader = {
    title: 'Quantity',
    actions: [
      {
        type: 'button',
        button: {
          behaviour: 'modal',
          name: 'Add',
          modal: {
            title: 'Add Quantity',
            content: ({ onClose }) => {
              const Form1 = renderFormV2({
                mapTToU: (f) => f,
                button: {
                  avoidSubmitBehavior: true,
                },
                initialFormState: {
                  productGrade: '',
                  quantity: '',
                  unit: '',
                  price: '',
                  priceUnit: '',
                  exchangeRate: '',
                },
                style: 'w-1/4',
                fieldsData: [
                  {
                    property: 'productGrade',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: useCombinedGrade,
                      onSearchValueSelect: () => null,
                    },
                  },
                  {
                    property: 'quantity',
                    label: 'quantity',
                    type: 'number',
                    required: true,
                  },
                  {
                    property: 'unit',
                    label: 'unit (fetch from master)',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'price',
                    label: 'Price',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'priceUnit',
                    label: 'unit (fetch from master)',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'exchangeRate',
                    label: 'Exchange Rate',
                    type: 'input',
                    required: true,
                  },
                ],
                onSubmit: async (form) => {
                  console.log('props', props);
                  const newProps = [...props];
                  newProps.push(form);
                  setProps(newProps);
                  onClose();
                },
              });
              return <Form1 />;
            },
          },
        },
      },
    ],
  };
  const Form = renderAddOAScheduleForm({ useCombinedGrade });

  function addActions(): TableActionsType[] {
    return [
      {
        name: 'Add Schedule',
        show: ({ qty }: { qty: any }) => {
          // we want to give ability to edit only when the revision
          // is in pending state.
          if (qty) {
            return true;
          }
          return false;
        },
        behaviour: 'modal',
        modal: {
          title: 'Add schedule',
          content: ({
            data: { qty },
            onClose,
          }: {
            data: {
              qty: any;
            };
            onClose: () => any;
          }) => {
            return (
              <div>
                <Form data={undefined} onClose={onClose} />
              </div>
            );
          },
        },
      },
    ];
  }

  const Card = renderCardComponent();
  return (
    <div>
      <div>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </span>
        <Card
          header={cardHeader}
          body={{
            type: 'columns',
            body: [],
          }}
        />
      </div>

      <Table
        header={[
          [
            { name: 'Product Grade' },
            { name: 'Quantity' },
            { name: 'Unit' },
            { name: 'Price' },
            { name: 'Price/Unit' },
            { name: 'Exchange Rate' },
            { name: 'Action' },
          ],
        ]}
        body={(props || []).map((x) => {
          return {
            cells: [
              { value: x.productGrade.name },
              { value: x.quantity },
              { value: x.unit },
              { value: x.price },
              { value: x.priceUnit },
              { value: x.exchangeRate },
            ],

            rowData: {
              qty: x,
            },
          };
        })}
        actions={addActions()}
      />
    </div>
  );
}
