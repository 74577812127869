export type BidActivity = {
  value: number;
  by: string;
  time: string;
  action: 'raise' | 'bonanza-raise' | 'exit';
};

export type Tournament = {
  name: string;
  logo: string;
  venue: string;
  auctionDate: string;
  auction: {
    range: Array<{
      from: number;
      to: number;
      increment: number;
      bonanza: number;
    }>;
    categories: Array<{
      name: string;
      baseValue: number;
      lockinBudget: number;
      players: number;
      maximumBid: number;
    }>;
    teamBudget: number;
  };
  // teamBudget: {
  //   overall: number;
  // };
  description: string;
  organizer: {
    name: string;
    photo: string;
  };
  auctionedBy: {
    name: string;
    photo: string;
    contact: string;
  };
  teams: Array<Team>;
  players: Array<Player>;
};

export type Team = {
  name: string;
  logo: string;
  description: string;
  owner: {
    name: string;
    photo: string;
    description: string;
  };
  purse:
    | {
        type: 'standard';
        spent: number;
        remaining: number;
      }
    | {
        type: 'custom';
        playersToBuy: Array<{ category: string; noOfPlayers: number }>;
        totalBudget: number;
        spent: number;
        remaining: number;
      };
  players: Array<Player>;
};

export type PlayerSkills = 'batsman' | 'bowler' | 'allrounder' | 'keeper';
export type PlayerCategory = 'A+' | 'A' | 'B' | 'C';

export type Player = {
  name: string;
  photo: string;
  category: PlayerCategory;
  description: string;
  skills: PlayerSkills;
  status: 'pending' | 'sold' | 'unsold';
  team: string;
  basePrice: number;
  soldAt: number;
  bidActivities: Array<BidActivity>;
};

export type Bid = {
  value: number;
  nextBid: number;
  topBidder: string;
  secondBidder: string;
  activities: Array<BidActivity>;
};

export function formatCurrency(value) {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0,
  }).format(value);
}

export function getTeamBudget(x: Team, tournament: Tournament) {
  if (x.purse.type === 'custom') {
    return x.purse.totalBudget || 0;
  }

  return tournament.auction.teamBudget;
}

export function getPlayersToBuy(
  x: Team,
  tournament: Tournament
): {
  category: string;
  noOfPlayers: number;
}[] {
  if (x?.purse?.type === 'custom') {
    return x.purse?.playersToBuy || [];
  }

  return tournament.auction.categories.map((c) => {
    return {
      category: c.name,
      noOfPlayers: c.players,
    };
  });
}

export function getBudgetAvailability({
  team,
  category,
  tournament,
}: {
  team: Team;
  category: string;
  tournament: Tournament;
}): {
  available: number;
  locked: number;
  currentCategoryLocked: number;
} {
  const playersBoughtForThisCategory = tournament.players.filter(
    (x) =>
      x.category === category && x.team === team.name && x.status === 'sold'
  );

  const budgetSpent = tournament.players
    .filter((x) => x.team === team.name && x.status === 'sold')
    .reduce((prev, curr) => {
      return prev + curr.soldAt;
    }, 0);

  const teamTotalBudget = getTeamBudget(team, tournament);

  const categories = tournament.auction.categories;

  const playersToBuyPerCategory = getPlayersToBuy(team, tournament);
  const playerToBuyFromCurrentCategory = playersToBuyPerCategory.find(
    (x) => x.category === category
  );

  const futureCategories = categories.slice(
    categories.findIndex((z) => z.name === category) + 1
  );

  const futureCategoryLockInAmount = futureCategories.reduce((prev, curr) => {
    const noOfPlayers = playersToBuyPerCategory.find(
      (p) => p.category === curr.name
    )
      ? playersToBuyPerCategory.find((p) => p.category === curr.name)
          ?.noOfPlayers || 0
      : curr.players;

    return prev + curr.lockinBudget * noOfPlayers;
  }, 0);

  let currentCategoryLockIn = 0;

  if (playerToBuyFromCurrentCategory?.noOfPlayers) {
    if (
      playerToBuyFromCurrentCategory.noOfPlayers ===
      playersBoughtForThisCategory.length
    ) {
      // Already bought all players, so no need to lockin any amount
    } else if (
      playerToBuyFromCurrentCategory.noOfPlayers ===
      playersBoughtForThisCategory.length + 1
    ) {
      // Only one player left to be bought. so skip locking in
    } else if (
      playerToBuyFromCurrentCategory.noOfPlayers >
      playersBoughtForThisCategory.length + 1
    ) {
      // There are more players to be bought except current one. hence lockin amount
      const count =
        playerToBuyFromCurrentCategory.noOfPlayers -
        (playersBoughtForThisCategory.length + 1);
      const currentCategoryLockinBudget = categories.find(
        (c) => c.name === category
      )?.lockinBudget;
      if (currentCategoryLockinBudget) {
        currentCategoryLockIn = count * currentCategoryLockinBudget;
      }
    }
  }

  const available =
    teamTotalBudget -
    budgetSpent -
    futureCategoryLockInAmount -
    currentCategoryLockIn;

  return {
    available,
    currentCategoryLocked: currentCategoryLockIn,
    locked: futureCategoryLockInAmount,
  };
}
