import { ChangeAssesmentType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedChangeAssesment } from '../../../../hooks/quality-assurance/change-assesment/use-change-assesment';
import { impactCategories } from '../../units/impact';
import { renderEvaluationQAApprovalForm } from '../forms/evaluation-qa-approval';

type RenderAcknowledgeChangeControl = {
  useCombinedChangeAssesment: UseCombinedChangeAssesment;
};

export type ImpactTicks = {
  [key: string]: {
    yesSelected: boolean;
    noSelected: boolean;
    unknownSelected: boolean;
    remark: string;
    naSelected: boolean;
  };
};

export const renderAcknowledgeChangeControl = ({
  useCombinedChangeAssesment,
}: RenderAcknowledgeChangeControl) => {
  return function AcknowledgeChangeControl({
    id,
    onClose,
  }: {
    id: string;
    onClose: () => any;
  }) {
    const { syncSet: setChangeAssesment } = useCombinedChangeAssesment();

    const { user: currentUser } = useContext(UserContext);

    const { company, companyGroup } = useContext(CurrentContext);

    const {
      list: changeAssesments,
      getAll: getChangeAssesments,
    } = useCombinedChangeAssesment();

    useEffect(() => {
      getChangeAssesments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //   let changeAssesmentData: any;
    //   if (changeAssesments) {
    //     console.log('id', id);
    //     changeAssesmentData = changeAssesments.find((y) => y.id === id)?.details
    //       .acknowledge;
    //     console.log('changeAssesmentData', changeAssesmentData, id);

    //     // setImpactTicks(changeAssesmentData);
    //   }
    // }, changeAssesments);

    useEffect(() => {
      if (impactCategories.length) {
        const pl: ImpactTicks = {};
        let changeAssesmentData = changeAssesments?.find((y) => y.id === id)
          ?.details.acknowledge;
        for (let item of impactCategories) {
          pl[item.name] = {
            yesSelected: changeAssesmentData?.[item.name]?.yesSelected || false,
            noSelected: changeAssesmentData?.[item.name]?.noSelected || false,
            unknownSelected:
              changeAssesmentData?.[item.name]?.unknownSelected || false,
            remark: changeAssesmentData?.[item.name]?.remark || '',
            naSelected: changeAssesmentData?.[item.name].naSelected || false,
          };
        }
        setImpactTicks(pl);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impactCategories, changeAssesments]);

    const [impactTicks, setImpactTicks] = useState<ImpactTicks>({});

    const Table = renderTableComponent();

    function handleChange(item: string, propertyName: string, value: string) {
      impactTicks[item][propertyName] = value;
      setImpactTicks({
        ...impactTicks,
      });
    }

    async function submitAck() {
      try {
        const itemData = changeAssesments?.find((x) => x.id === id);
        if (itemData) {
          await setChangeAssesment(({
            id: itemData.id,
            details: {
              ...itemData?.details,
              acknowledge: impactTicks,
            },
            status: 'evaluation',
            companyGroup: {
              id: companyGroup.id,
              name: companyGroup.name,
            },
            company: {
              id: company.id,
              name: company.name,
            },
            createdBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
            lastModifiedBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
          } as unknown) as ChangeAssesmentType);

          toast('Data added sucessfully');
          onClose();
          getChangeAssesments();
        }
      } catch (error) {
        toast('Something went wrong');
      }
    }

    const tableHeader: TableHeader = [
      [
        { name: 'Impact', rowSpan: 2 },
        {
          name: 'Tick wherever required',
          colSpan: 4,
          style: 'text-center',
        },
        { name: 'Remark', rowSpan: 2 },
      ],

      [
        // Tick wherever
        {
          name: 'Yes',
          style: 'border border-l-gray-150',
        },
        { name: 'No' },
        { name: 'Unknown' },
        { name: 'NA' },
      ],
    ];

    const tableData =
      impactCategories?.map((item) => {
        return {
          cells: [
            { value: item?.name },

            {
              value: (
                <input
                  type='checkbox'
                  key={item.name + '-yesSelected'}
                  name={item.name + '-yesSelected'}
                  checked={impactTicks[item?.name]?.yesSelected}
                  onChange={(e) => {
                    // e.preventDefault();
                    const newImpactTicks = { ...impactTicks };
                    if (!newImpactTicks[item.name]) {
                      newImpactTicks[item.name] = {
                        naSelected: false,
                        noSelected: false,
                        remark: '',
                        unknownSelected: false,
                        yesSelected: false,
                      };
                    }
                    newImpactTicks[item.name]['yesSelected'] = e.target.checked;
                    setImpactTicks({
                      ...newImpactTicks,
                    });
                  }}
                />
              ),
            },
            {
              value: (
                <input
                  type='checkbox'
                  checked={impactTicks[item?.name]?.noSelected}
                  onChange={(e) => {
                    e.preventDefault();
                    impactTicks[item?.name]['noSelected'] = e.target.checked;
                    setImpactTicks({
                      ...impactTicks,
                    });
                  }}
                  id='checked'
                  name='checked'
                />
              ),
            },
            {
              value: (
                <input
                  type='checkbox'
                  checked={impactTicks[item?.name]?.unknownSelected}
                  onChange={(e) => {
                    e.preventDefault();
                    impactTicks[item?.name]['unknownSelected'] =
                      e.target.checked;
                    setImpactTicks({
                      ...impactTicks,
                    });
                  }}
                  id='checked'
                  name='checked'
                />
              ),
            },
            {
              value: (
                <input
                  type='checkbox'
                  checked={impactTicks[item?.name]?.naSelected}
                  onChange={(e) => {
                    e.preventDefault();
                    impactTicks[item?.name]['naSelected'] = e.target.checked;
                    setImpactTicks({
                      ...impactTicks,
                    });
                  }}
                  id='checked'
                  name='checked'
                />
              ),
            },
            {
              value: (
                <input
                  type='text'
                  defaultValue={impactTicks[item?.name]?.remark}
                  className='border border-black text-left h-50 w-50'
                  onBlur={(e) => {
                    handleChange(item?.name, 'remark', e.target.value);
                  }}
                ></input>
              ),
            },
          ],
        };
      }) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title:
        'Evaluation of proposed change (to be filled by the department seeking change)',

      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'click',
            name: 'Submit',
            onClick: async () => submitAck(),
          },
        },
      ],
    };

    const hodApproval = async (
      form: any,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      const finalData = {
        id,
        ...form,
        details: {
          ...changeAssesment?.details,
        },
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const itemData = changeAssesments?.find((x) => x.id === id);
    const Form = renderEvaluationQAApprovalForm();

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table header={tableHeader} body={tableBody} />
          <div>
            <Form
              onSave={async (e) => {
                await hodApproval(
                  e,
                  itemData ? itemData.id : '',
                  itemData ? itemData : (itemData as any)
                );
                onClose();
              }}
              data={itemData as any}
              isEdit={true}
            />
          </div>
        </>
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
};
