import { Client } from '@erp_core/erp-types/dist/modules/order';
import { Registrations } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import { useContext } from 'react';
import { CurrentContext } from '../../../contexts/current';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { RenderClientBankDetails } from './bank-details';
import { RenderClientRegistration } from './registration';

type CreateItemCardType = {
  useFileTransfer: UseFileTransfer;
  client: Client;
  setClient: (s: Client) => Promise<Client>;
};

export function renderClientRegistrations({
  client,
  setClient,
  useFileTransfer,
}: CreateItemCardType): () => JSX.Element {
  return function ClientRegistrations(): JSX.Element {
    const { company, companyGroup } = useContext(CurrentContext);

    const editRegistration = (property: keyof Registrations, value: any) => {
      const saveData = { id: client.id, details: client.details };
      saveData.details = {
        ...saveData.details,
        registrations: {
          ...(client.details?.registrations || {}),
          [property]: value,
        } as Registrations,
      };
      setClient(saveData as Client);
    };

    const onEditClientBankDetails = async (form) => {
      editRegistration('bankDetails', { ...form });
    };

    return (
      <div className='w-full space-y-3'>
        <RenderClientRegistration
          data={client}
          setClient={setClient}
          useFileTransfer={useFileTransfer}
          company={company}
          companyGroup={companyGroup}
        />

        <br />

        <RenderClientBankDetails
          data={client}
          useFileTransfer={useFileTransfer}
          onEditClientBankDetails={onEditClientBankDetails}
          company={company}
          companyGroup={companyGroup}
        />
      </div>
    );
  };
}
