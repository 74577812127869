import { OfferDetails } from '@erp_core/erp-types/dist/modules/order';
import {
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableActionsType,
} from '@erp_core/erp-ui-components';
import {
  useCombinedCompanyAdmin,
  useCombinedDeliveryTerm,
  useCombinedPaymentTerm,
} from 'config/admin';
import { useCombinedClient } from 'config/order';
import { useEffect, useState } from 'react';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { renderAddOfferScheduleForm } from '../component/schedule-form/offer-schedule';

export function renderAddOfferForm({
  useCombinedGrade,
}: {
  useCombinedGrade: UseCombinedGrade;
}) {
  return function AddOfferForm({
    data,
    onClose,
  }: {
    data: OfferDetails;
    onClose: () => any;
  }): JSX.Element {
    const { list: clients, getAll: getClients } = useCombinedClient();
    const {
      list: deliveryTermsData,
      getAll: getDeliveryTerms,
    } = useCombinedDeliveryTerm();
    const {
      // list: paymentTermsData,
      getAll: getPaymentTerms,
    } = useCombinedPaymentTerm();

    useEffect(() => {
      getDeliveryTerms();
      getPaymentTerms();
      getClients();
      // eslint-disable-next-line
    }, []);

    const deliveryTerm = clients?.find(
      (x) => x.id === '88581066-DD3C-4A13-A1CA-E35D2724EFCB'
    )?.details.termsOfBusiness?.deliveryTerms;
    const paymentTerm = clients?.find(
      (x) => x.id === '88581066-DD3C-4A13-A1CA-E35D2724EFCB'
    )?.details.termsOfBusiness?.paymentTerms;
    const creditPeriod = clients?.find(
      (x) => x.id === '88581066-DD3C-4A13-A1CA-E35D2724EFCB'
    )?.details.termsOfBusiness?.creditPeriod;
    console.log('data', deliveryTerm, paymentTerm);
    const Form = renderFormV2({
      onSubmit: async (form) => {
        // onSave(form);
      },
      style: 'md:w-1/2',
      mapTToU: (f) => f,
      initialFormState: {
        company: data?.company || '',
        offerDate: data?.offerDate || '',
        creditPeriod: data?.creditPeriod || creditPeriod || '0',
        deliveryMode: data?.deliveryMode || '',
        deliveryTerms: data?.deliveryTerms || {
          id: deliveryTerm?.id,
          name: deliveryTerm?.name,
        },
        frieght: data?.frieght || '',
        modeOfEnq: data?.hsnCode || '',
        // paymentTerm: data?.paymentTerm || {
        //   id: paymentTerm?.id,
        //   name: paymentTerm?.name,
        // },
        hsnCode: data?.hsnCode || '',
        insurance: data?.insurance || '',
        remark: data?.remark || '',
        typeOfState: data?.typeOfState || '',
        quantityDeliverySchl: data?.quantityDeliverySchl
          ? data?.quantityDeliverySchl
          : ([] as any),
      },
      fieldsData: [
        {
          property: 'company',
          label: 'Company',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedCompanyAdmin,
            onSearchValueSelect: () => null,
          },
        },
        {
          property: 'typeOfState',
          label: 'Type of state (fetch Gst related per)',
          type: 'input',
        },
        {
          property: 'offerDate',
          label: 'Offer Date',
          type: 'input',
        },
        {
          property: 'creditPeriod',
          label: 'Credit Period',
          type: 'select',
          options: creditPeriod
            ? [{ text: `${creditPeriod}`, value: `${creditPeriod}` }]
            : [
                { text: '1', value: '1' },
                { text: '2', value: '2' },
                { text: '3', value: '3' },
                { text: '7', value: '7' },
                { text: '15', value: '15' },
                { text: '30', value: '30' },
                { text: '45', value: '45' },
                { text: '60', value: '60' },
                { text: '90', value: '90' },
              ],
        },
        {
          property: 'deliveryTerms',
          label: 'Delivery Terms',
          type: 'select',
          options: deliveryTerm
            ? [{ text: `${deliveryTerm?.name}`, value: `${deliveryTerm?.id}` }]
            : deliveryTermsData?.map((d) => ({
                text: d.name,
                value: d.id,
              })) || [],
        },
        {
          property: 'deliveryMode',
          label: 'Delivery Mode',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'air', value: 'air' },
            { text: 'water', value: 'water' },
            { text: 'sea', value: 'sea' },
            { text: 'courier', value: 'courier' },
          ],
        },
        // {
        //   property: 'paymentTerm',
        //   type: 'select',
        //   options: paymentTerm
        //     ? [{ text: `${paymentTerm?.name}`, value: `${paymentTerm?.id}` }]
        //     : paymentTermsData?.map((d) => ({
        //         text: d.name,
        //         value: d.id,
        //       })) || [],
        // },
        {
          property: 'insurance',
          label: 'Insurance',
          type: 'input',
        },
        {
          property: 'frieght',
          label: 'Frieght',
          type: 'input',
        },
        {
          property: 'remark',
          label: 'Remark',
          type: 'input',
        },
        {
          width: 'w-full',
          property: 'quantityDeliverySchl',
          type: 'jsx',
          // dependentOn: (formData) => {
          //   return formData.documents === ;
          // },
          renderObject: ({ formData, field, onSave }) => {
            console.log('property2', formData);
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                  useCombinedGrade={useCombinedGrade}
                />
              </div>
            );
          },
        },
      ],
    });

    return <Form />;
  };
}

function ObjectRender({
  formData,
  field,
  onSave,
  useCombinedGrade,
}): JSX.Element {
  console.log('field', field);
  const [props, setProps] = useState<any>(formData[field] || []);
  console.log('props', props);
  // const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const Table = renderTableComponent();
  const Form = renderAddOfferScheduleForm({ useCombinedGrade });

  const cardHeader: CardHeader = {
    title: 'Quantity',
    actions: [
      {
        type: 'button',
        button: {
          behaviour: 'modal',
          name: 'Add',
          modal: {
            title: 'Add Quantity',
            content: ({ onClose }) => {
              const Form1 = renderFormV2({
                mapTToU: (f) => f,
                button: {
                  avoidSubmitBehavior: true,
                },
                initialFormState: {
                  productGrade: '',
                  quantity: '',
                  unit: '',
                  price: '',
                  priceUnit: '',
                  exchangeRate: '',
                },
                style: 'w-1/4',
                fieldsData: [
                  {
                    property: 'productGrade',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: useCombinedGrade,
                      onSearchValueSelect: () => null,
                    },
                  },
                  {
                    property: 'quantity',
                    label: 'quantity',
                    type: 'number',
                    required: true,
                  },
                  {
                    property: 'unit',
                    label: 'unit (fetch from master)',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'price',
                    label: 'Price',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'priceUnit',
                    label: 'unit (fetch from master)',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'exchangeRate',
                    label: 'Exchange Rate',
                    type: 'input',
                    required: true,
                  },
                ],
                onSubmit: async (form) => {
                  console.log('props', props);
                  const newProps = [...props];
                  newProps.push(form);
                  setProps(newProps);
                  onClose();
                },
              });
              return <Form1 />;
            },
          },
        },
      },
    ],
  };

  function addActions(): TableActionsType[] {
    return [
      {
        name: 'Add Schedule',
        show: ({ qty }: { qty: any }) => {
          // we want to give ability to edit only when the revision
          // is in pending state.
          if (qty) {
            return true;
          }
          return false;
        },
        behaviour: 'modal',
        modal: {
          title: 'Add schedule',
          content: ({
            data: { qty },
            onClose,
          }: {
            data: {
              qty: any;
            };
            onClose: () => any;
          }) => {
            return (
              <div>
                <Form data={undefined} onClose={onClose} />
              </div>
            );
          },
        },
      },
    ];
  }
  const Card = renderCardComponent();
  return (
    <div>
      <div>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </span>
        <Card
          header={cardHeader}
          body={{
            type: 'columns',
            body: [],
          }}
        />
      </div>

      <Table
        header={[
          [
            { name: 'Product Grade' },
            { name: 'Quantity' },
            { name: 'Unit' },
            { name: 'Price' },
            { name: 'Price/Unit' },
            { name: 'Exchange Rate' },
            { name: 'Action' },
          ],
        ]}
        body={(props || []).map((x) => {
          return {
            cells: [
              { value: x.productGrade.name },
              { value: x.quantity },
              { value: x.unit },
              { value: x.price },
              { value: x.priceUnit },
              { value: x.exchangeRate },
            ],
            rowData: {
              qty: x,
            },
          };
        })}
        actions={addActions()}
      />
    </div>
  );
}
