import {
  renderCardComponent,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { nanoid } from 'nanoid';
import { UseCombinedGodown } from '../../../hooks/inventory/godown-inventory/use-godown';
import { UseGodownsTree } from '../../../hooks/inventory/godown-inventory/use-godowns-tree';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedBatchItemGodown } from '../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedStockGroup } from '../../../hooks/inventory/stockgroup/use-stockgroup';
import { FileTransferInterface } from '../../../models/interfaces/fileTransfer/fileTransfer';
import { renderGodownItemTree } from './godown-item/godown-item-tree';
import { renderGodownTree } from './godown/godown-tree';
import { renderStockGroupItemTree } from './stockgroup-item';

type CreateInventoryTree = {
  useGodownsTree: UseGodownsTree;
  useCombinedGodown: UseCombinedGodown;
  fileTransferService: FileTransferInterface;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useCombinedStockGroup: UseCombinedStockGroup;
  useCombinedItem: UseCombinedItem;
  useCombinedGrade: UseCombinedGrade;
};

export function createInventoryTree({
  useGodownsTree,
  useCombinedStockGroup,
  useCombinedBatchItemGodown,
  useCombinedItem,
  useCombinedGodown,
  useCombinedGrade,
  fileTransferService,
}: CreateInventoryTree): () => JSX.Element {
  const Card = renderCardComponent();
  const Tabs = renderTabsComponent();
  const GodownTree = renderGodownTree({
    useGodownsTree,
    useCombinedGodown,
    fileTransferService,
  });
  const GodownItemTree = renderGodownItemTree({
    useGodownsTree,
    useCombinedBatchItemGodown,
    fileTransferService,
  });
  const StockGroupItemTree = renderStockGroupItemTree({
    useCombinedItem,
    useCombinedStockGroup,
    useCombinedGrade,
  });

  const categories: Map<string, () => JSX.Element> = new Map<
    string,
    () => JSX.Element
  >([
    ['Godown', GodownTree],
    ['Godown-Items', GodownItemTree],
    ['Stockgroup-Items', StockGroupItemTree],
  ]);

  return function InventoryTree(): JSX.Element {
    return (
      <div className='h-full'>
        <Card
          header={{
            title: 'Inventory',
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div className='h-full'>
                <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={2} />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
