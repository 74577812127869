import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import { StockGroup } from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import _ from 'lodash';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { useContext, useEffect, useState } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedStockGroup } from '../../../../hooks/inventory/stockgroup/use-stockgroup';
import { TreeIcon } from '../../../../utils/common';
import { createItemSideView } from './item-side-view';

type TreeNodeType = {
  type: string;
  key: string;
  isLeaf: boolean;
  children: Array<TreeNodeType>;
  title: string;
};

type Props = {
  useCombinedStockGroup: UseCombinedStockGroup;
  useCombinedItem: UseCombinedItem;
  useCombinedGrade: UseCombinedGrade;
};

export function renderStockGroupItemTree({
  useCombinedStockGroup,
  useCombinedItem,
  useCombinedGrade,
}: Props): () => JSX.Element {
  return function GodownTree() {
    const { list: stockGroups, getAll } = useCombinedStockGroup();

    const {
      list: items,
      getAll: getAllItems,
      get,
      resource: currentItem,
    } = useCombinedItem();

    const { list: grades, getAll: getGrades } = useCombinedGrade();

    const { location } = useContext(CurrentContext);

    useEffect(() => {
      getAll();
      getAllItems();
      getGrades();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [nodeData, setNodeData] = useState<TreeNodeType>();

    useEffect(() => {
      if (stockGroups && items && grades) {
        setNodeData(
          renderTreeNode(
            location,
            _.sortBy(stockGroups, 'name'),
            _.sortBy(items, 'name'),
            _.sortBy(grades, 'name')
          )
        );
      }
    }, [stockGroups, location, items, grades]);

    const onRightClick = (event, node) => {
      if (node && node.key !== 'root') get(node.key);
    };

    const ItemSideView = createItemSideView({ useCombinedItem });

    return (
      <div className='flex min-h-96'>
        {nodeData ? (
          <Tree
            onClick={onRightClick}
            onCheck={onRightClick}
            defaultExpandAll
            className='max-h-[600px] p-2 text-lg scrollbar-none overflow-y-auto w-3/5'
            treeData={[renderTree(nodeData)]}
          />
        ) : null}
        {currentItem && currentItem.id ? (
          <ItemSideView
            data={currentItem}
            reload={() => {
              get(currentItem.id);
              getAllItems();
              getAll();
            }}
          />
        ) : null}
      </div>
    );
  };
}

function renderTreeNode(
  locationData: LocationType,
  stockGroups: Array<StockGroup>,
  items: Array<ItemRes>,
  grades: Array<Grade>
) {
  const data: TreeNodeType = {
    type: 'root',
    key: 'root',
    isLeaf: false,
    children: [],
    title: locationData.name || 'Root',
  };

  const item = (stockGroupId: string, grades: Grade[]) => {
    return items
      .filter((i) => i.stockgroup.id === stockGroupId)
      .map((i) => {
        const filteredGrades = grades.filter((g) => g.item.id === i.id);
        return {
          type: 'item',
          key: i.id,
          isLeaf: false,
          children: filteredGrades.map((x) => {
            return {
              title: x.name,
              type: 'grade',
              key: x.id,
              isLeaf: true,
              children: [],
            };
          }),
          title: i.name,
        };
      });
  };

  const recursiveFun = (stockGroups: StockGroup[], parent): TreeNodeType[] => {
    return stockGroups
      .filter((g) => g.parentStockGroupId === parent)
      .map((sg) => {
        return {
          type: 'stock-group',
          key: sg.id,
          isLeaf: false,
          children: [
            ...recursiveFun(stockGroups, sg.id),
            ...item(sg.id, grades),
          ],
          title: sg.name,
        };
      });
  };
  data.children = recursiveFun(stockGroups, 'root');
  return data;
}

function renderTree(data: TreeNodeType) {
  const result = {
    key: data.key,
    children: data.children.map((d) => renderTree(d)),
    title: data.title,
    icon: (
      <div className='w-5 text-indigo-900 self-center'>
        <TreeIcon type={data.type} />
      </div>
    ),
    expandAction: 'click',
  };
  return result;
}
