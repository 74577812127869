import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedClient } from '../../../hooks/order/sales/client/use-client';
import { renderAddEnquiryForm } from './form/new-enquiry';

export type RenderSalesRegisterProps = {
  useCombinedClient: UseCombinedClient;
  useCombinedGrade: UseCombinedGrade;
};

// eslint-disable-next-line
export function renderSalesRegisterForm({
  useCombinedClient,
  useCombinedGrade,
}: RenderSalesRegisterProps): () => JSX.Element {
  return function SalesRegister(): JSX.Element {
    const Card = renderCardComponent();

    const Form = renderAddEnquiryForm({ useCombinedClient, useCombinedGrade });

    const Table = renderTableComponent();

    const data = [
      {
        id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
        productGrade: 'standard',
        productCode: '123',
        endUse: 'aaa',
        modeOfApp: 'customer',
        modeOfEnq: 'email',
        remarks: 'aaab',
        casno: '12354',
        destination: 'Mumbai',
        alias: '1-Ethyl piperazine',
        contactPerson: 'Suraj',
        quantity: [
          {
            quantity: '75',
            unit: 'kg',
            date: '12/12/2024',
            deliveryPer: '20',
          },
        ],
        offerNo: '24-25/9890',
        date: '31/12/2024',
        customer: { id: '2222', name: 'Dr. Reddy' },
        product: { id: '22222', name: 'N-Methyl Piperazine' },
        status: 'Inquiry',
      },
    ];
    const tableHeader: TableHeader = [
      [
        { name: 'Offer No' },
        { name: 'Offer Date' },
        { name: 'Customer' },
        { name: 'Product' },
        { name: 'Status' },
      ],
    ];

    const tableData =
      data?.map((item) => ({
        rowData: {
          inqDtls: item,
        },
        cells: [
          { value: item.offerNo, link: `/sales/orders/${item.id}/details` },
          { value: item.date },
          { value: item.customer.name },
          { value: item.product.name },
          { value: item.status },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardHeader: CardHeader = {
      title: 'Sales Register',
      // subheading: 'this page for display the list of enquiries',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Enquiry',
              content: ({ onClose }) => {
                return (
                  <Form
                    data={{
                      id: '',
                      sirNo: '',
                      refNo: '',
                      offerNo: '',
                      status: '',
                      location: '',
                      address: '',
                      product: { id: '', name: '' },
                      productCode: '',
                      productGrade: '',
                      endUse: '',
                      modeOfApp: '',
                      modeOfEnq: '',
                      customer: { id: '', name: '' },
                      remarks: '',
                      casno: '',
                      date: '',
                      destination: '',
                      alias: '',
                      contactPerson: '',
                      quantity: [],
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          // actions={
          //   [
          //     {
          //       name: 'Edit',
          //       show: ({
          //         inqDtls
          //       }: {
          //         inqDtls: any;
          //       }) => {
          //         if(inqDtls)
          //         {
          //           return true;
          //         }
          //         return false;
          //       },
          //       behaviour: 'modal',
          //       modal: {
          //         title: 'Edit Enquiry',
          //         content: ({
          //           onClose,
          //         }: {
          //           data: {
          //             inqDtls: any;
          //           };
          //           onClose: () => any;
          //         }) => {
          //           return (
          //             <div>
          //               <Form data={{
          //                 id: data[0].id,
          //                 offerNo: data[0].offerNo,
          //                 status: data[0].status,
          //                 product:{id: data[0].product?.id, name: data[0].product?.name},
          //                 productCode: data[0].productCode,
          //                 productGrade: data[0].productGrade,
          //                 endUse: data[0].endUse,
          //                 modeOfApp: data[0].modeOfApp,
          //                 modeOfEnq: data[0].modeOfEnq,
          //                 customer: {id: data[0].customer?.id, name: data[0].customer?.name},
          //                 remarks: data[0].remarks,
          //                 casno: data[0].casno,
          //                 date: data[0].date,
          //                 destination: data[0].destination,
          //                 alias: data[0].alias,
          //                 contactPerson: data[0].contactPerson,
          //                 quantity: []
          //               }}/>
          //             </div>
          //           );
          //         },
          //       },
          //     },
          //   ]
          // }
        />
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
