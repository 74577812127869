import { Client } from '@erp_core/erp-types/dist/modules/order';
import { UnderConstruction } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCombinedDeliveryTerm } from '../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../hooks/constants/payment-term/use-payment-term';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClientItem } from '../../../hooks/order/sales/client-item/use-client-item';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { renderClientContact } from '../client-contact';
import { renderClientItemList } from '../client-items';
import { renderClientProfile } from '../client-profile/profile';
import { renderClientRegistrations } from '../client-registrations';
import { renderClientTerms } from '../client-terms';

export type ClientProfileProp = {
  client: Client;
  loading: boolean;
  setClient: (s: Client) => Promise<Client>;
  useCombinedItem: UseCombinedItem;
  useCombinedGrade: UseCombinedGrade;
  useCombinedClientItem: UseCombinedClientItem;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  itemService: ItemInterface;
};

export function renderClientProfileTabs(): (
  x: ClientProfileProp
) => JSX.Element {
  return function ClientProfileTabs({
    client,
    loading,
    setClient,
    useCombinedItem,
    useCombinedGrade,
    useCombinedClientItem,
    useCombinedDeliveryTerm,
    useCombinedPaymentTerm,
    useFileTransfer,
    usePublicTransfer,
    itemService,
  }: ClientProfileProp): JSX.Element {
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);

    useEffect(() => {
      let tabs: Array<string> = [];

      tabs = [
        'Basic Info',
        // 'Contacts',
        'Items',
        'Terms',
        'Registrations',
      ];
      setTabs(tabs);

      if (tabs.length) {
        setCurrentTab(tabs[0]);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (currentTab) {
        let Props = <div></div>;

        switch (currentTab) {
          case 'Basic Info': {
            const Profile = renderClientProfile({
              useFileTransfer,
              usePublicTransfer,
              setClient,
              client,
              loading,
            });
            const Contact = renderClientContact({ client, setClient });

            Props = (
              <>
                <Profile />
                <Contact />
              </>
            );
            break;
          }

          case 'Items': {
            const ItemList = renderClientItemList({
              client,
              loading,
              useCombinedItem,
              useCombinedGrade,
              useCombinedClientItem,
              itemService,
            });
            Props = <ItemList />;
            break;
          }

          // case 'Contacts': {
          //   break;
          // }

          case 'Terms': {
            const Terms = renderClientTerms({
              client,
              loading,
              setClient,
              useCombinedDeliveryTerm,
              useCombinedPaymentTerm,
            });

            Props = <Terms />;
            break;
          }

          case 'Registrations': {
            const Registrations = renderClientRegistrations({
              client,
              setClient,
              useFileTransfer,
            });
            Props = <Registrations />;
            break;
          }

          default: {
            Props = <UnderConstruction />;
          }
        }

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab]);

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    return (
      <div>
        <div className='flex w-full'>
          {tabs.map((x, idx) => (
            <div
              key={x || idx}
              onClick={() => {
                if (x !== currentTab) {
                  setCurrentTab(x);
                }
              }}
              className={currentTab === x ? selectedTabStyle : nonSelectedStyle}
            >
              {x}
            </div>
          ))}
        </div>
        <div className='w-full'>
          <div className='w-full flex flex-wrap'>{body}</div>
        </div>
      </div>
    );
  };
}
