import { ExcelDownload } from '@erp_core/erp-icons/icons/excel-download';
import {
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderRow,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import { UseCombinedRotationalShiftDay } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-day';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';

export function renderMonthSchedule({
  useCombinedRotationalShiftDay,
  useCombinedGodown,
}: {
  useCombinedRotationalShiftDay: UseCombinedRotationalShiftDay;
  useCombinedGodown: UseCombinedGodown;
}): () => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return function MonthSchedule(): JSX.Element {
    const { list: shifts, getAll: getShifts } = useCombinedRotationalShiftDay();
    const { list: godowns, getAll: getGodowns } = useCombinedGodown();
    const [month, setMonth] = useState<string>(moment().format('YYYY-MM'));
    const [tBody, setTBody] = useState<TableBody>([]);

    useEffect(() => {
      getGodowns({
        type: 'work-area',
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      getShifts({
        from: `more-than::${month}-00`,
        to: `less-than::${month}-32`,
      });
      // eslint-disable-next-line
    }, [month]);

    const workPlaces = useMemo(
      () =>
        godowns?.filter((g) =>
          ['work-area', 'supervisor-work-area'].includes(g.type)
        ) || [],
      [godowns]
    );

    useEffect(() => {
      const groupedByWorkplace = _.groupBy(
        shifts,
        (s) => s.details?.workArea?.name
      );

      const days: TableCell[] = [];
      _.times(moment(month, 'YYYY-MM').daysInMonth(), () => {
        days.push({ value: '' });
      });

      const tableBody: TableBody = [];
      workPlaces.forEach((w) => {
        const workplaceShifts = groupedByWorkplace[w.name];
        if (workplaceShifts) {
          const employees = _.groupBy(workplaceShifts, (s) => s.employee.name);

          const employeeLength = _.keys(employees).length;

          let first = true;
          for (const emp in employees) {
            const list = employees[emp];
            if (first) {
              first = false;
              const empDays: TableCell[] = [];
              for (
                let day = 1;
                day <= moment(month, 'YYYY-MM').daysInMonth();
                day++
              ) {
                const date = moment(`${month}-${day < 10 ? `0${day}` : day}`);
                const s = list.find(
                  (x) => x.date === date.format('YYYY-MM-DD')
                );
                if (s) {
                  empDays.push({
                    value: `${getSimpleShiftName(
                      s.details.shiftId?.name || '-'
                    )}`,
                  });
                } else {
                  // CHeck if there is a Week Off in the undefined grouping
                  const sd = groupedByWorkplace['undefined'].find(
                    (x) =>
                      x.employee.name === emp &&
                      x.date === date.format('YYYY-MM-DD')
                  );
                  if (sd && sd.attendance === 'weekly-off') {
                    empDays.push({ value: 'WO' });
                  } else {
                    empDays.push({ value: '-' });
                  }
                }
              }

              tableBody.push({
                cells: [
                  { rowSpan: employeeLength, value: `${w.name}` },
                  { value: emp },
                  ...empDays,
                ],
              });
            } else {
              const empDays: TableCell[] = [];
              for (
                let day = 1;
                day <= moment(month, 'YYYY-MM').daysInMonth();
                day++
              ) {
                const date = moment(`${month}-${day < 10 ? `0${day}` : day}`);
                const s = list.find(
                  (x) => x.date === date.format('YYYY-MM-DD')
                );
                if (s) {
                  empDays.push({
                    value: `${getSimpleShiftName(
                      s.details.shiftId?.name || '-'
                    )}`,
                  });
                } else {
                  // CHeck if there is a Week Off in the undefined grouping
                  const sd = groupedByWorkplace['undefined'].find(
                    (x) =>
                      x.employee.name === emp &&
                      x.date === date.format('YYYY-MM-DD')
                  );
                  if (sd && sd.attendance === 'weekly-off') {
                    empDays.push({ value: 'WO' });
                  } else {
                    empDays.push({ value: '-' });
                  }
                  // empDays.push({   value: '-' });
                }
              }

              tableBody.push({
                cells: [{ value: emp }, ...empDays],
              });
            }
          }
        }
      });

      setTBody(tableBody);
    }, [shifts, workPlaces, month]);

    const dayRow: TableHeaderRow = [];
    const dateRow: TableHeaderRow = [];

    for (let day = 1; day <= moment(month, 'YYYY-MM').daysInMonth(); day++) {
      const date = moment(`${month}-${day < 10 ? `0${day}` : day}`);
      dayRow.push({ name: date.format('ddd') });
      dateRow.push({ name: date.format('DD') });
    }

    const header: TableHeader = [
      [...[{ colSpan: 2, name: 'Date', rowSpan: 2 }], ...dateRow],
      dayRow,
    ];

    return (
      <div>
        <Card
          header={{
            title: `${moment(month, 'YYYY-MM').format('MMMM, YYYY')} Schedule`,
            subheading: (
              <div>
                <span className='italic text-blue-600'>Workplaces: </span>
                {workPlaces.map((x) => (
                  <span
                    className='mx-1 p-0.5 border border-gray-200 rounded-md'
                    key={x.id}
                  >
                    {x.name}
                  </span>
                ))}
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={month}
                      onChange={(m) => setMonth(m)}
                    />
                    <div
                      className='flex justify-end'
                      onClick={() => {
                        const elt = document.getElementById('month-sheet');
                        const workbook = utils.table_to_book(elt);
                        var ws = workbook.Sheets['Sheet1'];
                        utils.sheet_add_aoa(ws, [], { origin: -1 });
                        writeFileXLSX(workbook, `${month}-schedule.xlsx`);
                      }}
                    >
                      <ExcelDownload className='stroke-green-500 fill-green-400 w-8 cursor-pointer' />
                    </div>
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div id='month-sheet'>
                <Table header={header} body={tBody} />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}

function getSimpleShiftName(name: string) {
  switch (name) {
    case 'First': {
      return 'I';
    }
    case 'Second': {
      return 'II';
    }
    case 'Third': {
      return 'III';
    }
    case 'General': {
      return 'G';
    }
    default: {
      return name;
    }
  }
}
