import { Account } from '@erp_core/erp-icons/icons/account';
import { History } from '@erp_core/erp-icons/icons/history';
import { Label } from '@erp_core/erp-icons/icons/label';
import { MachineIcon } from '@erp_core/erp-icons/icons/machine-icon';
import { Mail } from '@erp_core/erp-icons/icons/mail';
import { UnderConstruction } from '@erp_core/erp-ui-components';
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  CheckBadgeIcon,
  CheckIcon,
  CircleStackIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CreditCardIcon,
  CubeTransparentIcon,
  CurrencyRupeeIcon,
  DocumentIcon,
  DocumentTextIcon,
  EllipsisHorizontalCircleIcon,
  FingerPrintIcon,
  InboxStackIcon,
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  TableCellsIcon,
  TagIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { renderBankSalaries } from '../../models/interfaces/accounts/disbursements';
import { Config } from '../../models/interfaces/common/app-config';
import { renderBill } from '../../modules/accounts/bills';
import { renderCashFlowDetails } from '../../modules/accounts/cashflow/cashflow-details';
import { renderDaybook } from '../../modules/accounts/daybook';
import { renderAccountsSummary } from '../../modules/accounts/tally/account-summary';
import { renderBalanceSheet } from '../../modules/accounts/tally/balance-sheet';
import { renderTallyDaybook } from '../../modules/accounts/tally/daybook';
import { renderGroupTree } from '../../modules/accounts/tally/groups/group-tree';
import { renderLedgerTree } from '../../modules/accounts/tally/ledgers/ledger-tree';
import { renderProfitNLoss } from '../../modules/accounts/tally/profit-n-loss';
import { renderAdminMasters } from '../../modules/admin';
import { renderTemplate } from '../../modules/admin/communication/template';
import { renderCompanyDetails } from '../../modules/admin/company-admin/company-profile';
import { renderCompanyGroupConfig } from '../../modules/admin/company-group-config';
import { renderAttendanceDevices } from '../../modules/admin/company-group-config/hrd/attendance-device';
import { renderConstants } from '../../modules/admin/constants';
import { renderDashboardSettings } from '../../modules/admin/dashboard';
import { renderDashboardModule } from '../../modules/admin/dashboard/module';
import { renderDatabaseSettings } from '../../modules/admin/database-settings';
import { renderHazardRegulations } from '../../modules/admin/hazard-regulations';
import { renderHazardClassPage } from '../../modules/admin/hazard-regulations/hazard-class-page';
import { renderLocationDetails } from '../../modules/admin/location-admin/location-profile';
import { renderSchedulerJob } from '../../modules/admin/scheduler/jobs';
import { renderSchedulerTask } from '../../modules/admin/scheduler/task';
import { renderAdhocPayments } from '../../modules/hrd/adhoc-payments';
import { renderAttendanceDetails } from '../../modules/hrd/attendance/attendance-details';
import { createNewAttendancePage } from '../../modules/hrd/attendance/day-book';
import { createEmployeeAttendancePage } from '../../modules/hrd/attendance/employee-book';
import { renderEmployeePendingActionsPage } from '../../modules/hrd/attendance/employee-pending-actions';
import { renderExpireLeavesBook } from '../../modules/hrd/attendance/expire-leaves-bank';
import { renderMonthGatePasses } from '../../modules/hrd/attendance/gate-passes';
import { renderEmployeeLeaves } from '../../modules/hrd/attendance/leaves-bank';
import { renderEmployeeMonthLeavesBook } from '../../modules/hrd/attendance/leaves-book';
import { renderOffSiteMonthWorkHours } from '../../modules/hrd/attendance/off-site-work-hours';
import { renderMonthOvertimes } from '../../modules/hrd/attendance/overtime';
import { renderRefillLeavesBook } from '../../modules/hrd/attendance/refill-leaves-bank';
import { renderDepartmentMaster } from '../../modules/hrd/department';
import { renderDepartmentList } from '../../modules/hrd/department/department-master';
import { renderMisconduct } from '../../modules/hrd/disciplinary/misconduct';
import { renderRuleRequestDetails } from '../../modules/hrd/disciplinary/misconduct/component/ruleRequestDetails';
import { renderEmployeeExit } from '../../modules/hrd/employee-exit';
import { renderFullNFinal } from '../../modules/hrd/employee-exit/full-n-final';
import { renderOffBoardingActions } from '../../modules/hrd/employee-exit/off-boarding-actions';
import { renderEmployeeOnboard } from '../../modules/hrd/employee-onboard';
import { renderEmployeeJobTask } from '../../modules/hrd/employee/emp-job-task';
import { renderEmployeeV2Profile } from '../../modules/hrd/employee/employee-profile';
import { renderEmployeeProfileV2List } from '../../modules/hrd/employee/employeelist';
import { renderPolicies } from '../../modules/hrd/insurance';
import { renderLoanList } from '../../modules/hrd/loan-management';
import { renderLoanDetails } from '../../modules/hrd/loan-management/loan-details';
import { renderReportList } from '../../modules/hrd/report';
import { createSalaryPage } from '../../modules/hrd/salary';
import { renderSalaryAdvances } from '../../modules/hrd/salary-advances';
import { renderSalaryBonus } from '../../modules/hrd/salary-bonus';
import { createSalaryParametersPage } from '../../modules/hrd/salary-parameters';
import { createSalaryParametersDetailsPage } from '../../modules/hrd/salary-parameters/details';
import { createSalaryRevisionPage } from '../../modules/hrd/salary-revisions';
import { createEmployeeShiftsPage } from '../../modules/hrd/shifts/employee-shifts';
import { renderMonthSchedule } from '../../modules/hrd/shifts/month-schedule';
import { renderEmployeeSwitchShifts } from '../../modules/hrd/shifts/switch-shift';
import { renderWorkAreaPage } from '../../modules/hrd/shifts/work-area';
import { renderWorkAreaV2Page } from '../../modules/hrd/shifts/work-area-v2';
import { renderWorkAreaDetailsPage } from '../../modules/hrd/shifts/work-area-v2/details-page';
import { renderGodownTransfer } from '../../modules/inventory/godown-transfer';
import { renderGodownProfile } from '../../modules/inventory/godowns/godown-profile';
import { renderGodownList } from '../../modules/inventory/godowns/godowns-list';
import { renderGradeList } from '../../modules/inventory/grade-list';
import { renderItemBatches } from '../../modules/inventory/item-batches';
import { renderGrade } from '../../modules/inventory/items/grade';
import { renderItemV2List } from '../../modules/inventory/items/item-list';
import { renderItemV2Profile } from '../../modules/inventory/items/item-profile';
import { renderStockItemSummary } from '../../modules/inventory/reports/stock-item-summary';
import { renderRequisitionList } from '../../modules/inventory/requisitions';
import { renderBOMDetails } from '../../modules/inventory/requisitions/bom-details';
import { renderBomFlow } from '../../modules/inventory/requisitions/bom-flow';
import { renderBomFlowDetails } from '../../modules/inventory/requisitions/bom-flow-details';
import { renderBomProjectDetails } from '../../modules/inventory/requisitions/bom-project-register/bom-project-details';
import { renderRevisionDetails } from '../../modules/inventory/revision';
import { renderRevisionProfile } from '../../modules/inventory/revision/component/revision-details';
import { renderStockGroupProfile } from '../../modules/inventory/stock-group/profile';
import { renderStockGroupList } from '../../modules/inventory/stock-group/stock-group-list';
import { createInventoryTree } from '../../modules/inventory/tree';
import { renderQuestionForm } from '../../modules/miscellaneous/questionnaire/question';
import { renderSubmittedAnswers } from '../../modules/miscellaneous/questionnaire/submitted-answers';
import { renderSurveyAnswersPage } from '../../modules/miscellaneous/questionnaire/survey-answers';
import { SurveyAnswerSubmitComponent } from '../../modules/miscellaneous/questionnaire/survey-answers/component/survey-answers-submit';
import { renderTopicForm } from '../../modules/miscellaneous/questionnaire/topic';
import { renderWorkLogReport } from '../../modules/miscellaneous/questionnaire/workLog-report';
import { renderStatutoryReportBuilder } from '../../modules/miscellaneous/report-builder';
import { renderLocationHolidaysList } from '../../modules/planning/location-holidays';
import { createManufacturingVoucherList } from '../../modules/planning/production-planning/manufacturing-voucher/manufacturing-voucher-list';
import { renderManufacturingVoucherDetails } from '../../modules/planning/production-planning/manufacturing-voucher/manufacturing-voucher-list-details';
import { renderMachine } from '../../modules/planning/production-planning/schedule/machine';
import { renderMachineSchedule } from '../../modules/planning/production-planning/schedule/machine/machine-schedule';
import { renderMachinesList } from '../../modules/planning/production-planning/schedule/machines';
import { renderPlan } from '../../modules/planning/production-planning/schedule/plan';
import { renderGoodsReceived } from '../../modules/purchase/goods-received';
import { renderPurchaseItemInspection } from '../../modules/purchase/inspection';
import { renderPurchaseRequisitions } from '../../modules/purchase/requistions';
import { renderPurchaseOrderDetails } from '../../modules/purchase/requistions/purchase-order/purchase-order-details';
import { renderPRDetails } from '../../modules/purchase/requistions/purchase-requisition/pr-flow-details';
import { PurchaseItemTransfer } from '../../modules/purchase/transfer';
import { renderVendorGodowns } from '../../modules/purchase/vendor/vendor-godowns';
import { renderVendorList } from '../../modules/purchase/vendor/vendor-list';
import { renderVendorV2Profile } from '../../modules/purchase/vendor/vendor-profile';
import { renderVendorItemSpec } from '../../modules/purchase/vendor/vendor-profile/vendor-items/component/vendor-item-specification';
import { renderClosureDatePeriod } from '../../modules/quality-assurance/closure-date-period';
import { renderRequisitionDetails } from '../../modules/quality-assurance/qa-requests/component/requisition-details';
import { renderChangeControlReqDetails } from '../../modules/quality-assurance/qa-requests/requestList';
// import { renderSalesMasters } from '../../modules/sales';
import { renderClientV2Profile } from '../../modules/sales/client-profile';
import { renderClientGodowns } from '../../modules/sales/client/client-godowns';
import { renderClientList } from '../../modules/sales/client/client-list';
// import renderSalesItemEnquiryDetails from '../../modules/sales/item-enquiry';
// import { renderSalesEnquiryItemsDetailsV2 } from '../../modules/sales/item-enquiry/item-enquiry-details';
// import renderSalesItemOrderDetails from '../../modules/sales/item-order';
// import { renderLeadCustomerEnquiryDetails } from '../../modules/sales/lead-customer-enquiry-details';
import { renderLeadCustomerList } from '../../modules/sales/lead-customer-list';
import { renderLeadCustomerProfile } from '../../modules/sales/lead-customer-profile';
// import renderSalesManufacturingEnquiryDetails from '../../modules/sales/manufacturing-enquiry';
// import renderSalesManufacturingOrderDetails from '../../modules/sales/manufacturing-order';
// import renderSalesQcEnquiryDetails from '../../modules/sales/qc-enquiry';
// import renderSalesQcOrderDetails from '../../modules/sales/qc-order';
// import { renderSalesEnquiryDetailsV2 } from '../../modules/sales/sales-enquiry/sales-enquiry-details';
import { renderDispatchScheduleForm } from '../../modules/sales/dipatch-schedule';
import { renderOAInfoForm } from '../../modules/sales/dipatch-schedule/component/oa-details';
import { renderSalesOrderTabs } from '../../modules/sales/sales-order-tabs';
// import { renderSalesOrderDetailsV2 } from '../../modules/sales/sales-order/sales-order-details/index-details';
import { renderSalesRegisterForm } from '../../modules/sales/sales-register';
import { renderdSalesDetails } from '../../modules/sales/sales-register/component/sales-details';
import { renderUserProfile } from '../../modules/users/user-profile';
import { renderEnquiryDetails } from '../purchase/requistions/purchase-enquiry/enquiry-details';

export type ModuleType = {
  name: string;
  icon: (p: { className: string }) => JSX.Element;
  path: string;
  auth?: string;
  hidden?: boolean;
  content?: () => JSX.Element;
  sidebarLinks: ModuleSidebarLink[];
};

export type ModuleSidebarLink = {
  name: string;
  icon?: (p: { className: string }) => JSX.Element;
  path: string;
  content?: any;
  type: 'link' | 'menu';
  auth?: string;
  menu?: Array<{
    name: string;
    icon?: (p: { className: string }) => JSX.Element;
    path: string;
    content: any;
    hidden?: boolean;
    auth?: string;
  }>;
  hidden?: boolean;
};

export class ModuleRenderer {
  modules: ModuleType[];

  constructor({ config }: { config: Config }) {
    const {
      services: {
        voucherService,
        companyGroupSettingService,
        userRendererService,
        employeeProfileService,
        attendanceService,
        leaveService,
        logRecordService,
        salaryService,
        loansService,
        purchaseEnquiryService,
        purchaseOrderService,
        itemV2Service,
        stockGroupService,
        fileTransferService,
        revisionService,
        purchaseRequisitionService,
        bomFlowService,
        gradeService,
        machineService,
        batchItemGodownService,
        vendorService,
        switchShiftScheduleService,
        salaryRevisionService,
        dashboardService,
        hrdDashboardReportService,
      },
      hooks: {
        useCombinedLedger,
        useCombinedVoucher,
        useCombinedAccountGroup,
        useCombinedCompanyGroupAdmin,
        useCombinedEmployeeCategory,
        useCombinedCurrency,
        useCombinedItemCategory,
        useCombinedDeliveryTerm,
        useCombinedMetric,
        useCombinedGST,
        useCombinedPaymentTerm,
        useCombinedEmployeeDesignation,
        useCombinedAppearance,
        useCombinedOdour,
        useCombinedColor,
        useCombinedCompanyAdmin,
        useFileTransfer,
        usePublicTransfer,
        useCombinedLocationAdmin,
        useCombinedModuleAdmin,
        useCombinedActionAdmin,
        useCombinedNotificationAction,
        useCombinedUser,
        useCombinedResourcesAdmin,
        useCombinedAuthorization,
        useCombinedRole,
        useCombinedNotificationConfigurator,
        useCombinedPrecautionaryStatement,
        useCombinedHazardGhsClass,
        useCombinedHazardStatement,
        useCombinedCompanyGroupSetting,
        useCombinedAttendanceDevice,
        useCombinedShiftSchedule,
        useGetMachineSchedule,
        useCombinedLocationHoliday,
        useCombinedTask,
        useCombinedJob,
        useCombinedTemplate,
        useCombinedPayrollReport,
        useCombinedSalary,
        useCombinedDaybook,
        useCombinedEmployeeProfile,
        useCombinedDepartment,
        useUserAuthorization,
        useCombinedEmployeeRegistration,
        useHrdDashboardReport,
        useCombinedAttendance,
        useCurrentUserRoles,
        useCombinedLeave,
        useCombinedGatePass,
        useCombinedOvertime,
        useCombinedOffSiteWorkHour,
        useCombinedSalaryParam,
        useCombinedSalaryRevision,
        useCombinedMisconduct,
        useCombinedSalaryAdvance,
        useCombinedSalaryBonus,
        useCombinedLoan,
        useCombinedRepaymentSchedule,
        useCombinedRotationalShiftDay,
        useCombinedSwitchShift,
        useDisbursementData,
        useDisbursementPostData,
        useAdvPaymentData,
        useSkipRepaymentData,
        useCombinedOnboardEmployee,
        useCombinedRuleRequest,
        useCombinedMisconductRuleBook,
        useCombinedPolicy,
        useCombinedPremium,
        useCombinedDocument,
        useCombinedEmployeeEducation,
        useCombinedEmployeeFamily,
        useCombinedEmployeeLetter,
        useAttendanceMonthlySummary,
        useSalaryAdvanceEligibleAmt,
        useCombinedQuestionnaireSurveyAnswer,
        useCombinedClientItem,
        useVendorItem,
        useCombinedItem,
        useCombinedStockGroup,
        useGodownsTree,
        useCombinedGodown,
        useCombinedBatchItemGodown,
        useCombinedRevision,
        useCombinedChangeAssesment,
        useCombinedClosureDatePeriod,
        useCombinedBomFlow,
        useCombinedPurchaseOrder,
        useCombinedPurchaseEnquiry,
        usePhysicalStock,
        useVirtualStock,
        useBatchGodownItemAllocate,
        useVirtualItemAllocate,
        useMRS,
        useCombinedGodownTransfer,
        useCombinedBom,
        useCombinedPurchaseRequisition,
        useCombinedBomProject,
        useCombinedVendor,
        useCombinedGrade,
        useCombinedBomDependency,
        useCombinedReport,
        useCombinedQuestionnaireTopic,
        useCombinedProductBatch,
        useCombinedQuestionnaireQuestion,
        useCombinedPurchaseOrderFulfilment,
        useCombinedMachine,
        useCombinedMachineSchedule,
        useCombinedManufacturingVoucher,
        useCombinedEnquiryQuote,
        useCombinedClient,
        // useCombinedLeadEnquiry,
        useCombinedLeadCustomer,
        useVirtualMfStock,
        useVirtuallyReserveMfVoucher,
        // useCombinedSalesEnquiry,
        // useCombinedLeadCustomerEnquiry,
        useCombinedSalesOrder,
        useCombinedHsn,
        useCombinedDashboard,
        useCombinedLicenseContractDocType,
        useCombinedLicenseContract,
      },
    } = config;

    const AdminMasters = renderAdminMasters({
      useCombinedCompanyGroupAdmin,
      useCombinedCompanyAdmin,
      useFileTransfer,
      usePublicTransfer,
      useCombinedLocationAdmin,
      useCombinedModuleAdmin,
      useCombinedResourcesAdmin,
      useCombinedRole,
      useCombinedActionAdmin,
      useCombinedNotificationAction,
      useCombinedUser,
      useCombinedAuthorization,
      useCombinedNotificationConfigurator,
    });

    const CompanyDetails = renderCompanyDetails({
      hooks: {
        useCombinedCompanyAdmin,
        useFileTransfer,
        useCombinedLocationAdmin,
      },
    });

    const LocationDetails = renderLocationDetails({
      hooks: {
        useCombinedLocationAdmin,
      },
    });

    const HazardRegulations = renderHazardRegulations({
      useCombinedHazardStatement,
      useCombinedPrecautionaryStatement,
      usePublicTransfer,
      useCombinedHazardGhsClass,
    });

    const HazardClassPage = renderHazardClassPage({
      useCombinedHazardGhsClass,
      useCombinedHazardStatement,
      useCombinedPrecautionaryStatement,
    });

    const CompanyGroupConfig = renderCompanyGroupConfig({
      useCombinedCompanyGroupSetting,
      companyGroupSettingService,
      useFileTransfer,
      useCombinedLocationAdmin,
      useCombinedAttendanceDevice,
      useCombinedEmployeeCategory,
      useCombinedEmployeeDesignation,
      useCombinedShiftSchedule,
      useCombinedLocationHoliday,
      useCombinedLicenseContractDocType,
      useCombinedLicenseContract,
      useCombinedItem,
      useCombinedClient,
      useCombinedVendor,
    });

    const Constants = renderConstants({
      useCombinedEmployeeCategory,
      useCombinedCurrency,
      useCombinedDeliveryTerm,
      useCombinedMetric,
      useCombinedItemCategory,
      useCombinedGST,
      useCombinedPaymentTerm,
      useCombinedEmployeeDesignation,
      useCombinedAppearance,
      useCombinedOdour,
      useCombinedColor,
      useCombinedHsn,
    });

    const DatabaseSettings = renderDatabaseSettings({
      dashboardService,
    });

    const DashboardSettings = renderDashboardSettings({
      useCombinedDashboard,
      useCombinedRole,
    });
    const DashboardModule = renderDashboardModule({
      useCombinedDashboard,
      useCombinedRole,
    });

    const Tasks = renderSchedulerTask({
      useCombinedTask,
      userRendererService,
    });

    const Jobs = renderSchedulerJob({
      useCombinedJob,
      userRendererService,
    });

    const Template = renderTemplate({
      useCombinedTemplate,
      useFileTransfer,
      userRendererService,
    });

    // Accounts

    const TallyDayBook = renderTallyDaybook({
      useCombinedGodown,
      itemService: itemV2Service,
      useCombinedVoucher,
      useCombinedLedger,
      useCombinedVendor,
      useCombinedClient,
      useCombinedItemCategory,
      useCombinedGrade,
      voucherService,
    });
    const BalanceSheet = renderBalanceSheet();
    const AccountSummary = renderAccountsSummary({
      useCombinedAccountGroup,
      useCombinedLedger,
    });
    const ProfitNLoss = renderProfitNLoss();
    const GroupsPage = renderGroupTree({ useCombinedAccountGroup });
    const LedgersPage = renderLedgerTree({
      useCombinedAccountGroup,
      useCombinedLedger,
    });

    const BankSalaries = renderBankSalaries({
      useFileTransfer,
      useCombinedSalary,
      useCombinedPayrollReport,
    });

    const CashFlowReport = renderCashFlowDetails({
      useCombinedDepartment,
      useCombinedGrade,
    });

    const Daybook = renderDaybook({
      useCombinedDaybook,
      useCombinedUser,
    });

    const Bill = renderBill({
      useCombinedDaybook,
    });

    // HRD
    const EmployeeV2List = renderEmployeeProfileV2List({
      userRendererService,
      useCombinedEmployeeProfile,
    });

    const EmployeeV2Profile = renderEmployeeV2Profile({
      leaveService,
      userRendererService,
      useCombinedRotationalShiftDay,
      useFileTransfer,
      usePublicTransfer,
      useCombinedEmployeeProfile,
      useCombinedGatePass,
      useCombinedEmployeeCategory,
      useCombinedUser,
      useCombinedShiftSchedule,
      useCombinedLeave,
      useCombinedCompanyAdmin,
      useCombinedMisconduct,
      useCombinedSalaryAdvance,
      useAttendanceMonthlySummary,
      useSalaryAdvanceEligibleAmt,
      useCombinedPolicy,
      useCombinedPremium,
      useCombinedLoan,
      useCombinedRepaymentSchedule,
      useSkipRepaymentData,
      useAdvPaymentData,
      useCombinedSalaryParam,
      useCombinedEmployeeRegistration,
      useCurrentUserRoles,
      useCombinedAttendance,
      useCombinedDepartment,
      useCombinedDocument,
      useCombinedEmployeeEducation,
      useCombinedEmployeeFamily,
      useCombinedEmployeeLetter,
    });

    const Holidays = renderLocationHolidaysList({
      useCombinedLocationHoliday,
    });

    const AttendanceDevice = renderAttendanceDevices({
      mode: 'view',
      useCombinedAttendanceDevice,
      refreshData: () => {},
    });

    const Department = renderDepartmentMaster({ useCombinedEmployeeProfile });

    const DepartmentList = renderDepartmentList({
      useCombinedDepartment,
      useCombinedEmployeeProfile,
      userRenderService: userRendererService,
    });

    const WorkLogReport = renderWorkLogReport({
      employeeProfileService,
      useCombinedEmployeeProfile,
    });

    const NewAttendancePage = createNewAttendancePage({
      userRendererService,
      attendanceService,
      useCombinedAttendance,
      useCurrentUserRoles,
      useCombinedEmployeeProfile,
      useCombinedLeave,
      useCombinedGatePass,
      useCombinedOvertime,
      useCombinedOffSiteWorkHour,
      leaveService,
      logRecordService,
      useCombinedShiftSchedule,
      useCombinedRotationalShiftDay,
    });

    const AttendanceDetails = renderAttendanceDetails({
      useUserAuthorization,
      isDaybook: false,
      attendanceService,
      useCombinedAttendance,
      useCurrentUserRoles,
      userRendererService,
    });

    const EmployeeAttendacePage = createEmployeeAttendancePage({
      useUserAuthorization,
      useCombinedAttendance,
      useCurrentUserRoles,
      attendanceService,
      userRendererService,
      useCombinedEmployeeProfile,
      useCombinedGatePass,
      useCombinedLeave,
      useCombinedOffSiteWorkHour,
      useCombinedOvertime,
      leaveService,
      logRecordService,
    });

    const EmployeePendingActionsPage = renderEmployeePendingActionsPage({
      attendanceService,
      userRendererService,
      leaveService,
      useCombinedEmployeeProfile,
      useCombinedLeave,
      useCombinedGatePass,
      useCombinedOffSiteWorkHour,
      useCombinedOvertime,
      useCombinedAttendance,
      useCurrentUserRoles,
      logRecordService,
    });

    const SalaryPage = createSalaryPage({
      useCombinedSalaryAdvance,
      useCombinedEmployeeRegistration,
      useHrdDashboardReport,
      useCombinedPayrollReport,
      useCombinedSalaryParam,
      useCombinedEmployeeProfile,
      useCombinedSalary,
      useCombinedSalaryRevision,
      useCombinedAttendance,
      useFileTransfer,
      useCurrentUserRoles,
      salaryService,
      useCombinedDepartment,
    });

    const SalaryRevisionPage = createSalaryRevisionPage({
      useCombinedEmployeeCategory,
      useCurrentUserRoles,
      useCombinedEmployeeProfile,
      useCombinedSalary,
      useCombinedSalaryRevision,
      useCombinedSalaryParam,
      useCombinedMisconduct,
      useCombinedSalaryAdvance,
      useCombinedPremium,
      useCombinedLoan,
      useCombinedRepaymentSchedule,
      userRendererService,
      salaryRevisionService,
    });

    const SalaryParametersPage = createSalaryParametersPage({
      useCombinedSalaryParam,
    });

    const SalaryParametersDetailsPage = createSalaryParametersDetailsPage({
      useCombinedEmployeeCategory,
      useCombinedSalaryParam,
      useFileTransfer,
    });

    const SalaryAdvancesPage = renderSalaryAdvances({
      useCombinedSalaryAdvance,
      useCombinedEmployeeProfile,
      userRendererService,
      useCombinedCompanyAdmin,
    });

    const AdhocPaymentsPage = renderAdhocPayments({
      useCombinedEmployeeProfile,
    });

    const SalaryBonusPage = renderSalaryBonus({
      useCombinedSalaryBonus,
      useCombinedEmployeeProfile,
      userRendererService,
      useCombinedCompanyAdmin,
    });

    const EmployeeLeavesBank = renderEmployeeLeaves({
      useCombinedEmployeeProfile,
      useCombinedLeave,
      useFileTransfer,
      useCurrentUserRoles,
      leaveService,
      userRendererService,
    });

    const EmployeeRefillLeavesBank = renderRefillLeavesBook({
      useCombinedEmployeeProfile,
      useCombinedLeave,
      leaveService,
      hrdDashboardReportService,
    });

    const ExpireLeaveBank = renderExpireLeavesBook({
      hrdDashboardReportService,
      leaveService,
    });

    const EmployeeMonthLeavesBook = renderEmployeeMonthLeavesBook({
      useCombinedLeave,
      useFileTransfer,
      useCombinedEmployeeProfile,
      leaveService,
      userRendererService,
    });

    const MonthGatePasses = renderMonthGatePasses({
      mode: 'month-book',
      useCombinedEmployeeProfile,
      useCombinedGatePass,
      useCurrentUserRoles,
      userRendererService,
    });

    const MonthOvertimes = renderMonthOvertimes({
      useCombinedEmployeeProfile,
      useCombinedOvertime,
      useCurrentUserRoles,
      userRendererService,
      useCombinedLocationHoliday,
    });

    const OffSiteMonthWorkHours = renderOffSiteMonthWorkHours({
      useCombinedOffSiteWorkHour,
      useCombinedEmployeeProfile,
      userRendererService,
      useCurrentUserRoles,
    });

    const SchedulePage = renderWorkAreaPage({
      useCombinedEmployeeProfile,
      useCombinedGodown,
    });

    const SchedulePage1 = renderWorkAreaV2Page({
      useCombinedGodown,
    });

    const WorkAreaDetails = renderWorkAreaDetailsPage({
      useCombinedGodown,
      useCombinedBatchItemGodown,
      useCombinedShiftSchedule,
      useCombinedEmployeeProfile,
      useCombinedRotationalShiftDay,
    });

    const EmployeeShiftsPage = createEmployeeShiftsPage({
      useCurrentUserRoles,
      useCombinedShiftSchedule,
      useCombinedGodown,
      useCombinedRotationalShiftDay,
      useCombinedOvertime,
      useCombinedEmployeeProfile,
      useCombinedSwitchShift,
      userRendererService,
    });

    const EmployeeSwitchShiftsPage = renderEmployeeSwitchShifts({
      useCombinedSwitchShift,
      useCombinedEmployeeProfile,
      userRendererService,
      switchShiftService: switchShiftScheduleService,
    });

    const MonthSchedule = renderMonthSchedule({
      useCombinedRotationalShiftDay,
      useCombinedGodown,
    });

    const LoanManagement = renderLoanList({
      loansService,
      useCombinedLoan,
      useDisbursementData,
      useDisbursementPostData,
      useCombinedRepaymentSchedule,
      useCombinedEmployeeProfile,
      useCurrentUserRoles,
      userRendererService,
    });

    const LoanDetails = renderLoanDetails({
      useCombinedLoan,
      loanService: loansService,
    });

    const EmployeeOnboard = renderEmployeeOnboard({
      hooks: {
        useCombinedEmployeeProfile,
        useCombinedCompanyAdmin,
        useCombinedDepartment,
        useCombinedLocationAdmin,
        useCombinedOnboardEmployee,
        useCombinedEmployeeCategory,
      },
    });

    const EmployeeExit = renderEmployeeExit({
      hooks: {
        useCombinedEmployeeProfile,
      },
    });

    const OffBoardingActions = renderOffBoardingActions({
      useCombinedEmployeeProfile,
    });

    const FullNFinal = renderFullNFinal({
      useCombinedEmployeeProfile,
    });

    const DisciplinaryActions = renderMisconduct({
      useCombinedMisconduct,
      useCombinedRuleRequest,
      useCombinedMisconductRuleBook,
      useCombinedEmployeeProfile,
      useFileTransfer,
      useCurrentUserRoles,
      userRenderService: userRendererService,
    });

    const RuleRequests = renderRuleRequestDetails({
      useCombinedRuleRequest,
      useCombinedMisconductRuleBook,
      useCombinedMisconduct,
      userRenderService: userRendererService,
    });

    const Insurance = renderPolicies({
      useCombinedPolicy,
      useCombinedPremium,
      useCombinedEmployeeProfile,
      useCurrentUserRoles,
      useFileTransfer,
      userRendererService,
    });

    const EmployeeJobTask = renderEmployeeJobTask({
      useCombinedEmployeeProfile,
      useCombinedQuestionnaireSurveyAnswer,
    });

    // Inventory Modules
    const ItemV2List = renderItemV2List({
      useCombinedUser,
      useCombinedStockGroup,
      useCombinedItem,
      itemService: itemV2Service,
      useCombinedItemCategory,
      userRendererService,
    });
    const GradeList = renderGradeList({
      useCombinedItem,
      useCombinedGrade,
      useCombinedUser,
      userRendererService,
    });
    const StockGroupList = renderStockGroupList({
      useCombinedStockGroup,
      useCombinedUser,
      userRendererService,
    });

    const InventoryTree = createInventoryTree({
      useCombinedGrade,
      useGodownsTree,
      useCombinedItem,
      useCombinedBatchItemGodown,
      useCombinedGodown,
      fileTransferService,
      useCombinedStockGroup,
    });

    const GodownsList = renderGodownList({
      useCombinedGodown,
      useCombinedUser,
      userRendererService,
    });

    const ItemBatches = renderItemBatches({
      useCombinedUser,
      useCombinedItem,
      useCombinedGodown,
      useCombinedBatchItemGodown,
      userRendererService,
    });

    const Revision = renderRevisionDetails({
      useCombinedRevision,
      useCombinedChangeAssesment,
      revisionService,
      useFileTransfer,
      userRendererService,
    });

    const RevisionDetails = renderRevisionProfile({ useCombinedRevision });

    const GodownTransfer = renderGodownTransfer();

    const ItemRequisitionList = renderRequisitionList({
      hooks: {
        useCombinedGodown,
        useCombinedUser,
        useCombinedBatchItemGodown,
        useCombinedGrade,
        usePhysicalStock,
        useVirtualStock,
        useCombinedPurchaseRequisition,
        useMRS,
        useCombinedGodownTransfer,
        useCombinedBom,
        useCombinedBomProject,
      },
      services: {
        itemV2Service: itemV2Service,
        purchaseRequisitionService,
        bomFlowService,
        gradeService,
      },
    });

    const BomProjectDetails = renderBomProjectDetails({
      useCombinedBomProject,
      useCombinedUser,
    });

    const BomFlow = renderBomFlow({
      useCombinedBomFlow,
    });

    const StockItemSummary = renderStockItemSummary({
      useCombinedStockGroup,
      useCombinedItem,
      useCombinedGrade,
      useCombinedBatchItemGodown,
      useCombinedGodown,
    });

    const ItemV2Profile = renderItemV2Profile({
      hooks: {
        useCombinedGST,
        useCombinedItem,
        useCombinedVendor,
        useCombinedClient,
        useCombinedGrade,
        useFileTransfer,
        usePhysicalStock,
        useCombinedHazardGhsClass,
        useCombinedHazardStatement,
        useCombinedPrecautionaryStatement,
        useCombinedMetric,
        useCombinedAppearance,
        useCombinedColor,
        useCombinedOdour,
        useCombinedRevision,
        useCombinedEmployeeProfile,
        useCurrentUserRoles,
        useCombinedRole,
        useCombinedUser,
        useCombinedItemCategory,
      },
      services: { itemV2Service, stockGroupService },
    });

    const GradeDetails = renderGrade({
      useCombinedGrade,
      bomFlowService,
      useCurrentUserRoles,
      useCombinedGST,
      useCombinedMetric,
      useCombinedAppearance,
      useCombinedColor,
      useCombinedOdour,
      useCombinedRevision,
      useCombinedEmployeeProfile,
      gradeService,
      useCombinedRole,
      useCombinedUser,
      useFileTransfer,
    });

    const GodownProfile = renderGodownProfile({
      hooks: {
        useCombinedGodown,
        // useBatchItemGodowns,
        // useShiftSchedules,
        useCombinedEmployeeProfile,
        useFileTransfer,
        // useEmployeeProfile,
      },
    });

    const StockGroupProfile = renderStockGroupProfile({
      hooks: {
        useCombinedStockGroup,
        useFileTransfer,
        useCombinedMetric,
        useCombinedAppearance,
        useCombinedColor,
        useCombinedOdour,
        useCombinedEmployeeProfile,
        useCurrentUserRoles,
        useCombinedRevision,
        useCombinedRole,
        useCombinedUser,
        useCombinedGST,
      },
      services: { stockGroupService },
    });

    const BomDetails = renderBOMDetails({
      hooks: {
        useCombinedBom,
        usePhysicalStock,
        useVirtualStock,
        useCombinedBatchItemGodown,
        useBatchGodownItemAllocate,
        useCombinedPurchaseOrder,
        useVirtualItemAllocate,
      },
    });

    const BomFlowDetails = renderBomFlowDetails({
      useCombinedBomFlow,
      useCombinedGrade,
      useCombinedBomDependency,
    });

    // Miscellaneous

    const ReportList = renderReportList({
      useCombinedReport,
      userRendererService,
    });

    const QuestionnaireTopic = renderTopicForm({
      useCombinedQuestionnaireTopic,
      useCombinedQuestionnaireQuestion,
      useCombinedQuestionnaireSurveyAnswer,
      userRendererService,
    });

    const ReportBuilder = renderStatutoryReportBuilder({ useCombinedReport });

    const Questions = renderQuestionForm({
      useCombinedQuestionnaireQuestion,
      userRendererService,
      useCombinedQuestionnaireTopic,
    });

    const SurveyAnswersPage = renderSurveyAnswersPage({
      useCombinedQuestionnaireQuestion,
      useCombinedQuestionnaireSurveyAnswer,
    });

    const SubmittedAnswersPage = renderSubmittedAnswers({
      useCombinedQuestionnaireSurveyAnswer,
      useCombinedQuestionnaireQuestion,
    });

    // Production
    const MachineSchedule = renderMachineSchedule({ useGetMachineSchedule });

    const MachineList = renderMachinesList({
      hooks: { useCombinedMachine, useCombinedShiftSchedule },
    });

    const Plan = renderPlan({
      hooks: {
        useCombinedMachine,
        useCombinedManufacturingVoucher,
        useCombinedMachineSchedule,
      },
    });

    const ManufacturingVoucherList = createManufacturingVoucherList({
      hooks: {
        useCombinedItem,
        useCombinedUser,
        useCombinedBom,
        useCombinedGodownTransfer,
        useCombinedManufacturingVoucher,
      },
      services: {
        machineService,
        batchItemService: batchItemGodownService,
      },
    });

    const Machine = renderMachine({ hooks: { useGetMachineSchedule } });

    const ManufacturingVoucherDetails = renderManufacturingVoucherDetails({
      hooks: { useCombinedManufacturingVoucher },
    });

    // Purchase
    const VendorList = renderVendorList({
      useCombinedVendor,
      useCombinedUser,
      userRendererService,
    });

    const VendorGodowns = renderVendorGodowns({
      useCombinedLocationAdmin,
      useCombinedGodown,
    });

    const VendorV2Profile = renderVendorV2Profile({
      useCombinedBatchItemGodown,
      useCombinedVendor,
      useFileTransfer,
      usePublicTransfer,
      useCombinedItem,
      useVendorItem,
      useCombinedStockGroup,
      useCombinedDeliveryTerm,
      useCombinedPaymentTerm,
      itemService: itemV2Service,
      useCombinedGodown,
      useCombinedLicenseContract,
    });

    const PurchaseRequisitions = renderPurchaseRequisitions({
      purchaseOrderService,
      purchaseEnquiryService,
      useCombinedGrade,
      useCombinedUser,
      useCombinedPurchaseRequisition,
      useCombinedVendor,
      useCombinedDeliveryTerm,
      useCombinedPaymentTerm,
      useCombinedBom,
      useCombinedPurchaseOrder,
      useCombinedPurchaseEnquiry,
      vendorService,
      useFileTransfer,
      itemService: itemV2Service,
      useCombinedItem,
      userRendererService,
    });

    const PurchaseOrderDetails = renderPurchaseOrderDetails({
      hooks: {
        useCombinedPurchaseOrderFulfilment,
        useCombinedPurchaseOrder,
        useCombinedBatchItemGodown,
        useCombinedUser,
        useFileTransfer,
        useCombinedQuestionnaireQuestion,
        useCombinedTemplate,
      },
      services: {
        itemService: itemV2Service,
      },
    });

    const PurchaseItemInspection = renderPurchaseItemInspection({
      useCombinedPurchaseOrderFulfilment,
      useCombinedQuestionnaireQuestion,
      itemService: itemV2Service,
      useCombinedTemplate,
      useFileTransfer,
    });

    const GoodsReceived = renderGoodsReceived({
      useCombinedUser,
      useCombinedPurchaseOrderFulfilment,
      useCombinedQuestionnaireQuestion,
      itemService: itemV2Service,
      useCombinedTemplate,
      useFileTransfer,
    });

    const ItemTransfer = () => {
      return (
        <PurchaseItemTransfer
          useCombinedPurchaseOrderFulfilment={
            useCombinedPurchaseOrderFulfilment
          }
        />
      );
    };

    const PRDetails = renderPRDetails({
      services: { purchaseRequisitionService },
      hooks: {
        useCombinedPurchaseRequisition,
        useCombinedBom,
      },
    });

    const VendorItemSpec = renderVendorItemSpec({
      itemService: itemV2Service,
      useCombinedGST,
      useCombinedMetric,
      useCombinedVendor,
    });

    const EnquiryDetails = renderEnquiryDetails({
      hooks: {
        useCombinedEnquiryQuote,
        useCombinedPurchaseOrder,
        useVirtualItemAllocate,
        useCombinedPurchaseEnquiry,
        useFileTransfer,
        useCombinedBom,
        useCombinedItem,
        useCombinedStockGroup,
        useCombinedPurchaseRequisition,
      },
    });

    // Quality Assurance
    const ChangeControlList = renderChangeControlReqDetails({
      useCombinedChangeAssesment,
      useCombinedClosureDatePeriod,
      useCombinedDepartment,
      useCombinedEmployeeProfile,
      useFileTransfer,
      useCombinedUser,
    });

    const ClosureDatePeriod = renderClosureDatePeriod({
      useCombinedClosureDatePeriod,
      userRendererService,
    });

    const RequisitionDtls = renderRequisitionDetails({
      useCombinedChangeAssesment,
    });

    // Sales
    const ClientList = renderClientList({
      useCombinedUser,
      useCombinedClient,
      useCombinedLeadCustomer,
      userRendererService,
    });

    const ClientGodowns = renderClientGodowns({
      useCombinedLocationAdmin,
      useCombinedGodown,
    });

    const LeadCustomerList = renderLeadCustomerList({
      useCombinedUser,
      useCombinedLeadCustomer,
      userRendererService,
    });

    // const SalesEnquiryTabs = renderSalesMasters({
    //   useCombinedClient,
    //   useVirtualMfStock,
    //   useVirtuallyReserveMfVoucher,
    //   useCombinedSalesOrder,
    //   useCombinedSalesEnquiry,
    //   useCombinedLeadCustomerEnquiry,
    //   usePhysicalStock,
    //   useCombinedBatchItemGodown,
    //   useBatchGodownItemAllocate,
    //   useCombinedManufacturingVoucher,
    //   useCombinedItem,
    //   useCombinedUser,
    //   useCombinedEmployeeProfile,
    //   useCombinedMetric,
    //   useCombinedLeadCustomer,
    //   userRendererService,
    //   useCombinedLeadEnquiry,
    // });

    const SalesOrderTabs = renderSalesOrderTabs({
      hooks: {
        useCombinedProductBatch,
        useCombinedQuestionnaireQuestion,
        gradeService,
        useCombinedGrade,
        useCombinedItem,
        useCombinedClient,
        useCombinedSalesOrder,
        usePhysicalStock,
        useBatchGodownItemAllocate,
        useCombinedBatchItemGodown,
        useVirtualMfStock,
        useCombinedManufacturingVoucher,
        useVirtuallyReserveMfVoucher,
        useCombinedUser,
        userRendererService,
      },
    });

    // const SalesEnquiryDetailsV2 = renderSalesEnquiryDetailsV2({
    //   hooks: {
    //     useCombinedSalesEnquiry,
    //   },
    // });

    const ClientV2Profile = renderClientV2Profile({
      useCombinedGodown,
      useCombinedClient,
      useCombinedSalesOrder,
      useCombinedDeliveryTerm,
      useCombinedItem,
      useCombinedPaymentTerm,
      useCombinedGrade,
      useCombinedClientItem,
      useFileTransfer,
      usePublicTransfer,
      itemService: itemV2Service,
      userRendererService,
    });

    const LeadCustomerProfile = renderLeadCustomerProfile({
      useCombinedLeadCustomer,
      useCombinedClient,
      useFileTransfer,
      usePublicTransfer,
    });

    // const LeadCustomerEnquirDetails = renderLeadCustomerEnquiryDetails({
    //   useCombinedItem,
    //   useCombinedEmployeeProfile,
    //   useCombinedLeadCustomer,
    //   useCombinedLeadCustomerEnquiry,
    //   useCombinedMetric,
    //   useCombinedClient,
    //   useCombinedClientItem,
    //   itemService: itemV2Service,
    // });

    // const SalesOrderDetailsV2 = renderSalesOrderDetailsV2({
    //   hooks: {
    //     useCombinedSalesOrder,
    //   },
    // });

    // const SalesItemEnquiryDetails = renderSalesItemEnquiryDetails({
    //   useCombinedSalesEnquiry,
    //   usePhysicalStock,
    //   useCombinedBatchItemGodown,
    //   useBatchGodownItemAllocate,
    //   useVirtualMfStock,
    //   useCombinedManufacturingVoucher,
    //   useVirtuallyReserveMfVoucher,
    // });

    // const SalesItemOrderDetails = renderSalesItemOrderDetails({
    //   useCombinedSalesOrder,
    //   usePhysicalStock,
    //   useBatchGodownItemAllocate,
    //   useCombinedBatchItemGodown,
    //   useVirtualMfStock,
    //   useCombinedManufacturingVoucher,
    //   useVirtuallyReserveMfVoucher,
    // });

    // const SalesManufacturingEnquiryDetails = renderSalesManufacturingEnquiryDetails();

    // const SalesManufacturingOrderDetails = renderSalesManufacturingOrderDetails();

    // const SalesQcEnquiryDetails = renderSalesQcEnquiryDetails();

    // const SalesQcOrderDetails = renderSalesQcOrderDetails();

    // const SalesEnquiryItemsDetailsV2 = renderSalesEnquiryItemsDetailsV2({
    //   hooks: {
    //     useCombinedSalesEnquiry,
    //   },
    // });

    const SalesRegister = renderSalesRegisterForm({
      useCombinedClient,
      useCombinedGrade,
    });

    const EnquiryDtls = renderdSalesDetails({
      useCombinedClient,
      useCombinedGrade,
    });

    // Dispatch Schedule
    const DispatchSchedule = renderDispatchScheduleForm();

    const OADetails = renderOAInfoForm();

    // Users
    const UserProfile = renderUserProfile({ useCombinedEmployeeProfile });

    this.modules = [
      // {
      //   name: 'Login',
      //   icon: LuLogIn,
      //   path: '/login',
      //   sidebarLinks: [],
      //   content: this.LoginPage,
      //   hidden: true,
      // },
      {
        name: 'System',
        icon: () => <ComputerDesktopIcon className='inline h-5' />,
        path: '/system',
        sidebarLinks: [
          {
            name: 'Administration',
            icon: () => <Account className='w-5 inline stroke-0' />,
            path: '/system/admin',
            type: 'menu',
            auth: 'UI:ADM:VIEW',
            menu: [
              {
                name: 'Company Group',
                path: '/system/admin/group',
                auth: 'UI:RECAT-ADM:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Company',
                path: '/system/admin/company',
                auth: 'UI:ADM:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/company-profile',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/registration',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/history',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/location',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Location',
                path: '/system/admin/location',
                auth: 'UI:ADM:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Location',
                hidden: true,
                path: '/system/admin/location/profile/:id/location-profile',
                auth: 'UI:ADM:VIEW',
                content: LocationDetails,
              },
              {
                name: 'Module',
                path: '/system/admin/module',
                auth: 'UI:PGMODULE:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Resource',
                path: '/system/admin/resource',
                auth: 'UI:PGRESOURCE:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Authorization',
                path: '/system/admin/authorization',
                auth: 'UI:PGAUTHORIZATION:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Action',
                path: '/system/admin/action',
                content: AdminMasters,
                auth: 'UI:PGACTION:VIEW',
              },
              {
                name: 'Role',
                path: '/system/admin/role',
                content: AdminMasters,
                auth: 'UI:PGUSERS:VIEW',
              },
              {
                name: 'Users',
                path: '/system/admin/user',
                content: AdminMasters,
                auth: 'UI:PGUSERS:VIEW',
              },
              {
                name: 'Notification',
                path: '/system/admin/notification',
                auth: 'UI:PGAUTHORIZATION:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Notification-v2',
                path: '/system/admin/notification-v2',
                auth: 'UI:PGAUTHORIZATION:VIEW',
                content: AdminMasters,
              },
              // {
              //   name: 'Users',
              //   type: 'path',
              //   path: '/users-master',
              //   component: component['UserAdmin'],
              //   auth: 'UI:PGUSERS:VIEW',
              //   permissions: []
              // },
            ],
          },
          {
            name: 'Group Setting',
            icon: () => <CubeTransparentIcon className='inline h-5' />,
            auth: 'UI:ADM:VIEW',
            type: 'menu',
            path: '/system/group-settings',
            menu: [
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/ghs-class-category',
                auth: '',
                content: HazardRegulations,
              },
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/hazard-class/:id',
                auth: '',
                hidden: true,
                content: HazardClassPage,
              },
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/ghs-class-category',
                auth: '',
                hidden: true,
                content: HazardRegulations,
              },
              {
                name: 'Hazard Regulations',
                path: '/system/group-settings/hazard-regulations/h-statements',
                auth: '',
                hidden: true,
                content: HazardRegulations,
              },
              {
                name: 'Hazard Regulations',
                path: '/system/group-settings/hazard-regulations/p-statements',
                auth: '',
                hidden: true,
                content: HazardRegulations,
              },
              {
                name: 'Config',
                path: '/system/group-settings/config/inventory',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/inventory-templates',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/work-order',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/quality-assurance',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/purchase',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/sales',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/company-group',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/license-contract',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/hrd',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/history',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Constants',
                path: '/system/group-settings/constants',
                content: Constants,
                auth: '',
              },
              {
                name: 'Database Settings',
                path: '/system/group-settings/database',
                content: DatabaseSettings,
                auth: 'UI:RECAT-ADM:VIEW',
              },
              {
                name: 'Dashboard',
                path: '/system/group-settings/dashboard',
                content: DashboardSettings,
                auth: '',
              },
              {
                name: 'Dashboard Module',
                path: '/system/group-settings/dashboard/module/:module',
                content: DashboardModule,
                hidden: true,
              },
            ],
          },
          {
            name: 'Scheduler',
            icon: () => <History className='w-5 inline stroke-0' />, // as () => JSX.Element,
            auth: 'UI:TSK-SCH:VIEW',
            type: 'menu',
            path: '/system/scheduler',
            menu: [
              {
                name: 'Task',
                path: '/system/scheduler/tasks',
                auth: 'UI:TSK-SCH:VIEW',
                content: Tasks,
              },
              {
                name: 'Task Jobs',
                path: '/system/scheduler/tasks/:id/jobs',
                auth: 'UI:TSK-SCH:VIEW',
                hidden: true,
                content: Jobs,
              },
            ],
          },
        ],
      },
      {
        name: 'Accounts',
        icon: () => <BookOpenIcon className='h-5 inline' />,
        path: '/accounts',
        sidebarLinks: [
          {
            name: 'Tally Masters',
            path: '/accounts/tally-masters',
            icon: () => <BookOpenIcon className='h-5 inline' />,
            type: 'menu',
            menu: [
              {
                name: 'Daybook',
                path: '/accounts/tally-masters/daybook',
                content: TallyDayBook,
              },
              {
                name: 'Groups',
                path: '/accounts/tally-masters/groups',
                content: GroupsPage,
              },
              {
                name: 'Ledgers',
                path: '/accounts/tally-masters/ledgers',
                content: LedgersPage,
              },
            ],
          },
          {
            name: 'Tally Reports',
            path: '/accounts/tally-reports',
            icon: () => <BookOpenIcon className='h-5 inline' />,
            type: 'menu',
            menu: [
              {
                name: 'Account-Summary',
                path: '/accounts/tally-reports/account-summary',
                content: AccountSummary,
              },
              {
                name: 'Balance-Sheet',
                path: '/accounts/tally-reports/balance-sheets',
                content: BalanceSheet,
              },
              {
                name: 'Profit-and-Loss',
                path: '/accounts/tally-reports/profit-n-loss',
                content: ProfitNLoss,
              },
            ],
          },
          {
            name: 'Disbursements',
            path: '/accounts/disbursements',
            icon: () => <ArrowRightOnRectangleIcon className='h-5 inline' />,
            type: 'menu',
            auth: 'UI:SAL-REP:VIEW',
            menu: [
              {
                name: 'Bank-Salary-Details',
                path: '/accounts/disbursements/bank-salary-reports',
                auth: 'UI:SAL-REP:VIEW',
                content: BankSalaries,
              },
            ],
          },
          {
            name: 'Cashflow',
            path: '/accounts/cashflow',
            icon: () => <CurrencyRupeeIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:CSH-FLW:VIEW',
            menu: [
              {
                name: 'Cashflow',
                path: '/accounts/cashflow/cashflow-details',
                auth: 'UI:CSH-FLW:VIEW',
                content: CashFlowReport,
              },
            ],
          },
          {
            name: 'Activities',
            path: '/accounts/activities',
            icon: () => <CheckIcon className='h-5 inline' />,
            type: 'menu',
            auth: 'UI:ACC-ACT:VIEW',
            menu: [
              {
                name: 'Daybook',
                path: '/accounts/activities/daybook',
                auth: 'UI:ACC-ACT:VIEW',
                content: Daybook,
              },
            ],
          },
          {
            name: 'Ledgers',
            path: '/accounts/ledgers',
            icon: () => <BookOpenIcon className='h-5 inline' />,
            type: 'menu',
            auth: 'UI:ACC-LEG:VIEW',
            menu: [
              {
                name: 'Bills',
                path: '/accounts/ledgers/bills',
                auth: 'UI:ACC-LEG:VIEW',
                content: Bill,
              },
              {
                name: 'Party',
                path: '/accounts/ledgers/party',
                auth: 'UI:ACC-LEG:VIEW',
                content: UnderConstruction,
              },
            ],
          },
          {
            name: 'Reports',
            path: '/accounts/reports',
            icon: () => <DocumentIcon className='h-5 inline' />,
            type: 'menu',
            auth: 'UI:ACC-REP:VIEW',
            menu: [],
          },
        ],
      },
      {
        name: 'HRD',
        icon: () => <UserCircleIcon className='h-5 inline' />,
        path: '/hrd',
        sidebarLinks: [
          {
            name: 'Resources',
            path: '/hrd/resources',
            icon: () => <CircleStackIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:EMP-LIST:VIEW',
            menu: [
              {
                name: 'Employees',
                path: '/hrd/resources/employees',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2List,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/profile',
                auth: '', // Skipping as it is disabling self profile
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/job-profile',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/letters',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/disciplinary-actions',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/leave',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/attendance',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/history',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/loan-advance',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/authorisers',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Holidays',
                path: '/hrd/resources/holidays',
                auth: 'UI:LOC-HOL:VIEW',
                content: Holidays,
              },
              {
                name: 'Attendance Devices',
                path: '/hrd/resources/attendance-devices',
                auth: 'UI:LOC-HOL:VIEW',
                content: () => <AttendanceDevice cgsDetails={{}} />,
              },
            ],
          },
          {
            name: 'Department',
            path: '/hrd/department',
            icon: () => <BuildingOfficeIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:DEPT-LIST:VIEW',
            menu: [
              {
                name: 'Department (Old)',
                path: '/hrd/department/old-list',
                auth: 'UI:DEPT-LIST:VIEW',
                content: Department,
              },
              {
                name: 'Department List',
                path: '/hrd/department/list',
                auth: 'UI:DEPT-LIST:VIEW',
                content: DepartmentList,
              },
              {
                name: 'WorkLog Report',
                path: '/hrd/department/workLog-report',
                auth: 'UI:WRK-LOG:VIEW',
                content: WorkLogReport,
              },
              {
                name: 'Employee Job Task',
                hidden: true,
                path: '/hrd/department/emp-job-task/:id',
                auth: 'UI:WRK-LOG:VIEW',
                content: EmployeeJobTask,
              },
            ],
          },
          {
            name: 'Attendance',
            path: '/hrd/attendance',
            icon: () => <FingerPrintIcon className='h-5 inline' />,
            type: 'menu',
            auth: 'UI:ATTEN:VIEW',
            menu: [
              {
                name: 'Day-Book',
                path: '/hrd/attendance/day-book',
                auth: 'UI:ATTEN:VIEW',
                content: NewAttendancePage,
              },
              {
                name: 'Attendance Details',
                path: '/hrd/attendance/date/:date/employee/:id',
                hidden: true,
                auth: 'UI:ATTEN:VIEW',
                content: AttendanceDetails,
              },
              {
                name: 'Employee-Book',
                path: '/hrd/attendance/employee-attendance',
                auth: 'UI:ATTEN-EBOOK:VIEW',
                content: EmployeeAttendacePage,
              },
              {
                name: 'Pending-Actions',
                path: '/hrd/attendance/employee-pending-actions',
                auth: 'UI:ATTEN:VIEW',
                content: EmployeePendingActionsPage,
              },
            ],
          },
          {
            name: 'Payroll',
            path: '/hrd/payroll',
            icon: () => <CurrencyRupeeIcon className='h-5 inline' />,
            type: 'menu',
            auth: 'UI:SAL:VIEW',
            menu: [
              {
                name: 'Salary-Book',
                path: '/hrd/payroll/salary-book',
                auth: 'UI:SAL:VIEW',
                content: SalaryPage,
              },
              {
                name: 'Salary-Revisions',
                path: '/hrd/payroll/salary-revisions',
                auth: 'UI:SAL-REV:VIEW',
                content: SalaryRevisionPage,
              },
              {
                name: 'Salary-Parameters',
                path: '/hrd/payroll/salary-parameters',
                auth: 'UI:SAL-PAR:VIEW',
                content: SalaryParametersPage,
              },
              {
                name: 'Salary-Parameters',
                hidden: true,
                path: '/hrd/payroll/salary-parameters/:id',
                auth: 'UI:SAL-PAR:VIEW',
                content: SalaryParametersDetailsPage,
              },
              {
                name: 'Salary-Advances',
                path: '/hrd/payroll/salary-advances',
                auth: 'UI:LON-ADV:VIEW',
                content: SalaryAdvancesPage,
              },
              {
                name: 'Salary-Bonus',
                path: '/hrd/payroll/salary-bonus',
                auth: 'UI:LON-ADV:VIEW',
                content: SalaryBonusPage,
              },
              {
                name: 'Adhoc-Payments',
                path: '/hrd/payroll/adhoc-payments',
                auth: 'UI:ADHC-PYMT:VIEW',
                content: AdhocPaymentsPage,
              },
            ],
          },
          {
            name: 'Leaves',
            path: '/hrd/leaves',
            icon: () => <CalendarIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Leaves-Bank',
                path: '/hrd/leaves/leaves-bank',
                auth: 'UI:LEAVE-BANK:VIEW',
                content: EmployeeLeavesBank,
              },
              {
                name: 'Refill-Leaves-Bank',
                path: '/hrd/leaves/refill-leaves-bank',
                auth: 'UI:REFILL-LEAVE-BANK:VIEW',
                content: EmployeeRefillLeavesBank,
              },
              {
                name: 'Expire-Leaves',
                path: '/hrd/leaves/expire-leaves-bank',
                auth: 'UI:REFILL-LEAVE-BANK:VIEW',
                content: ExpireLeaveBank,
              },
              {
                name: 'Leaves-Book',
                path: '/hrd/leaves/leaves-book',
                auth: 'UI:LEAVE:VIEW',
                content: EmployeeMonthLeavesBook,
              },
              {
                name: 'Gate-Passes',
                path: '/hrd/leaves/gate-passes',
                auth: 'UI:GATEP:VIEW',
                content: MonthGatePasses,
              },
              {
                name: 'Overtimes',
                path: '/hrd/leaves/overtimes',
                auth: 'UI:OVERT:VIEW',
                content: MonthOvertimes,
              },
              {
                name: 'Off-site Work-hours',
                path: '/hrd/leaves/off-site-work-hours',
                auth: 'UI:OFFSITEWORK:VIEW',
                content: OffSiteMonthWorkHours,
              },
            ],
          },
          {
            name: 'Shift Management',
            path: '/hrd/shift-management',
            icon: () => <ClockIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Employee-Shifts',
                path: '/hrd/shift-management/employee-shifts',
                auth: 'UI:EMP-SHFTS:VIEW',
                content: EmployeeShiftsPage,
              },
              {
                name: 'Switch-Shifts',
                path: '/hrd/shift-management/employee-switch-shifts',
                auth: 'UI:EMP-SHFT-SCH:VIEW',
                content: EmployeeSwitchShiftsPage,
              },
              // {
              //   name: 'Employees Shift-Schedule-List',
              //   type: 'path',
              //   auth: 'UI:EMP-SHFT-SCH:VIEW',
              //   path: '/employee-shift-schedule',
              //   component: component['EmpShiftScheduleList'],
              //   permissions: []
              // },
              {
                name: 'Month-Schedule',
                path: '/hrd/shift-management/month-schedule',
                auth: 'UI:MON-SHFT-SCH:VIEW',
                content: MonthSchedule,
              },
              {
                name: 'Work-Areas',
                path: '/hrd/shift-management/schedule-page-v2',
                auth: 'UI:WORK-AREA:VIEW',
                content: SchedulePage1,
              },
              {
                name: 'Work-Areas',
                hidden: true,
                path: '/hrd/shift-management/work-area/:id',
                auth: 'UI:WORK-AREA:VIEW',
                content: WorkAreaDetails,
              },
              {
                name: 'WA-Allocator',
                path: '/hrd/shift-management/schedule-page',
                auth: 'UI:WA-ALLOC:VIEW',
                content: SchedulePage,
              },
              // {
              //   name: 'Schedule Allocator',
              //   type: 'path',
              //   auth: 'UI:EMP-SHFT-SCH:VIEW',
              //   path: '/schedule-allocator',
              //   component: component['ScheduleAllocatorPage'],
              //   permissions: []
              // },
              // {
              //   name: 'Schedule Allocator',
              //   type: 'path',
              //   auth: 'UI:EMP-SHFT-SCH:VIEW',
              //   path: '/schedule-allocator-1',
              //   component: component['ScheduleAllocatorPage1'],
              //   permissions: [],
              // },
            ],
          },
          {
            name: 'Loan Management',
            path: '/hrd/loan-management',
            icon: () => <CreditCardIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Loans',
                path: '/hrd/loan-management/loans',
                auth: 'UI:LOANS-LIST:VIEW',
                content: LoanManagement,
              },
              {
                name: 'Loans',
                path: '/hrd/loan-management/loans/:id',
                hidden: true,
                auth: 'UI:LOANS-LIST:VIEW',
                content: LoanDetails,
              },
            ],
          },
          {
            name: 'Employee Onboard',
            path: '/hrd/employee-onboard',
            icon: () => <ArrowLeftOnRectangleIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Employee Onboard',
                path: '/hrd/employee-onboard/employee-onboard',
                auth: 'UI:EMP-ONBD:VIEW',
                content: EmployeeOnboard,
              },
            ],
          },
          {
            name: 'Employee Exit',
            path: '/hrd/employee-exit',
            icon: () => <ArrowRightOnRectangleIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Exit Date And Reason',
                path: '/hrd/employee-exit/exit-date-and-reason',
                auth: 'UI:EMP-EXIT:VIEW',
                content: EmployeeExit,
              },
              {
                name: 'Off-Boarding Actions',
                path: '/hrd/employee-exit/off-boarding-actions',
                auth: 'UI:EMP-EXIT:VIEW',
                content: OffBoardingActions,
              },
              {
                name: 'Full-N-Final',
                path: '/hrd/employee-exit/full-n-final',
                auth: 'UI:EMP-EXIT:VIEW',
                content: FullNFinal,
              },
            ],
          },
          {
            name: 'Disciplinary Actions',
            path: '/hrd/disciplinary-actions',
            icon: () => <ScaleIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Misconduct',
                path: '/hrd/disciplinary-actions/misconduct',
                auth: 'UI:DIS-ACT:VIEW',
                content: DisciplinaryActions,
              },
              {
                name: 'Rule Request',
                path: '/hrd/disciplinary-actions/rule-request',
                auth: 'UI:DIS-ACT:VIEW',
                content: RuleRequests,
              },
            ],
          },
          {
            name: 'Insurance',
            path: '/hrd/insurance',
            icon: () => <ShieldCheckIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Policies',
                path: '/hrd/insurance/policies',
                auth: 'UI:INS-POL:VIEW',
                content: Insurance,
              },
            ],
          },
        ],
      },
      {
        name: 'Inventory',
        icon: () => <InboxStackIcon className='inline h-5' />,
        path: '/inventory',
        sidebarLinks: [
          {
            name: 'Masters',
            path: '/inventory/masters',
            icon: () => <InboxStackIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:INV-MGMT:VIEW',
            menu: [
              {
                name: 'Grades',
                path: '/inventory/masters/grades',
                auth: 'UI:INV-LIST:VIEW',
                content: GradeList,
              },
              {
                name: 'Items',
                path: '/inventory/masters/items',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2List,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/properties',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/grades',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/specifications',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/label',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/stp',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/haz-reg-msds',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/end-use',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/packing',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/bom',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/shift-config',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/batches',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/stock',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/vendors-customers',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/vendors',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/customers',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/authorizers',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/history',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Item-Grade',
                hidden: true,
                path: '/inventory/masters/items/:id/grade/:id/properties',
                auth: 'UI:INV-LIST:VIEW',
                content: GradeDetails,
              },
              {
                name: 'Item-Grade',
                hidden: true,
                path: '/inventory/masters/items/:id/grade/:id/bom-charts',
                auth: 'UI:INV-LIST:VIEW',
                content: GradeDetails,
              },
              {
                name: 'StockGroups',
                path: '/inventory/masters/stock-groups',
                auth: 'UI:SG-LIST:VIEW',
                content: StockGroupList,
              },
              {
                name: 'StockGroups',
                hidden: true,
                path: '/inventory/masters/stock-groups/:id/profile',
                auth: 'UI:SG-LIST:VIEW',
                content: StockGroupProfile,
              },
              {
                name: 'Godowns',
                path: '/inventory/masters/godowns',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownsList,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/profile',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/authorisers',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/shift-config',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/history',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Tree',
                path: '/inventory/masters/tree',
                auth: 'UI:INV-TREE:VIEW',
                content: InventoryTree,
              },
              {
                name: 'Item Batches',
                path: '/inventory/masters/item-batches',
                auth: 'UI:ITM-BATCH:VIEW',
                content: ItemBatches,
              },
              {
                name: 'Revisions',
                path: '/inventory/masters/revisions',
                auth: 'UI:INV-LIST:VIEW',
                content: Revision,
              },
              {
                name: 'Revisions',
                hidden: true,
                path: '/inventory/masters/revisions/:id',
                auth: 'UI:INV-LIST:VIEW',
                content: RevisionDetails,
              },
            ],
          },
          {
            name: 'Operations',
            path: '/inventory/operations',
            icon: () => <PaperAirplaneIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:INV-MGMT:VIEW',
            menu: [
              {
                name: 'Godown Transfers',
                path: '/inventory/operations/godown-transfer',
                auth: 'UI:GDN-TRANS:VIEW',
                content: GodownTransfer,
              },
            ],
          },
          {
            name: 'Registers',
            path: '/inventory/registers',
            icon: () => <ClipboardDocumentListIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:INV-REQ:VIEW',
            menu: [
              {
                name: 'BOM Project Register',
                path: '/inventory/registers/bom-project-register',
                auth: 'UI:INV-REQ:VIEW',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Project Register',
                path: '/inventory/registers/bom-projects/:id',
                auth: 'UI:INV-REQ:VIEW',
                hidden: true,
                content: BomProjectDetails,
              },
              {
                name: 'BOM Register',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-register',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Draft Register',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-project-draft',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Draft',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-draft',
                content: ItemRequisitionList,
              },
              {
                name: 'MRS Register',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/mrs-register',
                content: ItemRequisitionList,
              },
              {
                name: 'MRS Completed',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/mrs-completed',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Flow',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-flow',
                content: BomFlow,
              },
              {
                name: 'BOM Flow',
                hidden: true,
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-flow/:id',
                content: BomFlowDetails,
              },
              {
                name: 'BOM',
                auth: 'UI:INV-REQ:VIEW',
                hidden: true,
                path: '/inventory/registers/bom/:id/details',
                content: BomDetails,
              },
              {
                name: 'BOM',
                auth: 'UI:INV-REQ:VIEW',
                hidden: true,
                path: '/inventory/registers/bom/:id/history',
                content: BomDetails,
              },
            ],
          },
          {
            name: 'Reports',
            path: '/inventory/reports',
            icon: () => <TableCellsIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:INV-MGMT:VIEW',
            menu: [
              {
                name: 'Stock Item Summary',
                path: '/inventory/reports/stock-item-summary',
                auth: 'UI:ITM-BATCH:VIEW',
                content: StockItemSummary,
              },
              // {
              //   name: 'Item Transactions',
              //   type: 'path',
              //   path: '/management/item-transactions',
              //   // Icon: Square2StackIcon as () => JSX.Element,
              //   auth: 'UI:ITM-BATCH:VIEW',
              //   component: component['ItemTransactions'],
              //   permissions: [],
              // },
            ],
          },
        ],
      },
      {
        name: 'Miscellaneous',
        icon: () => <EllipsisHorizontalCircleIcon className='inline h-5' />,
        path: '/miscellaneous',
        sidebarLinks: [
          {
            name: 'Reports',
            path: '/miscellaneous/reports',
            icon: () => <DocumentTextIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Report List',
                path: '/miscellaneous/reports/list',
                auth: '',
                content: ReportList,
              },
              {
                name: 'Report Builder',
                hidden: true,
                path: '/miscellaneous/reports/:id',
                auth: '',
                content: ReportBuilder,
              },
            ],
          },
          {
            name: 'Questionnaire',
            path: '/miscellaneous/questionnaire',
            icon: () => <QuestionMarkCircleIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Topics',
                path: '/miscellaneous/questionnaire/topics',
                auth: '',
                content: QuestionnaireTopic,
              },
              {
                name: 'Questions',
                hidden: true,
                path: '/miscellaneous/questionnaire/questions/:id',
                auth: '',
                content: Questions,
              },
              {
                name: 'Survey Answers',
                hidden: true,
                path:
                  '/miscellaneous/questionnaire/survey-answers/:id/date/:date',
                auth: '',
                content: SurveyAnswersPage,
              },
              {
                name: 'Submitted Answers',
                hidden: true,
                path: '/miscellaneous/questionnaire/:id/survey-answers/:ans',
                auth: '',
                content: SubmittedAnswersPage,
              },
              {
                name: 'Survey Ansers',
                hidden: true,
                path: '/miscellaneous/questionnaire/survey-successful',
                auth: '',
                content: SurveyAnswerSubmitComponent,
              },
            ],
          },
          {
            name: 'Templates',
            icon: () => <Mail className='w-5 inline stroke-0' />, // as () => JSX.Element,
            // auth: 'UI:DES-TEMP:VIEW',
            type: 'menu',
            path: '/miscellaneous/templates',
            menu: [
              {
                name: 'Template',
                path: '/miscellaneous/templates/template',
                // auth: 'UI:DES-TEMP:VIEW',
                content: Template,
              },
            ],
          },
        ],
      },
      {
        name: 'Production',
        icon: MachineIcon,
        path: '/production',
        sidebarLinks: [
          {
            name: 'Scheduler',
            path: '/production/scheduler',
            icon: () => <History className='w-5 inline stroke-0' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Schedule Production',
                path: '/production/scheduler/schedules/:id',
                auth: 'UI:MCN-SCH:VIEW',
                content: MachineSchedule,
              },
              {
                name: 'Machine List',
                path: '/production/scheduler/machines',
                auth: 'UI:MCN-LIST:VIEW',
                content: MachineList,
              },
              {
                name: 'Machine',
                path: '/production/scheduler/machines/:id',
                auth: 'UI:MCN-LIST:VIEW',
                content: Machine,
              },
              {
                name: 'Production Plan',
                path: '/production/scheduler/plan',
                auth: 'UI:PRD-PLAN:VIEW',
                content: Plan,
              },
            ],
          },
          {
            name: 'Manufacturing Voucher',
            path: '/production/manufacturing-voucher',
            icon: () => <Label className='w-5 inline stroke-0' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'List',
                path: '/production/manufacturing-voucher/list',
                auth: 'UI:MAN-VCHR:VIEW',
                content: ManufacturingVoucherList,
              },
              {
                name: 'Details',
                path: '/production/manufacturing-voucher/details/:id',
                auth: 'UI:MAN-VCHR:VIEW',
                content: ManufacturingVoucherDetails,
              },
            ],
          },
        ],
      },
      {
        name: 'Purchase',
        icon: () => <ShoppingCartIcon className='inline h-5' />,
        path: '/purchase',
        sidebarLinks: [
          {
            name: 'Vendors',
            path: '/purchase/masters',
            icon: () => <CurrencyRupeeIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Vendor List',
                path: '/purchase/masters/vendors',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorList,
              },
              {
                name: 'Vendor Godowns',
                path: '/purchase/masters/vendor-godowns',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorGodowns,
              },
              {
                name: 'Contracts',
                path: '/purchase/masters/vendor-contracts',
                auth: 'UI:VEN-LIST:VIEW',
                content: () => <div>Vendor Contracts</div>,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/overview',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/purchase-orders',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/godown',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/contracts',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/profile',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/transactions',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/enquiry-and-offers',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/history',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor Items',
                hidden: true,
                path:
                  '/purchase/masters/vendor-items/:id/:vendorId/specifications',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorItemSpec,
              },
            ],
          },
          {
            name: 'Registers',
            path: '/purchase/registers',
            icon: () => <TagIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Purchase Requisition',
                path: '/purchase/registers/purchase-requisition-register',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseRequisitions,
              },
              {
                name: 'PR Details',
                hidden: true,
                path: '/purchase/registers/purchase-requisitions/:id',
                auth: 'UI:PR-REQ:VIEW',
                content: PRDetails,
              },
              {
                name: 'Purchase Enquiry',
                path: '/purchase/registers/purchase-enquiry-register',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseRequisitions,
              },
              {
                name: 'PO Enquiry Details',
                hidden: true,
                path: '/purchase/registers/enquiry/:id/details',
                auth: 'UI:PR-REQ:VIEW',
                content: EnquiryDetails,
              },
              {
                name: 'PO Enquiry Details',
                hidden: true,
                path: '/purchase/registers/enquiry/:id/quotes',
                auth: 'UI:PR-REQ:VIEW',
                content: EnquiryDetails,
              },

              {
                name: 'Purchase Order',
                path: '/purchase/registers/purchase-order-register',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseRequisitions,
              },
              {
                name: 'PO Details',
                hidden: true,
                path: '/purchase/registers/purchase-order/:id/details',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseOrderDetails,
              },
              {
                name: 'PO Details',
                hidden: true,
                path: '/purchase/registers/purchase-order/:id/fulfilments',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseOrderDetails,
              },
              {
                name: 'Purchase Item Inspection',
                hidden: true,
                path: '/purchase/registers/purchase-item-inspection',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseOrderDetails,
              },
            ],
          },
          {
            name: 'Deliveries',
            path: '/purchase/deliveries',
            icon: () => <TagIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Goods Received',
                path: '/purchase/deliveries/goods-received',
                auth: 'UI:PR-REQ:VIEW',
                content: GoodsReceived,
              },
              {
                name: 'Inspection',
                path: '/purchase/deliveries/purchase-item-inspection',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseItemInspection,
              },
              {
                name: 'Item Transfer',
                path: '/purchase/deliveries/purchase-item-transfer',
                auth: 'UI:PR-REQ:VIEW',
                content: ItemTransfer,
              },
            ],
          },
        ],
      },
      {
        name: 'Quality Assurance',
        icon: () => <CheckBadgeIcon className='inline h-5' />,
        path: '/quality-assurance',
        sidebarLinks: [
          {
            name: 'Change Control',
            path: '/quality-assurance/change-control',
            icon: () => <DocumentTextIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Requisition',
                path: '/quality-assurance/change-control/requisition',
                auth: '',
                content: ChangeControlList,
              },
              {
                name: 'Requisition Details',
                hidden: true,
                path: '/quality-assurance/change-control/requisition/:id',
                auth: '',
                content: RequisitionDtls,
              },
              {
                name: 'Closure Date Period',
                path: '/quality-assurance/change-control/closure-date-period',
                auth: '',
                content: ClosureDatePeriod,
              },
            ],
          },
        ],
      },
      {
        name: 'Sales',
        icon: () => <ShoppingBagIcon className='inline h-5' />,
        path: '/sales',
        sidebarLinks: [
          {
            name: 'Masters',
            path: '/sales/masters',
            icon: () => <DocumentTextIcon className='inline h-5' />,
            type: 'menu',
            auth: 'UI:SALE-ENQ:VIEW',
            menu: [
              {
                name: 'Client List',
                path: '/sales/masters/clients',
                auth: 'UI:CLT-LIST:VIEW',
                content: ClientList,
              },
              {
                name: 'Client Godowns',
                path: '/sales/masters/client-godowns',
                auth: 'UI:CLT-LIST:VIEW',
                content: ClientGodowns,
              },
              {
                name: 'Client Details',
                hidden: true,
                path: '/sales/masters/clients/:id/profile',
                auth: 'UI:CLT-LIST:VIEW',
                content: ClientV2Profile,
              },
              {
                name: 'Lead Customer List',
                path: '/sales/masters/lead-customers',
                auth: 'UI:CLT-LIST:VIEW',
                content: LeadCustomerList,
              },
              {
                name: 'Lead Customer',
                hidden: true,
                path: '/sales/masters/lead-customers/:id/profile',
                auth: 'UI:CLT-LIST:VIEW',
                content: LeadCustomerProfile,
              },
            ],
          },
          {
            name: 'Sales Order',
            path: '/sales/orders',
            icon: () => <QuestionMarkCircleIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              // {
              //   name: 'Enquiry Details',
              //   path: '/sales/enquiries/sales-enquiries',
              //   auth: 'UI:SALE-ENQ:VIEW',
              //   content: SalesEnquiryTabs,
              // },
              // {
              //   name: 'Enquiry Details',
              //   hidden: true,
              //   path: '/sales/enquiries/enquiry/:id',
              //   auth: 'UI:SALE-ENQ:VIEW',
              //   content: SalesEnquiryDetailsV2,
              // },
              // {
              //   name: 'Enquiry Details-v2',
              //   path: '/sales/enquiries/sales-enquiries-v2',
              //   auth: 'UI:SALE-ENQ:VIEW',
              //   content: SalesEnquiryTabs,
              // },
              // {
              //   name: 'Lead Enquiry',
              //   path: '/sales/enquiries/lead-enquiries',
              //   auth: 'UI:SALE-ENQ:VIEW',
              //   content: SalesEnquiryTabs,
              // },
              {
                name: 'Sales Register',
                path: '/sales/orders/sales-register',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesRegister,
              },
              {
                name: 'Enquiry Details',
                hidden: true,
                path: '/sales/orders/:id/details',
                auth: 'UI:SALE-ENQ:VIEW',
                content: EnquiryDtls,
              },
              // {
              //   name: 'Item Enquiry',
              //   path: '/sales/enquiries/item-enquiries',
              //   auth: 'UI:SALE-ENQ:VIEW',
              //   content: SalesEnquiryTabs,
              // },
              // {
              //   name: 'Item Enquiry',
              //   hidden: true,
              //   path: '/sales/enquiries/item-enquiry',
              //   auth: 'UI:SALE-ENQ:VIEW',
              //   content: SalesItemEnquiryDetails,
              // },
              // {
              //   name: 'Item Enquiry',
              //   hidden: true,
              //   path: '/sales/enquiries/item-enquiry/details/:id',
              //   auth: 'UI:SALE-ENQ:VIEW',
              //   content: SalesEnquiryItemsDetailsV2,
              // },
              // {
              //   name: 'Lead Customer Enquiries',
              //   path: '/sales/enquiries/lead-customer-enquiry/:id/details',
              //   hidden: true,
              //   auth: '',
              //   content: LeadCustomerEnquirDetails,
              // },
              // {
              //   name: 'Manufacturing Enquiry',
              //   hidden: true,
              //   path: '/sales/enquiries/manufacturing-enquiry',
              //   auth: '',
              //   content: SalesManufacturingEnquiryDetails,
              // },
              // {
              //   name: 'QC Enquiry',
              //   hidden: true,
              //   path: '/sales/enquiries/qc-enquiry',
              //   auth: '',
              //   content: SalesQcEnquiryDetails,
              // },
            ],
          },
          {
            name: 'Product Batches',
            path: '/sales/product-batches',
            icon: () => <DocumentIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              // {
              //   name: 'Order',
              //   path: '/sales/sales-order/orders',
              //   auth: 'UI:SO:VIEW',
              //   content: SalesOrderTabs,
              // },
              // {
              //   name: 'Order',
              //   hidden: true,
              //   path: '/sales/sales-order/details/:id',
              //   auth: 'UI:SO:VIEW',
              //   content: SalesOrderDetailsV2,
              // },
              // {
              //   name: 'Item Order',
              //   hidden: true,
              //   path: '/sales/sales-order/item-order',
              //   auth: 'UI:SO:VIEW',
              //   content: SalesItemOrderDetails,
              // },
              // {
              //   name: 'Allocate',
              //   path: '/sales/sales-order/allocate',
              //   auth: 'UI:SO:VIEW',
              //   content: SalesOrproduct-batchesderTabs,
              // },
              // {
              //   name: 'Manufacture',
              //   path: '/sales/sales-order/manufacture',
              //   auth: 'UI:SO:VIEW',
              //   content: SalesOrderTabs,
              // },
              {
                name: 'Product Batch Register',
                path: '/sales/product-batches/product-batch-register',
                auth: 'UI:SO:VIEW',
                content: SalesOrderTabs,
              },
              {
                name: 'COA',
                path: '/sales/product-batches/coa',
                auth: 'UI:SO:VIEW',
                content: SalesOrderTabs,
              },
              {
                name: 'Packaging Request',
                path: '/sales/product-batches/packaging-request',
                auth: 'UI:SO:VIEW',
                content: SalesOrderTabs,
              },
              // {
              //   name: 'Manufacturing Order',
              //   hidden: true,
              //   path: '/sales/sales-order/manufacturing-order',
              //   auth: '',
              //   content: SalesManufacturingOrderDetails,
              // },
              // {
              //   name: 'QC Order',
              //   hidden: true,
              //   path: '/sales/product-batches/qc-enquiry',
              //   auth: '',
              //   content: SalesQcOrderDetails,
              // },
            ],
          },
          {
            name: 'Dispatch Schedule',
            path: '/sales/dispatch-schedules',
            icon: () => <DocumentIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Dispatch Schedule',
                path: '/sales/dispatch-schedules/dispatch-schedule',
                auth: 'UI:SO:VIEW',
                content: DispatchSchedule,
              },
              {
                name: 'Sales Register',
                hidden: true,
                path: '/sales/dispatch-schedules/:id/oa-details',
                auth: 'UI:SALE-ENQ:VIEW',
                content: OADetails,
              },
            ],
          },
        ],
      },
      {
        name: 'Users',
        icon: () => <UserCircleIcon className='inline h-5' />,
        path: '/users',
        sidebarLinks: [
          {
            name: 'Profile',
            path: '/users/profile',
            icon: () => <DocumentTextIcon className='inline h-5' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                hidden: true,
                name: 'Profile Details',
                path: '/users/profile/:id',
                auth: '',
                content: UserProfile,
              },
            ],
          },
        ],
      },
    ];
  }
}
