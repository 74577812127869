/* eslint-disable react-hooks/exhaustive-deps */
import {
  PaymentTerm,
  PaymentTermFilter,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableBodyRow,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseCombinedPaymentTerm } from '../../../hooks/constants/payment-term/use-payment-term';
import { renderAddPaymentTermsForm } from './form/add-payment-term';

export function renderPaymentTerms({
  useCombinedPaymentTerm,
}: {
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
}): () => JSX.Element {
  return function PaymentTerms(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      PaymentTerm,
      PaymentTermFilter
    >();

    const { syncSet: setPaymentTerm } = useCombinedPaymentTerm();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Payment Terms',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Payment Terms',
            behaviour: 'modal',
            modal: {
              title: 'Add Payment Terms',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveCurrency(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddPaymentTermsForm();

    const saveCurrency = async (form: Partial<PaymentTerm>) => {
      try {
        const finalData = {
          ...form,
        } as PaymentTerm;
        await setPaymentTerm(finalData as PaymentTerm);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [{ name: 'Payment Term' }, { name: 'Description' }],
    ];

    const bodyMapper: (d: PaymentTerm) => TableBodyRow = (d) => {
      return {
        cells: [{ value: d.name }, { value: d.description || '--' }],
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              type={{
                defaultPageSize: 10,
                type: 'paginated',
                usePaginatedResources: useCombinedPaymentTerm,
              }}
              bodyMapper={bodyMapper}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
