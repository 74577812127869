import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  InvEntityType,
  ItemProperty,
} from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedRole } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';

export function renderAuthEditProperty<T extends InvEntityType>({
  itemProperties,
  entity,
  useCombinedUser,
  useCombinedRole,
  setEntity,
}: {
  itemProperties: ItemProperty[];
  entity: T;
  useCombinedUser: UseCombinedUser;
  useCombinedRole: UseCombinedRole;
  setEntity: (s: T) => Promise<T>;
}) {
  return function AuthEditProperty({
    idx,
    onClose,
  }: {
    idx: number;
    onClose: () => void;
  }) {
    const obj = itemProperties[idx];

    const indexInEntity = entity.details.properties.findIndex(
      (x) => x.name === obj.name
    );

    if (indexInEntity > -1) {
      const Form = renderFormV2({
        allowUnchanged: true,
        initialFormState: {
          visibleToUser:
            obj.auth?.visibleToUser?.map((x) => {
              return { id: x.id, name: x.name };
            }) || [],
          visibleToRole:
            obj.auth?.visibleToRole?.map((x) => {
              return { id: x.id, name: x.name };
            }) || [],

          editableByUser:
            obj.auth?.editableByUser?.map((x) => {
              return { id: x.id, name: x.name };
            }) || [],
          editableByRole:
            obj.auth?.editableByRole?.map((x) => {
              return { id: x.id, name: x.name };
            }) || [],
        },
        fieldsData: [
          {
            property: 'visibleToUser',
            type: 'searchable-multi-select',
            searchOptions: {
              useSearch: useCombinedUser,
              onSearchValueSelect: (e) => {},
            },

            label: 'Visible To Users',
          },
          {
            property: 'visibleToRole',
            type: 'searchable-multi-select',
            searchOptions: {
              useSearch: useCombinedRole,
              onSearchValueSelect: (e) => {},
            },

            label: 'Visible To Roles',
          },
          {
            property: 'editableByUser',
            type: 'searchable-multi-select',
            searchOptions: {
              useSearch: useCombinedUser,
              onSearchValueSelect: (e) => {},
            },
            label: 'Editable By Users',
          },
          {
            property: 'editableByRole',
            type: 'searchable-multi-select',
            searchOptions: {
              useSearch: useCombinedRole,
              onSearchValueSelect: (e) => {},
            },
            label: 'Editable By Roles',
          },
        ],
        mapTToU: (f) => f,
        onSubmit: async (f) => {
          let auth: any;

          const visibleToUser: Array<IdName> =
            (f.visibleToUser as Array<IdName>) || [];
          const visibleToRole: Array<IdName> =
            (f.visibleToRole as Array<IdName>) || [];
          const editableByUser: Array<IdName> =
            (f.editableByUser as Array<IdName>) || [];
          const editableByRole: Array<IdName> =
            (f.editableByRole as Array<IdName>) || [];
          auth = {
            visibleToUser: visibleToUser.map((x) => ({
              id: x.id,
              name: x.name,
            })),
            visibleToRole: visibleToRole.map((x) => ({
              id: x.id,
              name: x.name,
            })),
            editableByUser: editableByUser.map((x) => ({
              id: x.id,
              name: x.name,
            })),
            editableByRole: editableByRole.map((x) => ({
              id: x.id,
              name: x.name,
            })),
          };

          entity.details.properties[indexInEntity].auth = auth;
          await setEntity(({
            id: entity.id,
            details: {
              properties: entity.details.properties,
            },
          } as unknown) as T);
          onClose();
        },
      });

      return <Form />;
    }

    return <></>;
  };
}
