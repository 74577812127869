import { ChangeAssesmentType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';

export type ExtensionFormType = {
  extdDate: Date;
  extReason: string;
};

// eslint-disable-next-line
export function renderExtensionForm() {
  return function ExtensionForm({
    onSave,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const closuredt = data?.details.tentativeDateClosure;
    const exdt = moment(closuredt).add(60, 'days');
    const formProps: FormProps<ExtensionFormType> = {
      fieldsData: [
        {
          property: 'extdDate',
          type: 'date',
          label: 'Extension Date',
        },
        {
          rows: 3,
          property: 'extReason',
          type: 'text-area',
          label: 'Extension Reason',
        },
      ],
      initialFormState: {
        extdDate: moment(exdt).format('YYYY-MM-DD') || '',
        extReason: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        const extention = data?.details?.extention || [];

        extention.push({
          extdDate: f.extdDate,
          extReason: f.extReason,
          createdAt: moment().utc().format(),
        });
        await onSave(({
          details: {
            extention: extention,
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<ExtensionFormType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<ExtensionFormType>): ExtensionFormType {
      return (b as unknown) as ExtensionFormType;
    }
  };
}
