import { Document } from '@erp_core/erp-icons/icons/document';
import {
  PurchaseOrderFulfilment,
  PurchaseOrderFulfilmentFilterType,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedTemplate } from '../../../hooks/admin/communication-template/use-template';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedQuestionnaireQuestion } from '../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedPurchaseOrderFulfilment } from '../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { downloadTemplatePdf } from '../../hrd/salary/utils/download-template';
import { renderInspectionForm } from './inspection-form';

export function renderPurchaseItemInspection({
  useCombinedPurchaseOrderFulfilment,
  itemService,
  useCombinedQuestionnaireQuestion,
  useCombinedTemplate,
  useFileTransfer,
}: {
  useCombinedPurchaseOrderFulfilment: UseCombinedPurchaseOrderFulfilment;
  itemService: ItemInterface;
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
  useCombinedTemplate: UseCombinedTemplate;
  useFileTransfer: UseFileTransfer;
}): () => JSX.Element {
  return function PurchaseItemInspection() {
    const {
      list: fulfilments,
      set: setFulfilments,
      syncSet: setPurchaseFulfillment,
    } = useCombinedPurchaseOrderFulfilment();
    const {
      list: data,
      getAll: getInspectionItems,
    } = useCombinedPurchaseOrderFulfilment();
    const InspectionForm = renderInspectionForm({
      itemService,
      useCombinedQuestionnaireQuestion,
    });
    const { getSync: getTemplate } = useCombinedTemplate();
    const { get: getFile } = useFileTransfer();
    const { getListSync: getQuestions } = useCombinedQuestionnaireQuestion();

    const getData = async () => {
      try {
        getInspectionItems(({
          status: 'under-inspection',
        } as unknown) as PurchaseOrderFulfilmentFilterType);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (fulfilments?.length) {
        getData();
        toast('Approved Sucessfully');
      }
      // eslint-disable-next-line
    }, [fulfilments]);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Invoice No' },
        { name: 'GRN No' },
        { name: 'Arrived at' },
        { name: 'Item' },
        { name: 'Quantity' },
        { name: 'remarks' },
        { name: 'Attachments' },
        // { name: 'Actions' },
      ],
    ];

    const tableBody: TableBody =
      data?.map((item) => ({
        rowData: {
          fulfillment: item,
        },
        cells: [
          { value: item.details.invoiceNo || '' },
          { value: item.details.grnNumber || '' },
          { value: item.details.arrivedAt || '' },
          { value: item?.purchaseOrder?.details?.itemDetails?.name || '' },
          { value: item.details.quantity || '' },
          { value: item.details.remarks || '' },
          {
            value: (
              <div>
                {item.purchaseOrder?.details?.itemDetails?.grade?.item?.id}
                {item.details?.attachments?.map((a, i) => (
                  <div key={i}>{a}</div>
                ))}
                {(item.details as any)?.inspectionAnswers ? (
                  <div
                    onClick={async () => {
                      const properties = await itemService.getProperties(
                        item.purchaseOrder?.details?.itemDetails?.grade?.item
                          ?.id
                      );

                      const templateId = (properties?.find(
                        (x) => x.name === 'coa-template'
                      ) as any)?.value?.data;
                      if (templateId) {
                        const template = await getTemplate(templateId);
                        const url = template.url;
                        const spec: any = {};
                        if (properties) {
                          properties.forEach((p) => {
                            spec[p.name] = (p as any)?.value?.data;
                          });
                        }
                        const questions = await getQuestions({
                          topicId: (item.details as any).inspectionAnswers.topic
                            .id,
                          order: 'asc',
                          sortBy: 'orderNo',
                        });
                        console.log('que', questions);
                        const inspectionAnswers = (item.details as any)
                          .inspectionAnswers;
                        console.log('inpectionAns', inspectionAnswers);
                        const coaAnswer: any = {};

                        questions.forEach((q) => {
                          // We only pick questions that have mapper prop
                          if (q.details.mapperProperty) {
                            const ans = inspectionAnswers?.details?.surveyAns.find(
                              (x) => x.questionId === q.id
                            );
                            if (ans) {
                              coaAnswer[q.details.mapperProperty] =
                                ans.answerValue;
                            }
                          }
                        });

                        const chemComp = spec['chemical-composition'];
                        const renderChemicalComposition = `<table border="1" style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0);">
                       <thead>
                        <tr>
                          <th style="border-color: rgb(0, 0, 0); width: 25%">Components</th>
                          <th style="border-color: rgb(0, 0, 0); width: 15%">Method</th>
                          <th colspan="2" style="border-color: rgb(0, 0, 0); width: 20%; text-align: center;">As per in-house Specifications</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">Test Value</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%;text-align: center;">Test Result</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer Spec</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer COA</th>
                        </tr> 
                       </thead>
                       <tbody>
                        ${coaAnswer.chemicalComposition
                          .map((x) => {
                            const comp = chemComp.find(
                              (y) => y.component === x.name
                            );
                            return `
                            <tr>
                              <td style="border-color: rgb(0, 0, 0);">${x.name}</td>
                              <td style="border-color: rgb(0, 0, 0);">${comp?.moa}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.expected}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">${comp?.uom}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.actual}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                            </tr>`;
                          })
                          .join('')}
                       </tbody>
                      </table>`;

                        const impurities = spec['assay-of-other-impurity'];

                        const renderImpurities = `<table border="1" style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0);">
                      <thead>
                       <tr>
                         <th style="border-color: rgb(0, 0, 0); width: 25%">Components</th>
                         <th style="border-color: rgb(0, 0, 0); width: 15%">Method</th>
                         <th colspan="2" style="border-color: rgb(0, 0, 0); width: 20%; text-align: center;">As per in-house Specifications</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">Test Value</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%;text-align: center;">Test Result</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer Spec</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer COA</th>
                       </tr> 
                      </thead>
                      <tbody>
                       ${coaAnswer.impurities
                         .map((x) => {
                           const imp = impurities.find(
                             (y) => y.impurity === x.name
                           );
                           if (imp && imp['test-done'] === 'yes') {
                             return `
                             <tr>
                               <td style="border-color: rgb(0, 0, 0);">${x.name}</td>
                               <td style="border-color: rgb(0, 0, 0);">${imp?.MoA}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.expected}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">${imp['unit-of-measurement']}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.actual}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                             </tr>`;
                           }
                           return '';
                         })
                         .join('')}
                      </tbody>
                     </table>`;

                        console.log({
                          grn: item,
                          purchaseOrder: item.purchaseOrder,
                          item: item.purchaseOrder.details?.itemDetails,
                          specification: spec,
                          coaAnswer,
                        });
                        if (url) {
                          await downloadTemplatePdf({
                            templateUrl: url,
                            dynamicRenderer: {
                              renderChemicalComposition: renderChemicalComposition,
                              renderImpurities: renderImpurities,
                            },
                            coaData: {
                              grn: item,
                              purchaseOrder: item.purchaseOrder,
                              item: item.purchaseOrder.details?.itemDetails,
                              specification: spec,
                              coaAnswer,
                            },
                            getFile,
                          });
                        }
                      }
                    }}
                    className='inline mx-1 p-1 border border-red-500 rounded'
                  >
                    <Document className='text-red-500 inline w-5' /> COA
                  </div>
                ) : null}
              </div>
            ),
          },
        ],
      })) || [];

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Purchase Item Inspection List',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              show: ({ fulfillment }) => {
                if (fulfillment.details?.inspectionAnswers) {
                  return false;
                }
                return true;
              },
              behaviour: 'modal',
              name: 'Inspect',
              modal: {
                title: 'Inspect',
                size: 'large',
                content: ({ data: { fulfillment }, onClose }) => {
                  return (
                    <InspectionForm
                      item={fulfillment}
                      onClose={onClose}
                      setPurchaseFulfillment={setPurchaseFulfillment}
                    />
                  );
                },
              },
            },
            {
              name: 'Approve',
              show: () => true,
              behaviour: 'confirm',
              onConfirm: ({ fulfillment }) => {
                const finalData = {
                  id: fulfillment.id,
                  underInspection: false,
                  isApproved: true,
                };
                return {
                  title: `GRN No. ${fulfillment?.details.grnNumber || ''}`,
                  message: `Are you sure? Approving ${
                    fulfillment?.details.quantity || ''
                  } ${fulfillment?.details.uom || ''} ${
                    fulfillment?.purchaseOrder?.details?.itemDetails?.name
                  }`,
                  onConfirm: async () => {
                    setFulfilments(
                      (finalData as unknown) as PurchaseOrderFulfilment
                    );
                  },
                };
              },
            },
            {
              name: 'Reject',
              show: () => true,
              behaviour: 'confirm',
              onConfirm: ({ fulfillment }) => {
                const finalData = {
                  id: fulfillment.id,
                  underInspection: false,
                  isApproved: false,
                };
                return {
                  title: `GRN No. ${fulfillment?.details.grnNumber || ''}`,
                  message: `Are you sure? Rejecting ${
                    fulfillment?.details.quantity || ''
                  } ${fulfillment?.details.uom || ''} ${
                    fulfillment?.purchaseOrder?.details?.itemDetails?.name
                  }`,
                  onConfirm: async () => {
                    setFulfilments(
                      (finalData as unknown) as PurchaseOrderFulfilment
                    );
                  },
                };
              },
            },
          ]}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
