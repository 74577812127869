import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  OvertimeFilterType,
  OvertimeType,
} from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import { LoadingButton } from '@erp_core/erp-ui-components';

type BulkOvertimeActionFormProps = {
  list: Array<OvertimeType>;
  onClose: () => void;
  setOvertime: (s: OvertimeType) => Promise<OvertimeType>;
  currentUser: CurrentUserType;
  getOvertime: (filter?: OvertimeFilterType) => void;
  date: string;
};

export function BulkOvertimeActionForm({
  list,
  onClose,
  setOvertime,
  currentUser,
  getOvertime,
  date,
}: BulkOvertimeActionFormProps): JSX.Element {
  const pendingValidnCheck = list
    .map((li) => li.status === 'approved')
    .includes(true);

  return (
    <div className='m-2 border border-gray-200 rounded-lg'>
      {pendingValidnCheck ? (
        <div className='text-center text-red-600'>
          Seems like approved entries are selected. Kindly deselect them to
          perform this action!
        </div>
      ) : (
        <>
          <div className='text-center'>
            <span className='text-gray-600 italic'>Entries: </span>
            {list.map((l, index) => (
              <div key={l.id}>
                {l.employee.name}-{l.status}-{l.date}
              </div>
            ))}
          </div>
          <div className='align-center m-1'>
            <LoadingButton
              text='Save'
              loadingStyle='text-red-600'
              behaviourParams={{}}
              behaviorFn={async () => {
                await new Promise(async (resolve) => {
                  setTimeout(async () => {
                    for (let i = 0; i < list.length; i++) {
                      const finalData = ({
                        id: list[i].id,
                        approvedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                        status: 'approved',
                      } as unknown) as OvertimeType;
                      await setOvertime(finalData);
                    }
                    resolve('');
                  }, 1000);
                });
                await onClose();
                await getOvertime({ date });
              }}
            ></LoadingButton>
          </div>
        </>
      )}
    </div>
  );
}
