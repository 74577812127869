import {
  EmployeeShiftScheduleFilterType,
  EmployeeShiftScheduleType,
  LocationHolidayFilterType,
  LocationHolidayType,
  LogRecordFilterType,
  LogRecordsType,
  Machine,
  MachineFilterType,
  MachineSchedule,
  MachineScheduleFilter,
  MachineShift,
  MachineShiftFilterType,
  RotationalShiftDayType,
  ShiftScheduleFilterType,
  ShiftScheduleType,
  SwitchShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import { RotationalShiftDayFilter } from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-day';
import {
  RotationalShiftScheduleFilter,
  RotationalShiftScheduleType,
} from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-schedule';
import {
  createUseCombinedResource,
  createUseResources,
} from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { SwitchShiftScheduleHttpRepo } from '../datalayer/repository/http/hrd/switch-shift-schedule';
import { HttpLogRecordRepo } from '../datalayer/repository/http/planning/log-record';
import { HttpMachineScheduleRepo } from '../datalayer/repository/http/planning/production/machine-schedule';
import { createGetMachineScheduleHook } from '../hooks/planning/production/machine-schedule';
import { RotationalShiftDayInterface } from '../models/interfaces/hrd/rotational-shift-day';
import { RotationalShiftScheduleInterface } from '../models/interfaces/hrd/rotational-shift-schedule';
import {
  SwitchShiftSchduleInterface,
  SwitchShiftScheduleFilter,
} from '../models/interfaces/hrd/switch-shift-schedule';
import { EmpShiftScheduleInterface } from '../models/interfaces/planning/emp-shift-schedule';
import { LocationHolidayInterface } from '../models/interfaces/planning/location-holidays';
import { LogRecordInterface } from '../models/interfaces/planning/log-record';
import { MachineInterface } from '../models/interfaces/planning/production/machine';
import { MachineScheduleInterface } from '../models/interfaces/planning/production/machine-schedule';
import { MachineShiftInterface } from '../models/interfaces/planning/production/machine-shift';
import { ShiftScheduleInterface } from '../models/interfaces/planning/shift-schedule';
import { erpV2Api } from './admin';
import { PLANNING_SERVER_URI } from './server-uri';

export const rotationalShiftScheduleService = new GenericHttpWithLogsRepo<
  RotationalShiftScheduleType,
  RotationalShiftScheduleFilter
>({
  erpv2Api: erpV2Api,
  baseURI: PLANNING_SERVER_URI,
  resourceEndpoint: '/api/v1/attendance/rotational-shift-schedule',
});

export const useCombinedRotationalShiftSchedule = createUseCombinedResource<
  RotationalShiftScheduleType,
  RotationalShiftScheduleFilter,
  RotationalShiftScheduleInterface
>({
  useService: () => rotationalShiftScheduleService,
});

export const rotationalShiftDayService = new GenericHttpWithLogsRepo<
  RotationalShiftDayType,
  RotationalShiftDayFilter
>({
  erpv2Api: erpV2Api,
  baseURI: PLANNING_SERVER_URI,
  resourceEndpoint: '/api/v1/attendance/rotational-shift-day',
});

export const useCombinedRotationalShiftDay = createUseCombinedResource<
  RotationalShiftDayType,
  RotationalShiftDayFilter,
  RotationalShiftDayInterface
>({
  useService: () => rotationalShiftDayService,
});

export const switchShiftScheduleService = new SwitchShiftScheduleHttpRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useCombinedSwitchShift = createUseCombinedResource<
  SwitchShiftScheduleType,
  SwitchShiftScheduleFilter,
  SwitchShiftSchduleInterface
>({
  useService: () => switchShiftScheduleService,
});

export const shiftScheduleService = new GenericHttpWithLogsRepo<
  ShiftScheduleType,
  ShiftScheduleFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: PLANNING_SERVER_URI,
  resourceEndpoint: '/api/v1/attendance/shift-schedule',
});

export const useCombinedShiftSchedule = createUseCombinedResource<
  ShiftScheduleType,
  ShiftScheduleFilterType,
  ShiftScheduleInterface
>({
  useService: () => shiftScheduleService,
});

export const empShiftScheduleService = new GenericHttpWithLogsRepo<
  EmployeeShiftScheduleType,
  EmployeeShiftScheduleFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: PLANNING_SERVER_URI,
  resourceEndpoint: '/api/v1/attendance/employee-shift-schedule',
});

export const useCombinedEmpShiftSchedule = createUseCombinedResource<
  EmployeeShiftScheduleType,
  EmployeeShiftScheduleFilterType,
  EmpShiftScheduleInterface
>({
  useService: () => empShiftScheduleService,
});

export const locationHolidaysService = new GenericHttpWithLogsRepo<
  LocationHolidayType,
  LocationHolidayFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: PLANNING_SERVER_URI,
  resourceEndpoint: '/api/v1/attendance/location-holidays',
});

export const useCombinedLocationHoliday = createUseCombinedResource<
  LocationHolidayType,
  LocationHolidayFilterType,
  LocationHolidayInterface
>({
  useService: () => locationHolidaysService,
});

export const logRecordService = new HttpLogRecordRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useLogRecords = createUseResources<
  LogRecordsType,
  LogRecordFilterType,
  LogRecordInterface
>({
  useService: () => logRecordService,
});

export const useCombinedLogRecord = createUseCombinedResource<
  LogRecordsType,
  LogRecordFilterType,
  LogRecordInterface
>({
  useService: () => logRecordService,
});

export const machineShiftService = new GenericHttpWithLogsRepo<
  MachineShift,
  MachineShiftFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: PLANNING_SERVER_URI,
  resourceEndpoint: '/api/v1/production/machines/shifts',
});

export const useCombinedMachineShift = createUseCombinedResource<
  MachineShift,
  MachineShiftFilterType,
  MachineShiftInterface
>({ useService: () => machineShiftService });

export const machineScheduleService = new HttpMachineScheduleRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useCombinedMachineSchedule = createUseCombinedResource<
  MachineSchedule,
  MachineScheduleFilter,
  MachineScheduleInterface
>({ useService: () => machineScheduleService });

export const useGetMachineSchedule = createGetMachineScheduleHook({
  useService: () => machineScheduleService,
});

export const machineService = new GenericHttpWithLogsRepo<
  Machine,
  MachineFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: PLANNING_SERVER_URI,
  resourceEndpoint: '/api/v1/production/machines',
});

export const useCombinedMachine = createUseCombinedResource<
  Machine,
  MachineFilterType,
  MachineInterface
>({ useService: () => machineService });
