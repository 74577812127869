import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { useContext } from 'react';
import { UseCombinedCompanyAdmin } from '../../../../../hooks/admin/company-admin/use-company-admin';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../../hooks/file-transfer/use-public-transfer';
import { UseCombinedDepartment } from '../../../../../hooks/hrd/department/use-department';
import { UseCombinedDocument } from '../../../../../hooks/hrd/employee-registration-docs/use-document';
import { UseCombinedEmployeeRegistration } from '../../../../../hooks/hrd/employee-registration-dtls/use-registration';
import { UseCombinedSalaryParam } from '../../../../../hooks/hrd/salary-parameter/use-salary-param';
import { renderEmployeeTimeLine } from '../../employee-timeline';
import { renderBankDetails } from './bank-details';
import { renderContactDetails } from './contact-details';
// import { renderDressCode } from './dress-code';
import { CurrentContext } from '../../../../../contexts/current';
import { UseCombinedEmployeeCategory } from '../../../../../hooks/admin/constants/employee-category/use-employee-category';
import { UseCombinedEmployeeEducation } from '../../../../../hooks/hrd/employee-education-dtls/use-employee-education';
import { UseCombinedEmployeeFamily } from '../../../../../hooks/hrd/employee-family-dtls/use-employee-family';
import { UseCombinedShiftSchedule } from '../../../../../hooks/planning/shift-schedule/use-shift-schedule';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { renderEmployeeEducationDetails } from './education-details/employee-education-details';
import { renderEmployeeProfile1 } from './employee-profile';
import { renderEmployeeFamilyDetails } from './family/employee-family-details';
import { renderPersonalDetails } from './personal-details';
import { renderEmployeeRegistrationDetails } from './registration-detail/employee-registration-details';

type CreateEmployeeProfileCardType = {
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
  id: string;
  useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
  useCombinedSalaryParam: UseCombinedSalaryParam;
  useCombinedEmployeeRegistration: UseCombinedEmployeeRegistration;
  useCombinedDocument: UseCombinedDocument;
  useCombinedDepartment: UseCombinedDepartment;
  useCombinedEmployeeEducation: UseCombinedEmployeeEducation;
  useCombinedEmployeeFamily: UseCombinedEmployeeFamily;
  useCombinedShiftSchedule: UseCombinedShiftSchedule;
  userRendererService: UserRendererInterface;
  isHr: boolean;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
};

type Props = {
  data: EmployeeProfileType;
  loading: boolean;
  setEmployee: (s: EmployeeProfileType) => void;
};

export function renderEmployeeProfile({
  useFileTransfer,
  usePublicTransfer,
  useCombinedEmployeeCategory,
  useCombinedCompanyAdmin,
  useCombinedSalaryParam,
  useCombinedEmployeeRegistration,
  useCombinedEmployeeEducation,
  useCombinedEmployeeFamily,
  useCombinedDocument,
  id,
  useCombinedDepartment,
  isHr,
  actions,
  userRendererService,
  useCombinedShiftSchedule,
}: CreateEmployeeProfileCardType): (p: Props) => JSX.Element {
  const BankDetails = renderBankDetails(useFileTransfer);
  const Profile = renderEmployeeProfile1({
    useCombinedShiftSchedule,
    usePublicTransfer,
    useCombinedEmployeeCategory,
    useCombinedCompanyAdmin,
    useCombinedSalaryParam,
    useCombinedDepartment,
  });

  const EmployeeRegistration = renderEmployeeRegistrationDetails();

  const EmployeeEducations = renderEmployeeEducationDetails();

  const EmployeeFamilies = renderEmployeeFamilyDetails();

  const PersonalDetails = renderPersonalDetails();

  const ContactDetails = renderContactDetails();

  // const DressCode = renderDressCode();

  return function EmployeeProfile({
    data,
    setEmployee,
    loading,
  }: {
    data: EmployeeProfileType;
    setEmployee: (s: EmployeeProfileType) => void;
    loading: boolean;
  }): JSX.Element {
    const Timeline = renderEmployeeTimeLine({
      data,
    });
    const { cgSetting } = useContext(CurrentContext);

    return (
      <>
        {' '}
        <div>
          <Profile
            isHr={isHr}
            emp={data}
            loading={loading}
            setEmployee={setEmployee}
            companyGroupSetting={cgSetting}
            id={id}
          />
        </div>
        <br />
        <div>
          <PersonalDetails
            setEmployee={setEmployee}
            employee={data}
            actions={actions}
          />
        </div>
        <br />
        <div>
          <Timeline setEmployee={setEmployee} />
        </div>
        <br />
        <div>
          <ContactDetails
            setEmployee={setEmployee}
            employee={data}
            isHr={isHr}
            actions={actions}
          />
        </div>
        <br />
        <br />
        <div>
          <EmployeeFamilies
            userRendererService={userRendererService}
            actions={actions}
            employee={data}
            useCombinedEmployeeFamily={useCombinedEmployeeFamily}
          />
        </div>
        <br />
        <div>
          <EmployeeEducations
            userRendererService={userRendererService}
            employee={data}
            isHr={isHr}
            useCombinedEmployeeEducation={useCombinedEmployeeEducation}
            useFileTransfer={useFileTransfer}
          />
        </div>
        <br />
        {/* <div>
          <DressCode employee={data} setEmployee={setEmployee} />
        </div> */}
        <br />
        <div>
          <EmployeeRegistration
            userRendererService={userRendererService}
            isHr={isHr}
            employee={data}
            actions={actions}
            useCombinedEmployeeRegistration={useCombinedEmployeeRegistration}
            useCombinedDocument={useCombinedDocument}
            useFileTransfer={useFileTransfer}
          />
        </div>
        <br />
        {/* <div>{data.id ? <Insurance id={data.id} /> : null}</div> */}
        <br />
        <div>
          {data.id ? (
            <BankDetails
              isHr={isHr}
              actions={actions}
              id={data.id}
              setEmployee={setEmployee}
              employee={data}
            />
          ) : null}
        </div>
      </>
    );
  };
}

export function empFullName(data) {
  return `${data.details?.fullName?.prefix}  ${data.details?.fullName?.firstName}  ${data.details?.fullName?.middleName}  ${data.details?.fullName?.lastName}`;
}

export function empPermanentAddress(data) {
  return `${data?.details?.contactDetails?.permanentAddress?.addressLine1 || ''}
  ${data?.details?.contactDetails?.permanentAddress?.addressLine2 || ''} ${
    data?.details?.contactDetails?.permanentAddress?.city || ''
  } ${data?.details?.contactDetails?.permanentAddress?.pincode || ''} 
  ${data?.details?.contactDetails?.permanentAddress?.district || ''} ${
    data?.details?.contactDetails?.permanentAddress?.state || ''
  }`;
}

export function empCurrentAddress(data) {
  return `${data?.details?.contactDetails?.currentAddress?.addressLine1 || ''}
  ${data?.details?.contactDetails?.currentAddress?.addressLine2 || ''} ${
    data?.details?.contactDetails?.currentAddress?.city || ''
  } ${data?.details?.contactDetails?.currentAddress?.pincode || ''} 
  ${data?.details?.contactDetails?.currentAddress?.district || ''} ${
    data?.details?.contactDetails?.currentAddress?.state || ''
  } `;
}
