import {
  AccountGroup,
  Ledger,
} from '@erp_core/erp-types/dist/modules/accounts';
import {
  DateSelector,
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseCombinedAccountGroup } from '../../../../hooks/accounts/tally/account-group/use-account-group';
import { UseCombinedLedger } from '../../../../hooks/accounts/tally/ledger/use-ledger';

export function renderAccountsSummary({
  useCombinedAccountGroup,
  useCombinedLedger,
}: {
  useCombinedAccountGroup: UseCombinedAccountGroup;
  useCombinedLedger: UseCombinedLedger;
}): () => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function AccountSummary(): JSX.Element {
    const [level, selectLevel] = useState<'all' | 'ledger' | 'account-group'>(
      'all'
    );
    const [view, selectView] = useState<
      | 'summary'
      | 'weekly'
      | 'fortnightly'
      | 'monthly'
      | 'quarterly'
      | 'half-yearly'
      | 'yearly'
    >('summary');

    const [header, setHeader] = useState<TableHeader>([]);
    const [body, setBody] = useState<TableBody>([]);

    const [selectedAccountGroup, setSelectedAccountGroup] = useState<
      AccountGroup | undefined
    >();
    const GroupSearchBox = renderSearchBox<AccountGroup>({
      useSearchValues: useCombinedAccountGroup,
      onSearchValueSelect: (x) => {
        setSelectedAccountGroup(x);
      },
    });

    const [selectedLedger, setSelectedLedger] = useState<Ledger | undefined>();
    const LedgerSearchBox = renderSearchBox<Ledger>({
      useSearchValues: useCombinedLedger,
      onSearchValueSelect: (x) => {
        setSelectedLedger(x);
      },
    });

    const [duration, setDuration] = useState<{ from: string; to: string }>({
      from: '2023-04-01',
      to: moment().format('YYYY-MM-DD'),
    });

    const [financialMoment, setFinancialMoment] = useState<{
      from: moment.Moment;
      to: moment.Moment;
    }>({
      from: moment(duration.from),
      to: moment(duration.to),
    });

    const [stock, setStock] = useState<{
      resource: string;
      name: string;
      view: string;
    }>({
      resource: '',
      name: '',
      view: '',
    });

    useEffect(() => {
      if (moment(duration.from).isBefore(moment(duration.to))) {
        setFinancialMoment({
          from: moment(duration.from),
          to: moment(duration.to),
        });
      }
    }, [duration]);

    useEffect(() => {
      const res = {
        resource: '',
        name: '',
        view: '',
      };
      if (level === 'all') {
        res.resource = 'All Resources';
      } else {
        res.name = level;
      }

      if (level === 'account-group' && selectedAccountGroup) {
        res.name += '-' + selectedAccountGroup.name;
      }

      if (level === 'ledger' && selectedLedger) {
        res.name += '-' + selectedLedger.name;
      }

      res.view = view;

      setStock(res);
    }, [level, view, selectedAccountGroup, selectedLedger]);

    useEffect(() => {
      const months: Array<string> = ['Opening Balance'];

      if (view === 'summary') {
        setHeader([
          [
            { name: 'Particulars', style: 'w-2/3', rowSpan: 3 },
            {
              name: (
                <div className='text-center'>
                  <div className='italic capitalize'>
                    {stock.resource} : {stock.name}
                  </div>
                  <div className='font-semibold text-gray-800 capitalize'>
                    {stock.view} view
                  </div>
                  <div className='font-light'>
                    {moment(financialMoment.from).format('DD-MMM-YY')} to{' '}
                    {moment(financialMoment.to).format('DD-MMM-YY')}
                  </div>
                </div>
              ),
              colSpan: 2,
            },
          ],
          [
            {
              name: 'Closing Balance',
              style: 'text-center border-l border-gray-200 ',
              colSpan: 2,
            },
          ],
          [
            {
              name: 'Debit',
              style: 'text-center text-gray-500 border-gray-200 border-l ',
            },
            {
              name: 'Credit',
              style: 'text-center text-gray-500',
            },
          ],
        ]);

        setBody(
          months.map((x) => {
            return {
              cells: [{ value: x }, { value: '' }, { value: '' }],
            };
          })
        );
      }

      if (
        [
          'weekly',
          'fortnightly',
          'monthly',
          'quarterly',
          'half-yearly',
          'yearly',
        ].includes(view)
      ) {
        setHeader([
          [
            { name: 'Particulars', style: 'w-1/2', rowSpan: 3 },
            {
              name: (
                <div className='text-center'>
                  <div className='italic capitalize'>
                    {stock.resource} {stock.name}
                  </div>
                  <div className='font-semibold text-gray-800 capitalize'>
                    {stock.view} view
                  </div>
                  <div className='font-light'>
                    {moment(financialMoment.from).format('DD-MMM-YY')} to{' '}
                    {moment(financialMoment.to).format('DD-MMM-YY')}
                  </div>
                </div>
              ),
              colSpan: 3,
            },
          ],
          [
            {
              name: 'Transactions',
              style: 'border-l border-gray-200 text-center',
              colSpan: 2,
            },
            {
              name: 'Closing Balance',
              style: 'text-center',
              colSpan: 1,
              rowSpan: 2,
            },
          ],
          [
            {
              name: 'Debit',
              style: 'border-l border-gray-200 text-center   text-gray-500',
            },
            {
              name: 'Credit',
              style: 'text-center text-gray-500',
            },
          ],
        ]);

        if (view === 'weekly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(1, 'week')
          ) {
            months.push(mon.format('DD-MMM-YY'));
          }
        }

        if (view === 'fortnightly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(15, 'day')
          ) {
            months.push(mon.format('DD-MMM-YY'));
          }
        }

        if (view === 'monthly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(1, 'month')
          ) {
            months.push(mon.format('MMM-YY'));
          }
        }

        if (view === 'quarterly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(3, 'month')
          ) {
            months.push(mon.format('MMM-YY'));
          }
        }

        if (view === 'half-yearly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(6, 'month')
          ) {
            months.push(mon.format('MMM-YY'));
          }
        }

        if (view === 'yearly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(1, 'year')
          ) {
            months.push(mon.format('MMM-YY'));
          }
        }

        setBody(
          months.map((x) => {
            return {
              cells: [
                { value: x },
                { value: '' },
                { value: '' },
                { value: '' },
              ],
            };
          })
        );
      }
    }, [view, financialMoment, stock]);

    return (
      <div>
        <Card
          header={{
            title: 'Account Summary',
            subheading: (
              <div className='flex'>
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Duration</div>
                  <div className='flex'>
                    <DateSelector
                      initialState={duration.from}
                      max={moment().format('YYYY-MM-DD')}
                      format='YYYY-MM-DD'
                      onChange={(date) =>
                        setDuration((d) => {
                          return { to: d.to, from: date };
                        })
                      }
                    />

                    <DateSelector
                      initialState={duration.to}
                      max={moment().format('YYYY-MM-DD')}
                      format='YYYY-MM-DD'
                      onChange={(date) =>
                        setDuration((d) => {
                          return { to: date, from: d.from };
                        })
                      }
                    />
                  </div>
                </div>
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Resource</div>
                  <select
                    value={level}
                    onChange={(evt) => selectLevel(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='all'>All</option>
                    <option value='account-group'>Group</option>
                    <option value='ledger'>Ledger</option>
                  </select>
                </div>
                {level === 'account-group' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Group</div>
                    <GroupSearchBox
                      currentSelected={selectedAccountGroup}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                {level === 'ledger' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Ledger</div>
                    <LedgerSearchBox
                      currentSelected={selectedLedger}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>View</div>
                  <select
                    value={view}
                    onChange={(evt) => selectView(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='summary'>Summary</option>
                    <option value='weekly'>Week</option>
                    <option value='fortnightly'>FortNight</option>
                    <option value='monthly'>Month</option>
                    <option value='quarterly'>Quarter</option>
                    <option value='half-yearly'>Half-Year</option>
                    <option value='yearly'>Year</option>
                  </select>
                </div>
              </div>
            ),
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table header={header} body={body} />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
