import initials from 'initials';
import moment from 'moment';
import { FileTransferInterface } from '../../../models/interfaces/fileTransfer/fileTransfer';
import { EmployeeProfileInterface } from '../../../models/interfaces/hrd/employee/empProfile';

export interface UserRendererInterface {
  profilePic({
    id,
    size,
    headerStyle,
  }: {
    id: string;
    size: 'small' | 'medium' | 'large';
    headerStyle?: string;
  }): JSX.Element;
  userCard({
    id,
    extraInfo,
    name,
    size,
    headerStyle,
    link,
  }: {
    size: 'small' | 'medium' | 'large';
    id: string;
    name: string;
    extraInfo?: string;
    headerStyle?: string;
    link: boolean;
  }): JSX.Element;
}

export class UserRenderer {
  fileService: FileTransferInterface;
  employeeService: EmployeeProfileInterface;

  cachedImages: { [id: string]: string };

  db: IDBDatabase | null;

  constructor({
    fileService,
    employeeService,
  }: {
    fileService: FileTransferInterface;
    employeeService: EmployeeProfileInterface;
  }) {
    const today = moment().format('YYYY-MM');
    this.fileService = fileService;
    this.employeeService = employeeService;

    this.cachedImages = {};

    this.profilePic = this.profilePic.bind(this);
    this.userCard = this.userCard.bind(this);

    this.db = null;
    const openRequest = indexedDB.open('recat');

    const that = this;

    openRequest.onupgradeneeded = function () {
      that.db = openRequest.result;
      if (!that.db.objectStoreNames.contains('user-profiles')) {
        that.db.createObjectStore('user-profiles', { keyPath: 'id' });
      }
    };

    openRequest.onsuccess = function () {
      that.db = openRequest.result;
      if (!that.db.objectStoreNames.contains('user-profiles')) {
        that.db.createObjectStore('user-profiles', { keyPath: 'id' });
      }

      const trans = that.db.transaction(['user-profiles'], 'readwrite');
      const userProfileStore = trans.objectStore('user-profiles');
      const readReq = userProfileStore.getAll();
      readReq.onsuccess = () => {
        readReq.result.forEach((val) => {
          if (val.id && val?.expiry.startsWith(today)) {
            that.cachedImages[val.id] = val.value;
          } else {
            that.deleteFromIndexDb(val.id);
          }
        });
      };
    };
  }

  private async getFromIndexDb(id: string): Promise<any> {
    return new Promise((resolve) => {
      if (this.db) {
        const trans = this.db.transaction(['user-profiles'], 'readwrite');
        const userProfileStore = trans.objectStore('user-profiles');

        try {
          const req = userProfileStore.get(id);
          req.onsuccess = () => {
            resolve(req.result);
          };
        } catch (err) {
          resolve('');
        }
      } else {
        // console.log('Index Db is not initialized');
        resolve('');
      }
    });
  }

  private setInIndexDb(id: string, value: string) {
    if (value === 'data:image/png;base64,') {
      return;
    }
    if (value === 'data:image/png;base64,Error: Network Error') {
      return;
    }
    const expiryDateTime = moment().add(1, 'day').utc().format();

    if (this.db) {
      const trans = this.db.transaction(['user-profiles'], 'readwrite');
      const userProfileStore = trans.objectStore('user-profiles');

      userProfileStore.add({
        id,
        expiry: expiryDateTime,
        value,
      });
    }
  }

  private deleteFromIndexDb(id: string) {
    if (this.db) {
      const trans = this.db.transaction(['user-profiles'], 'readwrite');
      const userProfileStore = trans.objectStore('user-profiles');

      userProfileStore.delete(id);
    }
  }

  private async fetchImage(id: string) {
    const resFromDb = await this.getFromIndexDb(id);
    if (resFromDb?.value) {
      this.cachedImages[id] = resFromDb.value;
      return;
    }

    const employee = await this.employeeService.get(id);
    const url = employee?.details?.photo
      ? employee?.details?.photo.replace(
          'https://erp-public-data.s3.ap-south-1.amazonaws.com/',
          ''
        )
      : `${employee?.companyGroup.id}/${employee?.company.id}/employees/${employee?.id}.png`;

    const trimmedUrl = url.replace(
      'https://catapharmafilemanagement.s3.ap-south-1.amazonaws.com/',
      ''
    );
    // this.employees[id] = employee;
    const res = await this.fileService.publicGet(trimmedUrl, true);
    if (typeof res === 'string' && res) {
      this.setInIndexDb(id, `data:image/png;base64,${res}`);
      this.cachedImages[id] = `data:image/png;base64,${res}`;
    }
  }

  profilePic({
    id,
    size,
  }: {
    id: string;
    size: 'small' | 'medium' | 'large';
  }): JSX.Element {
    if (this.cachedImages && !this.cachedImages[id]) {
      this.fetchImage(id);
    }
    const imageData =
      this.cachedImages[id] ||
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABlUlEQVRIia3VsWsUQRzF8U9kCUewCFYixkLEQsVCCFhYWqUIUYiIWqT3j3BaEbETRNBY2YtVEBGLgEHFQiRYHCIiIYWkCHIeIVjMblwvl5294V47M+87u/zemwlJhQ5u4SbOYxdf8RxPCL+bTk8kzKfxFPM4NGTDqwgOmwc5DDtU1x0sNOy7jPuEAw0aAOEElhIXgKs4lwFwAdMtAFO4mAM43sK80kwOYGcEwJ8cQFccyTb6lgP4jK0W5tv4mAEIP7HSArCK9QwAeICmpPZxLzMH4D0eNqwv402TQaIqIBR4J+airi7OEnpNp1NfgLCDL0MW1lPmpMuuwBye2Z/qX1jE2/ISowDCEbGHbuNk4pJdPMLysFYdAIRJXBNb9FTCeFDfcVd8I/Z+XQ0QJvEYN1CMaF5pFy/FN2K7BghFab6UaTyoF1gk9KspOobrYzInDsZp/o3pFDpjBBQ4XAdsiA/5uPRD2bAVYAtX8Fpz96TUw1rptcH+MS1wBpcwK2bgqBiyjv+T3ysvtilm4YPYrJ8I/WrTX6LCVOBid+gVAAAAAElFTkSuQmCC';
    return (
      <img
        className={`${sizeGetter(size)} border border-gray-200 rounded-full`}
        src={imageData}
        alt=''
      />
    );
  }

  userCard({
    id,
    extraInfo,
    name,
    size,
    headerStyle,
    link,
  }: {
    size: 'small' | 'medium' | 'large';
    id: string;
    name: string;
    extraInfo?: string;
    headerStyle?: string;
    link: boolean;
  }): JSX.Element {
    if (this.cachedImages && !this.cachedImages[id]) {
      this.fetchImage(id);
    }
    return (
      <div className='flex'>
        <div className='my-auto'>
          {this.cachedImages[id] ? (
            <span
              className={`rounded-full ${sizeGetter(
                size
              )} text-md bg-indigo-900 text-white`}
            >
              <this.profilePic id={id} size={size} />
            </span>
          ) : (
            <span className='rounded-full mr-1 p-0.5 text-md bg-indigo-900 text-white'>
              {initials(name)}
            </span>
          )}
        </div>
        <div className='my-auto'>
          <div>
            {link ? (
              <a
                className={headerStyle}
                href={`/users/profile/${id}`}
                target='_blank'
                rel='noreferrer'
              >
                {name}
              </a>
            ) : (
              <span className={headerStyle}>{name}</span>
            )}
          </div>
          {extraInfo ? (
            <div className='text-xs text-gray-600'>{extraInfo}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

function sizeGetter(size: 'small' | 'medium' | 'large') {
  switch (size) {
    case 'large': {
      return 'w-14 h-14';
    }
    case 'medium': {
      return 'w-10 h-10';
    }
    default: {
      return 'w-6 h-6';
    }
  }
}
