import { Building } from '@erp_core/erp-icons/icons/building';
import { CheckCircle } from '@erp_core/erp-icons/icons/check-circle';
import { ListBulleted } from '@erp_core/erp-icons/icons/list-bulleted';
import { Merge } from '@erp_core/erp-icons/icons/merge';
import { Node } from '@erp_core/erp-ui-components';
import {
  ArchiveBoxIcon,
  FunnelIcon,
  TruckIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useState } from 'react';
import { MarkerType, Position } from 'reactflow';
import {
  Edges,
  OverviewFlow,
  ReactFlowNodeType,
} from '../../../../components/flow-chart';
import { getNodeStyle } from '../../../../components/flow-chart/utils/get-node-style';
import { renderBomList } from '../bom-list';

export type BomNodeType = {
  name: string;
  stock: {
    unit: string;
    required: number;
    deficit: number;
    available: number;
    availableWithSiblingCompany: number;
  };
  processing: {
    type: 'manufacturing' | 'purchase';
    time: string;
    date: string;
    machine?: string;
  };
};

export function findDepth(
  node: Node,
  res: { depth: number }
): { depth: number } {
  if (node.children?.length) {
    if (res.depth < node.children.length) {
      res.depth = node.children.length;
    }

    node.children.forEach((n) => {
      res = findDepth(n, res);
    });
  }

  if (res.depth > 0) {
    return res;
  }

  return { depth: 0 };
}

export function renderBomChart(): ({ node }: { node: Node }) => JSX.Element {
  const BomList = renderBomList();
  return function BomChart({ node }: { node: Node }): JSX.Element {
    const [view, setView] = useState<'graph' | 'list'>('graph');
    // const [nodeDepth] = useState<{ depth: number }>(
    //   findDepth(node, { depth: 1 })
    // );

    // h-[${nodeDepth.depth * 10 > 50 ? nodeDepth.depth * 10 : 50}rem]

    return (
      <div className='relative'>
        <div className='w-full h-96'>
          <div className='absolute top-0 right-0 z-50'>
            <div className='flex'>
              <div
                onClick={() => {
                  if (view === 'graph') {
                    setView('list');
                  }
                }}
                className='p-1 border border-gray-200 rounded-md'
              >
                <ListBulleted
                  className={`cursor-pointer w-5 inline ${
                    view === 'list' ? 'text-indigo-900' : 'text-gray-300'
                  }`}
                />
              </div>
              <div
                onClick={() => {
                  if (view === 'list') {
                    setView('graph');
                  }
                }}
                className='p-1 border border-gray-200 rounded-md'
              >
                <Merge
                  className={`cursor-pointer w-5 inline ${
                    view === 'graph' ? 'text-indigo-900' : 'text-gray-300'
                  }`}
                />
              </div>
            </div>
          </div>
          {view === 'graph' ? (
            <OverviewFlow data={node} getFlowGraph={createFlowChart} />
          ) : (
            <BomList node={node} />
          )}
        </div>
      </div>
    );
  };
}

function addNodes(
  node: Node,
  nodes: Array<any>,
  stages: StageType,
  currentStage: number,
  parentId: string
) {
  node.parentId = parentId;
  // if current stage does not exist in the count obj then add to totalStages as well as to the obj
  if (!stages.count.hasOwnProperty(currentStage)) {
    stages.count[currentStage] = 1;
    stages.totalStages += 1;
  } else {
    stages.count[currentStage] += 1;
  }
  node.depth = 0;
  nodes.push(node);
  node.children?.forEach((n) => {
    addNodes(n, nodes, stages, currentStage + 1, node.id);
  });
}

function calculateDepth({ node }: { node: Node }): number {
  if (!node.children || node.children.length === 0) {
    return 1;
  }

  let depth = node.children.length;
  node.children.forEach((child) => {
    const childDepth = calculateDepth({ node: child });
    if (childDepth > 1) {
      depth += childDepth - 1;
    }
  });
  return depth;
}

type StageType = {
  totalStages: number;
  count: {
    [stage: number]: number;
  };
};

function calculatePosition(
  stages: StageType,
  nodes: Node[]
): Array<{ x: number; y: number; id: string }> {
  const result: Array<{ x: number; y: number; id: string }> = [];
  for (let i = 1; i <= stages.totalStages; i++) {
    for (let j = 1; j <= stages.count[i]; j++) {
      result.push({ x: (stages.totalStages - i) * 200, y: 0, id: '' });
    }
  }

  let nodesToCheck: Node[] = [];
  // Set x for first node
  const first = _.first(nodes);

  if (first) {
    result[0].id = first.id;
    result[0].y = 0;
    first.children?.forEach((x) => {
      const node = nodes.find((n) => n.id === x.id);
      if (node) {
        nodesToCheck.push(node);
      }
    });
  }

  while (nodesToCheck.length !== 0) {
    let nextLevelNodes: Node[] = [];
    let lastLevelDepth = 0;

    for (let node of nodesToCheck) {
      const parentNodeInResult = result.find((x) => x.id === node.parentId);
      if (parentNodeInResult) {
        const parentNodeInNodes = nodes.find((x) => x.id === node.parentId);
        if (parentNodeInNodes) {
          // Find an empty entry in result
          const nodeToUpdate = result.find((x) => !x.id);
          if (nodeToUpdate) {
            nodeToUpdate.id = node.id;
            nodeToUpdate.y = lastLevelDepth;
            lastLevelDepth += 200;
            node.children?.forEach((x) => {
              const nodeToPush = nodes.find((n) => n.id === x.id);
              if (nodeToPush) {
                nextLevelNodes.push(nodeToPush);
              }
            });
          }
        }
      }
    }

    lastLevelDepth = 0;
    nodesToCheck = nextLevelNodes;
  }

  return result;
}

function createFlowChart({
  data,
}: {
  data: Node;
}): { nodes: Array<ReactFlowNodeType>; edges: Array<Edges> } {
  const nodes: Array<Node> = [];
  const stages: StageType = {
    totalStages: 0,
    count: {},
  };

  if (data) {
    addNodes(data, nodes, stages, 1, '');

    nodes.forEach((x) => {
      x.depth = calculateDepth({ node: x });
    });
  }

  const positions = calculatePosition(stages, nodes);

  const finalresult: {
    nodes: Array<ReactFlowNodeType>;
    edges: Array<Edges>;
  } = {
    nodes: [],
    edges: [],
  };

  nodes.forEach((n) => {
    const pos = positions.find((x) => x.id === n.id);
    finalresult.nodes.push({
      id: n.id,
      data: {
        label: <BomNode {...computeNodeData(n)}></BomNode>,
      },
      position: { x: pos?.x || 0, y: pos?.y || 0 },
      draggable: false,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      style: getNodeStyle('x', 'y'),
    });

    n.children?.forEach((c) => {
      finalresult.edges.push({
        id: `e${c.id}-${n.id}`,
        source: c.id,
        target: n.id,
        animated: true,
        markerEnd: { type: MarkerType.ArrowClosed },
      });
    });
  });

  return finalresult;
}

function BomNode({
  name,
  stock: { unit, required, deficit, available, availableWithSiblingCompany },
  processing: { type, time, date, machine },
}: BomNodeType) {
  return (
    <div className='flex h-full bg-gray-50'>
      <div className='h-full my-auto'>
        <ArchiveBoxIcon className='w-5 h-5 text-red-800' />
        <div className='text-xs font-bold'>{deficit}</div>
      </div>
      <div className='text-xs bg-white'>
        <div>
          <span className='bold text-blue-500'>{required}-</span>
          <span className='italic'>
            {name} ({unit})
          </span>
        </div>
        <div className='flex'>
          <div className='text-green-800' title='Available Stock'>
            {available} <CheckCircle className='w-2 h-2 inline' />
          </div>
          <div
            className='text-green-500'
            title='Available Stock with Sibling Company'
          >
            {availableWithSiblingCompany}{' '}
            <Building className='w-2 h-2 inline' />
          </div>
        </div>
        <div className='text-gray-800' title='Processing time required'>
          {type === 'manufacturing' ? (
            <FunnelIcon className='w-3 h-3 inline' />
          ) : (
            <TruckIcon className='w-3 h-3 inline' />
          )}{' '}
          {machine} ({time} on {date} )
        </div>
      </div>
    </div>
  );
}

function computeNodeData(node: Node): BomNodeType {
  return {
    name: node.title,
    stock: {
      unit: 'Kg',
      required: 400,
      deficit: 100,
      available: node.stock?.total?.quantity || 0,
      availableWithSiblingCompany: node.stock?.siblingCompany?.quantity || 0,
    },
    processing: {
      type: node.children?.length ? 'manufacturing' : 'purchase',
      time: node.children?.length ? '5 hours' : '10 days',
      date: '2023-03-01',
    },
  };
}
