import { ClientLocation } from '@erp_core/erp-types/dist/modules/order';
import { VouchersType } from '@erp_core/erp-types/dist/types/modules/accounts/vouchers';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { GeneralAddressType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  KeyValue,
  LoadingButton,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import { CurrentContext } from '../../../../../contexts/current';
import { UseCombinedGrade } from '../../../../../hooks/inventory/grade/use-grade';
import { UseCombinedItemCategory } from '../../../../../hooks/inventory/item/item-category/use-item-category';
import { UseCombinedClient } from '../../../../../hooks/order/sales/client/use-client';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { EditSOShipToDetails } from './so-ship-to-details';

type ItemType = {
  grade: Grade;
  quantity: number;
  rate: number;
  uom: string;
  discountPercent: number;
  deliverySchedule: Array<{
    batchNumber: number;
    date: string;
    quantity: number;
  }>;
  amount: number;
  gst: number;
};

export type SOVoucher = {
  name: string;
  voucherNumber: string;
  itemCategory: IdName;
  date: string;
  referenceNumber: string;
  client: IdName;
  clientAddress: ClientLocation;
  paymentTerms: Array<{
    percent: number;
    paymentType: string;
    noOfDays: number;
  }>;
  buyer: {
    delivery: {
      type: 'self' | 'job-work';
      name: string;
      address: GeneralAddressType;
      contact: string;
      gst: string;
    };
    billing: {
      name: string;
      address: GeneralAddressType;
      contact: {
        mobile: string;
        email: string;
      };
      gst: string;
    };
  };
  salesLedger: IdName;
  items: Array<ItemType>;
  transportationPaidBy: 'seller' | 'buyer' | 'unknown';
  transportationCost: '' | 'part-of-invoice' | 'separate-invoice';
  total: number;
  orderNo: string;
  remarks: string;
};

export function renderSalesOrder({
  useCombinedItemCategory,
  useCombinedGrade,
  useCombinedClient,
  itemService,
}: {
  useCombinedClient: UseCombinedClient;
  useCombinedGrade: UseCombinedGrade;
  useCombinedItemCategory: UseCombinedItemCategory;
  itemService: ItemInterface;
}) {
  return function SalesOrderVoucher({
    so,
    onSave,
  }: {
    so?: VouchersType;
    onSave(s: VouchersType): Promise<void>;
  }) {
    const [voucher, setVoucher] = useState<SOVoucher>(
      so ? (so.details as SOVoucher) : ({} as SOVoucher)
    );
    const [editBasicDetails, setEditBasicDetails] = useState<boolean>(false);

    const { location, company } = useContext(CurrentContext);

    const { getAll: getClients, list: jobWorkClients } = useCombinedClient();

    useEffect(() => {
      if (voucher?.client?.id) {
        getClients();
      }
      // eslint-disable-next-line
    }, [voucher?.client?.id]);

    const Form1 = renderFormV2({
      formName: 'SO Details',
      initialFormState: {
        name: voucher.name || '',
        voucherNumber: voucher.voucherNumber || '',
        date: voucher.date || '',
        referenceNumber: voucher.referenceNumber || '',
        client: voucher.client || { id: '', name: '' },
        remarks: voucher.remarks || '',
        salesLedger: voucher.salesLedger || '',
        orderNo: voucher.orderNo || '',
        itemCategory: voucher.itemCategory || { id: '', name: '' },
        transportationPaidBy: voucher.transportationPaidBy || '',
        transportationCost: voucher.transportationCost || '',
      },
      style: 'w-1/6',
      fieldsData: [
        {
          property: 'name',
          label: 'SO Number',
          type: 'input',
          required: true,
        },
        {
          property: 'referenceNumber',
          type: 'input',
          required: true,
        },
        {
          property: 'date',
          type: 'date',
          required: true,
        },
        {
          property: 'voucherNumber',
          type: 'input',
          required: true,
        },
        {
          property: 'client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedClient,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
        {
          property: 'salesLedger',
          type: 'input',
          required: true,
        },
        {
          property: 'orderNo',
          type: 'input',
        },
        {
          property: 'itemCategory',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedItemCategory,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
        {
          property: 'transportationPaidBy',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Seller', value: 'seller' },
            { text: 'Buyer', value: 'buyer' },
            { text: 'Unknown', value: 'unknown' },
          ],
          required: true,
        },
        {
          property: 'transportationCost',
          type: 'select',
          dependentOn: (formData) => {
            return formData.transportationPaidBy === 'buyer';
          },
          options: [
            { text: 'Select', value: '' },
            { text: 'Part of Invoice', value: 'part-of-invoice' },
            { text: 'Separate Invoice', value: 'separate-invoice' },
          ],
        },
        {
          property: 'remarks',
          type: 'input',
        },
      ],
      mapTToU: (x) => x,
      onSubmit: async (form) => {
        console.log(form);
        let clientAddress: any = voucher.clientAddress || undefined;
        let clientPaymentTerms: any = voucher.paymentTerms;
        if (form.client) {
          clientAddress = undefined;
        }
        setVoucher((x) => ({
          ...x,
          ...{ clientAddress, paymentTerms: clientPaymentTerms },
          ...(form as any),
        }));
        setEditBasicDetails(false);
      },
    });
    const Card = renderCardComponent();
    return (
      <div>
        {editBasicDetails ? (
          <Form1 />
        ) : (
          <Card
            header={{
              title: 'Sales Order Details',
              actions: [
                {
                  type: 'button',
                  button: {
                    name: 'Edit',
                    behaviour: 'click',
                    onClick: async () => {
                      setEditBasicDetails(true);
                    },
                  },
                },
              ],
            }}
            body={{
              type: 'columns',
              body: [
                [
                  { key: 'SO Number', value: voucher.name },
                  { key: 'Reference #', value: voucher.referenceNumber },
                  { key: 'Item Category', value: voucher.itemCategory?.name },
                ],
                [
                  { key: 'Date', value: voucher.date },
                  { key: 'Voucher #', value: voucher.voucherNumber },
                  { key: 'Remarks', value: voucher.remarks },
                ],
                [
                  { key: 'Sales Ledger', value: voucher.salesLedger?.name },
                  { key: 'Party a/c Name', value: voucher.client?.name },
                ],
                [
                  { key: 'Order Number', value: voucher.orderNo },
                  {
                    key: 'Transportation Paid By',
                    value:
                      voucher.transportationPaidBy === 'buyer'
                        ? `${voucher.transportationPaidBy} (${voucher.transportationCost})`
                        : voucher.transportationPaidBy,
                  },
                ],
              ],
            }}
          />
        )}

        <div className='flex flex-wrap'>
          <div className='w-1/3'>
            <Card
              header={{ title: 'Sold By' }}
              body={{
                type: 'jsx-component',
                body: (
                  <div>
                    <div className='font-bold'>{location.company?.name}</div>
                    <div>
                      {location.details?.invoiceTo?.address?.addressLine1},{' '}
                      {location.details?.invoiceTo?.address?.addressLine2}
                    </div>
                    <div>
                      {location.details?.invoiceTo?.address.city} -{' '}
                      {location.details?.invoiceTo?.address.pincode},{' '}
                      {location.details?.invoiceTo?.address.district}
                    </div>
                    <div>
                      {location.details?.invoiceTo?.address.state},{' '}
                      {location.details?.invoiceTo?.address.country}
                    </div>
                    <div>
                      <KeyValue
                        lhs='PAN Number:'
                        rhs={
                          company.details?.documents.find(
                            (x) => x.type === 'pan-card'
                          )?.number || '-'
                        }
                      />
                      <KeyValue
                        lhs='GST'
                        rhs={
                          company.details?.documents.find(
                            (x) => x.type === 'gst'
                          )?.number || '-'
                        }
                      />
                    </div>
                  </div>
                ),
              }}
            />
          </div>
          <div className='w-1/4'>
            <Card
              header={{
                title: 'Shipping Address',
                actions: [
                  {
                    type: 'button',
                    button: {
                      name: 'Edit',
                      behaviour: 'modal',
                      modal: {
                        title: 'Edit Ship To',
                        content: () => {
                          console.log('jobWorkClients', jobWorkClients);
                          const clientAddr = jobWorkClients?.find(
                            (x) => x.id === voucher.client.id
                          )?.details;
                          console.log('clientAddr', clientAddr?.address);
                          let address, phone, gst;
                          if (clientAddr?.locations) {
                            address = clientAddr.locations[0].address;
                            console.log('add', address);
                          }
                          if (clientAddr?.contact) {
                            phone = clientAddr?.contact[0].phones[0];
                            console.log('phone', phone);
                          }
                          if (clientAddr?.registrations) {
                            gst = '';
                            // clientAddr?.registrations[0].gst[0].gstNo || '-';
                            console.log('gst', gst);
                          }
                          console.log('clientAddr', clientAddr);
                          return (
                            <EditSOShipToDetails
                              jobWorkClients={jobWorkClients || []}
                              so={voucher}
                              currentLocation={{
                                name: voucher.client.name,
                                address: address || '',
                                contact: phone || '',
                              }}
                              savePo={async (po) => setVoucher(po)}
                            />
                          );
                        },
                      },
                    },
                  },
                ],
              }}
              body={{
                type: 'jsx-component',
                body: (
                  <div>
                    <div className='font-bold'>
                      {voucher?.buyer?.delivery?.name}
                    </div>
                    <KeyValue
                      lhs='Address'
                      rhs={[
                        `${
                          voucher?.buyer?.delivery?.address?.addressLine1 || ''
                        }, ${
                          voucher?.buyer?.delivery?.address?.addressLine2 || ''
                        }`,
                        `${voucher?.buyer?.delivery?.address.city || ''} - ${
                          voucher?.buyer?.delivery?.address.pincode || ''
                        }, ${voucher?.buyer?.delivery?.address.district || ''}`,
                        `${voucher?.buyer?.delivery?.address.state || ''}, ${
                          voucher?.buyer?.delivery?.address.country || ''
                        }`,
                      ]}
                    />
                    <KeyValue
                      lhs='Contact'
                      rhs={voucher?.buyer?.delivery?.contact || '-'}
                    />
                    <KeyValue
                      lhs='GST'
                      rhs={voucher?.buyer?.delivery?.gst || '-'}
                    />
                  </div>
                ),
              }}
            />
          </div>
        </div>

        <LoadingButton
          text={'Save'}
          behaviorFn={async () => {
            await onSave({
              id: so?.id || '',
              dateTime: voucher.date,
              name: voucher.name,
              description: '',
              details: voucher,
              voucherType: 'sales-order',
            } as VouchersType);
          }}
        />
      </div>
    );
  };
}
