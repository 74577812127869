import { EllipsisH } from '@erp_core/erp-icons/icons/ellipsis-h';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  AdvancedLoadingButton,
  CardBody,
  CardHeader,
  KeyValue,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import _ from 'lodash';
import { LeaveInterface } from 'models/interfaces/hrd/leave';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLeave } from '../../../../hooks/hrd/leave/use-leave';
import { HrdDashboardReportInterface } from '../../../../models/interfaces/analytics/hrd-dashboard-report/report';
import { renderPersistRefillLeaves } from './persist-refill-leaves';

type RenderRefillLeavesBook = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedLeave: UseCombinedLeave;
  leaveService: LeaveInterface;
  hrdDashboardReportService: HrdDashboardReportInterface;
};

export type ProjectedLeaves = {
  [key: string]: {
    name: string;
    attendance: number;
    attendanceDetailed: { [key: string]: number };
    currentYear: {
      [key: string]: {
        [status: string]: number;
      };
    };
    'privilege-leave': number;
    'sick-leave': number;
    'casual-leave': number;
    'compensatory-off': number;
    reason: string;
    selected: boolean;
  };
};

export const renderRefillLeavesBook = ({
  useCombinedEmployeeProfile,
  // useCombinedLeave,
  leaveService,
  hrdDashboardReportService,
}: RenderRefillLeavesBook) => {
  return function EmployeeLeavesBook() {
    const Table = renderTableComponent();

    const previousYear =
      moment().format('YYYY') === '2024'
        ? '2024'
        : moment().subtract(1, 'year').format('YYYY');
    const newYear =
      moment().format('YYYY') === '2024' ? '2025' : moment().format('YYYY');

    const {
      list: employees,
      getAll: getAllEmployees,
    } = useCombinedEmployeeProfile();
    const [sortedEmp, setSortedEmp] = useState<Array<EmployeeProfileType>>([]);
    const [ignoredEmployees, setIgnoredEmployees] = useState<
      Array<EmployeeProfileType>
    >([]);
    // const { list: leaves, getAll: getLeaves } = useCombinedLeave();
    const [projectedLeaves, setProjectedLeaves] = useState<ProjectedLeaves>({});

    function handleChange(empId: string, propertyName: string, value: number) {
      projectedLeaves[empId][propertyName] = value;
      setProjectedLeaves({
        ...projectedLeaves,
      });
      // console.log('projectedLeaves',projectedLeaves)
    }

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // getLeaves({ crossGroup: 'true' });
      hrdDashboardReportService
        .getRefillLeavesSummary(previousYear)
        .then((res) => {
          console.log(res);
          const pl: ProjectedLeaves = { ...projectedLeaves };
          for (let emp of res) {
            pl[emp.employee.id] = {
              currentYear: emp.leaves.currentYear,
              attendance: emp.attendance,
              attendanceDetailed: emp.attendanceDetailed,
              name: emp.employee.name,
              'privilege-leave': emp.leaves.nextYear['privilege-leave'] || 0,
              'sick-leave': emp.leaves.nextYear['sick-leave'] || 0,
              'casual-leave': emp.leaves.nextYear['casual-leave'] || 0,
              'compensatory-off': emp.leaves.nextYear['compensatory-off'] || 0,
              reason: emp.reason,
              selected: false,
            };
          }
          setProjectedLeaves(pl);
        });

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      console.log(projectedLeaves);
    }, [projectedLeaves]);

    useEffect(() => {
      if (employees?.length) {
        const filteredEmployees = employees.filter(
          (x) =>
            x.details?.resigned !== 'yes' &&
            ['yes', 'Yes'].includes(x.details?.onboardToAttendance)
        );

        const ignoredEmployees = employees.filter(
          (x) =>
            x.details?.resigned === 'yes' ||
            ['no', 'No'].includes(x.details?.onboardToAttendance)
        );

        setIgnoredEmployees(ignoredEmployees);
        const sortedEmpData = _.orderBy(filteredEmployees, (e) => e.name, [
          'asc',
        ]);

        // const sortedEmpData = employees
        //   // .map(e => {

        //   //   return {
        //   //     id: e.id,
        //   //     name: e.name,
        //   //     employeeId: e?.details?.employeeId || '',
        //   //     grade: e?.details?.grade?.name || '',
        //   //     personalEmail: e.details?.contactDetails?.emailAddress?.personalEmail || '',
        //   //     officeEmail: e.details?.contactDetails?.emailAddress?.officeEmail || '',
        //   //     resigned: e.details?.resigned || 'no'
        //   //   }
        //   // })
        //   .orderBy('employee.name')((a, b) => a.details?.grade?.name < b.details?.grade?.name ? 1 : -1)
        setSortedEmp(sortedEmpData);
      }
    }, [employees]);

    // useEffect(() => {
    //   if (sortedEmp.length) {
    //     const pl: ProjectedLeaves = {};
    //     for (let emp of sortedEmp) {
    //       pl[emp.id] = {
    //         currentYear: {},
    //         name: emp.name,
    //         'privilege-leave': 0,
    //         'sick-leave': 0,
    //         'casual-leave': 0,
    //         'compensatory-off': 0,
    //         reason: '',
    //         selected: false,
    //       };
    //     }
    //     setProjectedLeaves(pl);
    //   }
    // }, [sortedEmp]);

    const PersistLeaves = renderPersistRefillLeaves({
      projectedLeaves,
      leaveService,
    });

    // const leavesFilterEmp = (empId: string) => {
    //   const unusedLeaves = leaves?.filter(
    //     (l) => l.status === 'un-consumed' && l?.employee?.id === empId
    //   );
    //   return unusedLeaves;
    // };

    const tableHeader: TableHeader = [
      [
        { name: 'Grade', rowSpan: 2 },
        { name: 'Employee', rowSpan: 2 },
        { name: 'Present Days', rowSpan: 2 },
        {
          name: `Previous Year Leaves (${previousYear})`,
          colSpan: 4,
          style: 'text-center',
        },
        {
          name: `Projected Leaves (${newYear})`,
          colSpan: 5,
          style: 'text-center',
        },
        { name: 'Actions', rowSpan: 2 },
      ],

      [
        // Current Leaves
        {
          name: 'Privilege',
          style: 'border border-l-gray-150',
        },
        { name: 'Casual' },
        { name: 'Sick' },
        { name: 'Comp-off' },

        // Projected Leaves
        { name: 'Privilege' },
        { name: 'Casual' },
        { name: 'Sick' },
        { name: 'Comp-off' },
        { name: 'Reason', style: 'w-1/6' },
      ],
    ];

    const tableData =
      sortedEmp?.map((emp) => {
        const pl = projectedLeaves[emp?.id]?.currentYear?.['privilege-leave'];
        const cl = projectedLeaves[emp?.id]?.currentYear?.['casual-leave'];
        const sl = projectedLeaves[emp?.id]?.currentYear?.['sick-leave'];
        const co = projectedLeaves[emp?.id]?.currentYear?.['compensatory-off'];

        return {
          cells: [
            { value: emp?.details?.grade?.name || '-' },
            {
              value: (
                <>
                  <div>{emp?.name}</div>
                  <div>Status {emp?.status}</div>
                  <div className='italic'>{emp?.company?.name || 'abc'}</div>
                </>
              ),
              link: `/users/profile/${emp?.id}`,
            },

            {
              value: (
                <>
                  {projectedLeaves[emp?.id]?.attendance || 0}
                  <Popover className='relative'>
                    <Popover.Button>
                      <EllipsisH className='h-5 inline' />
                    </Popover.Button>
                    <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                      <div>
                        {projectedLeaves[emp?.id]?.attendanceDetailed
                          ? _.keys(
                              projectedLeaves[emp?.id]?.attendanceDetailed
                            ).map((month) => (
                              <div key={month}>
                                <KeyValue
                                  lhs={month}
                                  rhs={
                                    projectedLeaves[emp?.id]
                                      ?.attendanceDetailed[month]
                                  }
                                />
                              </div>
                            ))
                          : null}
                      </div>
                    </Popover.Panel>
                  </Popover>
                </>
              ),
            },
            // Current Leaves Data
            {
              value: (
                <>
                  <div>Un-consumed: {pl?.['un-consumed'] || 0}</div>
                  <div>Redeemed: {pl?.redeemed || 0}</div>
                  <div>Approved: {pl?.approved || 0}</div>
                  <div>Pending: {pl?.pending || 0}</div>
                  <div>Cancelled: {pl?.cancelled || 0}</div>
                </>
              ), //getCount(leavesFilterEmp(emp?.id) || [], 'privilege-leave'),
            },
            {
              value: (
                <>
                  <div>Un-consumed: {cl?.['un-consumed'] || 0}</div>
                  <div>Redeemed: {cl?.redeemed || 0}</div>
                  <div>Approved: {cl?.approved || 0}</div>
                  <div>Pending: {cl?.pending || 0}</div>
                  <div>Cancelled: {cl?.cancelled || 0}</div>
                </>
              ), // getCount(leavesFilterEmp(emp?.id) || [], 'casual-leave')
            },
            {
              value: (
                <>
                  <div>Un-consumed: {sl?.['un-consumed'] || 0}</div>
                  <div>Redeemed: {sl?.redeemed || 0}</div>
                  <div>Approved: {sl?.approved || 0}</div>
                  <div>Pending: {sl?.pending || 0}</div>
                  <div>Cancelled: {sl?.cancelled || 0}</div>
                </>
              ), // getCount(leavesFilterEmp(emp?.id) || [], 'sick-leave')
            },
            {
              value: (
                <>
                  <div>Un-consumed: {co?.['un-consumed'] || 0}</div>
                  <div>Redeemed: {co?.redeemed || 0}</div>
                  <div>Approved: {co?.approved || 0}</div>
                  <div>Pending: {co?.pending || 0}</div>
                  <div>Cancelled: {co?.cancelled || 0}</div>
                </>
              ), // getCount(leavesFilterEmp(emp?.id) || [], 'compensatory-off'),
            },

            // Projected Leaves Data
            {
              value: (
                <input
                  type='number'
                  min={0}
                  value={projectedLeaves[emp?.id]?.['privilege-leave'] || 0}
                  className='border border-black text-left h-50 w-12'
                  onChange={(e) => {
                    handleChange(
                      emp?.id,
                      'privilege-leave',
                      parseFloat(e.target.value || '0')
                    );
                  }}
                ></input>
              ),
            },
            {
              value: (
                <input
                  type='number'
                  value={projectedLeaves[emp?.id]?.['casual-leave'] || 0}
                  className='border border-black text-left h-50 w-12'
                  onChange={(e) => {
                    handleChange(
                      emp?.id,
                      'casual-leave',
                      parseInt(e.target.value || '0')
                    );
                  }}
                ></input>
              ),
            },
            {
              value: (
                <input
                  type='number'
                  value={projectedLeaves[emp?.id]?.['sick-leave'] || 0}
                  className='border border-black text-left h-50 w-12'
                  onChange={(e) => {
                    handleChange(
                      emp?.id,
                      'sick-leave',
                      parseInt(e.target.value || '0')
                    );
                  }}
                ></input>
              ),
            },
            {
              value: (
                <input
                  type='number'
                  value={projectedLeaves[emp?.id]?.['compensatory-off'] || 0}
                  className='border border-black text-left h-50 w-12'
                  onChange={(e) => {
                    handleChange(
                      emp?.id,
                      'compensatory-off',
                      parseInt(e.target.value || '0')
                    );
                  }}
                ></input>
              ),
            },
            {
              value: projectedLeaves[emp?.id]?.reason,
            },
            {
              value: (
                <input
                  type='checkbox'
                  checked={projectedLeaves[emp?.id]?.selected}
                  onChange={(e) => {
                    e.preventDefault();
                    projectedLeaves[emp?.id]['selected'] = e.target.checked;
                    setProjectedLeaves({
                      ...projectedLeaves,
                    });
                  }}
                  id='checked'
                  name='checked'
                />
              ),
            },
          ],
        };
      }) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Refill Leaves Book',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table header={tableHeader} body={tableBody} />
          <div className='flex justify-end'>
            <AdvancedLoadingButton
              text='Submit'
              behaviour='modal'
              modal={{
                size: 'large',
                title: 'Refill Leave Bank',
                content: ({ onClose }) => {
                  return <PersistLeaves onClose={onClose} />;
                },
              }}
              defaultStyle='px-4 py-2 m-1.5 bg-green-600 border rounded-lg'
            />
          </div>

          <div className='my-2'>
            <Table
              header={[
                [
                  { name: 'Grade' },
                  { name: 'Employee Name' },
                  {
                    name: 'Resigned / Not Onboarded To Attendance',
                  },
                ],
              ]}
              body={ignoredEmployees.map((x) => {
                return {
                  cells: [
                    { value: x?.details?.grade?.name || '-' },
                    {
                      value: (
                        <>
                          <div>{x?.name}</div>
                          <div className='italic'>
                            {x.company?.name || 'abc'}
                          </div>
                        </>
                      ),
                      link: `/users/profile/${x?.id}`,
                    },
                    {
                      value:
                        x.details?.resigned === 'yes'
                          ? 'Resigned'
                          : 'Not Onboarded to Attendance',
                    },
                  ],
                };
              })}
            />
          </div>
        </>
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
};
