import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';

export function renderOAInfoForm() {
  return function OADetailsForm(): JSX.Element {
    const OAInfoCard = renderCardComponent();

    const OAQuantityInfoCard = renderCardComponent();

    const data = {
      id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
      sirNo: '9890',
      productCode: '123',
      endUse: '-',
      offerNo: '24-25/9890',
      oANo: '4100023370',
      productGrade: {
        id: '643132134654',
        name: 'SFG - NMP (Standard)',
        casNo: '546546',
        alias: '1-Amino-4-Methyl Piperazine',
        endUse: '-',
        productName: 'SFG - NMP',
        productCode: '2352',
      },
      poQty: '50',
      date: '31/12/2024',
      customer: { id: '2222', name: 'Dr. Reddy' },
      product: { id: '22222', name: 'N-Methyl Piperazine' },
      dispatchType: 'Domestic',
      qtySchedule: [
        {
          quantity: '50',
          dateOrLeadTime: '12 Jan 2025',
          unit: 'kg',
        },
      ],
    };

    const OAQuantityInfoCardHeader: CardHeader = {
      title: 'Quantity Details',
    };

    const OAInfoCardHeader: CardHeader = {
      title: 'OA',
      actions: [],
    };

    const OAInfoData: ColumnizedContent = [
      [
        {
          key: 'Product:',
          value: data.productGrade?.productName,
          type: 'text',
        },
        {
          key: 'Product Code:',
          value: data.productCode,
          type: 'text',
        },
        {
          key: 'Product Grade:',
          value: data.productGrade.name,
          type: 'text',
        },
        {
          key: 'Customer:',
          value: data.customer?.name,
          type: 'text',
        },
      ],
      [
        {
          key: 'PO Date:',
          value: data.date,
          type: 'text',
        },
        {
          key: 'End Use:',
          value: data.endUse,
          type: 'text',
        },
        {
          key: 'Dispatch Type:',
          value: data.dispatchType,
          type: 'text',
        },
      ],
      [],
    ];

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [{ name: 'Quantity' }, { name: 'Schedule' }, { name: 'Action' }],
    ];
    const tableBody: TableBody =
      data.qtySchedule.map((r) => ({
        rowData: { OA: r },
        cells: [{ value: r.quantity + r.unit }, { value: r.dateOrLeadTime }],
      })) || [];

    const OAInfoCardBody: CardBody = {
      type: 'columns',
      body: OAInfoData,
    };

    const OAQuantityCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Send Request',
                  show: () => true,
                  behaviour: 'click',
                  onClick: async () => {
                    toast('Request Send Successfully.');
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <OAInfoCard body={OAInfoCardBody} header={OAInfoCardHeader} />

        <OAQuantityInfoCard
          body={OAQuantityCardBody}
          header={OAQuantityInfoCardHeader}
        />
      </div>
    );
  };
}
