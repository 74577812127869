/* eslint-disable no-unused-vars */

import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { createContext } from 'react';
import { UserAuthorization } from '../models/interfaces/admin/user-admin';

export const UserContext = createContext<{
  user: CurrentUserType & {
    authorization: UserAuthorization;
  };
  setUser: (l: CurrentUserType) => void;
}>({
  user: {} as CurrentUserType & {
    authorization: UserAuthorization;
  },
  setUser: (l: CurrentUserType) => {},
});
