import {
  ConfiguratorFilterType,
  ConfiguratorType,
} from '@erp_core/erp-types/dist/modules/notification';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { NotificationRepo } from '../datalayer/repository/http/notification';
import { NotificationConfiguratorInterface } from '../models/interfaces/notification/configurator';
import { erpV2Api } from './admin';
import { NOTIFICATON_SERVER_URI } from './server-uri';

export const notificationService = new NotificationRepo(NOTIFICATON_SERVER_URI);

// Notification Configurator Service
export const notificationConfiguratorService = new GenericHttpWithLogsRepo<
  ConfiguratorType,
  ConfiguratorFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: NOTIFICATON_SERVER_URI,
  resourceEndpoint: '/notification-service/configurator',
});

export const useCombinedNotificationConfigurator = createUseCombinedResource<
  ConfiguratorType,
  ConfiguratorFilterType,
  NotificationConfiguratorInterface
>({ useService: () => notificationConfiguratorService });
