/* eslint-disable no-unused-vars */
import { Stockgroup } from '@erp_core/erp-icons/icons/stock-group';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderInlineInput,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCombinedRole } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedRevision } from '../../../../hooks/inventory/revision/use-revision';
import { UseCombinedStockGroup } from '../../../../hooks/inventory/stockgroup/use-stockgroup';
import { StockGroupInterface } from '../../../../models/interfaces/inventory/stock-group';
import { renderUploadStockgroupItems } from './components/upload-sg-item';
import { renderStockgroupTabs } from './new-tabs';

type RenderStockGroupProfile = {
  hooks: {
    useCombinedStockGroup: UseCombinedStockGroup;
    useFileTransfer: UseFileTransfer;
    useCombinedMetric: UseCombinedMetric;
    useCombinedGST: UseCombinedGST;
    useCombinedColor: UseCombinedColor;
    useCombinedAppearance: UseCombinedAppearance;
    useCombinedOdour: UseCombinedOdour;
    useCurrentUserRoles: UseCurrentUserRoles;
    useCombinedRevision: UseCombinedRevision;
    useCombinedRole: UseCombinedRole;
    useCombinedUser: UseCombinedUser;
    useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  };
  services: {
    stockGroupService: StockGroupInterface;
  };
};

export function renderStockGroupProfile({
  hooks: {
    useCombinedStockGroup,
    useFileTransfer,
    useCombinedMetric,
    useCombinedGST,
    useCombinedAppearance,
    useCombinedColor,
    useCombinedOdour,
    useCurrentUserRoles,
    useCombinedRevision,
    useCombinedRole,
    useCombinedUser,
    useCombinedEmployeeProfile,
  },
  services: { stockGroupService },
}: RenderStockGroupProfile): () => JSX.Element {
  const Card = renderCardComponent();
  const StockgroupTabs = renderStockgroupTabs({
    useFileTransfer,
    useCombinedMetric,
    useCombinedGST,
    useCombinedAppearance,
    useCombinedColor,
    useCombinedOdour,
    useCombinedEmployeeProfile,
    useCurrentUserRoles,
    useCombinedRevision,
    useCombinedRole,
    useCombinedUser,
  });

  return function ItemProfile(): JSX.Element {
    const { id } = useParams();

    const {
      resource: stockGroup,
      get,
      syncSet: setStockGroup,
      // getAll,
      // list
    } = useCombinedStockGroup();

    // useEffect(() => {
    //   getAll({
    //     getAll: 'true'
    //   } as any);
    // }, []);

    // useEffect(() => {
    //   console.log(list?.map(x => ({
    //     id: x.id,
    //     name: x.name
    //   })));
    // }, [list])

    const { syncSet: setRevision } = useCombinedRevision();

    const InlineInput = renderInlineInput({
      useResource: useCombinedStockGroup,
      id: id || '',
    });

    useEffect(() => {
      if (id) {
        get(id);
      }
      // eslint-disable-next-line
    }, [id]);

    const [header, setHeader] = useState<CardHeader>({
      title: `Stock Group Profile: ${stockGroup?.name || id}`,
    });

    useEffect(() => {
      if (stockGroup?.name) {
        document.title += stockGroup.name;
        setHeader({
          title: (
            <>
              <span>{stockGroup.name || id}</span>{' '}
              <span className='italic font-thin'>
                version {stockGroup.version || '0.0.0'}
              </span>
            </>
          ),
          icon: (
            <Stockgroup className='text-black w-24 fill-white self-center' />
          ),
          subheading: (
            <>
              <div className='text-gray-700 italic'>
                <InlineInput
                  name='description'
                  value={
                    stockGroup.description ||
                    'Add a description for the stockgroup...'
                  }
                />
              </div>
              <div>Parent Stockgroup: {stockGroup.parentStockGroupId}</div>
              <div className='text-gray-700'>
                created by{' '}
                <a
                  className='font-bold'
                  href={`/users/profile/${stockGroup.createdBy?.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <UserIcon className='w-4 inline' />{' '}
                  {stockGroup.createdBy?.name || '??'}
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
                <a
                  className='font-bold'
                  href={`/users/profile/${stockGroup.createdBy?.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <UserIcon className='w-4 inline' />{' '}
                  {stockGroup.lastModifiedBy?.name || '??'}
                </a>{' '}
                at {moment.utc(stockGroup.lastModifiedAt).fromNow()}
              </div>
            </>
          ),
          menu: {
            actions: [
              {
                name: 'Change Parent',
                behaviour: 'modal',
                show: () => Boolean(stockGroup?.id),
                modal: {
                  title: `Change ${stockGroup.name}'s Parent`,
                  content: () => {
                    const Form = renderFormV2({
                      initialFormState: {
                        isParentRoot:
                          stockGroup.parentStockGroupId === 'root'
                            ? 'yes'
                            : 'no',
                        parentStockgroup:
                          stockGroup.parentStockGroupId === 'root'
                            ? { id: '', name: '' }
                            : {
                                id: stockGroup.parentStockGroupId,
                                name: 'Non-Root Stockgroup',
                              },
                      },
                      fieldsData: [
                        {
                          property: 'isParentRoot',
                          type: 'select',
                          required: true,
                          options: [
                            { text: 'Select', value: '' },
                            { text: 'Yes', value: 'yes' },
                            { text: 'No', value: 'no' },
                          ],
                        },
                        {
                          property: 'parentStockgroup',
                          dependentOn: (formData) => {
                            return formData.isParentRoot === 'no';
                          },
                          type: 'searchable-select',
                          searchOptions: {
                            useSearch: useCombinedStockGroup,
                            onSearchValueSelect: () => {},
                          },
                        },
                      ],
                      onSubmit: async (form) => {
                        if (form.isParentRoot) {
                          await setStockGroup({
                            id: stockGroup.id,
                            parentStockGroupId: 'root',
                          } as any);
                        } else if ((form.parentStockgroup as any)?.id) {
                          await setStockGroup({
                            id: stockGroup.id,
                            parentStockGroupId: (form.parentStockgroup as any)
                              .id,
                          } as any);
                        }
                      },
                      mapTToU: (u) => u,
                    });
                    return (
                      <div>
                        <Form />
                      </div>
                    );
                  },
                },
              },
            ],
          },
          actions: [
            {
              type: 'button',
              button: {
                name: 'Download',
                behaviour: 'click',
                onClick: async () => {
                  stockGroupService.getSheetItemStockgroup(stockGroup.id);
                },
              },
            },
            {
              type: 'button',
              button: {
                name: 'Upload',
                behaviour: 'modal',
                modal: {
                  size: 'large',
                  title: 'Upload Stockgroup Items',
                  content: ({ onClose }) => {
                    const Upload = renderUploadStockgroupItems({
                      stockGroupService,
                      useCombinedRevision,
                    });
                    return (
                      <div>
                        <Upload id={stockGroup.id} onClose={onClose} />
                      </div>
                    );
                  },
                },
              },
            },
          ],
        });
      }
      // eslint-disable-next-line
    }, [stockGroup]);

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          {stockGroup ? (
            <StockgroupTabs
              sgService={stockGroupService}
              stockGroup={stockGroup}
              setStockGroup={setStockGroup}
              setRevision={setRevision}
            />
          ) : (
            <></>
          )}
        </div>
      ),
    };

    return <Card header={header} body={body} />;
  };
}
