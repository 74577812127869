import {
  AttendanceType,
  CombinedAttDataType,
  LeaveAppType,
  LeaveFilterType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';

export function renderUnFinalize({
  setAttendance,
  getData,
  setOvertime,
  getLeaves,
  setLeave,
}: {
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
  setOvertime: (s: OvertimeType) => Promise<OvertimeType>;
  getLeaves: (filter?: LeaveFilterType) => Promise<LeaveAppType[]>;
  setLeave: (s: LeaveAppType) => Promise<LeaveAppType>;
}): (p: {
  data: { attendance: CombinedAttDataType };
  onClose: () => void;
}) => JSX.Element {
  return function UnFinalize({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const [unfinalizable, setUnfinalizable] = useState<string>('');

    const compOffReasons = ['ot-to-comp-off', 'worked-week-off-comp-off'];
    const salaryReasons = ['ot-to-salary', 'worked-week-off-salary'];

    useEffect(() => {
      if (
        attendance.details?.summary?.autofinalizeReason &&
        compOffReasons.includes(
          attendance.details?.summary?.autofinalizeReason
        ) &&
        attendance.employee.id
      ) {
        // TODO: Rather the getting all leaves of employee
        // we can perform getById and verify status.
        getLeaves({
          crossGroup: 'true',
          employeeId: attendance.employee?.id,
        }).then((res) => {
          const filtered = res.filter((x) => x.status === 'un-consumed');
          for (const ot of attendance.overtimes) {
            if (Array.isArray(ot.details?.otCompoffsEarned)) {
              ot.details?.otCompoffsEarned?.forEach((id) => {
                if (!filtered.find((x) => x.id === id.toUpperCase())) {
                  setUnfinalizable(
                    'Some of the earned Comp-offs are already consumed'
                  );
                }
              });
            }
          }
        });
      }
      // eslint-disable-next-line
    }, [attendance]);
    let header = '';
    if (
      attendance.details?.summary?.autofinalizeReason &&
      salaryReasons.includes(attendance.details?.summary?.autofinalizeReason)
    ) {
      header = `This record was finalized by redeeming Overtime hours as Salary.
        We will reset the overtime back to issued state`;
    }

    if (
      attendance.details?.summary?.autofinalizeReason &&
      compOffReasons.includes(attendance.details?.summary?.autofinalizeReason)
    ) {
      header = `This record was finalized by redeeming Overtime hours as Comp-off.
      We will need to cancel the compoffs (condition if they are still unconsumed). Otherwise we cannot unfinalize`;
    }

    if (
      attendance?.details?.summary?.autofinalizeReason &&
      ['weekoff-leaves-approved', 'leaves-approved'].includes(
        attendance.details?.summary?.autofinalizeReason
      )
    ) {
      header =
        'This record was finalized by redeeming Leaves. We will needs to mark then back to pending state.';
    }

    const Form = renderFormV2({
      dynamicContent: {
        top: async () => {
          if (header) {
            return <div className='p-1 italic'>{header}</div>;
          }
          return <></>;
        },
      },
      formName: 'Are you sure you want to un-finalize?',
      fieldsData: [
        {
          property: 'unfinalize',
          label: 'Unfinalize',
          required: true,
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
      ],
      initialFormState: {
        unfinalize: '',
      },
      onSubmit: async (form) => {
        if (form.unfinalize === 'yes') {
          if (attendance.id) {
            if (
              attendance.details?.summary?.overtime?.id &&
              attendance.details.summary.overtime.reward === 'salary' &&
              attendance.details?.summary?.autofinalizeReason &&
              salaryReasons.includes(
                attendance.details?.summary?.autofinalizeReason
              )
            ) {
              await setOvertime({
                id: Array.isArray(attendance.details?.summary.overtime.id)
                  ? attendance.details.summary.overtime.id[0]
                  : attendance.details.summary.overtime.id,
                status: 'issued',
              } as any);
            }

            if (
              attendance.details?.summary?.overtime?.id &&
              attendance.details?.summary?.overtime.reward === 'comp-off' &&
              attendance.details?.summary?.autofinalizeReason &&
              compOffReasons.includes(
                attendance.details?.summary?.autofinalizeReason
              )
            ) {
              for (const ot of attendance.overtimes) {
                if (Array.isArray(ot.details?.otCompoffsEarned)) {
                  for (const id of ot.details?.otCompoffsEarned) {
                    // Cancel the comp-off leaves
                    await setLeave({
                      id: id.toUpperCase(),
                      status: 'cancelled',
                      reason: `Cancelled as ${attendance.date} was unfinalized`,
                    } as any);
                  }
                }
              }

              await setOvertime({
                id: Array.isArray(attendance.details?.summary.overtime.id)
                  ? attendance.details.summary.overtime.id[0]
                  : attendance.details.summary.overtime.id,
                status: 'issued',
              } as any);
            }

            if (
              attendance.details?.summary?.leaves?.length &&
              [
                'present-half-day',
                'leaves-approved',
                'weekoff-leaves-approved',
              ].includes(attendance.details?.summary?.autofinalizeReason || '')
            ) {
              for (const lv of attendance.details?.summary?.leaves) {
                if (lv?.id) {
                  await setLeave({
                    id: lv.id.toUpperCase(),
                    status: 'pending',
                  } as any);
                }
              }
            }

            await setAttendance({
              id: attendance.id,
              finalized: false,
            } as AttendanceType);
            await getData();
            onClose();
          }
        }
      },
      mapTToU: (p) => p,
    });

    if (!unfinalizable) {
      return <Form />;
    }

    return <div>{unfinalizable}</div>;
  };
}
