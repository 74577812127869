import { Fire } from '@erp_core/erp-icons/icons/fire';
import {
  HazardPrecaution,
  HazardPrecautionFilterType,
} from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedPrecautionaryStatement } from '../../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-precautionary-statement';
import { renderAddGhsPrecautionaryStatementForm } from './forms/ghs-precautionary-statement-add-form';

export type RenderGhsPrecautionaryStatementProps = {
  useCombinedPrecautionaryStatement: UseCombinedPrecautionaryStatement;
};

export function renderGhsPrecautionaryStatement({
  useCombinedPrecautionaryStatement,
}: RenderGhsPrecautionaryStatementProps): () => JSX.Element {
  return function GhsPrecautionaryStatement(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      HazardPrecaution,
      HazardPrecautionFilterType
    >();

    useEffect(() => {
      getGhsPrecautionaryStatements();
      // eslint-disable-next-line
    }, []);

    const {
      set: setGhsPrecautionaryStatement,
      getList: getGhsPrecautionaryStatements,
    } = useCombinedPrecautionaryStatement();

    // eslint-disable-next-line
    const handleGhsPrecautionaryStatementAdd = async (
      data: Partial<HazardPrecaution>
    ) => {
      try {
        setGhsPrecautionaryStatement(data as HazardPrecaution);
        toast('Added sucessfully');
      } catch (error) {
        toast((error as any).message);
      }
    };
    const GhsPrecautionaryStatementCard = renderCardComponent();
    const GhsPrecautionaryStatementCardHeader: CardHeader = {
      title: 'GHS Precautionary Statement',
      icon: (
        <Fire className='h-8 w-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add GHS Precautionary Statement',
            behaviour: 'modal',
            modal: {
              title: 'Add GHS Precautionary Statement',
              content: ({ onClose }) => {
                const Form = renderAddGhsPrecautionaryStatementForm();
                return (
                  <Form
                    onSave={async (form) => {
                      handleGhsPrecautionaryStatementAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const GhsPrecautionaryStatementTableHeader: TableHeader = [
      [{ name: 'Code' }, { name: 'Statement' }, { name: 'Chapter - Category' }],
    ];

    const bodyMapper = (r: HazardPrecaution) => {
      const cells: Array<TableCell> = [
        { value: r.code, style: 'text-center' },
        { value: r.statement },
        {
          value: (
            <div>
              {r.hazardClasses?.map((c) => (
                <span className='mx-0.5 px-2 py-1 border border-gray-200 rounded'>
                  {c.chapterNo} - {c.categoryName}
                </span>
              ))}
            </div>
          ),
        },
      ];
      return {
        cells,
      };
    };

    const filter: Filter<HazardPrecautionFilterType> = {
      version: 'v2',
      sortFields: [
        {
          key: 'Code',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
      ],
      filterMapper: (filterSelection: HazardPrecautionFilterType) => {
        const filterData: HazardPrecautionFilterType = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        return filterData as HazardPrecautionFilterType;
      },
    };

    const GhsPrecautionaryStatementCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={GhsPrecautionaryStatementTableHeader}
              bodyMapper={bodyMapper}
              filter={filter}
              type={{
                defaultPageSize: 10,
                type: 'paginated',
                usePaginatedResources: useCombinedPrecautionaryStatement,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <GhsPrecautionaryStatementCard
          header={GhsPrecautionaryStatementCardHeader}
          body={GhsPrecautionaryStatementCardBody}
        />
      </>
    );
  };
}
