/* eslint-disable no-unused-vars */
import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { useEffect, useState } from 'react';
import {
  UserAdminInterface,
  UserAuthorization,
} from '../../../models/interfaces/admin/user-admin';

export type UseCurrentUser = () => {
  data: CurrentUserType & {
    authorization: UserAuthorization;
  };
  setCurrentUser: (s: CurrentUserType) => void;
};

export interface UseCurrentUserOptions {
  useUserAdminService: () => UserAdminInterface;
}

export function createUseCurrentUser({
  useUserAdminService,
}: UseCurrentUserOptions): UseCurrentUser {
  return () => {
    const [user, setUser] = useState<
      CurrentUserType & {
        authorization: UserAuthorization;
      }
    >(
      {} as CurrentUserType & {
        authorization: UserAuthorization;
      }
    );
    const userService = useUserAdminService();

    const apiCall = async () => {
      const loc = await userService.getCurrent();
      if (loc.id) {
        const res = await userService.getAuthorization(loc.id);
        if (res) {
          setUser({ ...loc, authorization: res as UserAuthorization });
        }
      }
    };

    useEffect(() => {
      apiCall();
      // eslint-disable-next-line
    }, []);

    const setCurrentUser = async (u: CurrentUserType) => {
      await userService.setCurrent(u);
      if (u.id) {
        const res = await userService.getAuthorization(u.id);
        if (res) {
          setUser({ ...u, authorization: res as UserAuthorization });
        }
      }
    };

    return {
      data: user,
      setCurrentUser: (s: CurrentUserType) => {
        setCurrentUser(s);
      },
    };
  };
}
