import { Company, CompanyGroup } from '@erp_core/erp-types/dist/modules/admin';
import { Client } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  // LoadingButton,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
// import { renderAuthWrapper } from '../../../../../../components/auth';
import { UserContext } from '../../../../contexts/user';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';

export function RenderClientBankDetails({
  data,
  useFileTransfer,
  company,
  onEditClientBankDetails,
  companyGroup,
}: {
  data: Client;
  useFileTransfer: UseFileTransfer;
  company: Company;
  companyGroup: CompanyGroup;
  onEditClientBankDetails: (f: any) => Promise<void>;
}): JSX.Element {
  const ClientBankDetailsCard = renderCardComponent();
  const { user: currentUser } = useContext(UserContext);

  const ClientBankDetailsCardHeader: CardHeader = {
    title: 'Bank Details',
    actions: [
      {
        type: 'button',
        // auth: 'UI:CLIENT-ACCOUNT-EDIT:VIEW',
        button: {
          name: 'Edit Bank Details',
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({ onClose }) => {
              const Form = renderFormV2({
                fieldsData: [
                  {
                    property: 'beneficiaryName',
                    label: 'Beneficiary Name',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'accountNumber',
                    label: 'Account Number',
                    type: 'number',
                    required: true,
                  },
                  {
                    property: 'bankName',
                    label: 'Bank Name',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'branchName',
                    label: 'Branch Name',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'ifscCode',
                    label: 'IFSC Code',
                    type: 'input',
                    required: true,
                  },
                  { property: 'iban', label: 'IBAN', type: 'input' },
                  { property: 'swift', label: 'Swift', type: 'input' },

                  {
                    property: 'branchCodeOrMicr',
                    label: 'Branch Code / Micr',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'address',
                    label: 'Address',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'currency',
                    label: 'Currency',
                    type: 'select',
                    options: [
                      { value: '', text: 'SELECT' },
                      { value: 'INR', text: 'INR' },
                      { value: 'USD', text: 'USD' },
                    ],
                    required: true,
                  },
                  {
                    property: 'cancelledChequeReceived',
                    label: 'Canceled Cheque Received',
                    type: 'select',
                    options: [
                      { value: '', text: 'SELECT' },
                      { value: 'yes', text: 'YES' },
                      { value: 'no', text: 'NO' },
                    ],
                    required: true,
                  },
                  {
                    property: 'bankDetailsConfirmationLetter',
                    label: 'Bank Details Confirmation Letter',
                    type: 'file',
                    accept: 'application/pdf',
                  },
                ],
                initialFormState: {
                  beneficiaryName:
                    data?.details?.registrations?.bankDetails
                      ?.beneficiaryName || '',
                  accountNumber:
                    data?.details?.registrations?.bankDetails?.accountNumber ||
                    '',
                  bankName:
                    data?.details?.registrations?.bankDetails?.bankName || '',
                  branchName:
                    data?.details?.registrations?.bankDetails?.branchName || '',
                  iban: data?.details?.registrations?.bankDetails?.iban || '',
                  swift: data?.details?.registrations?.bankDetails?.swift || '',
                  currency:
                    data?.details?.registrations?.bankDetails?.currency || '',
                  branchCodeOrMicr:
                    data?.details?.registrations?.bankDetails
                      ?.branchCodeOrMicr || '',
                  ifscCode:
                    data?.details?.registrations?.bankDetails?.ifscCode || '',
                  address:
                    data?.details?.registrations?.bankDetails?.address || '',
                  cancelledChequeReceived:
                    data?.details?.registrations?.bankDetails
                      ?.cancelledChequeReceived || '',
                  bankDetailsConfirmationLetter:
                    data?.details?.registrations?.bankDetails
                      ?.bankDetailsConfirmationLetter || '',
                },
                style: 'md:w-1/2',
                onSubmit: onEditClientBankDetails,
                allowUnchanged: true,
                mapTToU(p) {
                  return (p as unknown) as any;
                },
                upload: {
                  useFileTransfer: useFileTransfer,
                  config: [
                    {
                      name: 'bankDetailsConfirmationLetter',
                      path: `${companyGroup.id}/${company.id}/client-data/bank-details-confirmation-letter/${data.id}`,
                      type: 'private',
                    },
                  ],
                },
              });
              return <Form />;
            },
          },
        },
      },
    ],
  };

  const ClientBankDetailsData: ColumnizedContent = [
    [
      {
        key: 'Name of Beneficiary:',
        value: data?.details?.registrations?.bankDetails?.beneficiaryName || '',
        type: 'text',
      },
      {
        key: 'Account Number:',
        value: data?.details?.registrations?.bankDetails?.accountNumber || '',
        type: 'text',
      },
      {
        key: 'Bank Name:',
        value: data?.details?.registrations?.bankDetails?.bankName || '',
        type: 'text',
      },
      {
        key: 'Bank Address:',
        value: data?.details?.registrations?.bankDetails?.address || '',
        type: 'text',
      },
    ],
    [
      {
        key: 'SWIFT:',
        value: data?.details?.registrations?.bankDetails?.swift || '',
        type: 'text',
      },
      {
        key: 'IBAN:',
        value: data?.details?.registrations?.bankDetails?.iban || '',
        type: 'text',
      },
      {
        key: 'IFSC Code:',
        value: data?.details?.registrations?.bankDetails?.ifscCode || '',
        type: 'text',
      },
      {
        key: 'Bank Branch Code/ MICR:',
        value:
          data?.details?.registrations?.bankDetails?.branchCodeOrMicr || '',
        type: 'text',
      },
    ],
    [
      {
        key: 'Currency:',
        value: data?.details?.registrations?.bankDetails?.currency || '',
        type: 'text',
      },
      {
        key: 'Bank Branch:',
        value: data?.details?.registrations?.bankDetails?.branchName || '',
        type: 'text',
      },
      {
        key: 'Cancelled Cheque Recd:',
        value:
          data?.details?.registrations?.bankDetails?.cancelledChequeReceived ||
          'no',
        type: 'text',
      },
      {
        key: 'Bank Details Confirmation Letter:',
        value: data?.details?.registrations?.bankDetails
          ?.bankDetailsConfirmationLetter
          ? (((<ArrowDownTrayIcon className='w-5 h-5' />) as unknown) as string)
          : '',
        type: 'text',
      },
    ],
  ];

  const ClientBankDetailsCardBody: CardBody = {
    type: 'columns',
    body: ClientBankDetailsData,
  };

  return (
    <ClientBankDetailsCard
      body={ClientBankDetailsCardBody}
      header={ClientBankDetailsCardHeader}
      auth={currentUser.authorization}
    />
  );
}
