import { RuleRequestFilter } from '@erp_core/erp-types/dist/modules/disciplinary';
import { RuleRequestType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  MisconductFilter,
  MisconductRuleBookFilter,
  MisconductRuleBookType,
  MisconductType,
} from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { MisconductInterface } from '../models/interfaces/hrd/disciplinary/misconduct';
import { MisconductRuleBookInterface } from '../models/interfaces/hrd/disciplinary/misconduct-rule-book';
import { RuleRequestInterface } from '../models/interfaces/hrd/disciplinary/rule-request';
import { erpV2Api } from './admin';
import { DISCIPLINARY_SERVER_URI } from './server-uri';

export const disciplinaryMisconductService = new GenericHttpWithLogsRepo<
  MisconductType,
  MisconductFilter
>({
  erpv2Api: erpV2Api,
  baseURI: DISCIPLINARY_SERVER_URI,
  resourceEndpoint: '/misconduct',
});

export const useCombinedMisconduct = createUseCombinedResource<
  MisconductType,
  MisconductFilter,
  MisconductInterface
>({
  useService: () => disciplinaryMisconductService,
});

export const disciplinaryMisconductRuleBookService = new GenericHttpWithLogsRepo<
  MisconductRuleBookType,
  MisconductRuleBookFilter
>({
  erpv2Api: erpV2Api,
  baseURI: DISCIPLINARY_SERVER_URI,
  resourceEndpoint: '/misconduct-rule-book',
});

export const useCombinedMisconductRuleBook = createUseCombinedResource<
  MisconductRuleBookType,
  MisconductRuleBookFilter,
  MisconductRuleBookInterface
>({
  useService: () => disciplinaryMisconductRuleBookService,
});

export const disciplinaryRuleRequestService = new GenericHttpWithLogsRepo<
  RuleRequestType,
  RuleRequestFilter
>({
  erpv2Api: erpV2Api,
  baseURI: DISCIPLINARY_SERVER_URI,
  resourceEndpoint: '/rule-request',
});

export const useCombinedRuleRequest = createUseCombinedResource<
  RuleRequestType,
  RuleRequestFilter,
  RuleRequestInterface
>({
  useService: () => disciplinaryRuleRequestService,
});
