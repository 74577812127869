import { FinishedGood } from '@erp_core/erp-icons/icons/finished-good';
import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import {
  CalendarDaysIcon,
  HomeIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { BomFlowInterface } from 'models/interfaces/inventory/bom-flow';
import { useEffect, useState } from 'react';

export function renderStep2({
  bomFlowService,
}: {
  bomFlowService: BomFlowInterface;
}): (p: { form: any; onSave: (list: any) => void }) => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  const tableHeader = [
    [
      { name: 'Name', rowSpan: 2 },
      { name: 'Quantity', rowSpan: 2 },
      { name: 'UOM', rowSpan: 2 },
      { name: 'Physical stock', colSpan: 2 },
      { name: 'Virtual stock', colSpan: 2 },
    ],
    [
      { name: 'Allocated /Unallocate' },
      { name: 'Total' },
      { name: 'Allocated /Unallocate' },
      { name: 'Total' },
    ],
  ];
  return function Step2({
    form,
    onSave,
  }: {
    form: any;
    onSave: (list: any) => void;
  }): JSX.Element {
    const [list, setList] = useState<any[]>([]);
    useEffect(() => {
      bomFlowService.getBomList(form.grade.id).then((res) => {
        console.log(res);
        setList(res);
      });
      // eslint-disable-next-line
    }, []);
    return (
      <div>
        <Card
          header={{
            title: `Bom For ${form.grade.name}`,
            subheading: (
              <>
                Quantity: {form.quantity} {form.uom}
              </>
            ),
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Header form={form} />
                <div className='border border-gray-200 p-1 rounded-md my-2'>
                  <Table
                    header={tableHeader}
                    multiSelect={{
                      actions: [
                        {
                          name: 'Proceed',
                          show: () => true,
                          behaviour: 'click',
                          onClick: async (entries) => {
                            const finalItems: any[] = [];
                            entries.forEach((i) => {
                              if (list[i]) finalItems.push(list[i]);
                            });

                            onSave(finalItems);
                          },
                        },
                      ],
                    }}
                    body={list.map((x) => {
                      const physical = x.stock?.currentCompany?.total?.physical;
                      const virtual = x.stock?.currentCompany?.total?.virtual;
                      const uom = x.uom;

                      return {
                        cells: [
                          { value: x.name },
                          {
                            value: `${
                              (x.ratio || 1) * parseInt(form.quantity)
                            }`,
                          },
                          {
                            value: uom,
                          },
                          {
                            value: `${physical.allocated || 0} / ${
                              physical.unallocated || 0
                            }`,
                          },
                          { value: `${physical.total}` },
                          {
                            value: `${virtual.allocated || 0} / ${
                              virtual.unallocated || 0
                            }`,
                          },
                          { value: `${virtual.total}` },
                        ],
                      };
                    })}
                  />
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  };
}

const priority = {
  low: <div className='ml-2 inline font-bold text-green-500'>LOW</div>,
  medium: <div className='ml-2 inline font-bold text-orange-500'>MEDIUM</div>,
  high: <div className='ml-2 inline font-bold text-red-500'>HIGH</div>,
};

function Header({ form }: { form: any }): JSX.Element {
  return (
    <div className='p-2 my-2 border-2 border-gray-200 p-1 rounded-md'>
      <div className='flex items-center font-bold mb-3'>
        <div className='flex flex-auto'>
          <FinishedGood className='w-7 h-7' />{' '}
          <span className='font-semibold'>{form.grade.name}</span>{' '}
          <span className='ml-5'>
            Quantity {form.quantity}
            {form.uom}
          </span>
        </div>
        <div className='flex flex-auto'>
          <span className='font-bold'>
            <CalendarDaysIcon className='w-5 h-5 text-red-500 inline' /> Target
            date: {form.targetDate}{' '}
          </span>
        </div>
      </div>

      <div className='flex'>
        <div className='flex-auto'>
          <span className='font-bold'>
            <UserIcon className='w-5 h-5 text-blue-500 inline' /> Engineer:{' '}
            {form.engineer?.name}{' '}
          </span>
        </div>
        <div className='flex-auto'>
          <span className='font-bold'>
            <HomeIcon className='w-5 h-5 text-orange-500 inline' /> Dispatch to:{' '}
            {form.dispatchTo?.name}{' '}
          </span>
        </div>
        <div className='flex-auto'>
          <span className='font-bold'>
            Priority: {priority[form.priority || 'low']}{' '}
          </span>
        </div>
      </div>
    </div>
  );
}
