import {
  DocumentsType,
  EmployeeRegistrationType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../../contexts/current';
import { UserContext } from '../../../../../../contexts/user';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedDocument } from '../../../../../../hooks/hrd/employee-registration-docs/use-document';
import { UseCombinedEmployeeRegistration } from '../../../../../../hooks/hrd/employee-registration-dtls/use-registration';
import { UserRendererInterface } from '../../../../../common/fragments/user';
import { renderAddEmployeeRegistrationForm } from '../../profile-form/registration-details/employee-registration-add-form';

type RenderEmployeeRegistrationProps = {
  useCombinedEmployeeRegistration: UseCombinedEmployeeRegistration;
  employee: EmployeeProfileType;
  useFileTransfer: UseFileTransfer;
  useCombinedDocument: UseCombinedDocument;
  userRendererService: UserRendererInterface;
  isHr: boolean;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
};

export function renderEmployeeRegistrationDetails(): ({
  useCombinedEmployeeRegistration,
  employee,
  useFileTransfer,
  useCombinedDocument,
}: RenderEmployeeRegistrationProps) => JSX.Element {
  return function EmployeeLoan({
    useCombinedEmployeeRegistration,
    employee,
    useFileTransfer,
    useCombinedDocument,
    actions,
    isHr,
    userRendererService,
  }: RenderEmployeeRegistrationProps): JSX.Element {
    const FileViewerUploader = renderFileViewerUploader();

    const {
      list: registrations,
      getAll: getEmployeeRegistrations,
    } = useCombinedEmployeeRegistration();

    const {
      syncSet: setEmployeeRegistration,
    } = useCombinedEmployeeRegistration();
    const { company, companyGroup } = useContext(CurrentContext);
    const { user: currentUser } = useContext(UserContext);

    const { list: documents, getAll: getDocuments } = useCombinedDocument();

    const { syncSet: setDocument } = useCombinedDocument();

    useEffect(() => {}, [registrations, documents]);

    useEffect(() => {
      getEmployeeRegistrations({ employeeId: employee.id });
      getDocuments();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Employee Registration Details',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Add Registration',
            behaviour: 'modal',
            modal: {
              title: 'Add Registration',
              content: ({ onClose }) => {
                const Form = renderAddEmployeeRegistrationForm();
                return (
                  <Form
                    onSave={async (form) => {
                      await saveEmployeeRegistration(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddEmployeeRegistrationForm();

    function computePath(ert: EmployeeRegistrationType) {
      if (ert.details.document) {
        return ert.details.document;
      }

      return `${companyGroup.id}/${company.id}/employees/registration-details/${ert.id}/${ert.regDocumentType}-${ert.accountNo}`.replaceAll(
        ' ',
        '-'
      );
    }

    const saveEmployeeRegistration = async (
      form: Partial<EmployeeRegistrationType>
    ) => {
      try {
        const finalData = {
          ...form,
          employee: {
            id: employee.id,
            name: employee.name,
          },
        } as EmployeeRegistrationType;
        await setEmployeeRegistration(finalData as EmployeeRegistrationType);
        toast('Data added sucessfully');
        getEmployeeRegistrations({ employeeId: employee.id });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const updateData = async (data: EmployeeRegistrationType) => {
      try {
        const finalData = {
          ...data,
        };
        await setEmployeeRegistration(finalData as EmployeeRegistrationType);
        toast('Data updated sucessfully');
        // getLoans();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Registration' },
        { name: 'Type Number/Account Number' },
        { name: 'Date of Registration / Valid Upto' },
        { name: 'Documents' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];

    function showuploadDoc(r: DocumentsType, ert: EmployeeRegistrationType) {
      // if (r.url) {
      return (
        <div>
          <FileViewerUploader
            mode={isHr ? 'upload' : 'view'}
            url={r?.url || computePath(ert) || ''}
            useFileTransfer={useFileTransfer}
            path={computePath(ert)}
            type='private'
            onUpload={async (path) => {
              let registrationDoc = path?.url;
              if (registrationDoc) {
                registrationDoc = path?.url;
                await setDocument(({
                  id: r.id,
                  url: registrationDoc,
                  registration: {
                    id: r.id,
                  },
                  urlType: 'private',
                } as unknown) as DocumentsType);
              }
            }}
          />
        </div>
      );
      // }
      // return <div></div>;
    }

    // const Action = ({ l }: { l: EmployeeRegistrationType }) => {
    //   return (
    //     <div className='flex flex-col p-2 space-y-2'>
    //       <LoadingButton
    //         defaultStyle='mb-1'
    //         behaviorFn={async () => handleEditEmployeeRegistration(l)}
    //         text='Edit'
    //       />
    //       <LoadingButton
    //         behaviorFn={async () => uploadDoc1(l)}
    //         defaultStyle='mb-1'
    //         text='Attach Document'
    //       />
    //     </div>
    //   );
    // };
    let regDocument;
    // function isDocument(id: string) {
    //   regDocument = documents?.find((x) => x.registration.id === id);
    //   if (regDocument) {
    //     return regDocument;
    //   }
    //   return;
    // }
    const tableData: TableBody =
      registrations?.map((l, idx) => ({
        rowData: {
          registration: l,
          index: idx,
        },
        cells: [
          { value: l.regDocumentType },
          { value: l.accountNo },
          { value: `${l.regDate} / ${l.validUpto}` },
          {
            value: showuploadDoc(regDocument, l), // isDocument(l.id) ? showuploadDoc(regDocument, l) : ''
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              auth={currentUser.authorization}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  behaviour: 'modal',
                  show: () => true,
                  auth: 'HRD:EMP-PROFILE:EDIT',
                  modal: {
                    title: 'Edit',
                    content: ({ data: { registration }, onClose }) => {
                      return (
                        <Form
                          onSave={async (form) => {
                            await updateData(form);
                            onClose();
                          }}
                          data={registration}
                          isEdit={true}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Attach Document',
                  behaviour: 'modal',
                  show: () => true,
                  auth: 'HRD:EMP-PROFILE:EDIT',
                  modal: {
                    title: 'Attach Document',
                    content: ({ data: { registration }, onClose }) => {
                      return (
                        <FileViewerUploader
                          url={''}
                          mode={isHr ? 'upload' : 'view'}
                          useFileTransfer={useFileTransfer}
                          path={computePath(registration)}
                          type='private'
                          onUpload={async (path) => {
                            let registrationDoc = path.url;
                            if (registrationDoc) {
                              registrationDoc = path.url;
                              await setDocument(({
                                url: registrationDoc,
                                registration: {
                                  id: registration.id,
                                },
                                urlType: 'private',
                              } as unknown) as DocumentsType);
                            }
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='border border-gray-200 rounded-lg p-2'>
        <Card header={cardHeader} body={cardBody} auth={{ actions }} />
      </div>
    );
  };
}
