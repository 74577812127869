import {
  JobsType,
  TaskFilter,
  TaskType,
} from '@erp_core/erp-types/dist/modules/admin';
import { JobsFilter } from '@erp_core/erp-types/dist/types/modules/admin/scheduler/job';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { JobInterface } from '../models/interfaces/admin/scheduler/job';
import { TaskInterface } from '../models/interfaces/admin/scheduler/task';
import { erpV2Api } from './admin';
import { SCHEDULER_SERVER_URI } from './server-uri';

export const schedulertaskService = new GenericHttpWithLogsRepo<
  TaskType,
  TaskFilter
>({
  erpv2Api: erpV2Api,
  baseURI: SCHEDULER_SERVER_URI,
  resourceEndpoint: '/tasks',
});

export const useCombinedTask = createUseCombinedResource<
  TaskType,
  TaskFilter,
  TaskInterface
>({
  useService: () => schedulertaskService,
});

export const schedulerjobService = new GenericHttpWithLogsRepo<
  JobsType,
  JobsFilter
>({
  erpv2Api: erpV2Api,
  baseURI: SCHEDULER_SERVER_URI,
  resourceEndpoint: '/jobs',
});

export const useCombinedJob = createUseCombinedResource<
  JobsType,
  JobsFilter,
  JobInterface
>({
  useService: () => schedulerjobService,
});
