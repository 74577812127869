import { TopicType } from '@erp_core/erp-types/dist/modules/utility';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedQuestionnaireQuestion } from '../../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedQuestionnaireSurveyAnswer } from '../../../../hooks/hrd/questionnaire/questionnaire-survey-ans/use-questionnaire-survey-answer';
import { UseCombinedQuestionnaireTopic } from '../../../../hooks/hrd/questionnaire/questionnaire-topic/use-questionnaire-topic';
import { QuestionnaireTopicFilter } from '../../../../models/interfaces/hrd/questionnaire/topic';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';
import { renderSurveyAnswerForm } from '../survey-answers/form';
import { renderAdddTopicForm } from './forms/topic-addform';

export type RenderTopicProps = {
  useCombinedQuestionnaireTopic: UseCombinedQuestionnaireTopic;
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
  useCombinedQuestionnaireSurveyAnswer: UseCombinedQuestionnaireSurveyAnswer;
  userRendererService: UserRendererInterface;
};

// eslint-disable-next-line
export function renderTopicForm({
  useCombinedQuestionnaireQuestion,
  useCombinedQuestionnaireSurveyAnswer,
  useCombinedQuestionnaireTopic,
  userRendererService,
}: RenderTopicProps): () => JSX.Element {
  return function Topic(): JSX.Element {
    const { companyGroup: currentCompanyGroup } = useContext(CurrentContext);
    const { user: currentUser } = useContext(UserContext);

    const { set: setTopic } = useCombinedQuestionnaireTopic();

    const { getAll: getTopics } = useCombinedQuestionnaireTopic();

    // eslint-disable-next-line
    const handleTopicAdd = async (data: TopicType) => {
      const finalData = {
        ...data,
        companyGroup: {
          id: currentCompanyGroup.id,
          name: currentCompanyGroup.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };

      setTopic(finalData as TopicType);
    };

    const Form = renderAdddTopicForm();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Topic',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Topic',
              content: ({ onClose }) => {
                return <Form onSave={handleTopicAdd} onClose={onClose} />;
              },
            },
          },
        },
      ],
    };

    const CopyIdtoClipboard = () => {
      try {
        toast('Copied to clipboard successfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableWithMapperComponent<
      TopicType,
      QuestionnaireTopicFilter
    >();

    const tableHeader: TableHeader = [
      [
        { name: 'Topic Name' },
        { name: 'Topic Description' },
        { name: 'Topic Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (r: TopicType) => {
      const cells: Array<TableCell> = [
        {
          value: r.name,
          link: `/miscellaneous/questionnaire/questions/${r.id}`,
        },
        { value: r.description },
        { value: r.status || 'draft' },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={r.createdBy?.id}
              name={r.createdBy?.name}
              extraInfo={moment.utc(r.createdAt).fromNow()}
            />
          ),
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={r.lastModifiedBy?.id}
              name={r.lastModifiedBy?.name}
              extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
            />
          ),
        },
      ];

      return {
        cells,
        rowData: {
          topic: r,
        },
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                type: 'simple',
                useResources: useCombinedQuestionnaireTopic,
              }}
              actions={[
                {
                  name: 'Publish',
                  show: ({ topic }) => topic.status === 'draft',
                  behaviour: 'confirm',
                  onConfirm: ({ topic }) => {
                    return {
                      message: `Do u want to publish this Topic ${topic.name}`,
                      onConfirm: async () => {
                        const final: Partial<TopicType> = {
                          id: topic.id,
                          status: 'published',
                        };
                        setTopic(final as TopicType);

                        toast('Topic published successfully');
                        getTopics();
                      },
                    };
                  },
                },
                {
                  name: 'Test',
                  show: ({ topic }) => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Test Answer Preview',
                    content: ({ data: { topic }, onClose }) => {
                      const SurveyForm = renderSurveyAnswerForm({
                        useCombinedQuestionnaireQuestion,
                      });
                      return (
                        <SurveyForm
                          id={topic.id}
                          dynamicData={{
                            grade: {
                              name: 'Crotonic Acid',
                            },
                            property: {
                              impurities: [
                                {
                                  name: 'Acetone',
                                  value: '55',
                                },
                                {
                                  name: 'Methy-Acetone',
                                  value: '90',
                                },
                                {
                                  name: 'Propyl-Acetone',
                                  value: '81',
                                },
                              ],
                            },
                            impurity: 'Acetone',
                          }}
                          onSave={async (p) => {
                            console.log(p);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Copy To Clipboard',
                  show: ({ topic }) => topic.status === 'published',
                  behaviour: 'modal',
                  modal: {
                    title: 'Copy',
                    content: ({ data: { topic }, onClose }) => {
                      return (
                        <CopyToClipboard text={topic.id}>
                          <LoadingButton
                            defaultStyle='font-semibold hover:text-black text-gray-600 w-fit text-xs text-left hover:underline'
                            behaviorFn={async () => {
                              CopyIdtoClipboard();
                              onClose();
                            }}
                            text='Copy to clipboard'
                          />
                        </CopyToClipboard>
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
