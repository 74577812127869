/* eslint-disable class-methods-use-this */

import {
  Godown,
  GodownFilterType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { BOMFilterType, BOMRes } from '@erp_core/erp-types/dist/modules/order';
import { INVENTORY_SERVER_URI } from '../../../../../config/server-uri';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GodownInterface } from '../../../../../models/interfaces/inventory/generic/godown';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpBomRepo extends GenericHttpWithLogsRepo<
  BOMRes,
  BOMFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  godownService: GodownInterface;

  constructor(
    apiUrl: string,
    erpv2Api: ErpV2Api
    // godownService: GodownInterface
  ) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/work-order/bom',
    });
    const godownService = new GenericHttpWithLogsRepo<Godown, GodownFilterType>(
      {
        erpv2Api,
        baseURI: INVENTORY_SERVER_URI,
        resourceEndpoint: '/godowns',
      }
    );

    this.godownService = godownService;
    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
