import { PurchaseOrderFulfilmentFilterType } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedPurchaseOrderFulfilment } from '../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';

export function PurchaseItemTransfer({
  useCombinedPurchaseOrderFulfilment,
}: {
  useCombinedPurchaseOrderFulfilment: UseCombinedPurchaseOrderFulfilment;
}) {
  const {
    list: data,
    getAll: getInspectionItems,
  } = useCombinedPurchaseOrderFulfilment();

  const getData = async () => {
    try {
      getInspectionItems(({
        underInspection: false,
        isApproved: true,
      } as unknown) as PurchaseOrderFulfilmentFilterType);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const Table = renderTableComponent();
  const tableHeader: TableHeader = [
    [
      { name: 'Item', rowSpan: 2 },
      { name: 'Quantity', rowSpan: 2 },
      { name: 'Actions', rowSpan: 2 },
    ],
  ];

  const tableData =
    data?.map((item) => ({
      cells: [
        { value: item?.purchaseOrder?.details?.itemDetails?.name },
        { value: item.details.quantity },
      ],
    })) || [];

  const tableBody: TableBody = tableData;

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'Purchase Item Transfer',
  };

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: <Table header={tableHeader} body={tableBody} />,
  };

  return <Card header={cardHeader} body={cardBody} />;
}
