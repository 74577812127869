/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/recat';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import Calendar from '@toast-ui/react-calendar';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

import { UseGetMachineSchedule } from '../../../../../../hooks/planning/production/machine-schedule';
import { mapSchedule } from './utils/map-schedule';

const calendars = [
  {
    id: 'cal1',
    name: 'Personal',
    backgroundColor: '#9e5fff',
    borderColor: '#9e5fff',
  },
];

const onAfterRenderEvent = (event) => {
  // console.log(event);
};

type Props = {
  useGetMachineSchedule: UseGetMachineSchedule;
};
export const renderMachineSchedule = ({ useGetMachineSchedule }: Props) =>
  function MachineSchedule() {
    const { id } = useParams();
    const { getByMachineId, data } = useGetMachineSchedule();
    useEffect(() => {
      if (id) {
        getByMachineId(id);
      }
      // eslint-disable-next-line
    }, [id]); // Fetching data when Page loads
    useEffect(() => {}, [data]); // Refresh after data changes
    const calendarRef = useRef<Calendar>(null);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Day', rowSpan: 2 },
        { name: 'Schedule', rowSpan: 2 },
      ],
    ];

    const tableData =
      (data?.details.schedule &&
        Object.entries(data?.details.schedule)?.map(([key, value]) => ({
          cells: [
            { value: key },
            { value: value.length === 0 ? 'closed' : ` runs on every ${key}` },
          ],
        }))) ||
      [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Detailed Machine Schedule',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} />,
    };

    const onClickSchedule = useCallback((e) => {
      // console.log(e);
    }, []);

    const onBeforeUpdateEvent = useCallback((e) => {
      // console.log(e);
      const { event, changes } = e;
      calendarRef?.current
        ?.getInstance()
        ?.updateEvent(event.id, event.calendarId, changes);
    }, []);

    const onBeforeDeleteEvent = (e) => {
      // console.log(e);
      const { id, calendarId } = e;
      calendarRef?.current?.getInstance()?.deleteEvent(id, calendarId);
    };

    const beforeCreateEvent: any = useCallback((scheduleData) => {
      // console.log(scheduleData);

      const schedule = {
        id: String(Math.random()),
        title: scheduleData.title,
        isAllDay: scheduleData.isAllDay,
        start: scheduleData.start,
        end: scheduleData.end,
        location: scheduleData.location,
        state: scheduleData.state,
      };

      calendarRef?.current?.getInstance()?.createEvents([schedule]);
    }, []);

    const calenderData = data ? mapSchedule(data) : null;

    const [view, setView] = useState<any>('week');
    return (
      <div className=''>
        <LoadingButton
          defaultStyle='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'
          behaviorFn={async () => setView('day')}
          text='Day'
        />
        <LoadingButton
          defaultStyle='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'
          behaviorFn={async () => setView('week')}
          text='Week'
        />
        <LoadingButton
          defaultStyle='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'
          behaviorFn={async () => setView('month')}
          text='Month'
        />
        {/* <button type='button' 
            className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'
            onClick={OnSave}
            > Save
        </button> */}
        {calenderData ? (
          <Calendar
            usageStatistics={false}
            ref={calendarRef}
            height='900px'
            isReadOnly={false}
            useDetailPopup
            useFormPopup
            onClickSchedule={onClickSchedule}
            onBeforeCreateEvent={beforeCreateEvent}
            onBeforeDeleteEvent={onBeforeDeleteEvent}
            onBeforeUpdateEvent={onBeforeUpdateEvent}
            view={view}
            month={{
              visibleWeeksCount: 2,
            }}
            week={{
              taskView: false,
              eventView: ['time'],
              startDayOfWeek: 1,
            }}
            calendars={calendars}
            events={calenderData}
            //  template={template}
            onAfterRenderEvent={onAfterRenderEvent}
          />
        ) : (
          <div className='w-full h-36 bg-zinc-200 justify-center items-center flex'>
            <Recat className='h-5 inline animate-pulse mx-4' />
          </div>
        )}

        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
