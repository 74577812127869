/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import {
  EnquiryQuotes,
  EnquiryQuotesFilterType,
} from '@erp_core/erp-types/dist/modules/order';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpEnquiryQuotesRepo extends GenericHttpWithLogsRepo<
  EnquiryQuotes,
  EnquiryQuotesFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/purchase/enquiry-quotes',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getQuotesByEnquiryId(id: string): Promise<EnquiryQuotes[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const res = await httpInstance.get(
      `/api/v1/purchase/enquiry-quotes/enquiry/${id}`
    );
    if (res.status !== 200) {
      throw new Error(`Cannot get Quotes for enquiry id: ${id}`);
    }

    const { data } = res;

    return data as EnquiryQuotes[];
  }
}
