import {
  BomDependency,
  BomDependencyFilter,
  BomFlow,
  BomFlowFilter,
  BomProjectFilterType,
  BomProjectType,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  BOMFilterType,
  BOMRes,
  Client,
  ClientFilterType,
  ClientItem,
  ClientItemFilterType,
  EnquiryQuotes,
  EnquiryQuotesFilterType,
  LeadCustomerEnquiryFilterType,
  LeadCustomerEnquiryType,
  LeadCustomerFilterType,
  LeadCustomerType,
  LeadEnquiryType,
  LicenseContract,
  LicenseContractDocType,
  LicenseContractDocTypeFilter,
  LicenseContractFilter,
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
  ProductBatch,
  ProductBatchFilter,
  PurchaseEnquiry,
  PurchaseEnquiryFilterType,
  PurchaseOrder,
  PurchaseOrderFilterType,
  PurchaseOrderFulfilment,
  PurchaseOrderFulfilmentFilterType,
  PurchaseRequisition,
  PurchaseRequisitionFilterType,
  SalesEnquiry,
  SalesOrder,
  SalesOrders,
  SalesOrdersFilter,
  Vendor,
  VendorFilterType,
  VirtualMFStock,
  VirtualMFStockFilter,
  VirtualStockType,
} from '@erp_core/erp-types/dist/modules/order';
import { SalesEnquiryFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/enquiry';
import { LeadEnquiryFilterType } from '@erp_core/erp-types/dist/types/modules/order/sales/lead-enquiry';
import { SalesOrderFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/order';
import {
  createUseCombinedResource,
  createUseResource,
} from '@erp_core/erp-ui-components';
import { HttpEnquiryQuotesRepo } from '../datalayer/repository/http/order/purchase/enquiry-quotes';
import { HttpPurchaseEnquiryRepo } from '../datalayer/repository/http/order/purchase/purchase-enquiry';
import { HttpPurchaseOrderRepo } from '../datalayer/repository/http/order/purchase/purchase-order';
import { HttpPurchaseOrderFulfilmentRepo } from '../datalayer/repository/http/order/purchase/purchase-order-fulfilment';
import { HttpVendorRepo } from '../datalayer/repository/http/order/purchase/vendors';
import { HttpBomRepo } from '../datalayer/repository/http/order/work-order/bom';
import { HttpBomFlowRepo } from '../datalayer/repository/http/order/work-order/bom-flow';
import { HttpManufacturingVoucherRepo } from '../datalayer/repository/http/order/work-order/manufacturing-voucher';
import { createUseVirtualItemAllocate } from '../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { createUseVendorItem } from '../hooks/inventory/purchase/vendors/use-vendor-item';
import { createUseVirtuallyReserveMfVoucher } from '../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { BomDependencyInterface } from '../models/interfaces/inventory/bom-dependency';
import { BomFlowInterface } from '../models/interfaces/inventory/bom-flow';
import { BomProjectInterface } from '../models/interfaces/inventory/bom-project';
import { VirtualStockInterface } from '../models/interfaces/inventory/item/stock/virtual-stock';
import { EnquiryQuotesInterface } from '../models/interfaces/order/purchase/enquiry-quotes';
import { PurchaseEnquiryInterface } from '../models/interfaces/order/purchase/purchase-enquiry';
import { PurchaseOrderInterface } from '../models/interfaces/order/purchase/purchase-order';
import { PurchaseOrderFulfilmentInterface } from '../models/interfaces/order/purchase/purchase-order-fulfilment';
import { PurchaseRequisitionInterface } from '../models/interfaces/order/purchase/purchase-requisition';
import { VendorInterface } from '../models/interfaces/order/purchase/vendors';
import { ClientInterface } from '../models/interfaces/order/sales/client';
import { ClientItemInterface } from '../models/interfaces/order/sales/client-item';
import { SalesEnquiryInterface } from '../models/interfaces/order/sales/enquiry';
import { LeadCustomerInterface } from '../models/interfaces/order/sales/lead-customer';
import { LeadCustomerEnquiryInterface } from '../models/interfaces/order/sales/lead-customer-enquiry';
import { LeadEnquiryInterface } from '../models/interfaces/order/sales/lead-enqiry';
import { SalesOrderInterface } from '../models/interfaces/order/sales/order';
import { ProductBatchInterface } from '../models/interfaces/order/sales/product-batch';
import { BOMInterface } from '../models/interfaces/order/work-order/bom';
import { LicenseContractInterface } from '../models/interfaces/order/work-order/license-contract';
import { LicenseContractDocTypeInterface } from '../models/interfaces/order/work-order/license-contract-doc-type';
import { ManufacturingVoucherInterface } from '../models/interfaces/order/work-order/manufacturing-voucher.ts';
import {
  VirtualMFStockInterface,
  VirtualStockFilterType,
} from '../models/interfaces/order/work-order/virtual-manufacturing-stock';
import { ORDER_SERVER_URI } from './server-uri';
// import {
//   LeadEnquiryFilterType,
//   LeadEnquiryType,
// } from '../models/types/sales/lead-enquiry';
// import {
//   ProductBatch,
//   ProductBatchFilter,
// } from '../models/types/sales/product-batch';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { SalesOrdersHttpRepo } from '../datalayer/repository/http/order/sales/sales-order';
import { SalesOrdersInterface } from '../models/interfaces/order/sales/sales-order';
import { erpV2Api } from './admin';

export const manufacturingVoucherService = new HttpManufacturingVoucherRepo(
  ORDER_SERVER_URI,
  erpV2Api
);

export const useCombinedManufacturingVoucher = createUseCombinedResource<
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
  ManufacturingVoucherInterface
>({
  useService: () => manufacturingVoucherService,
});

export const virtualManufacturingStockService = new GenericHttpWithLogsRepo<
  VirtualMFStock,
  VirtualMFStockFilter
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/work-order/mv/virtual-stock',
});

export const useVirtualMfStock = createUseResource<
  VirtualMFStock,
  VirtualMFStockFilter,
  VirtualMFStockInterface
>({
  useService: () => virtualManufacturingStockService,
});

export const clientService = new GenericHttpWithLogsRepo<
  Client,
  ClientFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/client',
});

export const useCombinedClient = createUseCombinedResource<
  Client,
  ClientFilterType,
  ClientInterface
>({
  useService: () => clientService,
});

export const leadCustomersService = new GenericHttpWithLogsRepo<
  LeadCustomerType,
  LeadCustomerFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/lead-customer',
});

export const useCombinedLeadCustomer = createUseCombinedResource<
  LeadCustomerType,
  LeadCustomerFilterType,
  LeadCustomerInterface
>({
  useService: () => leadCustomersService,
});

export const leadCustomerEnquiryService = new GenericHttpWithLogsRepo<
  LeadCustomerEnquiryType,
  LeadCustomerEnquiryFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/lead-customer-enquiries',
});

export const useCombinedLeadCustomerEnquiry = createUseCombinedResource<
  LeadCustomerEnquiryType,
  LeadCustomerEnquiryFilterType,
  LeadCustomerEnquiryInterface
>({
  useService: () => leadCustomerEnquiryService,
});

export const clientItemService = new GenericHttpWithLogsRepo<
  ClientItem,
  ClientItemFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/client-item',
});

export const useCombinedClientItem = createUseCombinedResource<
  ClientItem,
  ClientItemFilterType,
  ClientItemInterface
>({ useService: () => clientItemService });

export const vendorService = new HttpVendorRepo(ORDER_SERVER_URI, erpV2Api);

export const useCombinedVendor = createUseCombinedResource<
  Vendor,
  VendorFilterType,
  VendorInterface
>({
  useService: () => vendorService,
});

export const purchaseRequisitionService = new GenericHttpWithLogsRepo<
  PurchaseRequisition,
  PurchaseRequisitionFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/purchase/purchase-requisition',
});

export const useCombinedPurchaseRequisition = createUseCombinedResource<
  PurchaseRequisition,
  PurchaseRequisitionFilterType,
  PurchaseRequisitionInterface
>({
  useService: () => purchaseRequisitionService,
});

export const purchaseEnquiryService = new HttpPurchaseEnquiryRepo(
  ORDER_SERVER_URI,
  erpV2Api
);

export const useCombinedPurchaseEnquiry = createUseCombinedResource<
  PurchaseEnquiry,
  PurchaseEnquiryFilterType,
  PurchaseEnquiryInterface
>({
  useService: () => purchaseEnquiryService,
});

export const enquiryQuotesService = new HttpEnquiryQuotesRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useCombinedEnquiryQuote = createUseCombinedResource<
  EnquiryQuotes,
  EnquiryQuotesFilterType,
  EnquiryQuotesInterface
>({
  useService: () => enquiryQuotesService,
});

export const purchaseOrderFulfilmentService = new HttpPurchaseOrderFulfilmentRepo(
  ORDER_SERVER_URI,
  erpV2Api
);

export const useCombinedPurchaseOrderFulfilment = createUseCombinedResource<
  PurchaseOrderFulfilment,
  PurchaseOrderFulfilmentFilterType,
  PurchaseOrderFulfilmentInterface
>({
  useService: () => purchaseOrderFulfilmentService,
});

export const salesEnquiryService = new GenericHttpWithLogsRepo<
  SalesEnquiry,
  SalesEnquiryFilter
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/enquiries',
});

export const useCombinedSalesEnquiry = createUseCombinedResource<
  SalesEnquiry,
  SalesEnquiryFilter,
  SalesEnquiryInterface
>({
  useService: () => salesEnquiryService,
});

export const salesOrderService = new GenericHttpWithLogsRepo<
  SalesOrder,
  SalesOrderFilter
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/orders',
});

export const useCombinedSalesOrder = createUseCombinedResource<
  SalesOrder,
  SalesOrderFilter,
  SalesOrderInterface
>({
  useService: () => salesOrderService,
});

export const productBatchService = new GenericHttpWithLogsRepo<
  ProductBatch,
  ProductBatchFilter
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/product-batches',
});

export const useCombinedProductBatch = createUseCombinedResource<
  ProductBatch,
  ProductBatchFilter,
  ProductBatchInterface
>({
  useService: () => productBatchService,
});

export const virtualStockService = new GenericHttpWithLogsRepo<
  VirtualStockType,
  VirtualStockFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/purchase/purchase-order/virtual-stock',
});

export const useVirtualStock = createUseResource<
  VirtualStockType,
  VirtualStockFilterType,
  VirtualStockInterface
>({
  useService: () => virtualStockService,
});

export const bomService = new HttpBomRepo(
  ORDER_SERVER_URI,
  erpV2Api
  // godownService
);

export const useCombinedBom = createUseCombinedResource<
  BOMRes,
  BOMFilterType,
  BOMInterface
>({
  useService: () => bomService,
});

export const purchaseOrderService = new HttpPurchaseOrderRepo(
  ORDER_SERVER_URI,
  erpV2Api,
  bomService
);

export const useCombinedPurchaseOrder = createUseCombinedResource<
  PurchaseOrder,
  PurchaseOrderFilterType,
  PurchaseOrderInterface
>({
  useService: () => purchaseOrderService,
});

export const useVirtuallyReserveMfVoucher = createUseVirtuallyReserveMfVoucher({
  manufacturingVoucherService,
});

export const useVendorItem = createUseVendorItem({
  vendorService,
});

export const useVirtualItemAllocate = createUseVirtualItemAllocate({
  purchaseOrderService,
});

export const bomFlowService = new HttpBomFlowRepo(ORDER_SERVER_URI, erpV2Api);

export const useCombinedBomFlow = createUseCombinedResource<
  BomFlow,
  BomFlowFilter,
  BomFlowInterface
>({
  useService: () => bomFlowService,
});

export const bomDependencyService = new GenericHttpWithLogsRepo<
  BomDependency,
  BomDependencyFilter
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/work-order/bom-dependency',
});

export const useCombinedBomDependency = createUseCombinedResource<
  BomDependency,
  BomDependencyFilter,
  BomDependencyInterface
>({
  useService: () => bomDependencyService,
});

export const bomProjectService = new GenericHttpWithLogsRepo<
  BomProjectType,
  BomProjectFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/work-order/bom-project',
});

export const useCombinedBomProject = createUseCombinedResource<
  BomProjectType,
  BomProjectFilterType,
  BomProjectInterface
>({
  useService: () => bomProjectService,
});

export const salesLeadEnquiryService = new GenericHttpWithLogsRepo<
  LeadEnquiryType,
  LeadEnquiryFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/sales/lead-enquiry',
});

export const useCombinedLeadEnquiry = createUseCombinedResource<
  LeadEnquiryType,
  LeadEnquiryFilterType,
  LeadEnquiryInterface
>({
  useService: () => salesLeadEnquiryService,
});

export const licenseContractDocTypeService = new GenericHttpWithLogsRepo<
  LicenseContractDocType,
  LicenseContractDocTypeFilter
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/work-order/license-contract-doc-type',
});

export const useCombinedLicenseContractDocType = createUseCombinedResource<
  LicenseContractDocType,
  LicenseContractDocTypeFilter,
  LicenseContractDocTypeInterface
>({
  useService: () => licenseContractDocTypeService,
});

export const licenseContractService = new GenericHttpWithLogsRepo<
  LicenseContract,
  LicenseContractFilter
>({
  erpv2Api: erpV2Api,
  baseURI: ORDER_SERVER_URI,
  resourceEndpoint: '/api/v1/work-order/trade-addOn',
});

export const useCombinedLicenseContract = createUseCombinedResource<
  LicenseContract,
  LicenseContractFilter,
  LicenseContractInterface
>({
  useService: () => licenseContractService,
});

export const salesOrdersService = new SalesOrdersHttpRepo(
  ORDER_SERVER_URI,
  erpV2Api
);

export const useCombinedSalesOrders = createUseCombinedResource<
  SalesOrders,
  SalesOrdersFilter,
  SalesOrdersInterface
>({
  useService: () => salesOrdersService,
});
