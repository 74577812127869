/* eslint-disable no-unused-vars */
import { ChangeAssesmentType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import {
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';

type Props = {
  data: ChangeAssesmentType;
  onSave: any;
  changeAssesmentData: ChangeAssesmentType;
};

// eslint-disable-next-line
export function renderAddStorgedCopiesForm({
  useCombinedEmployeeProfile,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}): (props: Props) => JSX.Element {
  return function AddAddStorgedCopiesForm({
    data,
    onSave,
    changeAssesmentData,
  }: Props): JSX.Element {
    const Form = renderFormV2({
      mapTToU: (f) => f,
      initialFormState: {
        softcopies: (data.details?.softCopiesStorage.softcopies
          ? data.details?.softCopiesStorage.softcopies
          : []) as any,
      },
      fieldsData: [
        {
          width: 'w-full',
          property: 'softcopies',
          type: 'jsx',
          // dependentOn: (formData) => {
          //   return formData.documents === ;
          // },
          renderObject: ({ formData, field, onSave }) => {
            console.log('property2', field.property);
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                  changeAssesmentData={changeAssesmentData}
                  useCombinedEmployeeProfile={useCombinedEmployeeProfile}
                />
              </div>
            );
          },
        },
      ],
      onSubmit: async (form) => {
        console.log('docd', form.softcopies);
        onSave(form);
      },
    });

    return <Form />;
  };
}

function ObjectRender({
  formData,
  field,
  onSave,
  changeAssesmentData,
  useCombinedEmployeeProfile,
}): JSX.Element {
  console.log('field', field);
  const [props, setProps] = useState<any>(formData[field] || []);
  console.log('props', props);
  // const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const Table = renderTableComponent();
  // const Form = renderAddDocImplementationCompletionForm();
  const newDocNums = changeAssesmentData?.details.implementationActivitiesDocs.documents.map(
    (x) => x
  );
  const cardHeader: CardHeader = {
    title: 'Documents',
    actions: [
      {
        type: 'button',
        button: {
          behaviour: 'modal',
          name: 'Add Documents',
          modal: {
            title: 'Add Documents',
            content: ({ onClose }) => {
              const Form1 = renderFormV2({
                mapTToU: (f) => f,
                button: {
                  avoidSubmitBehavior: true,
                },
                initialFormState: {
                  docname: { id: '', name: '' },
                  docno: { id: '', name: '' },
                  docversion: { id: '', name: '' },
                  storageloc: '',
                  verifiedby: { id: '', name: '' },
                  storedby: { id: '', name: '' },
                },
                style: 'w-1/4',
                fieldsData: [
                  {
                    property: 'docname',
                    label: 'Name (Document)',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: () => {
                        const newDocs = newDocNums.map((x) => ({
                          ...x,
                          id: x.list,
                          name: x.list,
                        }));
                        const [filtered, setFiltered] = useState<
                          { list: string }[]
                        >(newDocs);

                        return {
                          data: filtered,
                          getAll: (filter) => {
                            if (filter.search) {
                              setFiltered(
                                newDocs.filter((x) =>
                                  x.list
                                    .toLowerCase()
                                    .includes(
                                      (filter.search || '').toLowerCase()
                                    )
                                )
                              );
                            } else {
                              setFiltered(newDocs);
                            }
                          },
                        };
                      },
                      onSearchValueSelect: (e) => {
                        // console.log(e);
                      },
                    },
                  },
                  {
                    property: 'docno',
                    label: 'Number (Document)',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: () => {
                        const newDocNo = newDocNums.map((x) => ({
                          ...x,
                          id: x.numberNewDoc,
                          name: x.numberNewDoc,
                        }));
                        const [filtered, setFiltered] = useState<
                          { numberNewDoc: string }[]
                        >(newDocNo);
                        return {
                          data: filtered,
                          getAll: (filter) => {
                            if (filter.search) {
                              setFiltered(
                                newDocNo.filter((x) =>
                                  x.numberNewDoc
                                    .toLowerCase()
                                    .includes(
                                      (filter.search || '').toLowerCase()
                                    )
                                )
                              );
                            } else {
                              setFiltered(newDocNo);
                            }
                          },
                        };
                      },
                      onSearchValueSelect: (e) => {
                        // console.log(e);
                      },
                    },
                  },
                  {
                    property: 'docversion',
                    label: 'Version (Document)',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: () => {
                        const newVersion = newDocNums.map((x) => ({
                          ...x,
                          id: x.versionNewDoc,
                          name: x.versionNewDoc,
                        }));

                        const [filtered, setFiltered] = useState<
                          { versionNewDoc: string }[]
                        >(newVersion);

                        return {
                          data: filtered,
                          getAll: (filter) => {
                            if (filter.search) {
                              setFiltered(
                                newVersion.filter((x) =>
                                  x.versionNewDoc.includes(filter.search || '')
                                )
                              );
                            } else {
                              setFiltered(newVersion);
                            }
                          },
                        };
                      },
                      onSearchValueSelect: (e) => {
                        //   console.log('abc', e);
                      },
                    },
                  },
                  {
                    property: 'storageloc',
                    label: 'Storage location',
                    type: 'input',
                  },
                  {
                    property: 'storedby',
                    label: 'Stored by',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: useCombinedEmployeeProfile,
                      onSearchValueSelect: (v) => {
                        console.log(v);
                      },
                    },
                  },
                  {
                    property: 'verifiedby',
                    label: 'Verified by',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: useCombinedEmployeeProfile,
                      onSearchValueSelect: (v) => {
                        console.log(v);
                      },
                    },
                  },
                ],
                onSubmit: async (form) => {
                  console.log('props', props);
                  const newProps = [...props];
                  newProps.push(form);
                  setProps(newProps);
                  // onSave(
                  //   {
                  //   softcopies: form,
                  // });
                  onClose();
                },
              });

              return <Form1 />;
            },
          },
        },
      },
    ],
  };
  const Card = renderCardComponent();
  return (
    <div>
      <div>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </span>
        <Card
          header={cardHeader}
          body={{
            type: 'columns',
            body: [],
          }}
        />
      </div>

      <Table
        header={[
          [
            { name: 'Document Name' },
            { name: 'Document No' },
            { name: 'Document Version' },
            { name: 'Stored By' },
            { name: 'Verified By' },
            {
              name: '',
            },
          ],
        ]}
        body={(props || []).map((x) => {
          return {
            cells: [
              { value: x.docname?.name || '' },
              { value: x.docno?.name || '' },
              { value: x.docversion?.name || '' },
              { value: x.storedby.name || '' },
              { value: x.verifiedby.name || '' },
            ],
          };
        })}
      />
    </div>
  );
}
