import {
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import {
  formatCurrency,
  getPlayersToBuy,
  getTeamBudget,
  Player,
  PlayerCategory,
  Team,
  Tournament,
} from './data';

export function renderEditTournament() {
  return function EditTournament({
    tournament,
    saveTournament,
    onClose,
  }: {
    tournament: Tournament;
    saveTournament: (trnmt: Tournament) => void;
    onClose: () => void;
  }) {
    const Card = renderCardComponent();
    const Table = renderTableComponent();

    return (
      <div className='text-sm text-black'>
        <div className='flex flex-wrap'>
          <div className='flex w-1/3 flex-col'>
            <div className=''>
              <Card
                header={{
                  title: 'General Details',
                  actions: [
                    {
                      type: 'button',
                      button: {
                        name: 'Edit',
                        behaviour: 'modal',
                        modal: {
                          title: 'Edit General Details',
                          content: ({ onClose: onClose2 }) => {
                            const Form = renderFormV2({
                              initialFormState: {
                                name: tournament?.name || '',
                                organizedBy: tournament?.organizer?.name || '',
                                auctionedBy:
                                  tournament?.auctionedBy?.name || '',
                                auctionDate: tournament.auctionDate,
                                teamBudget: tournament.auction.teamBudget || 0,
                              },
                              fieldsData: [
                                {
                                  property: 'name',
                                  type: 'input',
                                  required: true,
                                },
                                {
                                  property: 'organizedBy',
                                  type: 'input',
                                  required: true,
                                },
                                {
                                  property: 'auctionedBy',
                                  type: 'input',
                                  required: true,
                                },
                                {
                                  property: 'auctionDate',
                                  type: 'input',
                                  required: true,
                                },
                                {
                                  property: 'teamBudget',
                                  type: 'number',
                                  required: true,
                                },
                              ],
                              mapTToU: (m) => m,
                              onSubmit: async (form) => {
                                const newTour = {
                                  ...tournament,
                                };

                                newTour.name =
                                  (form.name as string) || tournament.name;
                                newTour.organizer.name =
                                  (form.organizedBy as string) ||
                                  tournament.organizer?.name;
                                newTour.auctionedBy.name =
                                  (form.auctionedBy as string) ||
                                  tournament.auctionedBy?.name;
                                newTour.auctionDate =
                                  (form.auctionDate as string) ||
                                  tournament.auctionDate;
                                newTour.auction.teamBudget = parseInt(
                                  `${
                                    (form.teamBudget as number) ||
                                    tournament.auction?.teamBudget
                                  }`
                                );

                                saveTournament(newTour);
                                onClose2();
                                onClose();
                              },
                            });

                            return <Form />;
                          },
                        },
                      },
                    },
                  ],
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <>
                      <div>Name: {tournament.name}</div>
                      <div>Organized by: {tournament.organizer.name}</div>
                      <div>Auctioneer: {tournament.auctionedBy.name}</div>
                      <div>Auction Date: {tournament.auctionDate}</div>
                      <div>
                        Team Budget:{' '}
                        {formatCurrency(tournament.auction.teamBudget || 0)}
                      </div>
                    </>
                  ),
                }}
              />
            </div>
            <div className=''>
              <Card
                header={{
                  title: 'Player Categories',
                  actions: [
                    {
                      type: 'button',
                      button: {
                        name: 'Add',
                        behaviour: 'click',
                        onClick: async () => {
                          const newTour = { ...tournament };
                          if (!newTour.auction.categories) {
                            newTour.auction.categories = [];
                          }
                          newTour.auction.categories.push({
                            name: '',
                            baseValue: 0,
                            lockinBudget: 0,
                            players: 0,
                            maximumBid: 0,
                          });
                          saveTournament(newTour);
                        },
                      },
                    },
                  ],
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <>
                      <Table
                        header={[
                          [
                            { name: 'Name' },
                            { name: 'Base Value' },
                            { name: 'Lockin Budget Per Player' },
                            { name: 'Players to Buy' },
                            { name: 'Maximum Bid Per Player' },
                          ],
                        ]}
                        body={(tournament.auction?.categories || [])?.map(
                          (x, idx) => {
                            return {
                              rowData: { category: x, idx: idx },
                              cells: [
                                { value: x.name },
                                { value: formatCurrency(x.baseValue) },
                                { value: formatCurrency(x.lockinBudget) },
                                { value: x.players },
                                { value: formatCurrency(x.maximumBid) },
                              ],
                            };
                          }
                        )}
                        actions={[
                          {
                            name: 'Edit',
                            behaviour: 'modal',
                            show: () => true,
                            modal: {
                              title: 'Edit Range',
                              content: ({
                                data: { category, idx },
                                onClose: onClose2,
                              }) => {
                                const Form = renderFormV2({
                                  initialFormState: {
                                    name: category.name,
                                    baseValue: category.baseValue,
                                    lockinBudget: category.lockinBudget,
                                    players: category.players,
                                    maximumBid: category.maximumBid,
                                  },
                                  fieldsData: [
                                    {
                                      property: 'name',
                                      type: 'input',
                                      required: true,
                                    },
                                    {
                                      property: 'baseValue',
                                      type: 'number',
                                      required: true,
                                    },
                                    {
                                      property: 'lockinBudget',
                                      type: 'number',
                                      required: true,
                                    },
                                    {
                                      property: 'players',
                                      type: 'number',
                                      required: true,
                                    },
                                    {
                                      property: 'maximumBid',
                                      type: 'number',
                                      required: true,
                                    },
                                  ],
                                  onSubmit: async (form) => {
                                    const newTour = {
                                      ...tournament,
                                    };
                                    console.log(newTour.auction.categories);
                                    newTour.auction.categories[idx] = {
                                      name: form.name || category.name,
                                      baseValue: parseInt(
                                        `${
                                          form.baseValue || category.baseValue
                                        }`
                                      ),
                                      lockinBudget: parseInt(
                                        `${
                                          form.lockinBudget ||
                                          category.lockinBudget
                                        }`
                                      ),
                                      players: parseInt(
                                        `${form.players || category.players}`
                                      ),
                                      maximumBid: parseInt(
                                        `${
                                          form.maximumBid || category.maximumBid
                                        }`
                                      ),
                                    };

                                    saveTournament(newTour);
                                    onClose2();
                                    onClose();
                                  },
                                  mapTToU: (x) => x,
                                });
                                return <Form />;
                              },
                            },
                          },
                          {
                            name: 'Delete',
                            behaviour: 'confirm',
                            show: () => true,
                            onConfirm: ({ idx }: { idx: number }) => {
                              return {
                                title:
                                  'Are you sure you want to delete this Category? This cannot be undone.',
                                message:
                                  'Before performing this operation ensure there are no players in this category',
                                type: 'warning',
                                onConfirm: async () => {
                                  const newTour = {
                                    ...tournament,
                                  };
                                  newTour.auction.categories.splice(idx, 1);
                                  saveTournament(newTour);
                                },
                              };
                            },
                          },
                        ]}
                      />
                    </>
                  ),
                }}
              />
            </div>
            <div className=''>
              <Card
                header={{
                  title: 'Bid Increment',
                  actions: [
                    {
                      type: 'button',
                      button: {
                        name: 'Add',
                        behaviour: 'click',
                        onClick: async () => {
                          const newTour = { ...tournament };
                          newTour.auction.range.push({
                            from: 0,
                            to: 0,
                            increment: 0,
                            bonanza: 0,
                          });
                          saveTournament(newTour);
                        },
                      },
                    },
                  ],
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={[
                        [
                          { name: 'From' },
                          { name: 'To' },
                          { name: 'Increment By' },
                          { name: 'Bonanza Bid' },
                        ],
                      ]}
                      body={(tournament.auction?.range || [])?.map((x, idx) => {
                        return {
                          rowData: { range: x, idx: idx },
                          cells: [
                            { value: formatCurrency(x.from) },
                            { value: formatCurrency(x.to) },
                            { value: formatCurrency(x.increment) },
                            { value: formatCurrency(x.bonanza) },
                          ],
                        };
                      })}
                      actions={[
                        {
                          name: 'Edit',
                          behaviour: 'modal',
                          show: () => true,
                          modal: {
                            title: 'Edit Range',
                            content: ({
                              data: { range, idx },
                              onClose: onClose2,
                            }) => {
                              const Form = renderFormV2({
                                initialFormState: {
                                  from: range.from,
                                  to: range.to,
                                  increment: range.increment,
                                  bonanza: range.bonanza,
                                },
                                fieldsData: [
                                  {
                                    property: 'from',
                                    type: 'number',
                                    required: true,
                                  },
                                  {
                                    property: 'to',
                                    type: 'number',
                                    required: true,
                                  },
                                  {
                                    property: 'increment',
                                    type: 'number',
                                    required: true,
                                  },
                                  {
                                    property: 'bonanza',
                                    type: 'number',
                                    required: true,
                                  },
                                ],
                                onSubmit: async (form) => {
                                  const newTour = {
                                    ...tournament,
                                  };
                                  newTour.auction.range[idx] = {
                                    from: parseInt(
                                      `${form.from || range.from}`
                                    ),
                                    to: parseInt(`${form.to || range.to}`),
                                    increment: parseInt(
                                      `${form.increment || range.increment}`
                                    ),
                                    bonanza: parseInt(
                                      `${form.bonanza || range.bonanza}`
                                    ),
                                  };

                                  console.log(newTour);
                                  saveTournament(newTour);
                                  onClose2();
                                  onClose();
                                },
                                mapTToU: (x) => x,
                              });
                              return <Form />;
                            },
                          },
                        },
                        {
                          name: 'Delete',
                          behaviour: 'confirm',
                          show: () => true,
                          onConfirm: ({ idx }: { idx: number }) => {
                            return {
                              title:
                                'Are you sure you want to delete this Bid Range? This cannot be undone.',
                              type: 'warning',
                              onConfirm: async () => {
                                const newTour = {
                                  ...tournament,
                                };
                                newTour.auction.range.splice(idx, 1);
                                saveTournament(newTour);
                              },
                            };
                          },
                        },
                      ]}
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div className='flex w-2/3'>
            <div className='w-full'>
              <Card
                header={{
                  title: 'Teams',
                  actions: [
                    {
                      type: 'button',
                      button: {
                        name: 'Add',
                        behaviour: 'click',
                        onClick: async () => {
                          const newTour = { ...tournament };
                          newTour.teams.push({
                            name: '',
                            logo: '',
                            description: '',
                            owner: {
                              name: '',
                              photo: '',
                              description: '',
                            },
                            purse: {
                              type: 'standard',
                              spent: 0,
                              remaining: 0,
                            },
                            players: [],
                          });
                          saveTournament(newTour);
                        },
                      },
                    },
                  ],
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={[
                        [
                          { name: 'Name' },
                          { name: 'Description' },
                          { name: 'Owner' },
                          { name: 'Team Budget' },
                          { name: 'Players To Buy' },
                        ],
                      ]}
                      body={(tournament.teams || [])?.map((x, idx) => {
                        return {
                          rowData: { team: x, idx: idx },
                          cells: [
                            { value: x.name },
                            { value: x.description },
                            { value: x.owner?.name },
                            {
                              value: `${formatCurrency(
                                getTeamBudget(x, tournament)
                              )} ${
                                x.purse.type === 'custom' ? '(Custom)' : ''
                              }`, // formatCurrency(x.purse?.total || 0)
                            },
                            {
                              value: (
                                <div>
                                  {getPlayersToBuy(x, tournament)?.map(
                                    (p, pIdx) => (
                                      <span
                                        className='border rounded-md border-gray-200 px-1 mx-0.5'
                                        key={pIdx}
                                      >
                                        {p.category} : {p.noOfPlayers}
                                      </span>
                                    )
                                  )}
                                </div>
                              ),
                            },
                          ],
                        };
                      })}
                      actions={[
                        {
                          name: 'Edit',
                          behaviour: 'modal',
                          show: () => true,
                          modal: {
                            title: 'Edit Team',
                            content: ({
                              data: { team, idx },
                              onClose: onClose2,
                            }: {
                              data: { team: Team; idx: number };
                              onClose: () => void;
                            }) => {
                              const Form = renderFormV2({
                                initialFormState: {
                                  name: team.name,
                                  description: team.description,
                                  owner: team.owner?.name,
                                  type: team.purse.type,
                                },
                                fieldsData: [
                                  {
                                    property: 'name',
                                    type: 'input',
                                    required: true,
                                  },
                                  {
                                    property: 'description',
                                    type: 'input',
                                    required: true,
                                  },
                                  {
                                    property: 'owner',
                                    type: 'input',
                                    required: true,
                                  },
                                  {
                                    property: 'type',
                                    type: 'select',
                                    options: [
                                      { text: 'Select', value: '' },
                                      { text: 'Standard', value: 'standard' },
                                      { text: 'Custom', value: 'custom' },
                                    ],
                                    required: true,
                                  },
                                ],
                                onSubmit: async (form) => {
                                  const newTour = {
                                    ...tournament,
                                  };
                                  newTour.teams[idx].name =
                                    (form.name as string) ||
                                    newTour.teams[idx].name;
                                  newTour.teams[idx].description =
                                    (form.description as string) ||
                                    newTour.teams[idx].description;
                                  newTour.teams[idx].owner.name =
                                    (form.owner as string) ||
                                    newTour.teams[idx].owner.name;
                                  newTour.teams[idx].purse.type =
                                    (form.type as 'standard' | 'custom') ||
                                    newTour.teams[idx].purse.type;

                                  if (
                                    newTour.teams[idx].purse.type === 'custom'
                                  ) {
                                    if (
                                      !(newTour.teams[idx].purse as any)
                                        .playersToBuy
                                    ) {
                                      (newTour.teams[idx]
                                        .purse as any).playersToBuy = tournament.auction.categories.map(
                                        (c) => {
                                          return {
                                            category: c.name,
                                            noOfPlayers: c.players,
                                          };
                                        }
                                      );
                                    }

                                    if (
                                      !(newTour.teams[idx].purse as any)
                                        .totalBudget
                                    ) {
                                      (newTour.teams[idx]
                                        .purse as any).totalBudget =
                                        tournament.auction.teamBudget;
                                    }
                                  } else {
                                    if (
                                      (newTour.teams[idx].purse as any)
                                        .playersToBuy
                                    ) {
                                      delete (newTour.teams[idx].purse as any)
                                        .playersToBuy;
                                    }
                                    if (
                                      (newTour.teams[idx].purse as any)
                                        .totalBudget
                                    ) {
                                      delete (newTour.teams[idx].purse as any)
                                        .totalBudget;
                                    }
                                  }
                                  saveTournament(newTour);
                                  onClose2();
                                  onClose();
                                },
                                mapTToU: (x) => x,
                              });
                              return <Form />;
                            },
                          },
                        },
                        {
                          name: 'Custom Type',
                          show: ({ team }: { team: Team }) => {
                            if (team.purse.type === 'custom') {
                              return true;
                            }
                            return false;
                          },
                          behaviour: 'modal',
                          modal: {
                            title: 'Edit Team Purse Type',
                            content: ({
                              data: { team },
                              onClose,
                            }: {
                              data: { team: Team };
                              onClose: () => void;
                            }) => {
                              return (
                                <CustomTeamPurse
                                  saveTournament={saveTournament}
                                  team={team}
                                  tournament={tournament}
                                  onClose={onClose}
                                />
                              );
                            },
                          },
                        },
                        {
                          name: 'Delete',
                          behaviour: 'confirm',
                          show: () => true,
                          onConfirm: ({ team }) => {
                            return {
                              title: `Are you sure you want to delete this Team '${team.name}'? This cannot be undone.`,
                              message:
                                'Deleting team releases all players back to pool',
                              type: 'warning',
                              onConfirm: async () => {
                                const newTour = {
                                  ...tournament,
                                };
                                newTour.players.forEach((p) => {
                                  if (
                                    p.status === 'sold' &&
                                    p.team === team.name
                                  ) {
                                    p.soldAt = 0;
                                    p.status = 'pending';
                                    p.bidActivities = [];
                                  }
                                });
                                newTour.teams.splice(
                                  newTour.teams.findIndex(
                                    (x) => x.name === team.name
                                  ),
                                  1
                                );
                                saveTournament(newTour);
                              },
                            };
                          },
                        },
                      ]}
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div className='w-full'>
            <Card
              header={{
                title: 'Players',
                actions: [
                  {
                    type: 'button',
                    button: {
                      name: 'Add',
                      behaviour: 'click',
                      onClick: async () => {
                        const newTour = { ...tournament };
                        newTour.players.push({
                          name: '',
                          photo: '',
                          description: '',
                          category: '' as any,
                          status: 'pending',
                          skills: '' as any,
                          team: '',
                          basePrice: 0,
                          soldAt: 0,
                          bidActivities: [],
                        });
                        saveTournament(newTour);
                      },
                    },
                  },
                ],
              }}
              body={{
                type: 'jsx-component',
                body: (
                  <Table
                    header={[
                      [
                        { name: 'Name' },
                        { name: 'Description' },
                        { name: 'Category' },
                        { name: 'Skills' },
                        { name: 'Status' },
                        { name: 'Team' },
                        { name: 'Base Price' },
                        { name: 'Sold At' },
                      ],
                    ]}
                    body={(tournament.players || [])?.map((x, idx) => {
                      return {
                        rowData: { player: x, idx: idx },
                        cells: [
                          { value: x.name },
                          { value: x.description },
                          { value: x.category },
                          {
                            value: x.skills,
                          },
                          {
                            value: x.status,
                          },
                          {
                            value: x.team,
                          },
                          {
                            value: x.basePrice,
                          },
                          {
                            value: x.soldAt ? '' : '-',
                          },
                        ],
                      };
                    })}
                    actions={[
                      {
                        name: 'Edit',
                        behaviour: 'modal',
                        show: () => true,
                        modal: {
                          title: 'Edit Player',
                          content: ({
                            data: { player, idx },
                            onClose: onClose2,
                          }: {
                            data: { player: Player; idx: number };
                            onClose: () => void;
                          }) => {
                            const Form = renderFormV2({
                              initialFormState: {
                                name: player.name,
                                description: player.description,
                                category: player.category,
                                skills: player.skills,
                                status: player.status,
                                // Team, baseprice, soldAt to be left
                              },
                              fieldsData: [
                                {
                                  property: 'name',
                                  type: 'input',
                                  required: true,
                                },
                                {
                                  property: 'description',
                                  type: 'input',
                                  required: true,
                                },
                                {
                                  property: 'category',
                                  type: 'select',
                                  required: true,
                                  options: [
                                    { text: 'Select', value: '' },
                                    ...tournament.auction.categories.map(
                                      (x) => {
                                        return {
                                          text: x.name,
                                          value: x.name,
                                        };
                                      }
                                    ),
                                  ],
                                },
                                {
                                  property: 'skills',
                                  type: 'select',
                                  options: [
                                    { text: 'Select', value: '' },
                                    { text: 'batsman', value: 'batsman' },
                                    { text: 'bowler', value: 'bowler' },
                                    { text: 'allrounder', value: 'allrounder' },
                                    { text: 'keeper', value: 'keeper' },
                                  ],
                                  required: true,
                                },
                                {
                                  property: 'status',
                                  type: 'select',
                                  options: [
                                    // "pending" | "sold" | "unsold"
                                    { text: 'Select', value: '' },
                                    { text: 'pending', value: 'pending' },
                                    { text: 'sold', value: 'sold' },
                                    { text: 'unsold', value: 'unsold' },
                                  ],
                                  required: true,
                                },
                              ],
                              onSubmit: async (form) => {
                                const newTour = {
                                  ...tournament,
                                };
                                newTour.players[idx].name =
                                  (form.name as string) || player.name;
                                newTour.players[idx].description =
                                  (form.description as string) ||
                                  player.description;
                                newTour.players[idx].category =
                                  (form.category as PlayerCategory) ||
                                  player.category;
                                newTour.players[idx].skills =
                                  (form.skills as any) || player.skills;
                                newTour.players[idx].status =
                                  (form.status as any) || player.status;

                                newTour.players[idx].basePrice =
                                  newTour.auction.categories.find(
                                    (x) =>
                                      x.name === newTour.players[idx].category
                                  )?.baseValue || 0;

                                if (
                                  ['pending', 'unsold'].includes(
                                    newTour.players[idx].status
                                  )
                                ) {
                                  newTour.players[idx].team = '';
                                  newTour.players[idx].soldAt = 0;
                                  newTour.players[idx].bidActivities = [];
                                }
                                saveTournament(newTour);
                                onClose2();
                                onClose();
                              },
                              mapTToU: (x) => x,
                            });
                            return <Form />;
                          },
                        },
                      },

                      {
                        name: 'Delete',
                        behaviour: 'confirm',
                        show: () => true,
                        onConfirm: ({ player, idx }) => {
                          return {
                            title: `Are you sure you want to delete this Player '${player.name}'? This cannot be undone.`,
                            message:
                              'Deleting player releases all budget back to teams pool',
                            type: 'warning',
                            onConfirm: async () => {
                              const newTour = {
                                ...tournament,
                              };

                              newTour.teams.splice(idx, 1);
                              saveTournament(newTour);
                            },
                          };
                        },
                      },
                    ]}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  };
}

function CustomTeamPurse({
  team,
  tournament,
  saveTournament,
  onClose,
}: {
  saveTournament: (trnmt: Tournament) => void;
  onClose: () => void;
  tournament: Tournament;
  team: Team;
}) {
  const [teamBudget, setTeamBudget] = useState<number>(
    (team.purse as any)?.totalBudget || 0
  );
  const [playersToBuy, setPlayersToBuy] = useState<
    Array<{
      category: string;
      noOfPlayers: number;
    }>
  >(
    (team.purse as any).playersToBuy ||
      tournament.auction.categories?.map((x) => {
        return {
          category: x.name,
          noOfPlayers: x.players,
        };
      })
  );

  return (
    <div className='text-left'>
      <div>
        <span className='font-bold'>Team Budget:</span>{' '}
        <input
          type='number'
          className='w-64 inline border border-gray-500'
          defaultValue={teamBudget}
          onBlur={(evt) => {
            setTeamBudget(parseInt(evt.target.value));
          }}
        />
      </div>
      <div>
        <div className='font-bold'>Players to Buy from each category</div>
        {playersToBuy.map((p, idx) => (
          <div key={p.category}>
            <span className='font-bold'>
              {p.category} :{' '}
              <input
                type='number'
                className='w-24 border border-gray-500'
                defaultValue={p.noOfPlayers}
                onBlur={(evt) => {
                  const newPlayers = [...playersToBuy];
                  newPlayers[idx].noOfPlayers = parseInt(evt.target.value);
                  setPlayersToBuy(newPlayers);
                }}
              />
            </span>
          </div>
        ))}
      </div>

      <LoadingButton
        text={'Save'}
        behaviorFn={async () => {
          const newTour = {
            ...tournament,
          };

          const selectedTeam = newTour.teams?.find((x) => x.name === team.name);
          if (selectedTeam) {
            (selectedTeam.purse as any).totalBudget = teamBudget;
            (selectedTeam.purse as any).playersToBuy = playersToBuy;

            saveTournament(newTour);
          }
        }}
      />
    </div>
  );
}
