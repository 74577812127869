import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { RotationalShiftDayType } from '@erp_core/erp-types/dist/modules/planning';
import { RotationalShiftDayFilter } from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-day';
import {
  AdvancedLoadingButton,
  LoadingButton,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SimpleShift } from '../../../schedule-allocator/utils/compute-schedule';
import { scheduleShifter } from '../utils/schedule-shifter';

type SchDay = { p?: Array<number>; w?: Array<number>; ot?: Array<number> };
type ShiftSch = Array<SchDay>;
type WeekSch = Array<ShiftSch>;
export type WASch = Array<WeekSch>;

export type ExtEmpProfile = EmployeeProfileType & {
  supervisorType: 'profile' | 'rotational';
};

type DataFormat = {
  employee: { name: string; id: string };
  weeks: Array<Array<{ p?: string; w?: Boolean; ot?: Boolean }>>;
}[];

function convertToDataFormat(
  schedule: WASch,
  employees: {
    name: string;
    roleName?: string;
    id: string;
  }[]
): DataFormat {
  const data: DataFormat = employees.map((x) => ({
    employee: x,
    weeks: _.times(3).map(() => {
      return _.times(7).map(() => {
        return {};
      });
    }),
  }));

  if (!schedule.length) {
    return data;
  }

  schedule.forEach((schWeek, schWeekIdx) => {
    schWeek.forEach((schShift, schShiftIdx) => {
      schShift.forEach((schDay, schDayIdx) => {
        if (schDay.hasOwnProperty('p')) {
          // This will have the array of indexs of employees.
          schDay.p?.forEach((e) => {
            const day = data[e]?.weeks[schWeekIdx][schDayIdx];
            if (day) {
              day.p = `${schShiftIdx}`;
            }
          });
        }

        if (schDay.hasOwnProperty('w')) {
          // This will have array of weekly offs
          schDay.w?.forEach((w) => {
            const day = data[w]?.weeks[schWeekIdx][schDayIdx];
            if (day) {
              day.w = true;
            }
          });
        }

        if (schDay.hasOwnProperty('ot')) {
          schDay.ot?.forEach((o) => {
            const day = data[o]?.weeks[schWeekIdx][schDayIdx];
            if (day) {
              day.ot = true;
            }
          });
        }
      });
    });
  });

  return data;
}

export function ShiftBuilderV2({
  name,
  godown,
  setGodown,
  employees,
  shifts,
  OnSave,
  onSaveGrid,
  schedule,
  getShifts,
  getEmployee,
  currentLocation,
  setRotationalShiftDay,
}: {
  name: string;
  godown: Godown;
  employees: Array<{ name: string; id: string; roleName?: string }>;
  shifts: Array<{ id: string; name: string }>;
  OnSave: ({
    data,
    onClose,
  }: {
    onClose: () => void;
    data: {
      sch: WASch;
      nextMonthSch: WASch;
      nextMonthDate: string;
      dates: Array<string>;
      shifts: Array<SimpleShift>;
      existingShifts: RotationalShiftDayType[];
      supervisorShifts: RotationalShiftDayType[];
      employeeProfiles: ExtEmpProfile[];
      currentLocation: LocationType;
      setGodown: (g: Godown, o?: any) => Promise<Godown>;
      godown: Godown;
      setRotationalShiftDay: (
        s: RotationalShiftDayType,
        o?: any
      ) => Promise<RotationalShiftDayType>;
    };
  }) => JSX.Element;
  onSaveGrid: (res: WASch) => void;
  schedule: WASch;
  getShifts: (
    filter?: RotationalShiftDayFilter | undefined,
    options?: any
  ) => Promise<RotationalShiftDayType[]>;
  getEmployee: (id: string, o?: any) => Promise<EmployeeProfileType>;
  currentLocation: LocationType;
  setGodown: (s: Godown, o?: any) => Promise<Godown>;
  setRotationalShiftDay: (
    s: RotationalShiftDayType,
    o?: any
  ) => Promise<RotationalShiftDayType>;
}): JSX.Element {
  const [data, setData] = useState<DataFormat>(
    convertToDataFormat(schedule, employees)
  );

  const [newData, setNewData] = useState<DataFormat>(
    convertToDataFormat(schedule, employees)
  );

  // TODO: Need to replace this from godown details
  // eslint-disable-next-line
  const [lastSchDate, setLastSchDate] = useState<moment.Moment>(
    moment(
      (godown.details as any).nextSchDate || `${moment().format('YYYY-MM')}-01`
    )
  );
  const [rotationalShifts, setRotationalShifts] = useState<
    RotationalShiftDayType[]
  >([]);
  const [employeeProfiles, setEmployeeProfiles] = useState<ExtEmpProfile[]>([]);

  useEffect(() => {
    getShifts(
      {
        from: `more-than::${lastSchDate.format('YYYY-MM')}-00`,
        to: `less-than::${lastSchDate.format('YYYY-MM')}-32`,
      },
      {
        enableLoading: false,
        enableResource: false,
      }
    ).then((res) => setRotationalShifts(res));

    const supervisorTypes: Array<'profile' | 'rotational'> = [];

    godown.details.scheduleConfig?.employees?.forEach((x) => {
      _.times(x.count, () => {
        supervisorTypes.push(x.supervisor);
      });
    });

    godown.details.scheduleConfig?.relievers?.forEach((x) => {
      _.times(x.count, () => {
        supervisorTypes.push(x.supervisor);
      });
    });

    let employeesToFetch = [
      ...(godown.details.selectedEmployees || []),
      ...(godown.details.selectedRelievers || []),
    ];
    if (godown.type === 'supervisor-work-area') {
      employeesToFetch = [...(godown.details.supervisors || [])];
    }

    Promise.all(
      employeesToFetch.map((x) =>
        getEmployee(x.id, {
          enableLoading: false,
          enableResource: false,
        })
      )
    ).then((emps) =>
      setEmployeeProfiles(
        emps.map((e, idx) => ({ ...e, supervisorType: supervisorTypes[idx] }))
      )
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(convertToDataFormat(schedule, employees));

    // Also update next month schedule

    const newSchedule = scheduleShifter(
      schedule,
      lastSchDate.daysInMonth() - 21
    );
    setNewData(convertToDataFormat(newSchedule, employees));

    // eslint-disable-next-line
  }, [schedule]);

  function getValue(empIdx, weekIdx, dayIdx) {
    const block = data[empIdx]?.weeks[weekIdx][dayIdx];
    if (block) {
      if (block.hasOwnProperty('p')) {
        if (block.hasOwnProperty('ot')) {
          return `${block.p}+OT`;
        }
        return block.p;
      }

      if (block.hasOwnProperty('w')) {
        return 'WO';
      }
    }

    return '';
  }

  function getNewValue(empIdx, weekIdx, dayIdx) {
    const block = newData[empIdx]?.weeks[weekIdx][dayIdx];
    if (block) {
      if (block.hasOwnProperty('p')) {
        if (block.hasOwnProperty('ot')) {
          return `${block.p}+OT`;
        }
        return block.p;
      }

      if (block.hasOwnProperty('w')) {
        return 'WO';
      }
    }

    return '';
  }

  function SaveSchedule({ onClose }: { onClose: () => void }) {
    const [result, setResult] = useState<{
      sch: WASch;
      nextMonthSch: WASch;
      nextMonthDate: string;
      dates: Array<string>;
      shifts: Array<SimpleShift>;
      existingShifts: RotationalShiftDayType[];
      supervisorShifts: RotationalShiftDayType[];
      employeeProfiles: ExtEmpProfile[];
      currentLocation: LocationType;
      setGodown: (g: Godown, o?: any) => Promise<Godown>;
      godown: Godown;
      setRotationalShiftDay: (
        s: RotationalShiftDayType,
        o?: any
      ) => Promise<RotationalShiftDayType>;
    }>({
      sch: [],
      nextMonthSch: [],
      nextMonthDate: moment(lastSchDate).add(1, 'month').format('YYYY-MM-DD'),
      dates: [],
      shifts: [],
      existingShifts: [],
      supervisorShifts: [],
      employeeProfiles: employeeProfiles,
      currentLocation: currentLocation,
      setRotationalShiftDay,
      setGodown,
      godown,
    });

    useEffect(() => {
      const filteredShifts = rotationalShifts.filter((x) => {
        if (x.details.workArea?.id === godown.id) {
          return true;
        }

        if (x.details.location && x.details.location === godown.id) {
          return true;
        }

        if (employeesForGrid.find((y) => y.id === x.employee.id)) {
          return true;
        }

        return false;
      });

      result.supervisorShifts = rotationalShifts.filter((x) => {
        if (x.details.role === 'supervisor' && x.details.workArea?.id) {
          return true;
        }

        return false;
      });

      _.times(3).forEach(() => {
        const weekArray: WeekSch = [];
        _.times(shifts.length).forEach(() => {
          const shiftArray: ShiftSch = [];
          _.times(7).forEach(() => {
            const day: SchDay = {};
            shiftArray.push(day);
          });
          weekArray.push(shiftArray);
        });
        result.sch.push(weekArray);
      });

      for (let schWeekIdx = 0; schWeekIdx < 3; schWeekIdx++) {
        const schWeek = result.sch[schWeekIdx];
        for (let schShiftIdx = 0; schShiftIdx < schWeek.length; schShiftIdx++) {
          const schShift = schWeek[schShiftIdx];
          for (let schDayIdx = 0; schDayIdx < schShift.length; schDayIdx++) {
            const schDay = schShift[schDayIdx];

            for (let empIdx = 0; empIdx < employees.length; empIdx++) {
              for (let weekIdx = 0; weekIdx < 3; weekIdx++) {
                for (let dayIdx = 0; dayIdx < 7; dayIdx++) {
                  const cell = data[empIdx].weeks[weekIdx][dayIdx];
                  if (cell.hasOwnProperty('p')) {
                    // This means empIdx is present on day dayIdx of week weekIdx
                    // value is shift index
                    // const shiftName = cell.p;
                    const shiftIdx = parseInt(cell.p || '');
                    if (
                      dayIdx === schDayIdx &&
                      schShiftIdx === shiftIdx &&
                      weekIdx === schWeekIdx
                    ) {
                      // Add employee to schDay
                      if (schDay.p) {
                        schDay.p.push(empIdx);
                      } else {
                        schDay.p = [empIdx];
                      }

                      if (cell.hasOwnProperty('ot')) {
                        if (schDay.ot) {
                          schDay.ot.push(empIdx);
                        } else {
                          schDay.ot = [empIdx];
                        }
                      }
                    }
                  }

                  if (cell.hasOwnProperty('w')) {
                    // This means empIdx is on weeklyoff on day dayIdx of week weekIdx
                    // we dont have shiftIdx as there is no such info in this scenario.
                    if (dayIdx === schDayIdx && weekIdx === schWeekIdx) {
                      if (schDay.w) {
                        if (!schDay.w.includes(empIdx)) {
                          schDay.w.push(empIdx);
                        }
                      } else {
                        schDay.w = [empIdx];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // Create next month sch as well (this has bugs)
      _.times(3).forEach(() => {
        const weekArray: WeekSch = [];
        _.times(shifts.length).forEach(() => {
          const shiftArray: ShiftSch = [];
          _.times(7).forEach(() => {
            const day: SchDay = {};
            shiftArray.push(day);
          });
          weekArray.push(shiftArray);
        });
        result.nextMonthSch.push(weekArray);
      });

      for (let schWeekIdx = 0; schWeekIdx < 3; schWeekIdx++) {
        const schWeek = result.nextMonthSch[schWeekIdx];
        for (let schShiftIdx = 0; schShiftIdx < schWeek.length; schShiftIdx++) {
          const schShift = schWeek[schShiftIdx];
          for (let schDayIdx = 0; schDayIdx < schShift.length; schDayIdx++) {
            const schDay = schShift[schDayIdx];

            for (let empIdx = 0; empIdx < employees.length; empIdx++) {
              for (let weekIdx = 0; weekIdx < 3; weekIdx++) {
                for (let dayIdx = 0; dayIdx < 7; dayIdx++) {
                  const cell = newData[empIdx].weeks[weekIdx][dayIdx];
                  if (cell.hasOwnProperty('p')) {
                    // This means empIdx is present on day dayIdx of week weekIdx
                    // value is shift index
                    // const shiftName = cell.p;
                    const shiftIdx = parseInt(cell.p || '');
                    if (
                      dayIdx === schDayIdx &&
                      schShiftIdx === shiftIdx &&
                      weekIdx === schWeekIdx
                    ) {
                      // Add employee to schDay
                      if (schDay.p) {
                        schDay.p.push(empIdx);
                      } else {
                        schDay.p = [empIdx];
                      }

                      if (cell.hasOwnProperty('ot')) {
                        if (schDay.ot) {
                          schDay.ot.push(empIdx);
                        } else {
                          schDay.ot = [empIdx];
                        }
                      }
                    }
                  }

                  if (cell.hasOwnProperty('w')) {
                    // This means empIdx is on weeklyoff on day dayIdx of week weekIdx
                    // we dont have shiftIdx as there is no such info in this scenario.
                    if (dayIdx === schDayIdx && weekIdx === schWeekIdx) {
                      if (schDay.w) {
                        if (!schDay.w.includes(empIdx)) {
                          schDay.w.push(empIdx);
                        }
                      } else {
                        schDay.w = [empIdx];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      for (
        let i = parseInt(moment(lastSchDate).format('DD'));
        i <= moment(lastSchDate).daysInMonth();
        i++
      ) {
        const date = moment(
          `${lastSchDate.format('YYYY-MM')}-${i < 10 ? '0' : ''}${i}`
        );
        result.dates.push(date.format('YYYY-MM-DD'));
      }

      const matrix = result.sch;

      calculateShifts({
        matrix,
        shifts,
        result,
        employees: employeesForGrid.map((x, idx) => {
          return {
            roleName: employees[idx].roleName,
            ...x,
          };
        }),
        lastSchDate,
        godown,
      });
      calculateShifts({
        matrix,
        shifts,
        result,
        employees: employeesForGrid.map((x, idx) => {
          return {
            roleName: employees[idx].roleName,
            ...x,
          };
        }),
        lastSchDate: lastSchDate.add(21, 'day'),
        godown,
      });

      result.shifts = result.shifts.sort((a, b) => {
        return moment(a.date).diff(b.date, 'd');
      });

      result.existingShifts = filteredShifts;

      setResult({ ...result });
      // eslint-disable-next-line
    }, []);
    return OnSave({ data: result, onClose });
  }

  function saveGrid() {
    const result: WASch = [];

    _.times(3).forEach(() => {
      const weekArray: WeekSch = [];
      _.times(shifts.length).forEach(() => {
        const shiftArray: ShiftSch = [];
        _.times(7).forEach(() => {
          const day: SchDay = {};
          shiftArray.push(day);
        });
        weekArray.push(shiftArray);
      });
      result.push(weekArray);
    });

    for (let schWeekIdx = 0; schWeekIdx < 3; schWeekIdx++) {
      const schWeek = result[schWeekIdx];
      for (let schShiftIdx = 0; schShiftIdx < schWeek.length; schShiftIdx++) {
        const schShift = schWeek[schShiftIdx];
        for (let schDayIdx = 0; schDayIdx < schShift.length; schDayIdx++) {
          const schDay = schShift[schDayIdx];

          for (let empIdx = 0; empIdx < employees.length; empIdx++) {
            for (let weekIdx = 0; weekIdx < 3; weekIdx++) {
              for (let dayIdx = 0; dayIdx < 7; dayIdx++) {
                const cell = data[empIdx].weeks[weekIdx][dayIdx];
                if (cell.hasOwnProperty('p')) {
                  // This means empIdx is present on day dayIdx of week weekIdx
                  // value is shift index
                  // const shiftName = cell.p;
                  const shiftIdx = parseInt(cell.p || '');
                  if (
                    dayIdx === schDayIdx &&
                    schShiftIdx === shiftIdx &&
                    weekIdx === schWeekIdx
                  ) {
                    // Add employee to schDay
                    if (schDay.p) {
                      schDay.p.push(empIdx);
                    } else {
                      schDay.p = [empIdx];
                    }

                    if (cell.hasOwnProperty('ot')) {
                      if (schDay.ot) {
                        schDay.ot.push(empIdx);
                      } else {
                        schDay.ot = [empIdx];
                      }
                    }
                  }
                }

                if (cell.hasOwnProperty('w')) {
                  // This means empIdx is on weeklyoff on day dayIdx of week weekIdx
                  // we dont have shiftIdx as there is no such info in this scenario.
                  if (dayIdx === schDayIdx && weekIdx === schWeekIdx) {
                    if (schDay.w) {
                      if (!schDay.w.includes(empIdx)) {
                        schDay.w.push(empIdx);
                      }
                    } else {
                      schDay.w = [empIdx];
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    onSaveGrid(result);
  }

  function setValue(value, empIdx, weekIdx, dayIdx) {
    // const block = data[empIdx].weeks[weekIdx][dayIdx];
    if (value === '') {
      data[empIdx].weeks[weekIdx][dayIdx] = {};
      setData([...data]);
      return;
    }

    if (value === 'WO') {
      data[empIdx].weeks[weekIdx][dayIdx] = {
        w: true,
      };
      setData([...data]);
      return;
    }

    const splitOnPlus = value.split('+');

    data[empIdx].weeks[weekIdx][dayIdx] = {
      p: splitOnPlus[0],
    };

    if (splitOnPlus.length === 2) {
      data[empIdx].weeks[weekIdx][dayIdx].ot = true;
    }
    setData([...data]);
  }

  function setNewValue(value, empIdx, weekIdx, dayIdx) {
    // const block = data[empIdx].weeks[weekIdx][dayIdx];
    if (value === '') {
      newData[empIdx].weeks[weekIdx][dayIdx] = {};
      setNewData([...newData]);
      return;
    }

    if (value === 'WO') {
      newData[empIdx].weeks[weekIdx][dayIdx] = {
        w: true,
      };
      setNewData([...newData]);
      return;
    }

    const splitOnPlus = value.split('+');

    newData[empIdx].weeks[weekIdx][dayIdx] = {
      p: splitOnPlus[0],
    };

    if (splitOnPlus.length === 2) {
      newData[empIdx].weeks[weekIdx][dayIdx].ot = true;
    }
    setNewData([...newData]);
  }

  const employeesForGrid = (godown.details.selectedEmployees || []).length
    ? [
        ...(godown.details.selectedEmployees || []),
        ...(godown.details.selectedRelievers || []),
      ]
    : employees;

  return (
    <div className='border p-2 border-gray-200'>
      <div className='border border-gray-200 rounded-lg  text-center text-xl text-teal-700 flex'>
        <div className='flex-auto'>
          Shift Generator for Workplace {name} for{' '}
          {lastSchDate.format('MMMM YYYY')}
        </div>
        <div className='flex-none'>
          <LoadingButton
            defaultStyle='bg-green-700 text-white p-0.5 border rounded-lg'
            behaviorFn={async () => saveGrid()}
            text='Save Grid'
          />
        </div>
        <div className='flex-none'>
          <AdvancedLoadingButton
            defaultStyle='bg-green-700 text-white p-0.5 border rounded-lg'
            behaviour='modal'
            modal={{
              size: 'large',
              title: 'Generate Schedule',
              content: ({ onClose }) => {
                return <SaveSchedule onClose={onClose} />;
              },
            }}
            // behaviorFn={saveSchedule}
            text='Generate Schedule'
          />
        </div>
      </div>
      <>
        <Grid
          employees={employeesForGrid}
          lastSchDate={lastSchDate}
          getValue={getValue}
          setValue={setValue}
          shifts={shifts}
          readOnly={false}
          startWeekIdx={0}
          footNote={false}
        />

        <Grid
          employees={employeesForGrid}
          lastSchDate={moment(lastSchDate).add(21, 'days')}
          getValue={getValue}
          setValue={setValue}
          shifts={shifts}
          readOnly={true}
          startWeekIdx={3}
          footNote={true}
        />

        <div className='my-3 text-center text-xl'>
          Next Month {moment(lastSchDate).add(1, 'M').format('MMMM YYYY')}{' '}
          Schedule
        </div>
        <Grid
          employees={employeesForGrid}
          lastSchDate={moment(lastSchDate).add(1, 'M')}
          getValue={getNewValue}
          setValue={setNewValue}
          shifts={shifts}
          readOnly={true}
          startWeekIdx={0}
          footNote={true}
        />
      </>
    </div>
  );
}

function Grid({
  employees,
  lastSchDate,
  getValue,
  setValue,
  shifts,
  readOnly,
  startWeekIdx,
  footNote,
}: {
  employees: {
    name: string;
    roleName?: string;
    id: string;
  }[];
  lastSchDate: moment.Moment;
  getValue(empIdx: any, weekIdx: any, dayIdx: any): string | undefined;
  setValue(value: any, empIdx: any, weekIdx: any, dayIdx: any): void;
  shifts: Array<{ id: string; name: string }>;
  readOnly: boolean;
  startWeekIdx: number;
  footNote: boolean;
}): JSX.Element {
  return (
    <>
      {_.times(3).map((w, wIdx) => {
        const nextDate = moment(lastSchDate).add(wIdx * 7, 'day');
        if (nextDate.month() > lastSchDate.month()) {
          return <div key={w}></div>;
        }

        return (
          <div key={w} className='my-1'>
            <div className='text-center font-bold bg-gray-50'>
              Week {wIdx + startWeekIdx + 1}
            </div>
            {employees.map((e, idx) => (
              <div key={idx} className='flex border-b border-gray-200 mt-2'>
                <div className='basis-5/12 my-auto'>{e.name}</div>
                {_.times(7).map((num, dayIdx) => {
                  const computedDate = moment(lastSchDate).add(
                    wIdx * 7 + dayIdx,
                    'day'
                  );

                  if (computedDate.month() > lastSchDate.month()) {
                    return (
                      <div key={num} className='basis-1/12 text-center'>
                        -
                      </div>
                    );
                  }
                  return (
                    <div key={num} className='basis-1/12 text-center'>
                      <div className='text-gray-800'>
                        {computedDate.format('DD-MM (ddd)')}
                      </div>
                      {readOnly ? (
                        <>
                          {shiftMapper[getValue(idx, wIdx, dayIdx) as any] ||
                            '-'}
                        </>
                      ) : (
                        <select
                          value={getValue(idx, wIdx, dayIdx)}
                          onChange={(e) =>
                            setValue(e.target.value, idx, wIdx, dayIdx)
                          }
                        >
                          <option value=''>Select</option>
                          {shifts.map((o, oIdx) => (
                            <option key={oIdx} value={`${oIdx}`}>
                              {shiftMapper[oIdx]}
                            </option>
                          ))}
                          {shifts.map((o, oIdx) => (
                            <option key={oIdx} value={`${oIdx}+OT`}>
                              {shiftMapper[oIdx]}+OT
                            </option>
                          ))}
                          <option value={'WO'}>WO</option>
                        </select>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        );
      })}
      {footNote ? (
        <div className='text-center italic my-2 bg-slate-50'>
          <span className='font-bold'>Note:</span>
          {shifts.map((o, oIdx) => (
            <span className='mx-2 bg-gray-50' key={oIdx}>
              {shiftMapper[oIdx]}: {o.name}
            </span>
          ))}
          {shifts.map((o, oIdx) => (
            <span className='mx-2 bg-gray-50' key={oIdx}>
              {shiftMapper[oIdx]}+OT: {o.name} + 4-Hours Overttime
            </span>
          ))}
          <span className='mx-2 bg-gray-50'>WO: Weekly Off</span>
        </div>
      ) : null}
    </>
  );
}

const shiftMapper = {
  '0': 'I',
  '0+OT': 'I+OT',
  '1': 'II',
  '1+OT': 'II+OT',
  '2': 'III',
  '2+OT': 'III+OT',
  '3': 'IV',
  '3+OT': 'IV+OT',
  '4': 'V',
  '4+OT': 'V+OT',
  '5': 'VI',
  '5+OT': 'VI+OT',
  '7': 'VII',
  '6+OT': 'VII+OT',
  WO: 'WO',
};

function calculateShifts({
  matrix,
  shifts,
  result,
  employees,
  lastSchDate,
  godown,
}: {
  matrix: WASch;
  shifts: {
    id: string;
    name: string;
  }[];
  result: {
    sch: WASch;
    dates: Array<string>;
    shifts: Array<SimpleShift>;
  };
  employees: {
    name: string;
    roleName?: string;
    id: string;
  }[];
  lastSchDate: moment.Moment;
  godown: Godown;
}) {
  for (let weekIdx = 0; weekIdx < matrix.length; weekIdx++) {
    const currentWeekMatrix = matrix[weekIdx];
    for (let shiftIdx = 0; shiftIdx < shifts.length; shiftIdx++) {
      const currentShift = shifts[shiftIdx];
      const shiftData = currentWeekMatrix[shiftIdx];

      for (let day = 0; day < 7; day++) {
        if (
          moment(lastSchDate).add(weekIdx, 'week').add(day, 'day').month() ===
          lastSchDate.month()
        ) {
          const matrixData = shiftData[day];

          if (matrixData.hasOwnProperty('p')) {
            matrixData.p?.forEach((p) => {
              if (employees[p]) {
                const dayToAdd: SimpleShift = result.shifts.find(
                  (r) =>
                    r.employee &&
                    employees[p] &&
                    r.employee.id === employees[p].id &&
                    r.date ===
                      moment(lastSchDate)
                        .add(weekIdx, 'week')
                        .add(day, 'day')
                        .format('YYYY-MM-DD') &&
                    r.shift?.id === currentShift.id
                ) || {
                  date: moment(lastSchDate)
                    .add(weekIdx, 'week')
                    .add(day, 'day')
                    .format('YYYY-MM-DD'),
                  employee: { id: employees[p]?.id, name: employees[p]?.name },
                  status: 'working',
                  shift: { id: currentShift.id, name: currentShift.name },
                  role:
                    godown.type === 'supervisor-work-area'
                      ? 'supervisor'
                      : 'employee',
                  roleName: employees[p]?.roleName,
                  location: { id: godown.id, name: godown.name },
                };
                if (
                  !result.shifts.find(
                    (r) =>
                      r.employee.id === employees[p]?.id &&
                      r.date ===
                        moment(lastSchDate)
                          .add(weekIdx, 'week')
                          .add(day, 'day')
                          .format('YYYY-MM-DD') &&
                      r.shift?.id === currentShift.id
                  )
                ) {
                  result.shifts.push(dayToAdd);
                }
              }
            });
          }

          if (matrixData.hasOwnProperty('w')) {
            matrixData.w?.forEach((w) => {
              if (employees[w]) {
                const dayToAdd: SimpleShift = result.shifts.find(
                  (r) =>
                    r.employee.id === employees[w]?.id &&
                    r.date ===
                      moment(lastSchDate)
                        .add(weekIdx, 'week')
                        .add(day, 'day')
                        .format('YYYY-MM-DD') &&
                    !r.shift
                ) || {
                  date: moment(lastSchDate)
                    .add(weekIdx, 'week')
                    .add(day, 'day')
                    .format('YYYY-MM-DD'),
                  employee: { id: employees[w]?.id, name: employees[w]?.name },
                  status: 'weekly-off',
                  role: 'employee',
                };

                if (
                  !result.shifts.find(
                    (r) =>
                      r.employee.id === employees[w]?.id &&
                      r.date ===
                        moment(lastSchDate)
                          .add(weekIdx, 'week')
                          .add(day, 'day')
                          .format('YYYY-MM-DD') &&
                      !r.shift
                  )
                ) {
                  result.shifts.push(dayToAdd);
                }
              }
            });
          }

          if (matrixData.hasOwnProperty('ot')) {
            matrixData['ot']?.forEach((x) => {
              if (employees[x]) {
                const daytoUpdate = result.shifts.find(
                  (r) =>
                    r.employee.id === employees[x].id &&
                    r.date ===
                      moment(lastSchDate)
                        .add(weekIdx, 'week')
                        .add(day, 'day')
                        .format('YYYY-MM-DD') &&
                    r.shift?.id === currentShift.id
                );

                if (daytoUpdate) {
                  daytoUpdate.overtime = true;
                }
              }
            });
          }
        }
      }
    }
  }
}
