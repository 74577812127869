import { ClosureDatePeriodType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

// eslint-disable-next-line

export type ClosureDatePeriodFormType = {
  type: string;
  days: string;
};

export function renderAddClosureDatePeriodForm() {
  return function AddClosureDatePeriodForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ClosureDatePeriodType) => Promise<void>;
    isEdit?: boolean;
    data?: ClosureDatePeriodFormType;
  }): JSX.Element {
    const formProps: FormProps<ClosureDatePeriodFormType> = {
      fieldsData: [
        {
          property: 'type',
          type: 'select',
          options: [
            { value: 'select', text: 'select' },
            { value: 'Major', text: 'Major' },
            { value: 'Minor', text: 'Minor' },
          ],
          required: true,
          label: 'Change Control Requisition Type',
          readonly: data?.type ? true : false,
        },
        {
          property: 'days',
          type: 'number',
          required: true,
          label: 'Period (in days)',
        },
      ],
      initialFormState: {
        days: data?.days || '',
        type: data?.type || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        await onSave(({
          days: f.days || data?.days,
          type: f.type || data?.type,
        } as unknown) as ClosureDatePeriodType);
      },
    };

    const Form = renderFormV2<ClosureDatePeriodFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<ClosureDatePeriodFormType>
    ): ClosureDatePeriodFormType {
      return (b as unknown) as ClosureDatePeriodFormType;
    }
  };
}
