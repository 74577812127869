import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CombinedAttDataType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { GatePassType } from '@erp_core/erp-types/dist/types/modules/hrd/gatepass';
import moment from 'moment';
import toast from 'react-hot-toast';
import { UseCombinedEmployeeProfile } from '../../../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLeave } from '../../../../../../hooks/hrd/leave/use-leave';
import { renderGatePassForm } from '../../../forms/gate-pass';

export function renderAddGatePassForm({
  getEmployeeSync,
  currentUser,
  setGatePass,
  useCombinedEmployeeProfile,
  useCombinedLeave,
}: {
  getEmployeeSync: (id: string) => Promise<EmployeeProfileType>;
  currentUser: CurrentUserType;
  setGatePass: (s: GatePassType) => Promise<GatePassType>;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedLeave: UseCombinedLeave;
}): ({ data, onClose }: { data: any; onClose: () => void }) => JSX.Element {
  const GatePassForm = renderGatePassForm({ useCombinedEmployeeProfile });

  return function AddGatePassForm({
    data,
    onClose,
  }: {
    data: {
      attendance: CombinedAttDataType;
    };
    onClose: () => void;
  }): JSX.Element {
    const exitTime =
      moment
        .utc(
          data.attendance?.punchResult?.checkOutList?.[0]?.punches?.[0]
            .timeStamp
        )
        .tz('Asia/Kolkata')
        .format('HH:mm') || '';

    const entryTime =
      moment
        .utc(
          data.attendance?.punchResult?.checkInList?.[1]?.punches?.[0].timeStamp
        )
        .tz('Asia/Kolkata')
        .format('HH:mm') || '';

    const saveGatePass = async (form: any) => {
      const empDetails = await getEmployeeSync(form.employee.id);
      try {
        const finalData = {
          ...form,
          company: empDetails.company,
          issuedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setGatePass(finalData);
        onClose();
        toast('Gate pass created sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    return (
      <GatePassForm
        data={{
          name: '',
          type: '',
          exitTime,
          entryTime,
          employee: data.attendance.employee as any,
          date: data.attendance.date,
          reason: '',
        }}
        onSave={saveGatePass}
      />
    );
  };
}
