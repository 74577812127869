import { Recat } from '@erp_core/erp-icons/icons/recat';
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  Card,
  ColumnizedContent,
  renderCardComponent,
} from '@erp_core/erp-ui-components';

type CreateVendorCardType = {
  vendor: Vendor;
  loading: boolean;
  initialData: Card;
  mapperFunction: (data: any) => ColumnizedContent;
};
export function createVendorCard({
  vendor,
  loading,
  initialData,
  mapperFunction,
}: CreateVendorCardType) {
  return function () {
    const CardComponent = renderCardComponent();

    if (loading)
      return (
        <CardComponent
          body={{
            type: 'jsx-component',
            body: (
              <div className=''>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ),
          }}
          header={initialData.header}
        />
      );

    if (!vendor)
      return (
        <CardComponent
          body={{
            type: 'jsx-component',
            body: <div className=''>No data present</div>,
          }}
          header={initialData.header}
        />
      );

    return (
      <CardComponent
        body={{ type: 'columns', body: mapperFunction(vendor) }}
        header={initialData.header}
      />
    );
  };
}
