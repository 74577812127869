import { CardHeader } from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import { useContext } from 'react';
import { UserContext } from '../../../../contexts/user';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLeave } from '../../../../hooks/hrd/leave/use-leave';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import { renderRequestLeaveFormV2 } from '../../../../modules/hrd/attendance/leaves-book/forms/request-leave/modal-content-v2';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderGenericLeaveCard } from '../../attendance/util/generic-leave-card';

type renderEmployeeLeaveProps = {
  id: string;
  useCombinedLeave: UseCombinedLeave;
  useFileTransfer: UseFileTransfer;
  userRendererService: UserRendererInterface;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  leaveService: LeaveInterface;
};

export function renderEmployeeLeave({
  id,
  useCombinedLeave,
  useFileTransfer,
  userRendererService,
  useCombinedEmployeeProfile,
  leaveService,
}: renderEmployeeLeaveProps): () => JSX.Element {
  return function EmployeeLeave(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const cardHeader: CardHeader = {
      title: '',
      actions: [
        {
          show: () => id === currentUser?.id,
          type: 'button',
          button: {
            style: 'bg-indigo-800 text-white',
            behaviour: 'modal',
            name: 'Request Leave',
            modal: {
              title: 'Request Leave',
              content: ({ onClose }) => {
                const RequestLeave = renderRequestLeaveFormV2({
                  leaveService,
                  currentUser,
                  useCombinedEmployeeProfile,
                  useCombinedLeave,
                });

                return (
                  <RequestLeave
                    data={{
                      attendance: {
                        employee: currentUser,
                      },
                    }}
                    onClose={async () => {
                      await sleep(1000);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };
    const GenericLeaveCard = renderGenericLeaveCard({
      id,
      cardHeader,
      useCombinedLeave,
      useFileTransfer,
      userRendererService,
    });

    return (
      <>
        <GenericLeaveCard />
      </>
    );
  };
}
