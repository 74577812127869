/* eslint-disable react-hooks/exhaustive-deps */
import {
  ColorFilterType,
  ColorType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseCombinedColor } from '../../../hooks/admin/constants/color/use-color';
import { renderAddColorForm } from './form/add-color-form';

export type RenderColorProps = {
  useCombinedColor: UseCombinedColor;
};

export function renderColor({
  useCombinedColor,
}: RenderColorProps): () => JSX.Element {
  return function Color(): JSX.Element {
    const Table = renderTableWithMapperComponent<ColorType, ColorFilterType>();
    const { syncSet: setColor } = useCombinedColor();
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Color',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Color',
            behaviour: 'modal',
            modal: {
              title: 'Add Color',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveColor(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddColorForm();

    const saveColor = async (form: Partial<ColorType>) => {
      try {
        const finalData = {
          ...form,
        } as ColorType;
        await setColor(finalData as ColorType);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [[{ name: 'Name' }]];

    const bodyMapper = (r: ColorType) => {
      const cells: Array<TableCell> = [{ value: r.name }];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                defaultPageSize: 10,
                type: 'paginated',
                usePaginatedResources: useCombinedColor,
              }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
