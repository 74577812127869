import {
  QuestionsFilter,
  QuestionsType,
  SurveyAnswersType,
} from '@erp_core/erp-types/dist/modules/utility';
import {
  TopicFilter,
  TopicType,
} from '@erp_core/erp-types/dist/types/modules/questionnaire/topic';
import {
  createUseCombinedResource,
  createUseResource,
  createUseResources,
} from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import {
  QuestionFilter,
  QuestionInterface,
} from '../models/interfaces/hrd/questionnaire/question';
import {
  SurveyAnswersFilter,
  SurveyAnswersInterface,
} from '../models/interfaces/hrd/questionnaire/survey-answers';
import {
  QuestionnaireTopicFilter,
  QuestionnaireTopicInterface,
} from '../models/interfaces/hrd/questionnaire/topic';
import { QuestionsInterface } from '../models/interfaces/questionnaire/questions';
import { TopicInterface } from '../models/interfaces/questionnaire/topic';
import { erpV2Api } from './admin';
import { QUESTIONNAIRE_SERVER_URI } from './server-uri';

export const topicService = new GenericHttpWithLogsRepo<TopicType, TopicFilter>(
  {
    erpv2Api: erpV2Api,
    baseURI: QUESTIONNAIRE_SERVER_URI,
    resourceEndpoint: '/api/v1/topics',
  }
);

export const useCombinedTopic = createUseCombinedResource<
  TopicType,
  TopicFilter,
  TopicInterface
>({
  useService: () => topicService,
});

export const questionsService = new GenericHttpWithLogsRepo<
  QuestionsType,
  QuestionsFilter
>({
  erpv2Api: erpV2Api,
  baseURI: QUESTIONNAIRE_SERVER_URI,
  resourceEndpoint: '/api/v1/questions',
});

export const useQuestions = createUseResources<
  QuestionsType,
  QuestionsFilter,
  QuestionsInterface
>({
  useService: () => questionsService,
});
export const useQuestion = createUseResource<
  QuestionsType,
  QuestionsFilter,
  QuestionsInterface
>({
  useService: () => questionsService,
});

export const questionnaireTopicService = new GenericHttpWithLogsRepo<
  TopicType,
  QuestionnaireTopicFilter
>({
  erpv2Api: erpV2Api,
  baseURI: QUESTIONNAIRE_SERVER_URI,
  resourceEndpoint: '/api/v1/topics',
});

export const useCombinedQuestionnaireTopic = createUseCombinedResource<
  TopicType,
  QuestionnaireTopicFilter,
  QuestionnaireTopicInterface
>({
  useService: () => questionnaireTopicService,
});

export const questionnaireQuestionService = new GenericHttpWithLogsRepo<
  QuestionsType,
  QuestionFilter
>({
  erpv2Api: erpV2Api,
  baseURI: QUESTIONNAIRE_SERVER_URI,
  resourceEndpoint: '/api/v1/questions',
});

export const useCombinedQuestionnaireQuestion = createUseCombinedResource<
  QuestionsType,
  QuestionFilter,
  QuestionInterface
>({
  useService: () => questionnaireQuestionService,
});

export const questionnaireSurveyAnswerService = new GenericHttpWithLogsRepo<
  SurveyAnswersType,
  SurveyAnswersFilter
>({
  erpv2Api: erpV2Api,
  baseURI: QUESTIONNAIRE_SERVER_URI,
  resourceEndpoint: '/api/v1/survey-answers',
});

export const useCombinedQuestionnaireSurveyAnswer = createUseCombinedResource<
  SurveyAnswersType,
  SurveyAnswersFilter,
  SurveyAnswersInterface
>({
  useService: () => questionnaireSurveyAnswerService,
});
