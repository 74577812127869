import { ChangeAssesmentType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedDepartment } from '../../../../hooks/hrd/department/use-department';

export type IntimationDeptCommentsType = {
  deptComments: string;
};

type RenderAddIntimationDeptCommentsForm = {
  useCombinedDepartment: UseCombinedDepartment;
};

export function renderAddIntimationDeptCommentsForm({
  useCombinedDepartment,
}: RenderAddIntimationDeptCommentsForm) {
  return function AddIntimationDeptCommentsForm({
    onSave,
    isEdit,
    data,
    dept,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
    dept: string;
  }): JSX.Element {
    const {
      list: departments,
      getAll: getDepartments,
    } = useCombinedDepartment();
    const { user: currentUser } = useContext(UserContext);
    useEffect(() => {
      getDepartments();
      // eslint-disable-next-line
    }, []);
    console.log('commentDep', dept);
    const formProps: FormProps<IntimationDeptCommentsType> = {
      fieldsData: [
        {
          rows: 3,
          property: 'deptComments',
          type: 'text-area',
          label: 'Comments',
        },
      ],
      initialFormState: {
        deptComments: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        let hod;
        const intimationDeptComments =
          data?.details?.intimationDeptComments || [];

        if (dept) {
          hod = departments?.find((x) => x.name === dept)?.hod;
        }
        let dptid = departments?.find((x) => x.name === dept)?.id;
        intimationDeptComments.push({
          dept: {
            id: dptid ? dptid : '',
            name: dept,
          },
          hod: {
            id: hod?.id,
            name: hod?.name,
          },
          comments: f.deptComments,
          commentedBy: {
            id: currentUser?.id,
            name: currentUser?.name,
          },
          createdAt: moment().utc().format(),
        });
        await onSave(({
          details: {
            intimationDeptComments: intimationDeptComments,
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<IntimationDeptCommentsType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<IntimationDeptCommentsType>
    ): IntimationDeptCommentsType {
      return (b as unknown) as IntimationDeptCommentsType;
    }
  };
}
