import { Godown, ItemFilter } from '@erp_core/erp-types/dist/modules/inventory';
import {
  GodownDetails,
  GodownFilterType,
} from '@erp_core/erp-types/dist/types/modules/inventory/godown';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import {
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderAddGodownForm } from './add-godown';

export type RenderGodownsListProps = {
  useCombinedGodown: UseCombinedGodown;
  useCombinedUser: UseCombinedUser;
  userRendererService: UserRendererInterface;
};

export function renderGodownList({
  useCombinedGodown,
  useCombinedUser,
  userRendererService,
}: RenderGodownsListProps): () => JSX.Element {
  return function GodownList(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);

    const { syncSet: setGodown } = useCombinedGodown();
    const { location, company } = useContext(CurrentContext);

    const Table = renderTableWithMapperComponent<Godown, GodownFilterType>();
    const tableHeader: TableHeader = [
      [
        { name: <>Godown Name</> },
        { name: <>Type</> },
        { name: <>Parent Godown</> },
        { name: <>Category</> },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (i: Godown) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              <div>
                {i.type === 'supervisor-work-area' ? (
                  <UserGroupIcon className='inline w-4 text-blue-800' />
                ) : null}
                {i.type === 'work-area' ? (
                  <UsersIcon className='inline w-4 text-blue-800' />
                ) : null}
                {i.name || ''}
              </div>
            </>
          ),
          link: `/inventory/masters/godowns/${i.id}/profile`,
        },
        {
          value: <>{i.type}</>,
        },
        {
          value: (
            <div className='p-1'>
              {i.parentGodownId === 'root' ? 'Root Godown' : i.parentGodownId}
            </div>
          ),
        },
        { value: <span className='capitalize'>{i.details.category}</span> },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.lastModifiedBy?.id}
              name={i.lastModifiedBy?.name}
              extraInfo={moment.utc(i.lastModifiedAt).fromNow()}
            />
          ),
        },
      ];

      return {
        cells,
      };
    };

    const addGodown = async (form) => {
      const save: Partial<Godown> = {};
      if (form.name) save['name'] = form.name;
      form.parentGodownId.id
        ? (save['parentGodownId'] = form.parentGodownId.id)
        : (save['parentGodownId'] = 'root');
      if (location) save['locationId'] = location.id;
      if (company) save['companyId'] = company.id;
      save['details'] = {
        address: form?.address,
      } as GodownDetails;
      save['description'] = '';

      await setGodown(save as Godown);
    };

    const Card = renderCardComponent();

    const header: CardHeader = {
      title: 'Godown List',
      actions: [
        {
          type: 'button',
          auth: 'UI:BTN-ADD-GODOWN:VIEW',
          button: {
            name: 'Add Godown',
            behaviour: 'modal',
            modal: {
              title: 'Add Godown',
              content: ({ onClose }) => {
                const AddGodownForm = renderAddGodownForm({
                  useCombinedGodown: useCombinedGodown,
                });
                return (
                  <AddGodownForm
                    onSave={async (form) => {
                      await addGodown(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const filter: Filter<ItemFilter> = {
      version: 'v2',
      sortFields: [
        {
          key: 'GodownName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'type',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'virtual', value: 'virtual' },
            { text: 'physical', value: 'physical' },
            { text: 'physical-currrent', value: 'physical-current' },
            { text: 'physical-fixed', value: 'physical-fixed' },
            { text: 'work-area', value: 'work-area' },
            { text: 'supervisor-work-area', value: 'supervisor-work-area' },
          ],
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: GodownFilterType) => {
        const filterData: GodownFilterType = {};

        // if (filterSelection.Status !== 'all') {
        //   filterData.status = filterSelection.Status;
        // }
        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        if (filterSelection.type !== 'all' && filterSelection.type !== '') {
          filterData.type = filterSelection.type;
        }

        return filterData as GodownFilterType;
      },
      filterResources: {
        lastModifiedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              auth={currentUser.authorization}
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                defaultPageSize: 25,
                type: 'paginated',
                usePaginatedResources: useCombinedGodown,
              }}
              filter={filter}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card header={header} body={body} auth={currentUser.authorization} />
      </>
    );
  };
}
