import { Recat } from '@erp_core/erp-icons/icons/recat';
import { SalaryBonus } from '@erp_core/erp-types/dist/types/modules/hrd/salary-bonus';
import {
  CardBody,
  CardHeader,
  FormProps,
  MonthSelector,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { BanknotesIcon, UserIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../contexts/current';
import { UserContext } from '../../../contexts/user';
import { UseCombinedCompanyAdmin } from '../../../hooks/admin/company-admin/use-company-admin';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedSalaryBonus } from '../../../hooks/hrd/salary-bonus/use-salary-bonus';
import { UserRendererInterface } from '../../common/fragments/user';
import { SalaryBonusApprovalFormComponent } from './form/negotiation';

export type RenderSalaryBonusProps = {
  useCombinedSalaryBonus: UseCombinedSalaryBonus;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  userRendererService: UserRendererInterface;
  useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
};

export function renderSalaryBonus({
  useCombinedSalaryBonus,
  useCombinedEmployeeProfile,
  userRendererService,
}: RenderSalaryBonusProps): () => JSX.Element {
  return function SalaryBonuss(): JSX.Element {
    const {
      list: salaryBonuses,
      getAll: getSalaryBonuses,
      loading,
    } = useCombinedSalaryBonus();

    const [month, setMonth] = useState(
      localStorage.getItem('bonus-month') || moment.utc().format('YYYY-MM')
    );
    const { user: currentUser } = useContext(UserContext);
    const {
      list: employees,
      getAll: getAllEmployees,
    } = useCombinedEmployeeProfile();
    const { set: setSalaryBonus } = useCombinedSalaryBonus();
    const { company, companyGroup } = useContext(CurrentContext);

    console.log(salaryBonuses);

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      getSalaryBonuses();
      localStorage.setItem('bonus-month', month);
      // eslint-disable-next-line
    }, []);

    const NegotiationForm = SalaryBonusApprovalFormComponent();

    const saveNegotiation = async (
      form: any,
      id: string,
      changeAssesment: SalaryBonus
    ) => {
      const finalData = {
        id,
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setSalaryBonus(finalData);
      toast('Data submitted sucessfully');
      getSalaryBonuses();
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Salary Bonus',
      icon: (
        <BanknotesIcon className='w-8 h-8 inline stroke-indigo-900 self-center' />
      ),
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(month).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={month}
                onChange={(m) => setMonth(m)}
              />
            </div>
          ),
        },
        {
          type: 'button',
          button: {
            name: 'Add Salary Bonus',
            behaviour: 'modal',
            modal: {
              title: 'Add Salary Bonus',
              content: ({ onClose }) => {
                const formProps: FormProps<any> = {
                  fieldsData: [
                    {
                      property: 'employee',
                      type: 'searchable-select',
                      searchOptions: {
                        useSearch: useCombinedEmployeeProfile,
                        filter: { crossGroup: true },
                        onSearchValueSelect: () => {},
                      },
                      label: 'Employee Name',
                      required: true,
                    },
                    {
                      property: 'reason',
                      type: 'input',
                      label: 'Reason',
                      required: true,
                    },
                    {
                      property: 'month',
                      type: 'input',
                      label: 'Month(YYYY-MM)',
                      required: true,
                    },
                    {
                      property: 'bonusType',
                      type: 'select',
                      options: [
                        { text: 'Select', value: '' },
                        { text: 'percentage-base', value: 'percentage-base' },
                        { text: 'fixed', value: 'fixed' },
                      ],
                      required: true,
                    },
                    {
                      property: 'bonusCategory',
                      type: 'select',
                      options: [
                        { text: 'Select', value: '' },
                        { text: 'diwali', value: 'diwali' },
                        { text: 'performance', value: 'performance' },
                        { text: 'retention', value: 'retention' },
                      ],
                      required: true,
                    },
                    {
                      property: 'amount',
                      type: 'number',
                      label: 'Amount',
                      required: true,
                    },
                  ],
                  initialFormState: {
                    employee: { id: '', name: '' },
                    amount: 0,
                    reason: '',
                  },
                  mapTToU: (x) => x,
                  style: 'md:w-1/2',
                  onSubmit: async (l) => {
                    const emp = await employees?.find(
                      (e) => e.id === l.employee.id
                    );
                    const salaryBonusAuthorizer = { id: '', name: '' };
                    if (emp) {
                      salaryBonusAuthorizer.id =
                        emp.details?.authorizations?.salaryAdvanceAuthorizer
                          ?.id || '';
                      salaryBonusAuthorizer.name =
                        emp.details?.authorizations?.salaryAdvanceAuthorizer
                          ?.name || '';
                    }
                    try {
                      await setSalaryBonus({
                        employee: {
                          id: l.employee.id,
                          name: l.employee.name,
                        },
                        authorizer: salaryBonusAuthorizer,
                        requstedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                        month: l.month,
                        amount: l.amount,
                        reason: l.reason,
                        bonusType: l.bonusType,
                        bonusCategory: l.bonusCategory,
                        status: 'pending',
                        companyGroup: {
                          id: companyGroup.id,
                          name: companyGroup.name,
                        },
                        company: {
                          id: company.id,
                          name: company.name,
                        },
                        createdBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                        lastModifiedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                      } as any);

                      toast('Data added sucessfully');
                      getSalaryBonuses({ month });
                    } catch (ex) {
                      toast('Something went wrong');
                    }

                    onClose();
                  },
                };

                const Form = renderFormV2(formProps);
                return (
                  <div>
                    <Form />
                  </div>
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();

    const TableHeader: TableHeader = [
      [
        { name: 'Employee' },
        { name: 'Month' },
        { name: 'Reason' },
        { name: 'Bonus Type ' },
        { name: 'Bonus Category' },
        { name: 'Amount' },
        { name: 'Requested By' },
        { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'negotiation',
          show: ({ salaryBonus }: { salaryBonus: SalaryBonus }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['pending'].includes(salaryBonus.status)) {
              return true;
            }
            return false;
          },

          behaviour: 'modal',
          modal: {
            title: 'Negotiation',
            size: 'large',
            content: ({ onClose, data: { salaryBonus } }) => {
              return (
                <NegotiationForm
                  data={salaryBonus}
                  title='Negotiation'
                  onSave={async (e) => {
                    await saveNegotiation(e, salaryBonus.id, salaryBonus);
                    onClose();
                  }}
                />
              );
            },
          },
        },
        {
          name: 'approval',
          show: ({ salaryBonus }: { salaryBonus: SalaryBonus }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['pending'].includes(salaryBonus.status)) {
              return true;
            }
            return false;
          },

          behaviour: 'modal',
          modal: {
            title: 'Approval',
            size: 'large',
            content: ({ onClose, data: { salaryBonus } }) => {
              return (
                <NegotiationForm
                  data={salaryBonus}
                  title='approval'
                  onSave={async (e) => {
                    await saveNegotiation(e, salaryBonus.id, salaryBonus);
                    onClose();
                  }}
                />
              );
            },
          },
        },
      ];
    }

    function renderTableBodyMapper(salaryBonuses?: SalaryBonus[]): TableBody {
      return (
        salaryBonuses?.map((r) => ({
          rowData: {
            salaryBonus: r,
          },
          cells: [
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.employee?.id}
                  name={r.employee.name}
                />
              ),
            },
            { value: r.month },
            { value: r.reason },
            { value: r.bonusType },
            { value: r.bonusCategory },
            { value: r.amount },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.requstedBy?.id}
                  name={r.requstedBy.name}
                />
              ),
            },
            { value: r.status },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.createdBy?.id}
                  name={r.createdBy?.name}
                  extraInfo={moment.utc(r.createdAt).fromNow()}
                />
              ),
            },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={r.lastModifiedBy?.id}
                  name={r.lastModifiedBy?.name}
                  extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
                />
              ),
            },
          ],
        })) || []
      );
    }

    const TableBody: TableBody = renderTableBodyMapper(salaryBonuses);

    const filteredSalaryBonuses = _.sortBy(
      salaryBonuses?.filter((x) => {
        const emp = employees?.find((e) => e.id === x.employee.id);
        if (
          emp &&
          emp.details?.authorizations?.salaryAdvanceAuthorizer?.id ===
            currentUser.id
        ) {
          return true;
        }
        return false;
      }),
      (l) => {
        switch ((l as SalaryBonus).status || (l as SalaryBonus).status) {
          case 'pending':
            return 0;
          default:
            return 1;
        }
      }
    );

    const FilteredTableBody: TableBody = renderTableBodyMapper(
      filteredSalaryBonuses
    );

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {loading ? (
              <div className='flex my-24 justify-center'>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ) : (
              <>
                <div className='my-5'>
                  <Card
                    header={{ title: 'Requests for you' }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={FilteredTableBody}
                          actions={addActions()}
                          auth={currentUser.authorization}
                        />
                      ),
                    }}
                  />
                </div>

                <div className='my-5'>
                  <Card
                    header={{ title: 'All Requests' }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={TableBody}
                          actions={addActions()}
                          auth={currentUser.authorization}
                        />
                      ),
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
