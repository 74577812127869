import {
  AdvancedLoadingButton,
  LoadingButton,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import { Switch } from '@headlessui/react';
import {
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
  ArrowUpIcon,
  PencilIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import {
  Bid,
  formatCurrency,
  getBudgetAvailability,
  getPlayersToBuy,
  Player,
  Team,
  Tournament,
} from './data';
import { PlayerCard } from './player-card';

export function renderPerformAuction() {
  return function PerformAunction({
    player,
    tournament,
    onClose,
    onSold,
    onUnsold,
  }: {
    player: Player;
    tournament: Tournament;
    onClose: () => void;
    onSold: ({ player, bid }: { player: Player; bid: Bid }) => void;
    onUnsold: ({ player }: { player: Player }) => void;
  }) {
    const { width, height } = useWindowSize();
    const [confetti, setConfetti] = useState<boolean>(false);

    const [bidDigitally, setBidDigitally] = useState<boolean>(false);

    const [bid, setBid] = useState<Bid>({
      value: 0,
      nextBid: player.basePrice || 0,
      topBidder: '',
      secondBidder: '',
      activities: [],
    });

    async function playerSold(finalBid?: Bid) {
      setConfetti(true);
      await sleep(10000);
      onSold({ player: player, bid: finalBid || bid });
      onClose();
    }

    return (
      <>
        <div className='bg-black'>
          <div className='flex bg-gray-800'>
            <div className='w-1/2'>
              <div className='flex justify-center'>
                <PlayerCard
                  size='large'
                  teams={tournament.teams}
                  player={player}
                  selectPlayer={async ({ player }) => {
                    console.log(player);
                  }}
                />
              </div>
              <div className='bg-black text-white'>
                {bid ? (
                  <div className='flex'>
                    <div className='flex-auto'>
                      <div>
                        <div className='text-6xl text-center animate-pulse text-yellow-500'>
                          <Switch
                            checked={bidDigitally}
                            onChange={() => setBidDigitally(!bidDigitally)}
                            className={`${
                              bidDigitally ? 'bg-yello-500' : 'bg-gray-300'
                            }
                          relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-gray-100 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                          >
                            <span className='sr-only'>Bid Digitally</span>

                            <span
                              aria-hidden='true'
                              className={`${
                                bidDigitally ? 'translate-x-3' : 'translate-x-0'
                              }
                              pointer-events-none inline-block h-[15px] w-[25px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                            />
                          </Switch>
                          Current Bid: {formatCurrency(bid.value)}
                        </div>
                        <div className='flex justify-center items-center'>
                          {/* 
                      https://cruip.com/animated-gradient-borders-with-tailwind-css/
                      */}
                          <div className='bg-gray-700 [background:linear-gradient(45deg,#172033,theme(colors.slate.800)_50%,#172033)_padding-box,conic-gradient(from_var(--border-angle),theme(colors.slate.600/.48)_80%,_theme(colors.yellow.500)_86%,_theme(colors.yellow.300)_90%,_theme(colors.yellow.500)_94%,_theme(colors.slate.600/.48))_border-box] rounded-2xl border border-transparent animate-border rounded-lg'>
                            {bid.topBidder ? (
                              <>
                                <img
                                  alt=''
                                  src={
                                    tournament.teams.find(
                                      (y) => y.name === bid.topBidder
                                    )?.logo || '/unknown-team.png'
                                  }
                                  className='h-48 mx-auto animate-hflip'
                                />
                                <div className='text-xl text-center'>
                                  {bid.topBidder}
                                </div>
                              </>
                            ) : (
                              <ShieldExclamationIcon className='h-48' />
                            )}
                          </div>
                          <div className='text-4xl mx-32'>V/S</div>
                          <div className=''>
                            {bid.secondBidder ? (
                              <div className='flex'>
                                <div className='bg-gray-700 border border-gray-500 rounded-lg'>
                                  <img
                                    alt={
                                      tournament.teams.find(
                                        (y) => y.name === bid.secondBidder
                                      )?.name
                                    }
                                    src={
                                      tournament.teams.find(
                                        (y) => y.name === bid.secondBidder
                                      )?.logo || '/unknown-team.png'
                                    }
                                    className='h-48 mx-auto'
                                  />
                                  <div className='text-xl text-center'>
                                    {bid.secondBidder}
                                  </div>
                                </div>
                                {bidDigitally ? (
                                  <div className='flex flex-col justify-center w-64 ml-5'>
                                    <div>
                                      <div className='animate-pulse'>
                                        Next Bid: {formatCurrency(bid.nextBid)}
                                      </div>
                                      <LoadingButton
                                        defaultStyle='bg-gray-800 border border-yellow-500 p-1 my-1 rounded-md text-yellow-500'
                                        text={
                                          <>
                                            <ArrowUpIcon className='inline h-12 mr-2 animate-bounce text-yellow-500' />
                                            Raise
                                          </>
                                        }
                                        behaviorFn={async () => {
                                          setBid({
                                            value: bid.nextBid,
                                            nextBid: calculateNextBid(
                                              tournament,
                                              bid.nextBid,
                                              'normal'
                                            ),
                                            topBidder: bid.secondBidder,
                                            secondBidder: bid.topBidder,
                                            activities: [
                                              {
                                                by: bid.secondBidder,
                                                value: bid.nextBid,
                                                action: 'raise',
                                                time: moment.utc().toString(),
                                              },
                                              ...bid.activities,
                                            ],
                                          });
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <LoadingButton
                                        defaultStyle='bg-gray-800 border border-red-500 p-1 my-1 rounded-md text-red-500'
                                        text={
                                          <>
                                            <ArrowRightOnRectangleIcon className='inline h-12 mr-2 text-red-500' />
                                            Exit
                                          </>
                                        }
                                        behaviorFn={async () => {
                                          setBid({
                                            value: bid.value,
                                            nextBid: bid.nextBid,
                                            topBidder: bid.topBidder,
                                            secondBidder: '',
                                            activities: [
                                              {
                                                by: bid.secondBidder,
                                                value: bid.value,
                                                action: 'exit',
                                                time: moment.utc().toString(),
                                              },
                                              ...bid.activities,
                                            ],
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <ShieldExclamationIcon className='h-48' />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='bg-black text-yellow-500 animate-pulse text-lg pl-5 mt-3'>
                <CategorySummary tournament={tournament} player={player} />
              </div>
            </div>
            <div className='w-1/2'>
              <div className='bg-black text-white'>
                {bid ? (
                  <div className='flex'>
                    <div className='flex-auto'>
                      <div>
                        <div className='flex flex-wrap justify-center my-5'>
                          {tournament.teams.map((x, idx) => {
                            return (
                              <div key={idx} className='w-1/3'>
                                <TeamBidCard
                                  bidDigitally={bidDigitally}
                                  player={player}
                                  x={x}
                                  bid={bid}
                                  tournament={tournament}
                                  setBid={setBid}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='flex justify-center'>
            <div className='mt-8 mb-2'>
              <div className='flex justify-center'>
                <AdvancedLoadingButton
                  text={
                    <>
                      <PencilIcon className='w-8 inline' />
                      Manual Bid
                    </>
                  }
                  defaultStyle='bg-green-500 text-white border rounded-md'
                  behaviour='modal'
                  modal={{
                    size: 'normal',
                    title: 'Set Winning Bid',
                    content: ({ onClose: onClose2 }) => {
                      const Form = renderFormV2({
                        initialFormState: {
                          value: 0,
                          topBidder: '',
                          secondBidder: '',
                        },
                        fieldsData: [
                          { property: 'value', type: 'number', required: true },
                          {
                            property: 'topBidder',
                            type: 'select',
                            required: true,
                            options: [
                              { text: 'Select', value: '' },
                              ...tournament.teams?.map((x) => {
                                return { text: x.name, value: x.name };
                              }),
                            ],
                          },
                          {
                            property: 'secondBidder',
                            type: 'select',
                            options: [
                              { text: 'Select', value: '' },
                              ...tournament.teams?.map((x) => {
                                return { text: x.name, value: x.name };
                              }),
                            ],
                          },
                        ],
                        mapTToU: (m) => m,
                        onSubmit: async (form) => {
                          let finalBid = {
                            value: parseInt(`${form.value}`),
                            nextBid: 0,
                            topBidder: form.topBidder as string,
                            secondBidder: form.secondBidder as string,
                            activities: [
                              {
                                by: form.secondBidder as string,
                                value: 0,
                                action: 'raise',
                                time: moment.utc().toString(),
                              },
                            ],
                          };
                          setBid(finalBid as any);

                          playerSold(finalBid as any);
                          onClose2();
                        },
                      });
                      return (
                        <div className='animate-none'>
                          <Form />
                        </div>
                      );
                    },
                  }}
                />

                {bid && bid.activities?.length ? (
                  <LoadingButton
                    defaultStyle='bg-gray-200 text-red-500 border rounded-md'
                    text={
                      <>
                        <ArrowLeftIcon className='w-8 inline mr-2 text-red-500' />
                        Revert
                      </>
                    }
                    behaviorFn={async () => {
                      if (bid.activities[0].action === 'raise') {
                        if (bid.activities.length > 1) {
                          bid.topBidder = bid.activities[1].by;
                          bid.secondBidder = '';
                          bid.value = calculatePreviousBid(
                            tournament,
                            bid.value,
                            'normal'
                          );
                          bid.activities.splice(0, 1);
                        } else {
                          bid.topBidder = '';
                          bid.secondBidder = '';
                          bid.value = calculatePreviousBid(
                            tournament,
                            bid.value,
                            'normal'
                          );
                          bid.activities = [];
                        }
                        setBid({ ...bid });
                      } else if (bid.activities[0].action === 'bonanza-raise') {
                        if (bid.activities.length > 1) {
                          bid.topBidder = bid.activities[1].by;
                          bid.secondBidder = '';
                          bid.value = calculatePreviousBid(
                            tournament,
                            bid.value,
                            'bonanza'
                          );
                          bid.activities.splice(0, 1);
                        } else {
                          bid.topBidder = '';
                          bid.secondBidder = '';
                          bid.value = calculatePreviousBid(
                            tournament,
                            bid.value,
                            'bonanza'
                          );
                          bid.activities = [];
                        }
                        setBid({ ...bid });
                      } else {
                        bid.activities?.splice(0, 1);
                        setBid({ ...bid });
                      }
                    }}
                  />
                ) : null}
                {bidDigitally && bid && bid.topBidder && !bid.secondBidder ? (
                  <LoadingButton
                    defaultStyle='bg-green-500 text-white border rounded-md px-4'
                    text={
                      <>
                        <ShieldCheckIcon className='h-8 inline mr-2' />
                        Sold
                      </>
                    }
                    behaviorFn={async () => {
                      playerSold();
                      onClose();
                    }}
                  />
                ) : null}
                {bid && !bid.topBidder && !bid.secondBidder ? (
                  <LoadingButton
                    defaultStyle='bg-gray-200 text-red-500 border rounded-md'
                    text={
                      <>
                        <XCircleIcon className='w-8 inline mr-2 text-red-500' />
                        Unsold
                      </>
                    }
                    behaviorFn={async () => {
                      onUnsold({ player: player });
                      onClose();
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {confetti ? (
          <>
            <img
              src='/images/sold-stamp.png'
              alt='sold'
              className='absolute top-5 w-2/5 animate-heartBeat left-24'
            />
            <Confetti width={width} height={height} />
          </>
        ) : null}
      </>
    );
  };
}

function TeamBidCard({
  bid,
  x,
  tournament,
  setBid,
  player,
  bidDigitally,
}: {
  bid: Bid;
  x: Team;
  tournament: Tournament;
  setBid: React.Dispatch<React.SetStateAction<Bid>>;
  player: Player;
  bidDigitally: boolean;
}) {
  const playersToBuy = getPlayersToBuy(x, tournament);
  const selectedCategory = playersToBuy.find(
    (q) => q.category === player.category
  );
  const playersBoughtForCategory = tournament.players.filter(
    (p) => p.category === player.category && p.team === x.name
  ).length;

  const completed =
    playersBoughtForCategory === selectedCategory?.noOfPlayers
      ? 'bg-green-900'
      : ''; // TODO link to team player requirements

  console.log(
    x,
    'completed',
    playersBoughtForCategory,
    selectedCategory?.noOfPlayers
  );
  const budgetAvailability = getBudgetAvailability({
    team: x,
    category: player.category,
    tournament,
  });

  return (
    <div
      className={`p-1 ${completed} cursor-pointer hover:bg-gray-800 border border-gray-700 rounded-md m-1`}
      onClick={() => {
        if (
          bidDigitally &&
          !completed &&
          bid &&
          // !bid.secondBidder &&
          x.purse.remaining >= bid.nextBid &&
          bid.topBidder !== x.name
        ) {
          setBid({
            value: bid.nextBid,
            nextBid: calculateNextBid(tournament, bid.nextBid, 'normal'),
            topBidder: x.name,
            secondBidder: bid.topBidder,
            activities: [
              {
                by: x.name,
                value: bid.nextBid,
                action: 'raise',
                time: moment.utc().toString(),
              },
              ...bid.activities,
            ],
          });
        }
      }}
    >
      <div className='flex'>
        <div className='my-auto'>
          <img
            alt=''
            src={x.logo || '/unknown-team.png'}
            className='h-16 mx-auto hover:scale-125'
          />
        </div>
        <div>
          <div className='text-2xl text-center text-yellow-500'>{x.name}</div>
          <div className='text-xl text-center'>
            Players Bought: {playersBoughtForCategory || 0} /{' '}
            {selectedCategory?.noOfPlayers || 0}
          </div>
          <div className='text-xl text-center text-red-500'>
            FC Locked: {formatCurrency(budgetAvailability.locked)}
          </div>
          <div className='text-xl text-center text-orange-500'>
            CC Locked:{' '}
            {formatCurrency(budgetAvailability.currentCategoryLocked)}
          </div>
          <div className='text-xl text-center text-green-500'>
            Available: {formatCurrency(budgetAvailability.available)}
          </div>
        </div>
      </div>
    </div>
  );
}

function CategorySummary({
  tournament,
  player,
}: {
  tournament: Tournament;
  player: Player;
}) {
  const category = tournament.auction.categories.find(
    (x) => x.name === player.category
  );
  const pendingPlayers = tournament.players.filter(
    (x) =>
      x.category === player.category &&
      ['pending', 'unsold'].includes(player.status)
  );
  return (
    <div>
      <div>
        {pendingPlayers.length} players left in {player.category} category
      </div>
      {category ? (
        <ul>
          <li>
            Every Team needs to buy {category?.players} players in this
            category.
          </li>
          <li>
            Every team's{' '}
            {formatCurrency(category.lockinBudget * category.players)} budget is
            locked-in for this category.
          </li>
          <li>
            Team can bid maximum {formatCurrency(category.maximumBid)} for a
            player in this category.
          </li>
        </ul>
      ) : null}
    </div>
  );
}

function calculateNextBid(
  tournament: Tournament,
  currentBid: number,
  bidType: 'normal' | 'bonanza'
) {
  const range = tournament.auction?.range?.find(
    (x) => x.from <= currentBid && x.to > currentBid
  );
  if (range) {
    if (bidType === 'normal') {
      return currentBid + range.increment;
    }

    return currentBid + range.bonanza;
  }

  return currentBid;
}

function calculatePreviousBid(
  tournament: Tournament,
  currentBid: number,
  bidType: 'normal' | 'bonanza'
) {
  const range = tournament.auction?.range?.find(
    (x) => x.from <= currentBid && x.to > currentBid
  );
  if (range) {
    if (bidType === 'normal') {
      return currentBid - range.increment;
    }

    return currentBid - range.bonanza;
  }

  return currentBid;
}
