import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseCombinedGrade } from '../.../../../../../hooks/inventory/grade/use-grade';
import { renderAddOrderAcceptanceForm } from '../form/add-order-acceptance';

// eslint-disable-next-line
export function renderOrderAcceptanceForm({
  useCombinedGrade,
}: {
  useCombinedGrade: UseCombinedGrade;
}) {
  return function OrderAcceptanceForm(): JSX.Element {
    const Card = renderCardComponent();

    const Form = renderAddOrderAcceptanceForm({ useCombinedGrade });

    const OAQuantityInfoCard = renderCardComponent();

    const data = {
      id: '45545862131213',
      sirNo: '9890',
      poNo: '546454',
      remark: 'xyz',
      company: {
        id: '534534534',
        name: 'Recat',
      },
      date: '30/12/2024',
      invoiceTo: 'Registered Office',
      deliverTo: 'Registered Office',
      invoiceToAdd: 'nashik road, nashik',
      deliverToAdd: 'nashik road, nashik',
      quantityDeliverySchl: [
        {
          productGrade: {
            id: 'C51E8109-00A4-4735-8127-2C5F6220A786',
            name: '1-Amino-4-Cyclopentyl Piperazine (C-Grade)',
            casNo: '543321',
            alias: 'AMP - Crude',
            endUse: '-',
            productName: '1-Amino-4-Cyclopentyl Piperazine',
            productCode: '',
          },
          quantity: '75',
          unit: 'kg',
          price: '75',
          priceUnit: 'Rs',
          exchangeRate: '1',
          quantityPriceDetails: [
            {
              leadDays: '7',
              date: '12/01/2025',
              qty: '50',
              packingDetails: [
                {
                  packingType: {
                    id: '6546546',
                    name: 'Drum-HM-HDPE,Narrow Mouth,55.00,2.50',
                  },
                  wtPerPkg: '25.00',
                  noOfPkg: '1.00 ',
                  qty: '25.00',
                },
                {
                  packingType: {
                    id: '6546546',
                    name: 'Drum-HM-HDPE,Narrow Mouth,55.00,2.50',
                  },
                  wtPerPkg: '25.00',
                  noOfPkg: '1.00 ',
                  qty: '25.00',
                },
              ],
            },
            {
              leadDays: '7',
              date: '12/01/2025',
              qty: '25',
              packingDetails: [
                {
                  packingType: {
                    id: '6546546',
                    name: 'Drum-HM-HDPE,Narrow Mouth,55.00,2.50',
                  },
                  wtPerPkg: '25.00',
                  noOfPkg: '1.00 ',
                  qty: '25.00',
                },
              ],
            },
          ],
        },
      ],
    };

    const OAInfoData: ColumnizedContent = [
      [
        {
          key: 'Company:',
          value: data.company?.name,
          type: 'text',
        },
        {
          key: 'Po No:',
          value: data.poNo,
          type: 'text',
        },
        {
          key: 'Date:',
          value: data.date || '',
          type: 'text',
        },
        {
          key: 'Deliver To:',
          value: data.deliverTo || '',
          type: 'text',
        },
        {
          key: 'Delivery To Address:',
          value: data.deliverToAdd,
          type: 'text',
        },
      ],
      [
        {
          key: 'Invoice To:',
          value: data.invoiceTo,
          type: 'text',
        },
        {
          key: 'Invoice To Address:',
          value: data.invoiceToAdd || '',
          type: 'text',
        },
      ],
    ];

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Product Grade', rowSpan: 3 },
        { name: 'Quantity', rowSpan: 3 },
        { name: 'Unit', rowSpan: 3 },
        { name: 'Price', rowSpan: 3 },
        { name: 'Price/Unit', rowSpan: 3 },
        { name: 'Exchange Rate', rowSpan: 3 },
        {
          name: 'Delivery Schedule',
          colSpan: 7,
          style: 'text-center',
        },
      ],
      [
        { name: 'LeadTime,days	', rowSpan: 2 },
        { name: 'Date', rowSpan: 2 },
        { name: 'Quantity', rowSpan: 2 },
        {
          name: 'Schedule',
          colSpan: 4,
          style: 'text-center',
        },
      ],
      [
        { name: 'Packing' },
        { name: 'Weight/Package' },
        { name: 'No of Package' },
        { name: 'Quantity' },
      ],
    ];

    const tableBody: TableBody =
      data.quantityDeliverySchl.map((r) => ({
        rowData: { authorization: r },
        cells: [
          { value: r.productGrade.name },
          { value: r.quantity },
          { value: r.unit },
          { value: r.price },
          { value: r.priceUnit },
          { value: r.exchangeRate },
          {
            value: (
              <>
                {r.quantityPriceDetails.map((a, i) => (
                  <div className='border border-blue-500'>{a.leadDays}</div>
                ))}
              </>
            ),
          },
          {
            value: (
              <>
                {r.quantityPriceDetails.map((a, i) => (
                  <div className='border border-blue-500'>{a.date}</div>
                ))}
              </>
            ),
          },
          {
            value: (
              <>
                {r.quantityPriceDetails.map((a, i) => (
                  <div className='border border-blue-500'>{a.qty}</div>
                ))}
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-800'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          <tr className='border border-blue-500'>
                            {b.packingType.name}
                          </tr>
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-500'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          {b.wtPerPkg}
                          <br />
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-500'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          {b.noOfPkg}
                          <br />
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
          {
            value: (
              <>
                <div>
                  {r.quantityPriceDetails.map((a, i) => (
                    <div className='border border-blue-500'>
                      {a.packingDetails?.map((b, j) => (
                        <>
                          {b.qty}
                          <br />
                        </>
                      ))}
                    </div>
                  ))}
                </div>
              </>
            ),
          },
        ],
      })) || [];

    const OAQunatityCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Close OA',
                  show: () => true,
                  behaviour: 'click',
                  onClick: async () => {
                    toast('OA Closed.');
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    const OAQuantityInfoCardHeader: CardHeader = {
      title: 'Quantity Details',
    };

    const cardHeader: CardHeader = {
      title: 'Order Acceptance',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add OA',
              content: ({ onClose }) => {
                return (
                  <Form
                    onClose={onClose}
                    data={{
                      id: '',
                      sirNo: '',
                      poNo: '',
                      remark: '',
                      company: {
                        id: '',
                        name: '',
                      },
                      date: '',
                      invoiceTo: '',
                      deliverTo: '',
                      invoiceToAdd: '',
                      deliverToAdd: '',
                      quantityDeliverySchl: [],
                    }}
                  />
                );
              },
            },
          },
        },
        {
          type: 'button',
          button: {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              size: 'normal',
              title: 'Edit',
              content: ({ onClose }) => {
                return (
                  <Form
                    onClose={onClose}
                    data={{
                      id: data.id,
                      sirNo: data.sirNo,
                      poNo: data.poNo,
                      company: {
                        id: data.company.id,
                        name: data.company.name,
                      },
                      date: data.date,
                      invoiceTo: data.invoiceTo,
                      deliverTo: data.deliverTo,
                      invoiceToAdd: data.invoiceToAdd,
                      deliverToAdd: data.deliverToAdd,
                      remark: data.remark,
                      quantityDeliverySchl: data.quantityDeliverySchl,
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'columns',
      body: OAInfoData,
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
        <OAQuantityInfoCard
          body={OAQunatityCardBody}
          header={OAQuantityInfoCardHeader}
        />
      </>
    );
  };
}
