import { CopyToClipboard as ClipboardIcon } from '@erp_core/erp-icons/icons/copy-to-clipboard';
import { DocImage } from '@erp_core/erp-icons/icons/doc-image';
import { Mail } from '@erp_core/erp-icons/icons/mail';
import { TemplatesType } from '@erp_core/erp-types/dist/modules/communication';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedTemplate } from '../../../../hooks/admin/communication-template/use-template';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';
import { renderHtmlTemplate } from './component/html-template';
import { renderAddTemplateForm } from './form/add-template-form';

type RenderTemplate = {
  useCombinedTemplate: UseCombinedTemplate;
  useFileTransfer: UseFileTransfer;
  userRendererService: UserRendererInterface;
};
export const renderTemplate = ({
  useCombinedTemplate,
  useFileTransfer,
  userRendererService,
}: RenderTemplate) => {
  return function Template() {
    const FileViewerUploader = renderFileViewerUploader();

    const { list: templates, getAll: getTemplates } = useCombinedTemplate();

    const { syncSet: setTemplate } = useCombinedTemplate();

    const { user: currentUser } = useContext(UserContext);
    const { companyGroup } = useContext(CurrentContext);

    useEffect(() => {}, [templates]);

    useEffect(() => {
      getTemplates();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Templates',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Template',
            behaviour: 'modal',
            modal: {
              title: 'Add Template',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      saveTemplate(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddTemplateForm();

    const saveTemplate = async (form: Partial<TemplatesType>) => {
      try {
        const finalData = {
          ...form,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as TemplatesType;
        await setTemplate(finalData as TemplatesType);
        toast('Data added sucessfully');
        getTemplates();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const EditTemplateForm = renderHtmlTemplate();

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },
        {
          name: 'Type',
          style: 'fill-blue stroke-pink-700',
        },
        { name: 'Enabled' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        { name: 'Copy' },
      ],
    ];

    const tableBody: TableBody =
      templates?.map((l, idx) => ({
        rowData: {
          template: l,
        },
        cells: [
          { value: l.name },
          { value: l.description },
          {
            value:
              l.type === 'mail' ? (
                <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                  <Mail className='inline mr-1 w-4 text-red-600' />
                  <span className='capitalize'>{l.type || ''}</span>
                </span>
              ) : (
                <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                  <DocImage className='inline mr-1 w-4 text-red-600' />
                  <span className='capitalize'>{l.type || ''}</span>
                </span>
              ),
          },
          { value: l.enabled ? 'Yes' : 'No' },
          {
            value: (
              <userRendererService.userCard
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
                link={true}
              />
            ),
          },
          {
            value: (
              <>
                <div className='flex'>
                  <CopyToClipboard
                    text={l.id}
                    onCopy={() => {
                      toast.success('Successfully copied!');
                    }}
                  >
                    <span>
                      <ClipboardIcon className='inline w-5 stroke-blue-400 cursor-pointer' />
                    </span>
                  </CopyToClipboard>
                </div>
              </>
            ),
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Upload Template',
                  show: (r) => Boolean(!r.template.url),
                  behaviour: 'modal',
                  modal: {
                    title: 'Upload Template',
                    content: ({ data, onClose }) => {
                      return (
                        <FileViewerUploader
                          url={data.template.url}
                          mode='upload'
                          useFileTransfer={useFileTransfer}
                          path={`${companyGroup.id}/email-templates/${data.template.type}/${data.template.id}`.replaceAll(
                            ' ',
                            '-'
                          )}
                          type='private'
                          onUpload={async (path) => {
                            let registrationDoc = path.url;
                            if (registrationDoc) {
                              registrationDoc = path.url;
                              await setTemplate({
                                id: data.template.id,
                                url: registrationDoc,
                              } as TemplatesType);
                              onClose();
                            }
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Edit Template',
                  show: (r) => Boolean(r.template.url),
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit Template',
                    size: 'large',
                    content: ({ data, onClose }) => {
                      return (
                        <EditTemplateForm
                          id={data.template.id}
                          type={data.template.type}
                          useFileTransfer={useFileTransfer}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
        </div>
      </>
    );
  };
};
