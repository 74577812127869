import {
  DeliveryTerm,
  DeliveryTermFilter,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableBodyRow,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { deliveryTermData } from '../../../datalayer/repository/in-memory/admin/delivery-terms';
import { UseCombinedDeliveryTerm } from '../../../hooks/constants/delivery-term/use-delivery-term';
import { renderAddDeliveryTermForm } from './form/add-delivery-terms';

export function renderDeliveryTerms({
  useCombinedDeliveryTerm,
}: {
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
}): () => JSX.Element {
  return function Currency(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      DeliveryTerm,
      DeliveryTermFilter
    >();
    const { syncSet: setDeliveryTerms } = useCombinedDeliveryTerm();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Delivery Terms',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Delivery Term',
            behaviour: 'modal',
            modal: {
              title: 'Add Delivery Term',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveDeliveryTerm(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddDeliveryTermForm();

    const saveDeliveryTerm = async (form: Partial<DeliveryTerm>) => {
      try {
        const finalData = {
          ...form,
        } as DeliveryTerm;
        await setDeliveryTerms(finalData as DeliveryTerm);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [{ name: 'Delivery Term' }, { name: 'Insurance' }, { name: 'Freight' }],
    ];

    const bodyMapper: (d: DeliveryTerm) => TableBodyRow = (d) => {
      return {
        cells: [
          { value: d.name },
          { value: d?.details?.insurance || '--' },
          { value: d.details?.CarriageToPortOfImport || '--' },
        ],
      };
    };
    deliveryTermData.map((d) => {});

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                defaultPageSize: 10,
                type: 'paginated',
                usePaginatedResources: useCombinedDeliveryTerm,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
