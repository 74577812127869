import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCombinedRole } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseCombinedRevision } from '../../../../hooks/inventory/revision/use-revision';
import { BomFlowInterface } from '../../../../models/interfaces/inventory/bom-flow';
import { GradeInterface } from '../../../../models/interfaces/inventory/grade';
import { renderProperties } from '../../properties/properties';
import { renderBomChartComponent } from './bom-chart';

export function renderGrade({
  useCombinedGrade,
  bomFlowService,
  useCurrentUserRoles,
  useCombinedGST,
  useCombinedMetric,
  gradeService,
  useCombinedRevision,
  useFileTransfer,
  useCombinedRole,
  useCombinedUser,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedOdour,
  useCombinedEmployeeProfile,
}: {
  useCombinedGrade: UseCombinedGrade;
  bomFlowService: BomFlowInterface;
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedColor: UseCombinedColor;
  useCombinedOdour: UseCombinedOdour;
  useCurrentUserRoles: UseCurrentUserRoles;
  gradeService: GradeInterface;
  useCombinedRevision: UseCombinedRevision;
  useFileTransfer: UseFileTransfer;
  useCombinedRole: UseCombinedRole;
  useCombinedUser: UseCombinedUser;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}): () => JSX.Element {
  const Card = renderCardComponent();
  const BomChart = renderBomChartComponent({
    bomFlowService,
  });
  return function Grade(): JSX.Element {
    const { id } = useParams();
    const [currentTab, setCurrentTab] = useState<string>('');
    const [proptabs, setPropTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);
    const [properties, setProperties] = useState<Array<ItemProperty>>([]);

    const { syncSet: setRevision } = useCombinedRevision();
    const {
      resource: grade,
      get: getGrade,
      syncSet: setGrade,
    } = useCombinedGrade();

    useEffect(() => {
      if (id) {
        getGrade(id);
      }
      // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
      let tabs: Array<string> = [];
      properties.forEach((p) => {
        p?.classifiers?.forEach((c) => {
          if (c.name && !tabs.includes(c.name)) {
            tabs.push(c.name);
          }
        });
      });

      tabs = [...tabs.sort(), 'Draft'];

      setPropTabs(tabs);

      if (tabs.length) {
        setCurrentTab(tabs[0]);
      }
      // eslint-disable-next-line
    }, [properties]);

    useEffect(() => {
      if (grade?.id) {
        gradeService.getProperties(grade.id).then((c) => {
          setProperties(c);
        });
      }
      // eslint-disable-next-line
    }, [grade]);

    useEffect(() => {
      if (currentTab && grade) {
        const Props = (
          <Properties
            renderOptions={{
              renderAuth: true,
              classifier: currentTab,
              renderAdd: false,
              renderEditValue: true,
            }}
            entityService={gradeService}
            entity={grade}
            setEntity={setGrade}
            type='grade'
            setRevision={setRevision}
          />
        );

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab, grade]);

    const Tabs = renderTabsV2Component();

    const Properties = renderProperties<Grade>({
      useCombinedMetric,
      useCombinedGST,
      useCombinedAppearance,
      useCombinedColor,
      useCombinedOdour,
      useCurrentUserRoles,
      useCombinedRevision,
      useFileTransfer,
      useCombinedRole,
      useCombinedUser,
      useCombinedEmployeeProfile,
    });

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    const tabs = [
      {
        name: 'properties',
        Fn: () =>
          grade ? (
            <div>
              <div className='flex w-full'>
                {proptabs.map((x, idx) => (
                  <div
                    key={x || idx}
                    onClick={() => {
                      if (x !== currentTab) {
                        setCurrentTab(x);
                      }
                    }}
                    className={
                      currentTab === x ? selectedTabStyle : nonSelectedStyle
                    }
                  >
                    {x}
                  </div>
                ))}
              </div>
              <div className='w-full'>
                <div className='w-full'>{body}</div>
              </div>
            </div>
          ) : (
            <></>
          ),
      },
      {
        name: 'bom-charts',
        Fn: () => <BomChart gradeId={grade?.id || ''} />,
      },
    ];

    return (
      <div>
        <Card
          header={{
            title: (
              <>
                <span>{grade?.name || id}</span>{' '}
                <span className='italic font-thin'>
                  version {grade?.version || '0.0.0'}
                </span>
              </>
            ),
            subheading: grade?.item?.name,
          }}
          body={{
            type: 'jsx-component',
            body: <Tabs data={tabs} manipulateLocation={true} />,
          }}
        />
      </div>
    );
  };
}
