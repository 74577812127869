import { Recat } from '@erp_core/erp-icons/icons/recat';
import { Client } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  LoadingButton,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { paymentTerms } from '@erp_core/erp-utils';
import { useState } from 'react';
import { deliveryModesData } from '../../../datalayer/repository/in-memory/admin/delivery-mode';
import { UseCombinedDeliveryTerm } from '../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../hooks/constants/payment-term/use-payment-term';

type CreateItemCardType = {
  client: Client;
  loading: boolean;
  setClient: (s: Client) => Promise<Client>;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
};

export function renderClientTerms({
  client,
  loading,
  setClient,
  useCombinedDeliveryTerm,
  useCombinedPaymentTerm,
}: CreateItemCardType) {
  return function ClientTerms(): JSX.Element {
    const onEditClientTerms = async (f: any) => {
      f.modeOfTransport = deliveryModesData.find(
        (i) => i.id === f.modeOfTransport
      );
      let termsOfBusiness = client.details?.termsOfBusiness;
      const saveData = {
        id: client.id,
        details: {
          termsOfBusiness: { ...termsOfBusiness, ...f },
        },
      };
      await setClient(saveData as Client);
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: `Terms of Business: ${client.name}`,
      actions: [
        {
          type: 'button',
          button: {
            name: 'Edit Payment Terms',
            behaviour: 'modal',
            modal: {
              title: 'Edit Payment Terms',
              content: ({ onClose }) => {
                return (
                  <EditPaymentTerms
                    paymentTerms={
                      Array.isArray(
                        client.details?.termsOfBusiness?.paymentTerms
                      )
                        ? client.details?.termsOfBusiness?.paymentTerms || []
                        : []
                    }
                    onSave={async (newPt) => {
                      let termsOfBusiness = client.details?.termsOfBusiness;
                      const saveData = {
                        id: client.id,
                        details: {
                          termsOfBusiness: {
                            ...termsOfBusiness,
                            ...{ paymentTerms: newPt },
                          },
                        },
                      };
                      await setClient(saveData as Client);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
        {
          type: 'button',
          show: () => !loading,
          button: {
            name: 'Edit Delivery Terms',
            behaviour: 'modal',
            modal: {
              title: 'Edit Vendor Terms',
              content: ({ onClose }) => {
                const Form = renderFormV2({
                  fieldsData: [
                    // {
                    //   property: 'paymentTerms',
                    //   label: 'Payment Term',
                    //   searchOptions: {
                    //     useSearch: usePaymentTerms,
                    //     onSearchValueSelect(u) {},
                    //   },
                    //   type: 'searchable-select',
                    //   required: true,
                    // },
                    // {
                    //   property: 'creditPeriod',
                    //   label: 'Credit Period',
                    //   type: 'number',
                    //   required: true,
                    // },
                    {
                      property: 'deliveryTerms',
                      label: 'Delivery Term',
                      type: 'searchable-select',
                      searchOptions: {
                        useSearch: useCombinedDeliveryTerm,
                        onSearchValueSelect(u) {},
                      },
                      required: true,
                    },
                    {
                      property: 'modeOfTransport',
                      label: 'Mode Of Transport',
                      type: 'select',
                      options: deliveryModesData.map((d) => ({
                        text: d.name,
                        value: d.id,
                      })),
                      required: true,
                    },
                    // {
                    //   property: 'preCarraigeBy',
                    //   label: 'Pre Carraige By',
                    //   type: 'input',
                    //   required: true,
                    // },
                    // {
                    //   property: 'placeOfReceiptByCarrier',
                    //   label: 'Place Of Receipt By Carrier',
                    //   type: 'input',
                    //   required: true,
                    // },
                  ],
                  initialFormState: {
                    // paymentTerms: (vendor?.details?.termsOfBusiness
                    //   ?.paymentTerms as string) || { id: '', name: '' },
                    // creditPeriod:
                    //   vendor?.details?.termsOfBusiness?.creditPeriod || '',
                    deliveryTerms:
                      ((client?.details?.termsOfBusiness
                        ?.deliveryTerms as unknown) as string) || '',
                    modeOfTransport:
                      client?.details?.termsOfBusiness?.modeOfTransport?.id ||
                      '',
                    // preCarraigeBy:
                    //   (vendor?.details?.termsOfBusiness
                    //     ?.preCarraigeBy as string) || '',
                    // placeOfReceiptByCarrier:
                    //   vendor?.details?.termsOfBusiness?.placeOfReceiptByCarrier ||
                    //   '',
                  },
                  style: 'md:w-1/2',
                  button: {
                    style: 'w-fit',
                  },
                  onSubmit: async (f) => {
                    await onEditClientTerms(f);
                    onClose();
                  },
                  allowUnchanged: true,
                  mapTToU(p) {
                    return (p as unknown) as any;
                  },
                });
                return <Form />;
              },
            },
          },
        },

        // {
        //   type: 'button',
        //   show: () => !loading,
        //   button: {
        //     name: 'Edit',
        //     behaviour: 'modal',
        //     modal: {
        //       title: 'Edit Vendor Terms',
        //       content: ({ onClose }) => {
        //         const Form = renderFormV2({
        //           fieldsData: [
        //             {
        //               property: 'paymentTerms',
        //               label: 'Payment Term',
        //               searchOptions: {
        //                 useSearch: useCombinedPaymentTerm,
        //                 onSearchValueSelect(u) {},
        //               },
        //               type: 'searchable-select',
        //               required: true,
        //             },
        //             {
        //               property: 'creditPeriod',
        //               label: 'Credit Period',
        //               type: 'number',
        //               required: true,
        //             },
        //             {
        //               property: 'deliveryTerms',
        //               label: 'Delivery Term',
        //               type: 'searchable-select',
        //               searchOptions: {
        //                 useSearch: useCombinedDeliveryTerm,
        //                 onSearchValueSelect(u) {},
        //               },
        //               required: true,
        //             },
        //             {
        //               property: 'modeOfTransport',
        //               label: 'Mode Of Transport',
        //               type: 'select',
        //               options: deliveryModesData.map((d) => ({
        //                 text: d.name,
        //                 value: d.id,
        //               })),
        //               required: true,
        //             },
        //             {
        //               property: 'preCarraigeBy',
        //               label: 'Pre Carraige By',
        //               type: 'select',
        //               options: deliveryModesData.map((d) => ({
        //                 text: d.name,
        //                 value: d.name,
        //               })),
        //               required: true,
        //             },
        //             {
        //               property: 'placeOfReceiptByCarrier',
        //               label: 'Place Of Receipt By Carrier',
        //               type: 'input',
        //               required: true,
        //             },
        //           ],
        //           initialFormState: {
        //             paymentTerms: (client?.details?.termsOfBusiness
        //               ?.paymentTerms as IdName) || { id: '', name: '' },
        //             creditPeriod:
        //               client?.details?.termsOfBusiness?.creditPeriod || '',
        //             deliveryTerms:
        //               (client?.details?.termsOfBusiness
        //                 ?.deliveryTerms as IdName) || '',
        //             modeOfTransport:
        //               client?.details?.termsOfBusiness?.modeOfTransport?.id ||
        //               '',
        //             preCarraigeBy:
        //               client?.details?.termsOfBusiness?.preCarraigeBy || '',
        //             placeOfReceiptByCarrier:
        //               client?.details?.termsOfBusiness
        //                 ?.placeOfReceiptByCarrier || '',
        //           },
        //           style: 'md:w-1/2',
        //           button: {
        //             style: 'w-fit',
        //           },
        //           onSubmit: async (f) => {
        //             onEditVendorTerms(f);
        //             onClose();
        //           },
        //           allowUnchanged: true,
        //           mapTToU(p) {
        //             return (p as unknown) as any;
        //           },
        //         });

        //         return <Form />;
        //       },
        //     },
        //   },
        // },
      ],
    };

    const body: ColumnizedContent = [
      [
        {
          key: 'Payment Terms:',
          value: paymentTerms(
            client.details?.termsOfBusiness?.paymentTerms as any
          ),
          type: 'text',
        },
        // {
        //   key: 'Credit Period, Days:',
        //   value:
        //     client?.details?.termsOfBusiness?.creditPeriod?.toString() || '0',
        //   type: 'text',
        // },
      ],
      [
        {
          key: 'Delivery Terms:',
          value: client?.details?.termsOfBusiness?.deliveryTerms?.name || '',
          type: 'text',
        },
        {
          key: 'Mode of Transport:',
          value: client?.details?.termsOfBusiness?.modeOfTransport?.name || '',
          type: 'text',
        },
      ],
      // [
      //   {
      //     key: 'Pre-Carraige By:',
      //     value: client?.details?.termsOfBusiness?.preCarraigeBy || '',
      //     type: 'text',
      //   },
      //   {
      //     key: 'Place of Receipt by Carrier:',
      //     value:
      //       client?.details?.termsOfBusiness?.placeOfReceiptByCarrier || '',
      //     type: 'text',
      //   },
      // ],
    ];

    const cardBody: CardBody = {
      type: 'columns',
      body,
    };
    if (loading)
      return (
        <div className='w-full h-36 flex justify-center items-center'>
          <Recat className='h-5 inline animate-pulse mx-4' />
        </div>
      );
    return (
      <div className='w-full space-y-3'>
        <Card body={cardBody} header={cardHeader} />
      </div>
    );
  };
}

function EditPaymentTerms({
  paymentTerms,
  onSave,
}: {
  paymentTerms: Array<{
    percent: number;
    noOfDays: number;
    paymentType: string;
  }>;
  onSave: (
    paymentTerms: Array<{
      percent: number;
      noOfDays: number;
      paymentType: string;
    }>
  ) => Promise<void>;
}) {
  const [pt, setPT] = useState<
    Array<{ percent: number; noOfDays: number; paymentType: string }>
  >(paymentTerms);
  return (
    <div>
      {pt.map((x, idx) => (
        <div key={idx}>
          <span className='font-semibold'>Percent</span>{' '}
          <input
            type='number'
            className='w-20 border border-gray-100'
            defaultValue={x.percent}
            onBlur={(evt) => {
              const newPt = [...pt];
              newPt[idx].percent = parseFloat(evt.target.value);
              setPT(newPt);
            }}
          />
          % &nbsp;&nbsp;&nbsp;
          <span className='font-semibold'>PaymentType:</span>{' '}
          <input
            className='border border-gray-100'
            defaultValue={x.paymentType}
            onBlur={(evt) => {
              const newPt = [...pt];
              newPt[idx].paymentType = evt.target.value;
              setPT(newPt);
            }}
          />{' '}
          &nbsp;&nbsp;&nbsp;
          <span className='font-semibold'>No of Days:</span>
          <input
            type='number'
            className='w-20 border border-gray-100'
            defaultValue={x.noOfDays}
            onBlur={(evt) => {
              const newPt = [...pt];
              newPt[idx].noOfDays = parseFloat(evt.target.value);
              setPT(newPt);
            }}
          />{' '}
        </div>
      ))}
      <LoadingButton
        text={'Add'}
        behaviourParams={{}}
        behaviorFn={async () => {
          setPT((pt) => [...pt, { percent: 0, noOfDays: 0, paymentType: '' }]);
        }}
      />

      <div className='float-right'>
        <LoadingButton
          text={'Save'}
          behaviourParams={{}}
          behaviorFn={async () => {
            await onSave(pt);
          }}
        />
      </div>
    </div>
  );
}
