import { Check } from '@erp_core/erp-icons/icons/check';
import { ItemFilter } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
} from '@erp_core/erp-ui-components';
import { PercentBadgeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user';
import { UseCombinedUser } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UserRendererInterface } from '../../common/fragments/user';

export function renderGradeList({
  useCombinedUser,
  useCombinedGrade,
  useCombinedItem,
  userRendererService,
}: {
  useCombinedGrade: UseCombinedGrade;
  useCombinedItem: UseCombinedItem;
  useCombinedUser: UseCombinedUser;
  userRendererService: UserRendererInterface;
}): () => JSX.Element {
  return function ItemList(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);

    const Table = renderTableWithMapperComponent<any, any>();

    const bodyMapper = (i) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              {i.name || ''}
              {i.status === 'approved' ? (
                <Check className='border rounded-full w-3 ml-1 inline bg-green-500 text-white' />
              ) : null}
            </>
          ),
          link: `/inventory/masters/items/${i.item.id}/grade/${i.id}/properties`,
        },
        {
          value: i.item.name,
        },
        { value: i.version || '0.0.0' },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.createdBy?.id}
              name={i.createdBy?.name}
              extraInfo={moment.utc(i.createdAt).fromNow()}
            />
          ),
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.lastModifiedBy?.id}
              name={i.lastModifiedBy?.name}
              extraInfo={moment.utc(i.lastModifiedAt).fromNow()}
            />
          ),
        },
      ];

      return {
        cells,
      };
    };
    const Card = renderCardComponent();

    const header: CardHeader = {
      title: 'Grade List',
    };

    const filter: any = {
      version: 'v2',
      sortFields: [
        {
          key: 'ItemName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'item',
          value: 'item',
          type: 'search-select',
        },
        {
          key: 'Status',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'approved', value: 'equal::approved' },
            { text: 'unapproved', value: 'equal::unapproved' },
          ],
        },
        {
          key: 'CreatedBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'LastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: any) => {
        const filterData: any = {};

        if (filterSelection.Status !== 'all') {
          filterData.status = filterSelection.Status;
        }
        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }

        if (filterSelection.stockGroupId) {
          filterData.stockGroupId = filterSelection.stockGroupId;
        }

        if (filterSelection.CreatedBy) {
          filterData.createdBy = filterSelection.CreatedBy;
        }

        if (filterSelection.LastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.LastModifiedBy;
        }

        return (filterData as unknown) as ItemFilter;
      },
      filterResources: {
        item: {
          searchOptions: {
            useSearch: useCombinedItem,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
        CreatedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
        LastModifiedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
      },
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={[
            [
              {
                name: (
                  <>
                    <PercentBadgeIcon className='inline w-6 h-6' />
                    Grade Name
                  </>
                ),
              },
              {
                name: 'Item',
              },
              { name: 'Version' },
              {
                name: (
                  <>
                    <UserIcon className='inline w-5 h-5' />
                    Created By
                  </>
                ),
              },
              {
                name: (
                  <>
                    <UserIcon className='inline w-5 h-5' />
                    Last Modified By
                  </>
                ),
              },
            ],
          ]}
          bodyMapper={bodyMapper}
          filter={filter}
          type={{
            defaultPageSize: 25,
            type: 'paginated',
            usePaginatedResources: useCombinedGrade,
          }}
        />
      ),
    };

    return (
      <Card header={header} body={body} auth={currentUser.authorization} />
    );
  };
}
