import { Approval } from '@erp_core/erp-icons/icons/approval';
import { Unapproval } from '@erp_core/erp-icons/icons/unapproval';
import {
  CurrentUserType,
  RoleType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { TableBody, TableBodyRow } from '@erp_core/erp-ui-components';
import { BoltIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { renderAuthWrapper } from '../../../../../../components/auth';
import { AttendanceFilter } from '../../../../../../models/interfaces/hrd/attendance';
import { calculateShiftDuration } from '../../../../../../modules/hrd/attendance/util/calculate-shift-duration';
import { computePayableDayRatio } from '../../../../../../modules/hrd/attendance/util/payable-day';
import {
  EmployeeAttendanceStatusMapper,
  EmployeeStatusIconMapper,
  employeeTrackngStatus,
} from '../../../../../../utils/common';
import { UserRendererInterface } from '../../../../../common/fragments/user';
import { renderGroup } from '../../../util/segregate-punches';
import { GatePassIcon } from '../icons/gatepass-icon';
import { LeavesIcon } from '../icons/leaves-icon';
import { OffSiteIcon } from '../icons/offsite-icon';
import { OvertimeIcon } from '../icons/overtime-icon';
import { ShiftInfo } from '../shift-info';
import { renderSummary } from '../summary';

export type AttendanceBodyProps = {
  type: 'pending-actions' | 'daybook' | 'employee-book';
  list: Array<{ attendance: CombinedAttDataType }>;
};

export function renderAttendanceBody({
  isDaybook,
  userRendererService,
  currentUser,
  getAllAttendanceSync,
  setAttendance,
  currentUserRoles,
  fetchData,
}: {
  isDaybook: boolean;
  userRendererService: UserRendererInterface;
  currentUser: CurrentUserType;
  getAllAttendanceSync: (
    filter?: AttendanceFilter
  ) => Promise<AttendanceType[]>;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  currentUserRoles: RoleType[];
  fetchData: () => Promise<void>;
}) {
  const Summary = renderSummary();

  return function AttendanceBody({
    type,
    list,
  }: AttendanceBodyProps): TableBody {
    const AuthWrapper = renderAuthWrapper();

    return list.map((pr) => {
      const row: TableBodyRow = {
        cells: [],
        rowData: {
          attendance: pr.attendance,
        },
      };
      if (type === 'pending-actions' || type === 'employee-book') {
        row.cells.push({
          value: pr?.attendance?.date || '-',
          style: 'hidden md:table-cell',
        });
      }

      row.cells.push({
        value: (
          <div>
            <div className='text-md flex'>
              <div className='my-auto bg-slate-50'>
                {
                  <span
                    className='text-3xl'
                    title={pr.attendance?.employeeProfile?.details?.grade?.name}
                  >
                    <userRendererService.profilePic
                      size='large'
                      id={pr.attendance.employee.id}
                    />
                  </span>
                }
              </div>
              <div>
                <div>
                  <a
                    href={`/hrd/attendance/date/${pr.attendance.date}/employee/${pr.attendance?.employee.id}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {pr.attendance?.finalized ? (
                      <span
                        title={`Finalized by ${pr.attendance?.finalizedBy?.name} as ${pr.attendance?.status}`}
                        className='border-r font-bold bg-green-200 border-green-300'
                      >
                        {pr.attendance?.employee.name}
                        {pr.attendance?.details?.summary?.autofinalized ? (
                          <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
                        ) : null}
                      </span>
                    ) : (
                      <span className='font-bold'>
                        {pr.attendance?.employee.name}
                      </span>
                    )}
                  </a>
                  {
                    <span
                      title='Attendance device emp-id'
                      className='font-semibold border ml-1 px-1 border-gray-500 rounded text-xs'
                    >{` #${
                      pr.attendance?.employeeProfile?.details.employeeId || '?'
                    }`}</span>
                  }
                  {
                    <span title={pr.attendance?.employeeProfile?.status}>
                      <EmployeeStatusIconMapper
                        status={pr.attendance?.employeeProfile?.status || ''}
                      />
                    </span>
                  }
                  <div className='inline-flex'>
                    <GatePassIcon
                      d={pr.attendance}
                      gatePasses={pr.attendance?.gatePasses || []}
                    />
                    <OvertimeIcon d={pr.attendance} />
                    {pr.attendance?.isOnWeeklyOff && (
                      <span title='On weekly off'>🏡</span>
                    )}
                    <LeavesIcon empLeaves={pr.attendance?.empLeaves} />
                    <OffSiteIcon d={pr.attendance} />
                    {!employeeTrackngStatus({
                      grade: pr.attendance?.employeeProfile?.details?.grade,
                    }) && <span title={'No tracking'}>🙈</span>}
                  </div>
                </div>
                <div className='text-xs font-thin'>
                  {pr.attendance?.employeeProfile?.company.name}
                </div>
                <ShiftInfo
                  d={pr.attendance}
                  date={pr.attendance.date}
                  attendances={[]}
                  employee={pr.attendance?.employeeProfile}
                  rotationalShiftDays={[]}
                  shiftSchedules={[]}
                />
              </div>
            </div>
            <div className='md:hidden'>
              <div className='flex'>
                <div>{pr?.attendance?.date}</div>
                <div>
                  Check-In
                  {renderGroup({
                    isDaybook,
                    list: pr.attendance?.punchResult?.checkInList || [],
                    date: pr.attendance?.date,
                    type: 'check-in',
                    isFinalized: pr.attendance?.finalized,
                    currentUser: currentUser,
                    getAllAttendanceSync: getAllAttendanceSync,
                    setAttendance: setAttendance,
                    getData: async () => {
                      await fetchData();
                    },
                    d: pr.attendance,
                  })}
                </div>
                <div>
                  Check-out
                  {renderGroup({
                    isDaybook,
                    list: pr.attendance?.punchResult?.checkOutList || [],
                    date: pr.attendance?.date,
                    type: 'check-out',
                    isFinalized: pr.attendance?.finalized,
                    currentUser: currentUser,
                    getAllAttendanceSync: getAllAttendanceSync,
                    setAttendance: setAttendance,
                    getData: async () => {
                      await fetchData();
                    },
                    d: pr.attendance,
                  })}
                </div>
              </div>
              <div>
                Summary
                <Summary
                  pr={pr}
                  key={pr.attendance?.employee?.id}
                  d={pr.attendance}
                  punchResult={pr.attendance?.punchResult}
                  punchRemarks={''}
                  autoFinalizeFilter={''}
                />
              </div>
              <div>
                <AuthWrapper
                  action='UI:BTN-ATTEN-FINALIZE:VIEW'
                  children={
                    <>
                      {!pr.attendance?.finalized &&
                      pr.attendance?.safeToAutoFinallize ? (
                        <div className='px-1 border bg-red-50 w-fit font-semibold border-amber-500 text-black'>
                          Auto-Finalizable{' '}
                          <BoltIcon className='text-amber-500 animate-pulse inline h-5' />
                        </div>
                      ) : null}
                      <EmployeeAttendanceStatusMapper
                        status={pr.attendance?.status}
                        leaves={pr.attendance?.empLeaves}
                      />
                    </>
                  }
                />
                {pr.attendance?.verified?.verified === 'yes' ? (
                  <div
                    title={`Verified at ${moment(
                      pr.attendance?.verified?.verifiedAt
                    ).format('LLL')}`}
                    className='px-1 border bg-green-50 w-fit border-green-300 text-green-800'
                  >
                    <Approval className='inline w-5 h-5 stroke-green-800' />{' '}
                    {pr.attendance?.verified?.verifiedBy?.name}
                  </div>
                ) : (
                  <div
                    title='Verification pending'
                    className='px-1 border bg-red-50 w-fit border-red-300 text-red-800'
                  >
                    <Unapproval className='inline w-5 h-5 stroke-red-800' />{' '}
                    {pr.attendance?.employeeProfile?.details?.authorizations
                      ?.attendanceSupervisor?.name ||
                      pr.attendance?.shiftDay?.supervisor?.name ||
                      'unknown'}
                  </div>
                )}
              </div>
              <div>
                Reason:
                {pr.attendance.pendingActions?.map((x, idx) => (
                  <div key={idx}>
                    ‣ {x.action} -{x.actor.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ),
      });

      row.cells.push({
        style: 'hidden md:table-cell',
        value: (
          <div className='flex flex-col'>
            {renderGroup({
              isDaybook,
              list: pr.attendance?.punchResult?.checkInList || [],
              date: pr.attendance?.date,
              type: 'check-in',
              isFinalized: pr.attendance?.finalized,
              currentUser: currentUser,
              getAllAttendanceSync: getAllAttendanceSync,
              setAttendance: setAttendance,
              getData: fetchData,
              d: pr.attendance,
            })}
          </div>
        ),
      });

      row.cells.push({
        style: 'hidden md:table-cell',
        value: (
          <div className='flex flex-col'>
            {renderGroup({
              isDaybook,
              list: pr.attendance?.punchResult?.checkOutList || [],
              date: pr.attendance?.date,
              type: 'check-out',
              isFinalized: pr.attendance?.finalized,
              currentUser: currentUser,
              getAllAttendanceSync: getAllAttendanceSync,
              setAttendance: setAttendance,
              getData: fetchData,
              d: pr.attendance,
            })}
          </div>
        ),
      });

      row.cells.push({
        style: 'hidden md:table-cell',
        value: (
          <Summary
            key={pr.attendance?.employee?.id}
            d={pr.attendance}
            pr={pr}
            punchResult={pr.attendance?.punchResult}
            punchRemarks={''}
            autoFinalizeFilter={''}
          />
        ),
      });

      // row.cells.push({
      //   style: 'hidden md:table-cell',
      //   value: (
      //     <div>
      //       <AuthWrapper
      //         action='UI:BTN-ATTEN-FINALIZE:VIEW'
      //         children={
      //           <>
      //             {!pr.attendance?.finalized &&
      //             pr.attendance?.safeToAutoFinallize ? (
      //               <div className='px-1 border bg-red-50 w-fit font-semibold border-amber-500 text-black'>
      //                 Auto-Finalizable{' '}
      //                 <BoltIcon className='text-amber-500 animate-pulse inline h-5' />
      //               </div>
      //             ) : null}
      //             {pr.attendance.finalized &&
      //             pr.attendance.details?.summary?.autofinalized ? (
      //               <span className='border border-gray-200 rounded-full py-1 px-2'>
      //                 <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
      //                 <span className='mx-1'>
      //                   {pr.attendance.details?.summary?.autofinalizeReason}
      //                 </span>
      //               </span>
      //             ) : null}
      //             {pr.attendance.finalized ? (
      //               <span className='border border-gray-200 rounded-full py-1 px-2'>
      //                 <CurrencyRupeeIcon className='text-green-500 animate-ping w-4 inline' />
      //                 <span className='mx-1'>
      //                   {pr.attendance.details?.summary?.payableWorkHours} Hours
      //                 </span>
      //               </span>
      //             ) : null}
      //             <EmployeeAttendanceStatusMapper
      //               status={pr.attendance?.status}
      //               leaves={pr.attendance?.empLeaves}
      //             />
      //           </>
      //         }
      //       />
      //       {pr.attendance?.verified?.verified === 'yes' ? (
      //         <div
      //           title={`Verified at ${moment(
      //             pr.attendance?.verified?.verifiedAt
      //           ).format('LLL')}`}
      //           className='px-1 border bg-green-50 w-fit border-green-300 text-green-800'
      //         >
      //           <Approval className='inline w-5 h-5 stroke-green-800' />{' '}
      //           {pr.attendance?.verified?.verifiedBy?.name}
      //         </div>
      //       ) : (
      //         <div
      //           title='Verification pending'
      //           className='px-1 border bg-red-50 w-fit border-red-300 text-red-800'
      //         >
      //           <Unapproval className='inline w-5 h-5 stroke-red-800' />{' '}
      //           {pr.attendance?.employeeProfile?.details?.authorizations
      //             ?.attendanceSupervisor?.name ||
      //             pr.attendance?.shiftDay?.supervisor?.name ||
      //             'unknown'}
      //         </div>
      //       )}
      //     </div>
      //   ),
      // });

      if (type === 'pending-actions' || type === 'daybook') {
        row.cells.push({
          style: 'hidden md:table-cell',
          value: (
            <>
              {pr.attendance.pendingActions.map((v, idx) => (
                <div key={idx}>
                  ‣ {v.action} - {v.actor.name}
                </div>
              ))}
            </>
          ),
        });
      }

      if (type === 'employee-book') {
        const pdr = computePayableDayRatio(pr.attendance);

        const shiftHours =
          pr.attendance?.details?.summary &&
          pr.attendance?.details.summary.hasOwnProperty('shiftDuration')
            ? pr.attendance?.details?.summary?.shiftDuration
            : calculateShiftDuration({
                employee: pr.attendance?.employeeProfile,
              });
        // const payableDayRatio = parseFloat(
        //   (
        //     (pr.attendance?.details?.summary?.payableWorkHours || 0) /
        //     shiftHours
        //   ).toFixed(2)
        // );

        row.cells.push({
          style: 'hidden md:table-cell',
          value: renderFinalizationDetails({
            e: pr.attendance,
            pdr,
            // payableDayRatio,
            shiftHours,
          }),
        });
      }

      return row;
    });
  };
}

function renderFinalizationDetails({
  e,
  shiftHours,
  // payableDayRatio,
  pdr,
}: {
  e: AttendanceType;
  shiftHours: number;
  pdr: {
    total: number;
    adjusted: number;
  };
  // payableDayRatio: number;
}) {
  const AuthWrapper = renderAuthWrapper();

  return (
    <AuthWrapper
      showUnAuthorizedWarning={true}
      action='UI:BLK-ATTN-FINL:VIEW'
      children={
        <div>
          {e.finalized ? (
            <>
              <div>
                {e.details?.summary?.autofinalized ? (
                  <div>
                    <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
                    {e.details.summary.autofinalizeReason}
                  </div>
                ) : null}
              </div>
              <div>
                Payable Day: {e.details?.summary?.payableDay ? 'Yes' : 'No'}
              </div>
              <div>PayableHours: {e.details?.summary?.payableWorkHours}Hrs</div>
              <div>Shift Duration: {shiftHours}</div>
              <div>
                Payable Day Ratio:{' '}
                {pdr.total === pdr.adjusted ? (
                  pdr.total
                ) : (
                  <>
                    {pdr.total} (Adjusted: {pdr.adjusted})
                  </>
                )}
              </div>
              {e.details?.summary?.payableDayPenalty ? (
                <div>Penalty: {e.details.summary.lateMarkMins || 0}Hrs.</div>
              ) : null}
              {e.details?.summary?.overtime?.reward ? (
                <div>
                  Overtime reward type: {e.details.summary.overtime?.reward}
                  {e.details.summary.overtime?.reward === 'salary' ? (
                    <div>
                      Overtime Hours:{' '}
                      {e.details.summary.addSalaryForOvertime?.overtimeHours?.toFixed(
                        2
                      )}
                    </div>
                  ) : null}
                  {e.details.summary.overtime?.reward === 'comp-off' ? (
                    <div>
                      Comp-offs: {e.details.summary.overtime.totalCompOffEarned}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {(e.details?.summary as any)?.leaves?.length ? (
                <div>
                  {(e.details?.summary as any).leaves[0].type} leave redeemed (
                  {(e.details?.summary?.leaves?.length || 0) / 2} count)
                </div>
              ) : null}
              {e.details?.summary?.payableDayPenalty
                ? 'Payable Day Penalty'
                : null}
              {e.details?.summary?.layoffRule?.type ? (
                <div>
                  <div>Layoff Type: {e.details?.summary?.layoffRule?.type}</div>
                  <div>
                    Layoff Rule: {e.details?.summary?.layoffRule?.value}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>Finalization Pending</>
          )}
        </div>
      }
    />
  );
}
