import { Recat } from '@erp_core/erp-icons/icons/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useContext, useEffect } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCombinedCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCombinedEmployeeCategory } from '../../../../hooks/admin/constants/employee-category/use-employee-category';
import { UseCombinedEmployeeDesignation } from '../../../../hooks/admin/constants/employee-designation/use-employee-designation';
import { UseCombinedAttendanceDevice } from '../../../../hooks/hrd/attendance-device/use-attendance-device';
import { CGConfigRenderer } from '../config-renderer';
import { renderAttendanceDevices } from './attendance-device';
import { renderAttendanceRules } from './attendance-rules';
import { renderLeaveRules } from './leave-rules';
import { LeaveRuleFormDataType } from './leave-rules/form';
import { renderSalaryRules } from './salary-rules';

type CreateInventoryTemplatesPage = {
  useCombinedAttendanceDevice: UseCombinedAttendanceDevice;
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
  useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
  useCombinedEmployeeDesignation: UseCombinedEmployeeDesignation;
};

export const createHrdConfig = ({
  useCombinedEmployeeCategory,
  useCombinedCompanyGroupSetting,
  useCombinedAttendanceDevice,
  useCombinedEmployeeDesignation,
}: CreateInventoryTemplatesPage) => {
  return function () {
    const { cgSetting } = useContext(CurrentContext);
    const {
      list: attendanceDevices,
      getAll: getAllAttendanceDevices,
    } = useCombinedAttendanceDevice();
    const {
      syncSet: setCompanyGroupSetting,
    } = useCombinedCompanyGroupSetting();

    const {
      list: categories,
      getAll: getAllCategories,
    } = useCombinedEmployeeCategory();

    const getData = async () => {
      getAllAttendanceDevices();
      getAllCategories();
    };

    useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateAttendanceRule2 = async (
      ruleName: string,
      value: { categories: UserIdName[]; designations: UserIdName[] }
    ) => {
      const newRule = {
        categories: value.categories.map((c) => ({
          id: c.id,
          name: c.name,
          value: true,
        })),
        designations: value.designations.map((c) => ({
          id: c.id,
          name: c.name,
          value: true,
        })),
      };
      if (!cgSetting) return;
      const final: any = {
        id: cgSetting.id,
        details: {
          hrd: {
            ...cgSetting?.details?.hrd,
            attendanceRules: {
              ...cgSetting?.details?.hrd?.attendanceRules,
              [ruleName]: newRule,
            },
            salaryRules: {
              ...cgSetting?.details?.hrd?.salaryRules,
            },
          },
        },
      };
      await setCompanyGroupSetting(final as CompanyGroupSetting);
      getData();
    };

    if (!cgSetting || !cgSetting.id) return <div />;

    const updateAttendanceRuleForCategories = async (
      ruleName: string,
      value: {
        categories: (UserIdName & { value?: number })[];
        designations: (UserIdName & { value?: number })[];
      }
    ) => {
      const newRule = {
        categories: value.categories.map((c) => ({
          id: c.id,
          name: c.name,
          value: c.value,
        })),
        designations: value.designations.map((c) => ({
          id: c.id,
          name: c.name,
          value: 20,
        })),
      };

      if (!cgSetting) return;
      const final: any = {
        id: cgSetting.id,
        details: {
          hrd: {
            ...cgSetting?.details?.hrd,
            attendanceRules: {
              ...cgSetting?.details?.hrd?.attendanceRules,
              [ruleName]: newRule,
            },
            salaryRules: {
              ...cgSetting?.details?.hrd?.salaryRules,
            },
          },
        },
      };
      await setCompanyGroupSetting(final as CompanyGroupSetting);
      getData();
    };

    const saveSalaryRules = async (salRules: any) => {
      const hrdDetails = cgSetting?.details?.hrd;
      if (hrdDetails) {
        hrdDetails.salaryRules = {
          ...hrdDetails?.salaryRules,
          ...salRules,
        };

        const final: Partial<CompanyGroupSetting> = {
          id: cgSetting?.id,
          details: {
            hrd: hrdDetails,
          },
        };
        await setCompanyGroupSetting(final as CompanyGroupSetting);
        getData();
      }
    };

    const saveEditLeaveRules = async (
      formData: LeaveRuleFormDataType,
      index: number
    ) => {
      const structuredFormData = {
        basicInformation: {
          leaveName: formData?.leaveName || '',
          leaveType: formData?.leaveType || '',
          dependOnGrade: formData?.dependOnGrade || '',
          grades: (formData?.grades as { id: string; name: string }[]) || [],
          dependOnGender: formData?.dependOnGender || '',
          gender: formData?.gender || '',
          allowedDuringProbation: formData?.allowedDuringProbation || '',
        },
        availmentRules: {
          availType: formData?.availType || '',
          maxLeavesAllotment: formData?.maxLeavesAllotment || '',
          document: formData?.document || '',
          noticePeriod: formData?.noticePeriod || '',
        },
        redemptionRules: {
          encashmentAllowed: formData?.encashmentAllowed || '',
          leavesRedeemed: formData?.leavesRedeemed || '',
        },
        expirationRules: {
          allowCarryForward: formData?.allowCarryForward || '',
          leavesExpire: formData?.leavesExpire || '',
        },
      };

      const leaveRules = cgSetting?.details?.hrd?.leaveRules || [];
      leaveRules[index] = structuredFormData;
      const finalData = {
        id: cgSetting?.id,
        details: {
          hrd: {
            attendanceRules: {
              ...cgSetting?.details?.hrd?.attendanceRules,
            },
            salaryRules: {
              ...cgSetting?.details?.hrd?.salaryRules,
            },
            leaveRules: leaveRules,
          },
        },
      };
      await setCompanyGroupSetting(finalData as CompanyGroupSetting);
      getData();
    };

    const saveAddLeaveRules = async (formData: LeaveRuleFormDataType) => {
      const finalData = {
        id: cgSetting?.id,
        details: {
          hrd: {
            attendanceRules: {
              ...cgSetting?.details?.hrd?.attendanceRules,
            },
            salaryRules: {
              ...cgSetting?.details?.hrd?.salaryRules,
            },
            leaveRules: [
              ...(cgSetting?.details?.hrd?.leaveRules || []),
              {
                basicInformation: {
                  leaveName: formData?.leaveName || '',
                  leaveType: formData?.leaveType || '',
                  dependOnGrade: formData?.dependOnGrade || '',
                  grades:
                    (formData?.grades as { id: string; name: string }[]) || [],
                  dependOnGender: formData?.dependOnGender || '',
                  gender: formData?.gender || '',
                  allowedDuringProbation:
                    formData?.allowedDuringProbation || '',
                },
                availmentRules: {
                  availType: formData?.availType || '',
                  maxLeavesAllotment: formData?.maxLeavesAllotment || '',
                  document: formData?.document || '',
                  noticePeriod: formData?.noticePeriod || '',
                },
                redemptionRules: {
                  encashmentAllowed: formData?.encashmentAllowed || '',
                  leavesRedeemed: formData?.leavesRedeemed || '',
                },
                expirationRules: {
                  allowCarryForward: formData?.allowCarryForward || '',
                  leavesExpire: formData?.leavesExpire || '',
                },
              },
            ],
          },
        },
      };
      await setCompanyGroupSetting(finalData as CompanyGroupSetting);
      getData();
    };

    const AttendanceRules = renderAttendanceRules({
      updateAttendanceRule: updateAttendanceRuleForCategories,
      updateAttendanceRule2,
      useCombinedEmployeeCategory,
      useCombinedEmployeeDesignation,
    });

    const SalaryRules = renderSalaryRules();

    const LeaveRules = renderLeaveRules({ useCombinedEmployeeCategory });

    type Configs = {
      settingName: string;
      settingValue?: string;
      editAction: () => JSX.Element;
    };

    const configs: Configs[] = [
      {
        settingName: 'Attendance Devices',
        settingValue:
          attendanceDevices?.map((ad) => ad.name).join() ||
          ((<Recat className='h-5 inline animate-pulse mx-4' />) as any),
        editAction: () => <></>,
      },
    ];

    const Card = renderCardComponent();

    return (
      <div className='w-full'>
        <Card
          header={{ title: 'Attendance Devices' }}
          body={{
            type: 'jsx-with-behaviour',
            body: {
              content: <CGConfigRenderer configs={configs} loading={false} />,
              behaviour: 'modal',
              modal: {
                title: 'Attendance Devices',
                content: ({ onClose }) => {
                  const Form = renderAttendanceDevices({
                    mode: 'config',
                    useCombinedAttendanceDevice,
                    refreshData: getData,
                  });
                  return <Form cgsDetails={cgSetting?.details || {}} />;
                },
              },
            },
          }}
        />

        {categories && categories.length > 0 ? (
          <Card
            header={{ title: 'Attendance Rules' }}
            body={{
              type: 'jsx-component',
              body: (
                <AttendanceRules
                  comGrpSet={cgSetting}
                  categories={categories || []}
                />
              ),
            }}
          />
        ) : (
          <Recat className='h-5 inline animate-pulse mx-4' />
        )}

        <Card
          header={{ title: 'Salary Rules' }}
          body={{
            type: 'jsx-component',
            body: (
              <SalaryRules
                comGrpSet={cgSetting}
                saveSalaryRules={saveSalaryRules}
              />
            ),
          }}
        />
        <LeaveRules
          comGrpSet={cgSetting}
          saveEditLeaveRules={saveEditLeaveRules}
          saveAddLeaveRules={saveAddLeaveRules}
        />
      </div>
    );
  };
};
