import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { nanoid } from 'nanoid';
import { UseCombinedCompanyAdmin } from '../../../hooks/admin/company-admin/use-company-admin';
import { UseCombinedEmployeeCategory } from '../../../hooks/admin/constants/employee-category/use-employee-category';
import { UseCombinedLocationAdmin } from '../../../hooks/admin/location-admin/use-location';
import { UseCombinedOnboardEmployee } from '../../../hooks/admin/onboard-employee/use-onboard-employee';
import { UseCombinedDepartment } from '../../../hooks/hrd/department/use-department';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { renderEmployeeOnboardsRequest } from './employee-onboard-tab/employee-onboard-request';
import { renderEmployeeOnboards } from './employee-onboard-tab/employee-onboard-tab';

type RenderEmployeeOnboardProps = {
  hooks: {
    useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
    useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
    useCombinedDepartment: UseCombinedDepartment;
    useCombinedLocationAdmin: UseCombinedLocationAdmin;
    useCombinedOnboardEmployee: UseCombinedOnboardEmployee;
    useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
  };
};

export function renderEmployeeOnboard({
  hooks: {
    useCombinedEmployeeProfile,
    useCombinedCompanyAdmin,
    useCombinedDepartment,
    useCombinedLocationAdmin,
    useCombinedOnboardEmployee,
    useCombinedEmployeeCategory,
  },
}: RenderEmployeeOnboardProps): () => JSX.Element {
  return function EmployeeExit(): JSX.Element {
    const Tabs = renderTabsComponent();

    const EmployeeOnboardDate = renderEmployeeOnboards({
      useCombinedEmployeeProfile,
      useCombinedCompanyAdmin,
      useCombinedDepartment,
      useCombinedLocationAdmin,
      useCombinedEmployeeCategory,
      useCombinedOnboardEmployee,
    });

    const EmployeeOnboardingRequests = renderEmployeeOnboardsRequest({
      useCombinedEmployeeProfile,
      useCombinedOnboardEmployee,
      useCombinedDepartment,
      useCombinedLocationAdmin,
      useCombinedEmployeeCategory,
      useCombinedCompanyAdmin,
    });

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      ['Employee Onboard', EmployeeOnboardDate],
      ['Onboarding Requests', EmployeeOnboardingRequests],
    ]);

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Employee Onboard',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
