/* eslint-disable react-hooks/exhaustive-deps */
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  Card,
  ColumnizedContent,
  FormDataType,
  FormField,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UsePublicTransfer } from '../../../../hooks/file-transfer/use-public-transfer';
import { createVendorCard } from './card/profile-card';

type CreateItemCardType = {
  usePublicTransfer: UsePublicTransfer;
  setVendor: (s: Vendor) => Promise<Vendor>;
  vendor: Vendor;
  loading: boolean;
};

export function renderVendorProfile({
  usePublicTransfer,
  setVendor,
  vendor,
  loading,
}: CreateItemCardType): () => JSX.Element {
  return function VendorProfile(): JSX.Element {
    const vendorProfileInitialData: Card = {
      header: {
        title: 'Vendor Profile',
        actions: [
          {
            type: 'button',
            button: {
              name: 'Edit',
              behaviour: 'modal',
              modal: {
                title: 'Edit Vendor',
                content: ({ onClose }) => {
                  const Form = renderFormV2<any>({
                    style: 'md:w-1/2',
                    fieldsData: getFields(),
                    initialFormState: { ...vendor.details?.partyProfile },
                    onSubmit: async (data) => {
                      await setVendor({
                        id: vendor.id,
                        details: { partyProfile: data },
                      } as Vendor);
                      onClose();
                    },
                    mapTToU: (b: FormDataType<any>) => b,
                  });

                  return <Form />;
                },
              },
            },
          },
        ],
      },
      body: {
        type: 'columns',
        body: [],
      },
    };
    const initialVendorData = {
      profileData: vendorProfileInitialData,
    };
    const ProfileCard = createVendorCard({
      initialData: initialVendorData.profileData,
      mapperFunction: mapVendorProfileBody,
      vendor,
      loading,
    });
    return (
      <div className='w-full space-y-3'>
        <ProfileCard />
      </div>
    );
  };
}

const mapVendorProfileBody = (data: Vendor): ColumnizedContent => [
  [
    {
      key: 'Name:',
      value: data?.name || '-',
      type: 'text',
    },
    // {
    //   key: 'Approval Status:',
    //   value: data?.details?.partyProfile?.approvalStatus || 'pending',
    //   type: 'text',
    // },
    {
      key: 'Vendor-Type:',
      value: data?.details?.partyProfile?.vendorType || 'manufacturer',
      type: 'text',
    },
    {
      key: 'Website:',
      value: '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Currency:',
      value: data?.details?.partyProfile?.currency || 'INR',
      type: 'text',
    },
    {
      key: 'Contact',
      value: (data?.details?.partyProfile as any)?.phone || '',
    },
    {
      key: 'Email:',
      value: data?.details?.email || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Logo',
      value: data?.details?.partyProfile?.logo || '',
      type: 'image',
    },
  ],
];

function getFields(): FormField<any>[] {
  return [
    { property: 'logo', type: 'file' },
    { property: 'phone', type: 'input' },
    { property: 'email', type: 'email' },
    // {
    //   property: 'approvalStatus',
    //   type: 'select',
    //   options: [
    //     { text: 'approved', value: 'approved' },
    //     { text: 'pending', value: 'pending' },
    //   ],
    // },
    {
      property: 'currency',
      type: 'select',
      options: [
        { text: 'INR', value: 'INR' },
        { text: 'USD', value: 'USD' },
      ],
    },
    {
      property: 'vendorType',
      type: 'select',
      options: [
        { text: 'manufacturer', value: 'manufacturer' },
        { text: 'trader', value: 'trader' },
        { text: 'service-provider', value: 'service-provider' },
      ],
    },
  ];
}
