import { ChangeAssesmentType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type AckApproveRejectFormType = {
  status: string;
  comments: string;
  changeControlFormNo: string;
};

// eslint-disable-next-line
export function renderAckApproveRejectForm() {
  return function AckApproveRejectForm({
    onSave,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const formProps: FormProps<AckApproveRejectFormType> = {
      fieldsData: [
        {
          property: 'status',
          type: 'select',
          label: 'Status',
          options: [
            { value: 'select', text: 'select' },
            { value: 'approved', text: 'approved' },
            { value: 'rejected', text: 'rejected' },
          ],
        },
        {
          property: 'comments',
          type: 'text-area',
          label: 'Comments',
          rows: 3,
        },
        {
          property: 'changeControlFormNo',
          type: 'input',
          label: 'Change Control Form No',
          dependentOn: (formData) => {
            return (
              (data?.details.typeOfChange === 'permanent' ||
                data?.details.typeOfChange === 'temporary') &&
              formData.status === 'approved'
            );
          },
        },
      ],
      initialFormState: {
        status: data?.status || '',
        comments: data?.details.ackComment || '',
        changeControlFormNo: data?.details.changeControlFormNo || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        await onSave(({
          details: {
            ...data?.details,
            changeControlFormNo:
              f.changeControlFormNo || data?.details?.changeControlFormNo,
            commentsOfQA: f.comments || data?.details?.commentsOfQA,
          },
          status: f.status === 'approved' ? 'ccf-accepted' : 'ccf-rejected',
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<AckApproveRejectFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<AckApproveRejectFormType>
    ): AckApproveRejectFormType {
      return (b as unknown) as AckApproveRejectFormType;
    }
  };
}
