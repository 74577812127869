/* eslint-disable react-hooks/exhaustive-deps */
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/user';

export const renderAuthWrapper = () => {
  return function AuthWrapper({
    children,
    action,
    showUnAuthorizedWarning,
    customAuthorization,
  }: {
    children: JSX.Element;
    action: string;
    showUnAuthorizedWarning?: boolean;
    customAuthorization?: JSX.Element;
  }): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const [allowedActions, setAllowedActions] = useState<any>();

    const isActionValid = () => {
      if (!allowedActions) return false;
      if (!allowedActions.hasOwnProperty(action)) return false;
      if (!allowedActions[action].action) return false;
      return true;
    };

    useEffect(() => {
      if (currentUser.authorization?.actions) {
        setAllowedActions(currentUser?.authorization?.actions);
      }
    }, [currentUser]);
    if (!isActionValid() && showUnAuthorizedWarning) {
      return (
        <div>
          <LockClosedIcon className='inline text-red-700 w-4' /> You are not
          authorized!
        </div>
      );
    }

    if (!isActionValid() && customAuthorization) {
      return customAuthorization;
    }

    if (!isActionValid()) return <></>;
    return children;
  };
};
