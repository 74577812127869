import {
  AccountGroup,
  AccountGroupFilterType,
  Daybook,
  DaybookFilterType,
  Ledger,
  LedgerFilterType,
  VouchersFilterType,
  VouchersType,
  VoucherTransactionsFilterType,
  VoucherTransactionsType,
} from '@erp_core/erp-types/dist/modules/accounts';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { HttpVouchersRepo } from '../datalayer/repository/http/accounts/vouchers/vouchers';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { DaybookInterface } from '../models/interfaces/accounts/daybook';
import { AccountGroupInterface } from '../models/interfaces/accounts/tally/account-group';
import { LedgerInterface } from '../models/interfaces/accounts/tally/ledger';
import { VoucherTransactionsInterface } from '../models/interfaces/accounts/vouchers/voucher-transactions';
import { VouchersInterface } from '../models/interfaces/accounts/vouchers/vouchers';
import { erpV2Api } from './admin';
import { ACCOUNTS_SERVER_URI } from './server-uri';

export const daybookService = new GenericHttpWithLogsRepo<
  Daybook,
  DaybookFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ACCOUNTS_SERVER_URI,
  resourceEndpoint: '/api/v1/transaction',
});

export const useCombinedDaybook = createUseCombinedResource<
  Daybook,
  DaybookFilterType,
  DaybookInterface
>({
  useService: () => daybookService,
});

export const accountGroupService = new GenericHttpWithLogsRepo<
  AccountGroup,
  AccountGroupFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ACCOUNTS_SERVER_URI,
  resourceEndpoint: '/api/v1/groups',
});

export const useCombinedAccountGroup = createUseCombinedResource<
  AccountGroup,
  AccountGroupFilterType,
  AccountGroupInterface
>({
  useService: () => accountGroupService,
});

export const ledgerService = new GenericHttpWithLogsRepo<
  Ledger,
  LedgerFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ACCOUNTS_SERVER_URI,
  resourceEndpoint: '/api/v1/ledgers',
});

export const useCombinedLedger = createUseCombinedResource<
  Ledger,
  LedgerFilterType,
  LedgerInterface
>({ useService: () => ledgerService });

export const voucherService = new HttpVouchersRepo(
  ACCOUNTS_SERVER_URI,
  erpV2Api
);

export const useCombinedVoucher = createUseCombinedResource<
  VouchersType,
  VouchersFilterType,
  VouchersInterface
>({ useService: () => voucherService });

export const voucherTransactionService = new GenericHttpWithLogsRepo<
  VoucherTransactionsType,
  VoucherTransactionsFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: ACCOUNTS_SERVER_URI,
  resourceEndpoint: '/api/v1/voucher-transactions',
});

export const useCombinedVoucherTransaction = createUseCombinedResource<
  VoucherTransactionsType,
  VoucherTransactionsFilterType,
  VoucherTransactionsInterface
>({ useService: () => voucherTransactionService });
