import { TopicType } from '@erp_core/erp-types/dist/modules/utility';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

// eslint-disable-next-line
export function renderAdddTopicForm() {
  return function AddTopicForm({
    onSave,
    onClose,
  }: {
    onSave: any;
    onClose: () => any;
  }): JSX.Element {
    const formProps: FormProps<Partial<TopicType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Topic Name',
        },
        {
          property: 'description',
          type: 'input',
          required: true,
          label: 'Topic Description',
        },
      ],
      initialFormState: {
        name: '',
        description: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (data) => {
        onSave(data);
        onClose();
      },
    };

    const Form = renderFormV2<Partial<TopicType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<TopicType>>): TopicType {
      return {
        name: b.name as string,
        description: b.description as string,
      } as TopicType;
    }
  };
}
