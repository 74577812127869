import { ExcelDownload } from '@erp_core/erp-icons/icons/excel-download';
import {
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import { AttendanceInterface } from '../../../../../models/interfaces/hrd/attendance';

export function FinalizationDetailsReport({
  attendanceService,
  month,
}: {
  attendanceService: AttendanceInterface;
  month: string;
}): JSX.Element {
  const FinalizationReport = renderBulkDownload({
    mapperFun: async () => {
      const {
        data,
        excelMapper,
        fileName,
      } = await attendanceService.getSummary(month);

      const result: {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[] = [];

      if (data.length !== 0) {
        result.push({ mapper: excelMapper, data, fileName });
      }
      return result;
    },
    downloadIcon: ExcelDownload,
  });

  return (
    <div className='mx-auto'>
      <div className='flex justify-center'>
        <span className='flex border border-green-500 rounded p-1'>
          <FinalizationReport /> Download Finalization Report
        </span>
      </div>
    </div>
  );
}
