import {
  LoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { utils, writeFileXLSX } from 'xlsx';
import { getCategories } from '../utils/category-sorter';
import { formatParam } from '../utils/generate-salary';
import { SRType } from './create-statutory-reports';

export type DetailsProps = {
  srList: SRType;
};

export function renderSalaryDetailsOneLineReport({
  companyGroupId,
}: {
  companyGroupId: string;
}): (p: DetailsProps) => JSX.Element {
  const Table = renderTableComponent();
  return function SalaryDetailsOneLineReport({
    srList,
  }: DetailsProps): JSX.Element {
    const categories: Array<{ name: string; list: SRType }> = getCategories({
      srList,
    });

    function calculateBody(c: { name: string; list: SRType }): TableBody {
      const body: TableBody = [];
      c.list.data.forEach((x) => {
        const sal = x.salary;
        const emp = x.employee;

        body.push({
          cells:
            companyGroupId === 'A49079C2-8852-471B-A86E-FCD70D53DBDE'
              ? [
                  { value: emp.details?.employeeId },
                  { value: emp.name },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'other.adjustedPayableDays'
                    ),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'earning.basicDa'),
                  },
                  { value: _.get(sal?.details?.salaryParams, 'earning.lta') },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.washAllowance'
                    ),
                  },
                  {
                    value: formatParam({
                      value:
                        sal?.details?.salaryParams?.earning
                          ?.conveyanceAllowance,
                      metric: 'number',
                      scale: 2,
                      plain: true,
                    }),

                    // _.get(
                    //   sal?.details?.salaryParams,
                    //   'earning.conveyanceAllowance'
                    // ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.medicalAllowance'
                    ),
                  },
                  { value: _.get(sal?.details?.salaryParams, 'earning.hra') },
                  { value: _.get(sal?.details?.salaryParams, 'earning.npa') },
                  { value: _.get(sal?.details?.salaryParams, 'earning.skill') },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.education'
                    ),
                  },
                  { value: _.get(sal?.details?.salaryParams, 'earning.other') },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.overtime'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.managementIncentive'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.performanceBonus'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.attendanceBonus'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.exGratiaMonthly'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.adhocEarningsVal'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.grossEarnings'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.employeeTakeHome'
                    ),
                  },

                  // Employee Deductions

                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.employeePf'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.employeeEsic'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.employeePt'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.employeeMlwf'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.salaryAdvanceTaken'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.adjustedAdvances'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.insurancePremiumDeductions'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.loanEmiDeductions'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.loanInterestEmiDeductions'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.incomeTaxDeduction'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.adhocDeductionsVal'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.otherDeductions'
                    ),
                  },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.totalEmployeeDeductions'
                    ),
                  },
                  { value: '' },

                  // Employer Deductions
                  {
                    value: _.get(sal?.details?.salaryParams, 'deduction.pf'),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'deduction.pfa'),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'deduction.edli'),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'deduction.esic'),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'deduction.mlwf'),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.employeesCompensation'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.exGratiaQuarterly'
                    ),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'earning.bonus'),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.indirectExpenses'
                    ),
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  {
                    value:
                      _.get(
                        sal?.details?.salaryParams,
                        'deduction.totalStatutoryDeductions'
                      ) +
                      _.get(
                        sal?.details?.salaryParams,
                        'earning.exGratiaQuarterly'
                      ) +
                      _.get(sal?.details?.salaryParams, 'earning.bonus') +
                      _.get(
                        sal?.details?.salaryParams,
                        'deduction.indirectExpenses'
                      ),
                  },
                  { value: '' },
                ]
              : [
                  { value: emp.details?.employeeId },
                  { value: emp.name },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'other.payableRatio'
                    )?.toFixed(2),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'earning.basicDa'),
                  },
                  { value: _.get(sal?.details?.salaryParams, 'earning.bonus') },
                  // {
                  //   value: _.get(sal?.details?.salaryParams, 'other.overtime'),
                  // },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.performanceBonus'
                    ),
                  },
                  // {
                  //   value: _.get(
                  //     sal?.details?.salaryParams,
                  //     'earning.managementIncentive'
                  //   ),
                  // },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.grossEarnings'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'earning.takeHome'
                    ),
                  },

                  // Employee Deductions

                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.pfEmployee'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.pfEmployer'
                    ),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'deduction.pfa'),
                  },
                  {
                    value: _.get(sal?.details?.salaryParams, 'deduction.edli'),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.ptEmployee'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.advance1'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.advance2'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.loanPrincipalAmount'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.loanInterestAmount'
                    ),
                  },
                  {
                    value: _.get(
                      sal?.details?.salaryParams,
                      'deduction.adhocDeductionsVal'
                    ),
                  },
                ],
        });
      });

      return body;
    }
    return (
      <div id='employee-details-report'>
        <div className='flex flex-row-reverse'>
          <LoadingButton
            defaultStyle='bg-green-500 text-white p-1 border rounded-lg'
            behaviorFn={async () => {
              categories.forEach((c) => {
                const elt = document.getElementById(`${c.name}-sal-sheet`);
                const workbook = utils.table_to_book(elt);
                var ws = workbook.Sheets['Sheet1'];
                utils.sheet_add_aoa(ws, [], { origin: -1 });
                writeFileXLSX(workbook, `${c.name}.xlsx`);
              });
            }}
            text={
              <>
                <ArrowDownTrayIcon className='w-5 inline' /> Download
              </>
            }
          />
        </div>

        {companyGroupId === 'A49079C2-8852-471B-A86E-FCD70D53DBDE'
          ? categories.map((c, idx) => {
              const add = _.first(c.list.data)?.company?.details?.address;
              const companyAddress = `${add?.addressLine1 || '-'}, ${
                add?.addressLine2 || '-'
              }, ${add?.city || '-'}, ${add?.district} - ${add?.pincode}, ${
                add?.state
              }`;
              const salMonth = _.first(c.list.data)?.salary?.month || '';
              return (
                <div key={idx} id={`${c.name}-sal-sheet`}>
                  <Table
                    header={[
                      [
                        {
                          name: _.first(c.list.data)?.company.name || '',
                          colSpan: 21,
                          style: 'text-center',
                        },
                      ],
                      [
                        {
                          name: companyAddress,
                          colSpan: 21,
                          style: 'text-center',
                        },
                      ],
                      [
                        {
                          name: `Salary Sheet for ${c.name}`,
                          colSpan: 21,
                          style: 'text-center',
                        },
                      ],
                      [
                        { name: `Month: ${salMonth}`, colSpan: 11 },
                        {
                          name: `No of entries ${c.list.data.length}`,
                          colSpan: 10,
                        },
                      ],
                      [
                        { name: 'EmpId', rowSpan: 2 },
                        { name: 'Name', rowSpan: 2 },
                        { name: 'Payable Days', rowSpan: 2 },
                        {
                          name: 'Earning',
                          style: 'text-center',
                          colSpan: 18,
                        },
                        {
                          name: 'Employee Deductions',
                          style: 'text-center',
                          colSpan: 18,
                        },
                        {
                          name: 'Employer Deductions',
                          style: 'text-center',
                          colSpan: 18,
                        },
                      ],
                      [
                        { name: 'Basic-DA' },
                        { name: 'LTA' },
                        { name: 'Wash' },
                        { name: 'Conveyance' },
                        { name: 'Medical' },
                        { name: 'HRA' },
                        { name: 'NPA' },
                        { name: 'Skill' },
                        { name: 'Education' },
                        { name: 'Other' },
                        { name: 'Overtime' },
                        { name: 'Management Inc' },
                        { name: 'Performance Bonus' },
                        { name: 'Attendance Bonus' },
                        { name: 'Ex-Gratia (Monthly)' },
                        { name: 'Adhoc Earning' },
                        { name: 'Gross Earnings' },
                        { name: 'Net Pay' },

                        // Employee Deductions
                        { name: 'PF' },
                        { name: 'ESIC' },
                        { name: 'PT' },
                        { name: 'MLWF' },
                        { name: 'Advance' },
                        { name: 'Advance2' },
                        { name: 'Insurance Premium' },
                        { name: 'Loan EMI' },
                        { name: 'Loan Interest EMI' },
                        { name: 'Income Tax' },
                        { name: 'Adhoc deduction' },
                        { name: 'Others' },
                        { name: '' },
                        { name: '' },
                        { name: '' },
                        { name: '' },
                        { name: 'Total Deductions' },
                        { name: 'Sign' },

                        // Employer Deductions
                        { name: 'PF' },
                        { name: 'PFA' },
                        { name: 'EDLI' },
                        { name: 'ESIC' },
                        { name: 'MLWF' },
                        { name: 'Employees Compensation' },
                        { name: 'Ex-Gratia (Quarterly)' },
                        { name: 'Bonus' },
                        { name: 'Other Indirect Expenses' },
                        { name: '' },
                        { name: '' },
                        { name: '' },
                        { name: '' },
                        { name: '' },
                        { name: '' },
                        { name: '' },
                        {
                          name: 'Total Employer Deductions',
                        },
                        { name: '' },
                      ],
                    ]}
                    body={calculateBody(c)}
                  />
                </div>
              );
            })
          : categories.map((c, idx) => {
              const add = _.first(c.list.data)?.company?.details?.address;
              const companyAddress = `${add?.addressLine1 || '-'}, ${
                add?.addressLine2 || '-'
              }, ${add?.city || '-'}, ${add?.district} - ${add?.pincode}, ${
                add?.state
              }`;
              const salMonth = _.first(c.list.data)?.salary?.month || '';
              return (
                <div key={idx} id={`${c.name}-sal-sheet`}>
                  <Table
                    header={[
                      [
                        {
                          name: _.first(c.list.data)?.company.name || '',
                          colSpan: 21,
                          style: 'text-center',
                        },
                      ],
                      [
                        {
                          name: companyAddress,
                          colSpan: 21,
                          style: 'text-center',
                        },
                      ],
                      [
                        {
                          name: `Salary Sheet for ${c.name}`,
                          colSpan: 21,
                          style: 'text-center',
                        },
                      ],
                      [
                        { name: `Month: ${salMonth}`, colSpan: 11 },
                        {
                          name: `No of entries ${c.list.data.length}`,
                          colSpan: 10,
                        },
                      ],
                      [
                        { name: 'EmpId', rowSpan: 1 },
                        { name: 'Name', rowSpan: 1 },
                        { name: 'Payable Days', rowSpan: 1 },
                        { name: 'Basic-DA' },
                        { name: 'Bonus' },
                        // { name: 'Overtime' },
                        { name: 'Performance Bonus' },
                        // { name: 'Management Inc' },
                        { name: 'Gross Earnings' },
                        { name: 'Net Pay' },

                        // Employee Deductions
                        { name: 'PF-Employee' },
                        { name: 'PF-Employer' },
                        { name: 'PFA' },
                        { name: 'EDLI' },
                        { name: 'PT' },
                        { name: 'Advance1' },
                        { name: 'Advance2' },
                        { name: 'Loan EMI' },
                        { name: 'Loan Interest EMI' },
                        { name: 'Adhoc deduction' },
                        { name: 'Sign' },
                      ],
                    ]}
                    body={calculateBody(c)}
                  />
                </div>
              );
            })}
      </div>
    );
  };
}
