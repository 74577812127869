import { ExcelDownload } from '@erp_core/erp-icons/icons/excel-download';
import {
  LoadingButton,
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';

export function renderEmployeeLateByMinsReport({
  getReport,
}: {
  getReport: (
    month: string,
    reportType: 'employee-late-by-mins-report'
  ) => Promise<any>;
}): ({ month }: { month: string }) => JSX.Element {
  return function StatutoryReports({ month }: { month: string }): JSX.Element {
    const [message, setMessage] = useState<string>('');

    const reports: Array<{
      name: string;
      key: 'employee-late-by-mins-report';
      mapper?: XlsxDownloadMapper;
      type: 'excel';
    }> = [
      {
        name: 'Employee Late By Mins',
        key: 'employee-late-by-mins-report',
        type: 'excel',
      },
    ];
    return (
      <div>
        {reports.map((x) => (
          <Report
            x={x}
            getReport={getReport}
            month={month}
            setMessage={setMessage}
          />
        ))}
        <div className='text-center my-2 animate-pulse'>{message}</div>
      </div>
    );
  };
}

function Report({
  x,
  getReport,
  month,
  setMessage,
}: {
  x: {
    name: string;
    key: 'employee-late-by-mins-report';
    fileName?: string;
    mapper?: XlsxDownloadMapper;
    type: 'excel';
  };
  getReport: (
    month: string,
    reportType: 'employee-late-by-mins-report'
  ) => Promise<any>;
  month: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}) {
  if (x.type === 'excel') {
    let header:
      | {
          title: string;
          date?: string;
          subtitle?: string[];
        }
      | undefined = undefined;

    const Report = renderBulkDownload({
      name: x.name,
      mapperFun: async () => {
        const result: {
          mapper: XlsxDownloadMapper;
          data: any[];
          fileName: string;
          header?: {
            title: string;
            date?: string;
            subtitle?: string[];
          };
        }[] = [];

        const res = await getReport(month, x.key);
        console.log(res);
        if (res.success) {
          if (res.data.length === 0) {
            setMessage('Seems like there is no report to download');
            return [];
          } else {
            console.log(res.data);

            header = res.data.header;

            if (header && res.data.data?.length === 0) {
              setMessage('Seems like there is no report to download');
              return [];
            }
            // TODO: Logic to bind mapper and download Excel.
            const fileName = res.fileName || `${x.key}-${month}.xlsx`;
            if (res.excelMapper || x.mapper) {
              result.push({
                header: header,
                mapper: res.excelMapper || x.mapper,
                data: res.data.header ? res.data.data : res.data,
                fileName,
              });
            }
            return result;
          }
        }

        return [];
      },
      downloadIcon: ExcelDownload,
    });

    return (
      <div key={x.key} className='border border-gray-200 flex justify-center'>
        <Report />
      </div>
    );
  }

  return (
    <div key={x.key} className='border border-gray-200 flex justify-center'>
      <LoadingButton
        defaultStyle='border border-green-500 text-green-500 p-1 rounded-md'
        text={x.name}
        behaviourParams={{}}
        behaviorFn={async () => {
          setMessage('');
          const res = await getReport(month, x.key);
          if (res.success) {
            if (res.data.length === 0) {
              setMessage('Seems like there is no report to download');
            } else {
              console.log(res.data);
            }
          }
        }}
      />
    </div>
  );
}
