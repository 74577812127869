import {
  TemplatesFilter,
  TemplatesType,
} from '@erp_core/erp-types/dist/modules/communication';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { TemplateInterface } from '../models/interfaces/admin/communication/template';
import { erpV2Api } from './admin';
import { COMMUNICATION_SERVER_URI } from './server-uri';

export const communicationTemplateService = new GenericHttpWithLogsRepo<
  TemplatesType,
  TemplatesFilter
>({
  erpv2Api: erpV2Api,
  baseURI: COMMUNICATION_SERVER_URI,
  resourceEndpoint: '/template',
});

export const useCombinedTemplate = createUseCombinedResource<
  TemplatesType,
  TemplatesFilter,
  TemplateInterface
>({
  useService: () => communicationTemplateService,
});
