import { CheckCircle } from '@erp_core/erp-icons/icons/check-circle';
import { Recat } from '@erp_core/erp-icons/icons/recat';
import { Vendor, VendorItem } from '@erp_core/erp-types/dist/modules/order';
import { VendorItemContract } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import {
  CardBody,
  CardHeader,
  downloadPdfFile,
  FormField,
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
  useConfirm,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  DocumentArrowDownIcon,
  NewspaperIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CurrentContext } from '../../../../../contexts/current';
import { UserContext } from '../../../../../contexts/user';
import { UseCombinedDeliveryTerm } from '../../../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../../../hooks/constants/payment-term/use-payment-term';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../../hooks/file-transfer/use-public-transfer';
import { UseCombinedItem } from '../../../../../hooks/inventory/item/use-combined-item';
import { UseVendorItem } from '../../../../../hooks/inventory/purchase/vendors/use-vendor-item';
import { UseCombinedStockGroup } from '../../../../../hooks/inventory/stockgroup/use-stockgroup';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { CategoryIcons } from '../../../../inventory/items/item-categories';
import { renderItemApprovalForm } from '../forms/vendor-items/item-approval-form';
import { renderAddVendorItemContractForm } from './forms/add-contract-form';

// eslint-disable-next-line
export function renderVendorItemsList({
  vendor,
  loading,
  setVendor,
  useVendorItem,
  useCombinedPaymentTerm,
  usePublicTransfer,
  useFileTransfer,
  useCombinedItem,
  useCombinedStockGroup,
  useCombinedDeliveryTerm,
  itemService,
}: {
  useCombinedItem: UseCombinedItem;
  useVendorItem: UseVendorItem;
  usePublicTransfer: UsePublicTransfer;
  useFileTransfer: UseFileTransfer;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedStockGroup: UseCombinedStockGroup;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
  vendor: Vendor;
  loading: boolean;
  setVendor: (s: Vendor) => Promise<Vendor>;
  itemService: ItemInterface;
}): () => JSX.Element {
  return function VendorItemsList(): JSX.Element {
    const { company, companyGroup: currentCompanyGroup } = useContext(
      CurrentContext
    );
    const { user: currentUser } = useContext(UserContext);
    const { Confirm, openConfirm } = useConfirm();
    const { deleteVendorItem } = useVendorItem();

    const { get: getPublicFile } = usePublicTransfer();
    const { get: getPrivateFile } = useFileTransfer();

    const navigate = useNavigate();

    type AddItemForm = {
      item: { id: string; name: string };
      manufacturer: string;
      supplierLocation: string;
      contactPerson: string;
    };

    const saveVendorItemContract = async (
      vendorItemId: string,
      vendorItemContract: VendorItemContract
    ) => {
      const save: Vendor = {
        id: vendor.id,
        items: vendor.items.map((i) => {
          if (i.id === vendorItemId) {
            return {
              ...i,
              details: {
                contract: vendorItemContract,
              },
            };
          }
          return i;
        }),
      } as Vendor;
      await setVendor(save);
    };

    const addItem = async (form: AddItemForm) => {
      const existingItems = vendor.items
        ? vendor.items.map((v) => ({
            id: v.id,
            vendor: { id: v.vendor.id },
            item: { id: v.item.id },
            details: v.details,
          }))
        : [];

      if (
        existingItems.find(
          (i) =>
            i.item.id === form.item.id &&
            i.details.contactPerson.id === form.contactPerson &&
            i.details.supplierLocation.id === form.supplierLocation
        )
      ) {
        toast(
          `Item ${form.item.name} with same contact person and supplier already exists`
        );
        return;
      }

      const finalData = {
        id: vendor.id,
        items: [
          ...existingItems,
          {
            item: { id: form.item.id },
            vendor: { id: vendor.id },
            approved: false,
            details: {
              manufacturer: {
                id: '',
                name: form.manufacturer,
              },
              supplierLocation: {
                id: form.supplierLocation,
                name:
                  vendor.details?.locations?.find((s) => {
                    return (
                      s.id === form.supplierLocation ||
                      s.name === form.supplierLocation
                    );
                  })?.name || '',
              },
              contactPerson: {
                id: form.contactPerson,
                name:
                  vendor.details?.contact?.find((s) => {
                    return (
                      s.id === form.contactPerson ||
                      s.name === form.contactPerson
                    );
                  })?.name || '',
              },
            },
          },
        ],
      };
      setVendor(finalData as Vendor);
    };

    const updateApprovalStatus = (
      itemId: string,
      type: 'approve' | 'unapprove'
    ) => {
      const items = vendor.items.map((i) => {
        if (i.item.id === itemId) {
          return {
            ...i,
            approved: type === 'approve' ? true : false,
          };
        }
        return i;
      });
      const finalData = {
        id: vendor.id,
        items,
      };

      if (type === 'unapprove') {
        openConfirm({
          message: 'Are you sure to mark un approved this item?',
          onConfirm: async () => {
            await setVendor(finalData as Vendor);
            setVendor(finalData as Vendor);
          },
        });
        return;
      }
    };

    const handleItemApproval = (
      form: any,
      {
        itemSpecPath,
        siteDocsPath,
        vqPath,
        qcPath,
        performanceReportPath,
      }: {
        itemSpecPath: string;
        siteDocsPath: string;
        vqPath: string;
        qcPath: string;
        performanceReportPath: string;
      },
      itemId: string
    ) => {
      const { remarks, ...specs } = form;

      const items = vendor.items.map((i) => {
        if (i.item.id === itemId) {
          return {
            ...i,
            approved: true,
            details: {
              ...i.details,
              approvalRemarks: form.remarks,
              itemSpecifications: specs,
              approvalDocuments: [
                {
                  name: 'Item Spec',
                  path: itemSpecPath,
                },
                {
                  name: 'Site Doc',
                  path: siteDocsPath,
                },
                {
                  name: 'VQ',
                  path: vqPath,
                },
                {
                  name: 'QC',
                  path: qcPath,
                },
                {
                  name: 'Performance Report',
                  path: performanceReportPath,
                },
                ...(i.details?.approvalDocuments || []),
              ],
            },
          };
        }
        return i;
      });
      const finalData = {
        id: vendor.id,
        items,
      };
      setVendor(finalData as Vendor);
    };

    const addSpecifications = async (
      itemId: string,
      data: { specifications: string }
    ) => {
      const items = vendor.items.map((i) => {
        if (i.item.id === itemId) {
          return {
            ...i,
            details: {
              ...i.details,
              specifications: i.details?.specifications
                ? [
                    ...i.details.specifications,
                    {
                      addedOn: moment().format('DD-MM-YYYY'),
                      link: data.specifications,
                    },
                  ]
                : [
                    {
                      addedOn: moment().format('DD-MM-YYYY'),
                      link: data.specifications,
                    },
                  ],
            },
          };
        }
        return i;
      });
      const finalData = {
        id: vendor.id,
        items,
      };
      setVendor((finalData as unknown) as Vendor);
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Items',
      actions: [
        {
          type: 'button',
          auth: 'UI:BTN-ADD-VENDOR-ITEM:VIEW',
          button: {
            behaviour: 'modal',
            name: 'Add Item',
            modal: {
              title: 'Add Item',
              content: ({ onClose }) => {
                const initialOptions = [{ value: '', text: 'Select' }];

                const fields: Array<FormField<AddItemForm>> = [
                  {
                    property: 'item',
                    type: 'searchable-select',
                    required: true,
                    searchOptions: {
                      useSearch: useCombinedItem,
                      onSearchValueSelect: () => {},
                      searchOptionsBody: {
                        customBody(data) {
                          return (
                            <div>
                              {data.categories?.map((x) => (
                                <CategoryIcons
                                  category={x.category}
                                  key={x.id}
                                />
                              ))}
                              {data?.name}
                            </div>
                          );
                        },
                      },
                      filter: {},
                    },
                  },
                  {
                    property: 'manufacturer',
                    type: 'input',
                    required: true,
                    readonly:
                      vendor.details?.partyProfile?.vendorType ===
                      'manufacturer',
                  },
                  {
                    property: 'supplierLocation',
                    type: 'select',
                    options: [
                      ...initialOptions,
                      ...(vendor.details?.locations?.map((m) => ({
                        value: m.id || m.name,
                        text: m.name,
                      })) || []),
                    ],
                    required: true,
                  },
                  {
                    property: 'contactPerson',
                    type: 'select',
                    options: [
                      ...initialOptions,
                      ...(vendor.details?.contact?.map((m) => ({
                        value: m.id || m.name,
                        text: m.name,
                      })) || []),
                    ],
                    required: true,
                  },
                ];
                const Form = renderFormV2<AddItemForm>({
                  style: 'md:w-1/2',
                  fieldsData: fields,
                  initialFormState: {
                    item: { id: '', name: '' },
                    manufacturer:
                      vendor.details?.partyProfile?.vendorType ===
                      'manufacturer'
                        ? 'self'
                        : '',
                    supplierLocation: '',
                    contactPerson: '',
                  },
                  onSubmit: async (data) => {
                    await addItem(data);
                    onClose();
                  },
                  allowUnchanged: true,
                  mapTToU: (b) => b as AddItemForm,
                });

                return <Form />;
              },
            },
          },
        },
      ],
    };

    const downloadSpecifications = async (link: string, index: number) => {
      const path = link.split(`${company.id}/`);
      const res = await getPublicFile(`${company.id}/${path[1]}`, true);
      downloadPdfFile({
        fileName: `specifications-${vendor.id}-(${index + 1}).pdf`,
        result: res,
      });
    };

    const downloadDocs = async (path, fileName) => {
      const res = await getPrivateFile(`${path}.pdf`, true);
      downloadPdfFile({
        fileName: `${fileName}.pdf`,
        result: res,
      });
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Item Name' },
        { name: 'Supplier Location' },
        { name: 'Contact Person' },
        { name: 'Specifications' },
        { name: 'Approval Documents' },
      ],
    ];

    const VendorItemSpecificationsAction = ({ item }: { item: VendorItem }) => {
      return (
        <div className='flex flex-col-reverse'>
          {item?.details?.specifications?.map((b, index) => {
            return (
              <div key={index} className='mb-2 '>
                <LoadingButton
                  defaultStyle='p-2 hover:bg-blue-200 border rounded-lg mr-3 mb-2 '
                  behaviorFn={() => downloadSpecifications(b.link, index)}
                  text={<ArrowDownTrayIcon className='h-6 w-6' />}
                />
                Specifications {index + 1} added on {b.addedOn}
              </div>
            );
          })}
        </div>
      );
    };

    const VendorItemDocuments = ({ item }: { item: VendorItem }) => {
      return (
        <div>
          {item.details?.approvalDocuments?.map((d, index) => {
            return (
              <div
                key={index}
                className='cursor-pointer'
                onClick={() => downloadDocs(d.path, `${d.name}-${item.id}`)}
              >
                <DocumentArrowDownIcon className='inline mx-1 w-5 text-red-500' />{' '}
                {d.name}
              </div>
            );
          })}
        </div>
      );
    };

    const HighlightContract = ({ validity }) => {
      const today = moment().utc().format('YYYY-MM-DD');
      if (today > validity)
        return (
          <NewspaperIcon
            title='Contract is expired.'
            className='text-red-600 inline-block w-5 h-5'
          />
        );
      if (today < validity)
        return (
          <NewspaperIcon
            title='Contract is available.'
            className='text-blue-600 inline-block w-5 h-5'
          />
        );
      return (
        <NewspaperIcon
          title='Contract will expire today.'
          className='text-yellow-600 inline-block w-5 h-5'
        />
      );
    };

    const tableBody: TableBody = vendor.items?.length
      ? vendor.items.map((i: VendorItem) => ({
          rowData: {
            vendorItem: i,
          },
          cells: [
            {
              value: (
                <div>
                  {i.item.name}{' '}
                  {i.details.contract?.validity ? (
                    <HighlightContract validity={i.details.contract.validity} />
                  ) : null}{' '}
                  {i.approved ? (
                    <CheckCircle className='inline w-3 text-green-600' />
                  ) : null}
                </div>
              ),
              link: `/inventory/masters/items/${i.item.id}/properties`,
            },
            {
              value: `${i.details?.supplierLocation?.name || '--'}`,
            },
            {
              value: `${i.details?.contactPerson?.name || '--'}`,
            },
            {
              value: (
                <Popover className='relative'>
                  {({ open }) => (
                    <>
                      <Popover.Button>
                        <ArrowDownTrayIcon className='h-6 w-6' />{' '}
                      </Popover.Button>
                      <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                        <VendorItemSpecificationsAction item={i} />
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              ),
            },
            {
              value:
                i.details.approvalDocuments?.length > 0 ? (
                  <Popover className='relative'>
                    {({ open }) => (
                      <>
                        <Popover.Button>
                          <ArrowDownTrayIcon className='h-6 w-6' />{' '}
                        </Popover.Button>
                        <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                          <VendorItemDocuments item={i} />
                        </Popover.Panel>
                      </>
                    )}
                  </Popover>
                ) : (
                  <div>No Documents available</div>
                ),
            },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              auth={currentUser.authorization}
              actions={[
                // {
                //   name: 'Edit Item',
                //   show: () => true,
                //   auth: 'UI:BTN-ADD-VENDOR-ITEM:VIEW',
                //   modalTitle: 'Edit Item',
                //   modalContent: ({ data: { vendorItem } }: { data: { vendorItem: VendorItem; } }) => {
                //     const initialOptions = [{ value: '', text: 'Select' }];

                //     const fields: Array<FormField<AddItemForm>> = [
                //       {
                //         property: 'item',
                //         type: 'searchable-select',
                //         required: true,
                //         searchOptions: {
                //           useSearch: useItems,
                //           onSearchValueSelect: () => {},
                //           searchOptionsBody: {
                //             customBody(data) {
                //               return (
                //                 <div>
                //                   {data.categories?.map((x) => (
                //                     <CategoryIcons category={x.category} key={x.id} />
                //                   ))}
                //                   {data?.name}
                //                 </div>
                //               );
                //             },
                //           },
                //           filter: {},
                //         },
                //       },
                //       {
                //         property: 'manufacturer',
                //         type: 'input',
                //         required: true,
                //         readonly: vendor.details?.partyProfile?.vendorType === 'manufacturer',
                //       },
                //       {
                //         property: 'supplierLocation',
                //         type: 'select',
                //         options: [
                //           ...initialOptions,
                //           ...(vendor.details?.suppliers?.map((m) => ({
                //             value: m.id || m.name,
                //             text: m.name,
                //           })) || []),
                //         ],
                //         required: true,
                //       },
                //       {
                //         property: 'contactPerson',
                //         type: 'select',
                //         options: [
                //           ...initialOptions,
                //           ...(vendor.details?.contact?.map((m) => ({
                //             value: m.id || m.name,
                //             text: m.name,
                //           })) || []),
                //         ],
                //         required: true,
                //       },
                //     ];
                //     const Form = renderFormV2<any>({
                //       style: 'md:w-1/2',
                //       fieldsData: fields,
                //       initialFormState: {
                //         id: vendorItem.id,
                //         item: vendorItem.item,
                //         manufacturer: vendorItem.details.manufacturer
                //         supplierLocation: '',
                //         contactPerson: '',
                //       },
                //       onSubmit: async (data) => {
                //         await addItem(data);
                //       },
                //       allowUnchanged: true,
                //       mapTToU: (b) => b as any,
                //     });

                //     return <Form />
                //   }
                // }
                {
                  name: 'Approve',
                  auth: 'UI:VENDOR-ITEM-APPROVAL:VIEW',
                  show: ({ vendorItem }: { vendorItem: VendorItem }) =>
                    !vendorItem.approved,
                  behaviour: 'modal',
                  modal: {
                    title: 'Approve',
                    content: ({ data: { vendorItem }, onClose }) => {
                      const ApprovalForm = renderItemApprovalForm({
                        itemId: vendorItem.id,
                        currentCompanyGroupId: currentCompanyGroup.id,
                        currentCompanyId: company.id,
                        vendorId: vendor.id,
                        useFileTransfer,
                        onSave: handleItemApproval,
                        useCombinedItem,
                        useCombinedStockGroup,
                      });

                      return <ApprovalForm />;
                    },
                  },
                },
                {
                  name: 'Un-Approve',
                  auth: 'UI:VENDOR-ITEM-APPROVAL:VIEW',
                  show: ({ vendorItem }: { vendorItem: VendorItem }) =>
                    vendorItem.approved,
                  behaviour: 'click',
                  onClick: async ({ vendorItem }) => {
                    await updateApprovalStatus(vendorItem.id, 'unapprove');
                  },
                },
                {
                  name: 'Add Specs',
                  auth: 'UI:BTN-ADD-VENDOR-ITEM-SPECS:VIEW',
                  show: ({ vendorItem }: { vendorItem: VendorItem }) =>
                    vendorItem.approved,
                  behaviour: 'modal',
                  modal: {
                    content: ({ data: { vendorItem }, onClose }) => {
                      const fields: Array<FormField<{
                        specifications: string;
                      }>> = [
                        {
                          property: 'specifications',
                          type: 'file',
                          accept: 'application/pdf',
                        },
                      ];
                      const Form = renderFormV2<any>({
                        style: 'md:w-1/2',
                        fieldsData: fields,
                        initialFormState: {
                          specifications: '',
                        },
                        onSubmit: async (data) => {
                          await addSpecifications(vendorItem.id, data);
                        },
                        mapTToU: (b) => b,
                        upload: {
                          useFileTransfer: usePublicTransfer,
                          config: [
                            {
                              name: 'specifications',
                              path: `${company.id}/${
                                vendor.id
                              }/specifications/${JSON.stringify(new Date())}`,
                              type: 'public',
                            },
                          ],
                        },
                      });

                      return <Form />;
                    },
                    title: 'Add Specs',
                  },
                },
                {
                  name: 'Add Contract',
                  auth: 'UI:BTN-ADD-VENDOR-ITEM-SPECS:VIEW', // should be UI:BTN-ADD-VENDOR-ITEM-CONTRACT:VIEW
                  show: ({ vendorItem }: { vendorItem: VendorItem }) => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Contract',
                    content: ({ data: { vendorItem }, onClose }) => {
                      const Form = renderAddVendorItemContractForm({
                        vendorItem,
                        useCombinedPaymentTerm,
                        useCombinedDeliveryTerm,
                        onSubmit: (contract) =>
                          saveVendorItemContract(vendorItem.id, contract),
                      });
                      return <Form />;
                    },
                  },
                },
                {
                  name: 'View Specs',
                  auth: 'UI:VENDOR-ITEM-APPROVAL:VIEW',
                  show: ({ vendorItem }: { vendorItem: VendorItem }) =>
                    vendorItem.approved,
                  behaviour: 'click',
                  onClick: async ({ vendorItem }) => {
                    navigate(
                      `/purchase/masters/vendor-items/${vendorItem.id}/${vendor.id}/specifications`
                    );
                  },
                },
                {
                  name: 'Delete Item',
                  auth: 'UI:DELETE-VENDOR-ITEM:VIEW',
                  show: () => true,
                  behaviour: 'confirm',
                  onConfirm: ({ vendorItem }) => {
                    return {
                      type: 'warning',
                      onConfirm: () => deleteVendorItem(vendorItem.id),
                      message: 'This action can not be reverted.',
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='w-full space-y-3'>
        <Confirm />
        {loading ? (
          <Recat className='h-5 inline animate-pulse mx-4' />
        ) : (
          <Card header={cardHeader} body={cardBody} />
        )}
      </div>
    );
  };
}
