import {
  LeadCustomerFilterType,
  LeadCustomerType,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  Filter,
  FormField,
  renderCardComponent,
  renderFormV2,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { UseCombinedUser } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedLeadCustomer } from '../../../hooks/order/sales/lead-customer/use-lead-customer';
import { UserRendererInterface } from '../../common/fragments/user';

export type RenderLeadCustomerListProps = {
  useCombinedLeadCustomer: UseCombinedLeadCustomer;
  useCombinedUser: UseCombinedUser;
  userRendererService: UserRendererInterface;
};

export function renderLeadCustomerList({
  useCombinedUser,
  useCombinedLeadCustomer,
  userRendererService,
}: RenderLeadCustomerListProps): () => JSX.Element {
  return function LeadCustomerList(): JSX.Element {
    const { syncSet: setLeadCustomer } = useCombinedLeadCustomer();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Lead Customer List',
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            name: 'Lead Customer',
            modal: {
              title: 'Lead Customer',
              content: ({ onClose }) => {
                const fields: Array<FormField<{
                  name: string;
                  description: string;
                }>> = [
                  {
                    property: 'name',
                    type: 'input',
                    required: true,
                    label: 'Lead Customer name',
                  },
                ];
                const Form = renderFormV2<any>({
                  style: 'md:w-1/2',
                  fieldsData: fields,
                  initialFormState: {
                    name: '',
                  },
                  onSubmit: async (data) => {
                    await setLeadCustomer({
                      name: data.name,
                      details: {
                        createdAs: 'lead',
                      },
                    } as LeadCustomerType);
                    onClose();
                  },
                  mapTToU: (b) => b,
                });

                return <Form />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableWithMapperComponent<
      LeadCustomerType,
      LeadCustomerFilterType
    >();
    const tableHeader: TableHeader = [
      [
        {
          name: (
            <>
              <UserIcon className='w-5 h-5 inline mr-1' />
              Lead Customer Name
            </>
          ),
        },
        // {
        //   name: 'Transformed as Client',
        //
        //
        // },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (r: LeadCustomerType) => {
      let cells: Array<TableCell> = [];

      if (r?.details?.createdAs === 'lead' && !r?.details?.convertedToClient) {
        cells = [
          {
            value: r?.name,
            link: `/sales/masters/lead-customers/${r.id}/profile`,
          },
          //  { value: r?.details?.convertedToClient || '-' },
          {
            value: (
              <userRendererService.userCard
                size='small'
                link={true}
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                size='small'
                link={true}
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
        ];
      }

      return {
        cells,
      };
    };

    const filter: Filter<LeadCustomerFilterType> = {
      version: 'v2',
      sortFields: [
        {
          key: 'LeadCustomerName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: LeadCustomerFilterType) => {
        const filterData: LeadCustomerFilterType = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        return filterData as LeadCustomerFilterType;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                defaultPageSize: 25,
                type: 'paginated',
                usePaginatedResources: useCombinedLeadCustomer,
              }}
              filter={filter}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
