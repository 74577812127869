import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { ChangeAssesmentType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedDepartment } from '../../../../hooks/hrd/department/use-department';
// import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';

export type SelectionCrossFunctionalTeamType = {
  depts: Array<{ id: string; name: string }>;
  expert: UserIdName;
};

type RenderSelectionCrossFunctionalTeamForm = {
  useCombinedDepartment: UseCombinedDepartment;
  useCombinedUser: UseCombinedUser;
};

export function renderSelectionCrossFunctionalTeamForm({
  useCombinedDepartment,
  useCombinedUser,
}: RenderSelectionCrossFunctionalTeamForm) {
  // eslint-disable-next-line
  const { list: departments, getAll: getDepartments } = useCombinedDepartment();

  // eslint-disable-next-line
  useEffect(() => {
    getDepartments();
    // eslint-disable-next-line
  }, []);

  return function AddSelectionCrossFunctionalTeamForm({
    onSave,
    isEdit,
    data,
    dept,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
    dept: string;
  }): JSX.Element {
    const formProps: FormProps<SelectionCrossFunctionalTeamType> = {
      fieldsData: [
        {
          groupName: 'Department info',
          type: 'searchable-multi-select',
          searchOptions: {
            useSearch: useCombinedDepartment,
            onSearchValueSelect: (e) => {},
          },
          label: 'Departments',
          property: 'depts',
        },
        {
          groupName:
            'Expert Selection (Any not part of above department person)',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (e) => {},
          },
          label: 'Choose Expert',
          property: 'expert',
        },
      ],
      initialFormState: {
        depts: [],
        expert: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        let hod;
        const crossFunctionalTeam = data?.details?.crossFunctionalTeam || [];
        for (let i = 0; i < f.depts.length; i++) {
          let department = f.depts[i].name;
          if (department) {
            hod = departments?.find((x) => x.name === department)?.hod;
          }

          let dptid = departments?.find((x) => x.name === department)?.id;

          crossFunctionalTeam.push({
            dept: {
              id: dptid ? dptid : '',
              name: department,
            },
            hod: {
              id: hod?.id,
              name: hod?.name,
            },
          });
        }

        await onSave(({
          details: {
            crossFunctionalTeam: crossFunctionalTeam,
            crossfunctionTeamExpert: f.expert,
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<SelectionCrossFunctionalTeamType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<SelectionCrossFunctionalTeamType>
    ): SelectionCrossFunctionalTeamType {
      return (b as unknown) as SelectionCrossFunctionalTeamType;
    }
  };
}
