/* eslint-disable no-unused-vars */
import {
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
// import { renderAddDocImplementationCompletionForm } from './add-implementation-docs';

type Props = {
  data: any;
  onSave: any;
  mode: 'add' | 'edit';
  avoidSubmitBehavior?: boolean;
};

// eslint-disable-next-line
export function renderAddImplementationCompletionForm({
  useCombinedEmployeeProfile,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}): (props: Props) => JSX.Element {
  return function AddAddImplementationCompletionForm({
    data,
    onSave,
    mode,
  }: Props): JSX.Element {
    console.log('data', data);
    const Form = renderFormV2({
      mapTToU: (f) => f,
      onSubmit: async (form) => {
        console.log('docd', form.documents);
        onSave(form);
      },
      initialFormState: {
        documents: (data.details?.implementationActivitiesDocs.documents
          ? data.details?.implementationActivitiesDocs.documents
          : []) as any,
        responsibleperson: { id: '', name: '' },
        qadocs: '',
        list: '',
      },
      fieldsData: [
        {
          property: 'responsibleperson',
          label: 'Training to responsible persons completed on:',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            onSearchValueSelect: (v) => {
              console.log(v);
            },
          },
        },
        {
          property: 'qadocs',
          label: 'Which documents are submitted to QA with CCF?',
          type: 'input',
        },

        {
          width: 'w-full',
          property: 'documents',
          type: 'jsx',
          // dependentOn: (formData) => {
          //   return formData.documents === ;
          // },
          renderObject: ({ formData, field, onSave }) => {
            console.log('property2', formData);
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                />
              </div>
            );
          },
        },
      ],
    });
    return <Form />;
  };
}

function ObjectRender({ formData, field, onSave }): JSX.Element {
  console.log('field', field);
  const [props, setProps] = useState<any>(formData[field] || []);
  console.log('props', props);
  // const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const Table = renderTableComponent();
  // const Form = renderAddDocImplementationCompletionForm();

  const cardHeader: CardHeader = {
    title: 'Documents',
    actions: [
      {
        type: 'button',
        button: {
          behaviour: 'modal',
          name: 'Add Documents',
          modal: {
            title: 'Add Documents',
            content: ({ onClose }) => {
              const Form1 = renderFormV2({
                mapTToU: (f) => f,
                button: {
                  avoidSubmitBehavior: true,
                },
                initialFormState: {
                  list: '',
                  numberOldDoc: '',
                  versionOldDoc: '',
                  numberNewDoc: '',
                  versionNewDoc: '',
                  effectiveDate: '',
                },
                style: 'w-1/4',
                fieldsData: [
                  {
                    property: 'list',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'numberOldDoc',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'versionOldDoc',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'numberNewDoc',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'versionNewDoc',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'effectiveDate',
                    type: 'date',
                    required: true,
                  },
                ],
                onSubmit: async (form) => {
                  console.log('props', props);
                  const newProps = [...props];
                  newProps.push(form);
                  setProps(newProps);
                  onClose();
                },
              });
              return <Form1 />;
            },
          },
        },
      },
    ],
  };
  const Card = renderCardComponent();
  return (
    <div>
      <div>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </span>
        <Card
          header={cardHeader}
          body={{
            type: 'columns',
            body: [],
          }}
        />
      </div>

      <Table
        header={[
          [
            { name: 'List' },
            { name: 'Number (Old document)' },
            { name: 'Version (Old document)' },
            { name: 'Number (New document)' },
            { name: 'Version (New document)' },
            { name: 'Effective Date' },
            {
              name: '',
            },
          ],
        ]}
        body={(props || []).map((x) => {
          return {
            cells: [
              { value: x.list },
              { value: x.numberOldDoc },
              { value: x.versionOldDoc },
              { value: x.numberNewDoc },
              { value: x.versionNewDoc },
              { value: x.effectiveDate },
            ],
          };
        })}
      />
    </div>
  );
}
