import { renderTableComponent } from '@erp_core/erp-ui-components';
import { formatCurrency, getPlayersToBuy, Team, Tournament } from './data';

export function Rules({ tournament }: { tournament: Tournament }) {
  const Table = renderTableComponent();
  return (
    <div className='text-xl'>
      <div className='text-center text-3xl font-bold'>
        Team Points Allocation
      </div>

      <div>
        - All teams start with budget of{' '}
        {formatCurrency(tournament.auction.teamBudget)}.
      </div>
      {tournament.teams.filter((x) => x?.purse?.type === 'custom')?.length ? (
        <div>
          - Exceptions:{' '}
          {tournament.teams
            .filter((x) => x?.purse?.type === 'custom')
            .map((x) => (
              <span className='mx-2' key={x.name}>
                {x.name} - {formatCurrency(tournament.auction.teamBudget)},
              </span>
            ))}
        </div>
      ) : null}
      <div>
        - All teams need to buy following set of players:{' '}
        {getPlayersToBuy(
          tournament.teams.find((t) => t.purse?.type === 'standard') as Team,
          tournament
        )?.map((p, pIdx) => (
          <span
            className='border rounded-md border-gray-200 px-1 mx-0.5'
            key={pIdx}
          >
            {p.category} : {p.noOfPlayers}
          </span>
        ))}
      </div>
      {tournament.teams.filter((x) => x.purse?.type === 'custom')?.length ? (
        <div>
          - Exceptions:{' '}
          {tournament.teams
            .filter((x) => x.purse?.type === 'custom')
            .map((x) => (
              <div className='mx-2' key={x.name}>
                {x.name} -{' '}
                {getPlayersToBuy(x, tournament)?.map((p, pIdx) => (
                  <span
                    className='border rounded-md border-gray-200 px-1 mx-0.5'
                    key={pIdx}
                  >
                    {p.category} : {p.noOfPlayers}
                  </span>
                ))}
                ,
              </div>
            ))}
        </div>
      ) : null}

      <div>- Following are the categories / pools of players:</div>
      <div className='bg-white text-black mx-5 mb-5'>
        <Table
          header={[
            [
              { name: 'Name' },
              { name: 'Base Value' },
              { name: 'Lockin Budget Per Player' },
              { name: 'Players to Buy' },
              { name: 'Maximum Bid Per Player' },
            ],
          ]}
          body={(tournament.auction?.categories || [])?.map((x, idx) => {
            return {
              cells: [
                { value: x.name },
                { value: formatCurrency(x.baseValue) },
                { value: formatCurrency(x.lockinBudget) },
                { value: x.players },
                { value: formatCurrency(x.maximumBid) },
              ],
            };
          })}
        />
      </div>

      <div className='text-3xl text-center mt-5 font-bold'>Auction Process</div>
      <div>- Following are Bid increment rules</div>
      <div className='bg-white text-black mx-5 mb-5'>
        <Table
          header={[
            [
              { name: 'From' },
              { name: 'To' },
              { name: 'Increment By' },
              { name: 'Bonanza Bid' },
            ],
          ]}
          body={(tournament.auction?.range || [])?.map((x, idx) => {
            return {
              rowData: { range: x, idx: idx },
              cells: [
                { value: formatCurrency(x.from) },
                { value: formatCurrency(x.to) },
                { value: formatCurrency(x.increment) },
                { value: formatCurrency(x.bonanza) },
              ],
            };
          })}
        />
      </div>

      <div>- Only owners are allowed to participate in the auction.</div>
      <div>- Bidding increments will be counted, even if made by mistake.</div>
      <div>- First raise will be base value points.</div>
      <div>
        - If a player goes unsold, the auction will be repeated after squad
        completion.
      </div>
      <div>
        - No player can be unsold twice; if this occurs, the chit system will be
        implemented.
      </div>

      <div className='text-3xl text-center mt-5 font-bold'>
        General Guidelines
      </div>
      <div>- Owners must be present 30 minutes before the auction.</div>
      <div>- No proxies or representatives allowed.</div>
    </div>
  );
}
