/* eslint-disable react-hooks/exhaustive-deps */
import { Recat } from '@erp_core/erp-icons/icons/recat';
import {
  PurchaseEnquiry,
  PurchaseOrder,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderComparision,
  renderFileViewerUploader,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedItem } from '../../../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedEnquiryQuote } from '../../../../../../hooks/inventory/purchase/enquiry-quotes/use-quote';
import { UseVirtualItemAllocate } from '../../../../../../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { UseCombinedPurchaseOrder } from '../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { renderCreatePoForm } from '../../../../shared-forms/create-po';
import { getDeliverySchedule } from '../../../../shared-forms/create-po/utils/get-delivery-schedule';
import { getPaymentSchedule } from '../../../../shared-forms/create-po/utils/get-payment-schedule';
import { getVendorBusinessTerms } from '../../../../shared-forms/create-po/utils/get-vendor-business-terms';
// import { renderAddQuoteAttachmentForm } from './forms/add-quote-attachment';
import { CurrentContext } from '../../../../../../contexts/current';
import { UserContext } from '../../../../../../contexts/user';
import { renderAddQuotesForm } from './forms/add-quotes';
import { EnquiryQuotesDetails } from './types';
import { CreatePoSaveData } from './types/create-po';
// import { getColumnHeadings } from './utils/comparison/get-column-headings';
import { UseCombinedStockGroup } from '../../../../../../hooks/inventory/stockgroup/use-stockgroup';
import { UseCombinedBom } from '../../../../../../hooks/order/work-order/bom/use-bom';
import { getColumnHeadings } from './utils/comparison/get-column-headings';
import { getCompleteData } from './utils/comparison/get-complete-data';
import { getRowHeadings } from './utils/comparison/get-row-headings';

type RenderEnquiryQuotes = {
  enquiry: PurchaseEnquiry;
  setPurchaseEnquiry: (s: PurchaseEnquiry) => Promise<PurchaseEnquiry>;
  useCombinedEnquiryQuote: UseCombinedEnquiryQuote;
  useCombinedPurchaseOrder: UseCombinedPurchaseOrder;
  useVirtualItemAllocate: UseVirtualItemAllocate;
  useFileTransfer: UseFileTransfer;
  useCombinedBom: UseCombinedBom;
  useCombinedItem: UseCombinedItem;
  useCombinedStockGroup: UseCombinedStockGroup;
};

export const renderEnquiryQuotes = ({
  enquiry,
  setPurchaseEnquiry,
  useCombinedEnquiryQuote,
  useCombinedPurchaseOrder,
  useVirtualItemAllocate,
  useFileTransfer,
  useCombinedBom,
  useCombinedItem,
  useCombinedStockGroup,
}: RenderEnquiryQuotes): (() => JSX.Element) => {
  const FileViewerUploader = renderFileViewerUploader();

  return function EnquiryQuotes() {
    const { company: currentCompany, cgSetting, companyGroup } = useContext(
      CurrentContext
    );
    const { user: currentUser } = useContext(UserContext);
    const { set: setAllocation } = useVirtualItemAllocate();
    // const { set: setPurchaseRequisition } = usePurchaseRequisition();
    const { getSync: getBom } = useCombinedBom();

    const {
      syncSet: setEnquiryQuote,
      loading: editing,
    } = useCombinedEnquiryQuote();
    const {
      resource: purchaseOrder,
      syncSet: setPurchaseOrder,
    } = useCombinedPurchaseOrder();
    const {
      getAll: getEnquiryQuotes,
      list: enquiryQuotesData,
      loading,
    } = useCombinedEnquiryQuote();

    const {
      get: getStockGroup,
      resource: stockGroup,
    } = useCombinedStockGroup();
    const { get: getItem, resource: item } = useCombinedItem();

    useEffect(() => {
      enquiry?.purchaseRequisitions &&
        enquiry?.purchaseRequisitions[0]?.grade?.id &&
        getItem(enquiry?.purchaseRequisitions[0]?.grade?.item?.id);
      // eslint-disable-next-line
    }, [enquiry]);

    useEffect(() => {
      if (item && item.stockgroup && item.stockgroup.id) {
        getStockGroup(item.stockgroup.id);
      }
      // eslint-disable-next-line
    }, [item]);
    const vendors = enquiry?.details?.vendors; // ?.map((q) => q.vendor) as any;

    const itemDetails = {
      ...((enquiry?.purchaseRequisitions &&
        enquiry?.purchaseRequisitions[0]?.itemDetails) ||
        {}),
      quantity:
        (enquiry?.purchaseRequisitions &&
          enquiry?.purchaseRequisitions[0]?.details?.quantity) ||
        0,
    };

    const handleCreatePo = async (
      data: CreatePoSaveData,
      enquiryQuote: any
    ) => {
      const finalData = {
        name: data.orderName,
        vendor: { id: enquiryQuote?.vendor.id },
        details: {
          quoteId: enquiryQuote?.id,
          attachments: [],
          itemDetails: {
            id: itemDetails.id,
            name: itemDetails.name,
            uom: enquiry.details.itemDetails?.uom || itemDetails.uom,
            grade: enquiry.details.itemDetails?.grade,
            quantity: enquiry.details.itemDetails?.quantity,
          },
          quantity: data.quantity,
          rate: enquiryQuote?.details?.quoteDetails?.[0]?.rate,
          remarks: data.remarks,
          contactPerson: enquiryQuote?.details.contactPerson,
          supplier: enquiryQuote?.details.supplierLocation,
          activity: [
            {
              action: 'po-created',
              details: '',
              user: currentUser,
              event: `${currentUser.name} created an Order`,
              time: moment.utc().format(),
            },
          ],
        },
        purchaseEnquiry: { id: enquiry.id },
      };

      // const enqActivity = {
      //   action: 'po-created',
      //   details: '',
      //   user: currentUser,
      //   event: `${currentUser.name} created an Order`,
      //   time: moment.utc().format(),
      // };
      try {
        const po = await setPurchaseOrder(
          (finalData as unknown) as PurchaseOrder
        );
        enquiryQuote?.purchaseEnquiry?.purchaseRequisitions[0]?.bomIds.map(
          async (bId) => {
            const bomData = await getBom(bId);
            setAllocation({
              id: po.id,
              referenceId: bId,
              quantity: bomData.details.rawMaterial.quantity,
              referenceType: 'bom',
              allocationComplete: true, // it will virtually allocate all the required quantity
              currentUser,
            });
          }
        );
        // setPurchaseEnquiry({
        //   id: enquiry.id,
        //   details: {
        //     ...enquiryQuote?.purchaseEnquiry.details,
        //     status: 'raised-po',
        //     orderId: po.id,
        //     selectedQuoteId: enquiryQuote?.id,
        //     activity: [
        //       enqActivity,
        //       ...(enquiryQuote?.purchaseEnquiry.details.activity || []),
        //     ],
        //   },
        // } as any);

        // const prActivity = {
        //   action: 'po-created',
        //   details: '',
        //   user: currentUser,
        //   event: `${currentUser.name} created an Order`,
        //   time: moment.utc().format(),
        // };
        // setPurchaseRequisition({
        //   id: enquiryQuote?.purchaseEnquiry.purchaseRequisitions[0].id,
        //   details: {
        //     ...enquiryQuote?.purchaseEnquiry.purchaseRequisitions[0].details,
        //     status: 'raised-po',
        //     orderId: po.id,
        //     activity: [
        //       prActivity,
        //       ...(enquiryQuote?.purchaseEnquiry.purchaseRequisitions[0].details
        //         .activity || []),
        //     ],
        //   },
        // } as any);
      } catch (error) {
        toast((error as any).message);
      }
    };

    useEffect(() => {
      getEnquiryQuotes({ purchaseEnquiryId: enquiry.id } as any);
    }, []);

    useEffect(() => {
      if (purchaseOrder?.id) {
        toast(
          <div
            className='cursor-pointer'
            onClick={() => (window.location.href = '/inventory/purchase-order')}
          >
            <div>PO created</div>
            <div>Click to view</div>
          </div>
        );
      }
    }, [purchaseOrder]);

    const handleQuotesSave = async (data: EnquiryQuotesDetails) => {
      const req = enquiryQuotesData?.find(
        (eq) => eq.vendor.id === data.vendor.id
      );
      const finalData = {
        id: req?.id,
        purchaseEnquiry: {
          id: enquiry.id,
          name: enquiry.name,
        },
        vendor: data.vendor,
        details: {
          quoteDetails: [
            {
              ...data,
              attachments: [data.attachment],
              createdAt: moment(),
            },
            ...(req?.details.quoteDetails || []),
          ],
        },
      };

      const alreadyAdded =
        req?.details?.quoteDetails?.length &&
        req?.details?.quoteDetails?.length > 0;
      const enqActivity = {
        action: alreadyAdded ? 'quote-updated' : 'quote-created',
        details: '',
        user: currentUser,
        event: `${currentUser.name} ${
          alreadyAdded ? 'updated' : 'created'
        } an quote for vendor ${req?.vendor.name}`,
        time: moment.utc().format(),
      };

      try {
        await setEnquiryQuote(finalData as any);
        setPurchaseEnquiry({
          id: enquiry.id,
          details: {
            ...req?.purchaseEnquiry.details,
            activity: [
              enqActivity,
              ...(req?.purchaseEnquiry.details.activity || []),
            ],
          },
        } as any);
        getEnquiryQuotes({ purchaseEnquiryId: enquiry.id } as any);
      } catch (error) {
        toast((error as any).message);
      }
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Vendor' },
        { name: 'Quote No.' },
        { name: 'Rate' },
        { name: 'Attachments' },
        { name: 'Remarks' },
        { name: 'Created At' },
      ],
    ];

    const tableBody: TableBody =
      enquiryQuotesData
        ?.map((item) => {
          return item.details?.quoteDetails?.map((q, index) => {
            return {
              cells: [
                {
                  value: (
                    <>
                      {item.vendor?.name}
                      {enquiry.details?.selectedVendor?.id ===
                      item.vendor?.id ? (
                        <CheckCircleIcon className='h-6 w-6 text-blue-500 animate-pulse inline' />
                      ) : null}
                    </>
                  ),
                },
                { value: q?.quoteNo || '...' },
                { value: q?.rate || '...' },
                {
                  value: (
                    <>
                      <FileViewerUploader
                        mode='upload'
                        url={q.attachments[0]}
                        useFileTransfer={useFileTransfer}
                        path={`${companyGroup?.id}/${
                          currentCompany.id
                        }/purchase/enquiries/${enquiry.id}/${
                          item.vendor.id
                        }/quotes/${moment().unix()}`}
                        type='private'
                        onUpload={async (path) => {
                          console.log(path);
                          // console.log(quoteId, index, form.attachment);
                          const req = enquiryQuotesData?.find(
                            (eq) => eq.purchaseEnquiry.id === enquiry.id
                          );
                          const finalData = {
                            id: req?.id,
                            details: {
                              ...req?.details,
                              quoteDetails: req?.details.quoteDetails.map(
                                (q, ind) => {
                                  if (index !== ind) return q;
                                  return {
                                    ...q,
                                    attachments: [path.url],
                                  };
                                }
                              ),
                            },
                          };

                          const enqActivity = {
                            action: 'attachment-added',
                            details: '',
                            user: currentUser,
                            event: `${currentUser.name} added an attacment for quote ${req?.details?.quoteDetails?.[index]?.quoteNo}`,
                            time: moment.utc().format(),
                          };

                          try {
                            await setEnquiryQuote(finalData as any);
                            setPurchaseEnquiry({
                              id: enquiry.id,
                              details: {
                                ...req?.purchaseEnquiry.details,
                                activity: [
                                  enqActivity,
                                  ...(req?.purchaseEnquiry.details.activity ||
                                    []),
                                ],
                              },
                            } as any);
                            getEnquiryQuotes({
                              purchaseEnquiryId: enquiry.id,
                            } as any);
                          } catch (error) {
                            toast((error as any).message);
                          }
                        }}
                      />
                      {/* <ArrowUpTrayIcon
                    className='h-6 w-6 cursor-pointer'
                    onClick={() =>
                      onUploadAttachmentClick(index, item.id, item.vendor.id)
                    }
                  /> */}
                    </>
                  ),
                },
                { value: q?.remarks || '...' },
                {
                  value:
                    moment(q?.createdAt).format('DD-MM-YYYY, h:mm A') || '...',
                },
              ],
              rowData: {
                enquiryQuote: item,
              },
            };
          });
        })
        .flat()
        .filter(Boolean) || [];

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: `Quotes for ${enquiry?.name || enquiry.id}`,
      actions: [
        {
          type: 'button',

          button: {
            name: 'Add / Update',
            behaviour: 'modal',
            modal: {
              size: 'large',
              title: 'Add / Update',
              content: ({ onClose }) => {
                const Form = renderAddQuotesForm();

                return (
                  <Form
                    vendors={vendors}
                    onSave={async (data) => {
                      await handleQuotesSave(data);
                      onClose();
                    }}
                    itemId={enquiry?.purchaseRequisitions[0]?.itemDetails?.id}
                    enquiryQuotes={enquiryQuotesData}
                    quantity={
                      enquiry?.purchaseRequisitions[0]?.details?.quantity
                    }
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          {loading || editing ? (
            <Recat className='h-5 inline animate-pulse mx-4' />
          ) : (
            <Table
              header={tableHeader}
              body={tableBody}
              auth={currentUser.authorization}
              actions={[
                {
                  name: 'Finalize Vendor',
                  auth: 'UI:CREATE-PO:VIEW',
                  show: ({ enquiryQuote }) => {
                    return (
                      enquiryQuote?.purchaseEnquiry?.status === 'enquiry-open'
                    );
                  },
                  behaviour: 'confirm',
                  onConfirm: ({ enquiryQuote }) => {
                    return {
                      title: `Are you sure you want to finalize vendor ${enquiryQuote?.vendor?.name}?`,
                      type: 'warning',
                      onConfirm: async () => {
                        await setPurchaseEnquiry({
                          id: enquiry.id,
                          details: {
                            selectedVendor: {
                              id: enquiryQuote?.vendor?.id,
                              name: enquiryQuote?.vendor?.name,
                            },
                          },
                          status: 'vendor-selected',
                        } as any);
                      },
                    };
                  },
                },
                {
                  name: 'Create PO',
                  auth: 'UI:CREATE-PO:VIEW',
                  show: ({ enquiryQuote }) => {
                    return false;
                    // return enquiryQuote?.status !== 'raised-po';
                  },
                  behaviour: 'modal',
                  modal: {
                    size: 'large',
                    title: 'Create PO',
                    content: ({ data: { enquiryQuote }, onClose }) => {
                      const Form = renderCreatePoForm({
                        useCombinedPurchaseOrder,
                      });
                      return (
                        <div>
                          <Form
                            quantity={itemDetails.quantity}
                            enquiryDetails={
                              <div className='mb-2'>
                                <div>
                                  Item:{' '}
                                  <span className='font-medium'>
                                    {' '}
                                    {itemDetails.name}{' '}
                                  </span>{' '}
                                </div>
                                <div>
                                  Enquiry No:{' '}
                                  <span className='font-medium'>
                                    {' '}
                                    {enquiry?.name || enquiry.id}{' '}
                                  </span>
                                </div>
                                <div>
                                  Enquiry Quantity:{' '}
                                  <span className='font-medium'>
                                    {itemDetails.quantity} {itemDetails.uom}s
                                  </span>
                                </div>
                                <div>
                                  Quote No:{' '}
                                  <span className='font-medium'>
                                    {
                                      enquiryQuote?.details?.quoteDetails?.[0]
                                        ?.quoteNo
                                    }
                                  </span>
                                </div>
                              </div>
                            }
                            vendorBusinessTerms={getVendorBusinessTerms({
                              deliveryTerm:
                                enquiry.details.terms.delivery.deliveryTerms
                                  .name || '',
                              paymentTerm:
                                enquiry.details.terms.payment.paymentTerms
                                  ?.map((x) => {
                                    return `${x.percent}% ${x.paymentType} - {if n>0 Then "${x.noOfDays}" Days}.`;
                                  })
                                  .join(', ') || '',
                            })}
                            vendorDetails={
                              <div className='mb-2'>
                                <div>
                                  Vendor:{' '}
                                  <span className='font-medium'>
                                    {enquiryQuote?.vendor.name}
                                  </span>
                                </div>
                              </div>
                            }
                            deliverySchedule={getDeliverySchedule(
                              enquiryQuote?.details?.quoteDetails?.[0]
                                ?.deliverySchedule || [],
                              itemDetails.uom
                            )}
                            paymentSchedule={getPaymentSchedule(
                              enquiryQuote?.details?.quoteDetails?.[0]
                                ?.paymentSchedule || {
                                amountIn: 'per',
                                schedule: [],
                              }
                            )}
                            onSave={async (data) => {
                              await handleCreatePo(data, enquiryQuote);
                              onClose();
                            }}
                          />
                        </div>
                      );
                    },
                  },
                },
              ]}
            />
          )}
        </div>
      ),
    };

    const QuoteComparison = renderComparision();

    return (
      <div className=''>
        <Card header={cardHeader} body={cardBody} />
        {stockGroup && item ? (
          <QuoteComparison
            startColumnIndex={2}
            columnHeadings={getColumnHeadings(enquiryQuotesData)}
            rowHeading={getRowHeadings({ stockGroup })}
            completeData={getCompleteData({
              enquiryQuotesData,
              landingCostFormula:
                cgSetting?.details.purchase?.landingCostFactors || '',
              rows: getRowHeadings({ stockGroup }),
              columns: getColumnHeadings(enquiryQuotesData),
              item,
            })}
            selectedColumnId={
              enquiryQuotesData?.find(
                (q) => q.id === enquiry?.details?.selectedQuoteId
              )?.vendor?.id
            }
            recommendedColumnId=''
          />
        ) : (
          <></>
        )}
      </div>
    );
  };
};
