import {
  ChangeAssesmentFilterType,
  ChangeAssesmentType,
  ClosureDatePeriodFilterType,
  ClosureDatePeriodType,
} from '@erp_core/erp-types/dist/modules/quality-assurance';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { ChangeAssesmentInterface } from '../models/interfaces/quality-assurance/change-assesment';
import { ClosureDatePeriodInterface } from '../models/interfaces/quality-assurance/closure-date-period';
// import {
//   ChangeAssesmentFilterType,
//   ChangeAssesmentType,
// } from '../models/types/quality-assurance/change-assesment.js';
// import {
//   ClosureDatePeriodFilterType,
//   ClosureDatePeriodType,
// } from '../models/types/quality-assurance/closure-date-period.js';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { erpV2Api } from './admin';
import { QUALITY_ASSURANCE_SERVER_URI } from './server-uri';

export const changeAssesmentService = new GenericHttpWithLogsRepo<
  ChangeAssesmentType,
  ChangeAssesmentFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: QUALITY_ASSURANCE_SERVER_URI,
  resourceEndpoint: '/change-assesment',
});

export const useCombinedChangeAssesment = createUseCombinedResource<
  ChangeAssesmentType,
  ChangeAssesmentFilterType,
  ChangeAssesmentInterface
>({
  useService: () => changeAssesmentService,
});

export const closureDatePeriodService = new GenericHttpWithLogsRepo<
  ClosureDatePeriodType,
  ClosureDatePeriodFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: QUALITY_ASSURANCE_SERVER_URI,
  resourceEndpoint: '/closure-date-period',
});

export const useCombinedClosureDatePeriod = createUseCombinedResource<
  ClosureDatePeriodType,
  ClosureDatePeriodFilterType,
  ClosureDatePeriodInterface
>({
  useService: () => closureDatePeriodService,
});
