import { FinishedGood } from '@erp_core/erp-icons/icons/finished-good';
import { IntermediatePoint } from '@erp_core/erp-icons/icons/intermediate-point';
import { MachineIcon } from '@erp_core/erp-icons/icons/machine-icon';
import { RawMaterial } from '@erp_core/erp-icons/icons/raw-material';
import { ItemCategoryRes } from '@erp_core/erp-types/dist/modules/inventory';
import { CogIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export function CategoryIcons({
  category,
}: {
  category: ItemCategoryRes;
}): JSX.Element {
  switch (category?.shortName) {
    case 'RM': {
      return (
        <span title={category.name}>
          <RawMaterial className='w-6 h-6 text-red-700 inline mr-2' />
        </span>
      );
    }

    case 'MP': {
      return (
        <CogIcon
          title={category.name}
          className='w-6 h-6 text-cyan-700 inline'
        />
      );
    }

    case 'RM/FG': {
      return (
        <span title={category.name}>
          <RawMaterial className='w-4 h-6 text-red-700 inline' />
          <FinishedGood className='w-4 h-6 text-blue-700 inline mr-2' />
        </span>
      );
    }

    case 'FG': {
      return (
        <span title={category.name}>
          <FinishedGood className='w-6 h-6 text-blue-700 inline mr-2' />
        </span>
      );
    }

    case 'INT': {
      return (
        <span title={category.name}>
          <IntermediatePoint className='w-6 h-6 text-indigo-700 inline mr-2' />
        </span>
      );
    }

    case 'FA': {
      return (
        <span title={category.name}>
          <MachineIcon className='w-6 h-6 text-pink-700 inline mr-2' />
        </span>
      );
    }

    default: {
      return (
        <span title={category?.name}>
          <QuestionMarkCircleIcon className='w-6 h-6 text-red-700 inline mr-2' />
        </span>
      );
    }
  }
}
