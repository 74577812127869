import {
  Godown,
  GodownFilterType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { useEffect, useState } from 'react';
import { GodownInterface } from '../../../models/interfaces/inventory/generic/godown';

export type UseGodownsTree = () => {
  data?: Array<Godown>;
  // eslint-disable-next-line no-unused-vars
  getAll: (filter?: GodownFilterType) => void;
  loading: boolean;
};

export interface UseGetGodownOptions {
  useGodownService: () => GodownInterface;
}

export function createUseGetAllGodown({
  useGodownService,
}: UseGetGodownOptions): UseGodownsTree {
  return () => {
    const godownService = useGodownService();
    const [godownData, setGodownData] = useState<Array<Godown>>([]);
    const apiCall = async (filter?: GodownFilterType) => {
      const g = (await godownService.getAll({
        getAll: true,
        ...filter,
      })) as Godown[];
      setGodownData(g);
    };

    useEffect(() => {
      apiCall();
      // eslint-disable-next-line
    }, []);

    return {
      data: godownData,
      getAll: (filter?: GodownFilterType) => {
        apiCall(filter);
      },
      loading: false,
    };
  };
}
