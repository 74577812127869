import { ExcelDownload } from '@erp_core/erp-icons/icons/excel-download';
import {
  downloadFile,
  LoadingButton,
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { PayrollReport } from '../../../../hooks/analytics/hrd-dashboard-report/use-hrd-dashboard-report';

export function renderStatutoryReportsV2({
  getPayrollReport,
}: {
  getPayrollReport: (month: string, reportType: PayrollReport) => Promise<any>;
}): ({ month }: { month: string }) => JSX.Element {
  return function StatutoryReports({ month }: { month: string }): JSX.Element {
    const [message, setMessage] = useState<string>('');

    const reports: Array<{
      name: string;
      key: PayrollReport;
      mapper?: XlsxDownloadMapper;
      type: 'excel' | 'csv' | 'hash-tilda' | 'unknown';
    }> = [
      {
        name: 'Loan Recovery',
        key: 'loan-recovery-report',
        type: 'excel',
      },
      {
        name: 'TDS Report',
        key: 'tds-report',
        type: 'excel',
      },
      {
        name: 'ESIC Report',
        key: 'esic-report',
        type: 'excel',
      },
      { name: 'EPF Report', key: 'epf-report', type: 'hash-tilda' },
      {
        name: 'PT Report',
        key: 'pt-report',
        type: 'excel',
      },
      {
        name: 'Salary Sheet Regulatory Report',
        key: 'salary-sheet-regulatory',
        type: 'unknown',
      },
      {
        name: 'Salary Sheet Details One Line',
        key: 'salary-sheet-details-one-line',
        type: 'unknown',
      },
      {
        name: 'Salary Sheet Details',
        key: 'salary-sheet-details',
        type: 'unknown',
      },
      {
        name: 'Bonus Retention Report',
        key: 'bonus-retention-report',
        type: 'excel',
      },
      {
        name: 'Advance Recovery Report',
        key: 'advance-recovery-report',
        type: 'excel',
      },
      { name: 'Summary Report', key: 'summary-report', type: 'unknown' },
    ];
    return (
      <div>
        <div className='grid grid-cols-4 gap-4'>
          {reports.map((x) => (
            <Report
              x={x}
              getPayrollReport={getPayrollReport}
              month={month}
              setMessage={setMessage}
            />
          ))}
        </div>
        <div className='text-center my-2 animate-pulse'>{message}</div>
      </div>
    );
  };
}

function Report({
  x,
  getPayrollReport,
  month,
  setMessage,
}: {
  x: {
    name: string;
    key: PayrollReport;
    fileName?: string;
    mapper?: XlsxDownloadMapper;
    type: 'excel' | 'csv' | 'hash-tilda' | 'unknown';
  };
  getPayrollReport: (month: string, reportType: PayrollReport) => Promise<any>;
  month: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}) {
  if (x.type === 'excel') {
    let header:
      | {
          title: string;
          date?: string;
          subtitle?: string[];
        }
      | undefined = undefined;

    const Report = renderBulkDownload({
      name: x.name,
      mapperFun: async () => {
        const result: {
          mapper: XlsxDownloadMapper;
          data: any[];
          fileName: string;
          header?: {
            title: string;
            date?: string;
            subtitle?: string[];
          };
        }[] = [];

        const res = await getPayrollReport(month, x.key);
        console.log(res);
        if (res.success) {
          if (res.data.length === 0) {
            setMessage('Seems like there is no report to download');
            return [];
          } else {
            console.log(res.data);

            header = res.data.header;

            if (header && res.data.data?.length === 0) {
              setMessage('Seems like there is no report to download');
              return [];
            }
            // TODO: Logic to bind mapper and download Excel.
            const fileName = res.fileName || `${x.key}-${month}.xlsx`;
            if (res.excelMapper || x.mapper) {
              result.push({
                header: header,
                mapper: res.excelMapper || x.mapper,
                data: res.data.header ? res.data.data : res.data,
                fileName,
              });
            }
            return result;
          }
        }

        return [];
      },
      downloadIcon: ExcelDownload,
    });

    return (
      <div key={x.key} className='border border-gray-200 flex justify-center'>
        <Report />
      </div>
    );
  }

  return (
    <div key={x.key} className='border border-gray-200 flex justify-center'>
      <LoadingButton
        defaultStyle='border border-green-500 text-green-500 p-1 rounded-md'
        text={x.name}
        behaviourParams={{}}
        behaviorFn={async () => {
          setMessage('');
          const res = await getPayrollReport(month, x.key);
          if (res.success) {
            if (res.data.length === 0) {
              setMessage('Seems like there is no report to download');
            } else {
              console.log(res.data);
              if (x.type === 'hash-tilda') {
                // TODO: Logic to bind mapper and download Excel.
                const sep = '#~#';
                downloadFile({
                  fileType: 'application/csv',
                  fileName: `${x.name}.txt`,
                  result: res.data.data
                    .map(
                      (y) =>
                        `${y.epf_number}${sep}${y.name}${sep}${y.grossearnings}${sep}${y.epfWages}${sep}${y.epsWages}${sep}${y.edliWages}${sep}${y.employeePf}${sep}${y.epsContribution}${sep}${y.epfContribution}${sep}${y.pfNcp}${sep}`
                    )
                    .join('\n'),
                });
              }
            }
          }
        }}
      />
    </div>
  );
}
