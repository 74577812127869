import {
  BatchItemGodownFilterType,
  Godown,
  GodownFilterType,
  GodownTransfer,
  GodownTransferFilterType,
  HazardCategory,
  HazardClass,
  HazardClassFilterType,
  HazardPrecaution,
  HazardPrecautionFilterType,
  HazardStatement,
  HazardStatementFilterType,
  ItemBatchGodown,
  ItemCategoryFilter,
  ItemCategoryRes,
  ItemFilter,
  ItemRes,
  PhysicalStockType,
  Revision,
  RevisionFilterType,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  Grade,
  GradeFilter,
} from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  StockGroup,
  StockGroupTempFilterType,
} from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import {
  createUseCombinedResource,
  createUseResource,
} from '@erp_core/erp-ui-components';
import { HttpGradeRepo } from '../datalayer/repository/http/inventory/generic/grade';
import { HttpItemV2Repo } from '../datalayer/repository/http/inventory/generic/item';
import { HttpRevisionRepo } from '../datalayer/repository/http/inventory/generic/revision';
import { HttpStockGroupRepo } from '../datalayer/repository/http/inventory/generic/stock-group';
import { HttpBatchItemGodownRepo } from '../datalayer/repository/http/inventory/item/batch/batch-item-godown';
import { ItemCategoryHttpRepo } from '../datalayer/repository/http/inventory/itemCategory';
import { createUseGetAllGodown } from '../hooks/inventory/godown-inventory/use-godowns-tree';
import { createUseBatchItemAllocate } from '../hooks/inventory/item/batch/use-batch-item-allocate';
import { createUseMRS } from '../hooks/inventory/mrs/use-mrs';
import { HazardGhsClassInterface } from '../models/interfaces/admin/hazard-regulations/ghs-class';
import { GhsPrecautionaryStatementInterface } from '../models/interfaces/admin/hazard-regulations/ghs-precautionary-statement';
import {
  HazardCategoryFilterType,
  HazardCategoryInterface,
} from '../models/interfaces/admin/hazard-regulations/hazard-category';
import { HazardStatementInterface } from '../models/interfaces/admin/hazard-regulations/hazard-statement';
import { GodownInterface } from '../models/interfaces/inventory/generic/godown';
import { ItemCategoryV2Interface } from '../models/interfaces/inventory/generic/item-category';
import { GodownTransferInterface } from '../models/interfaces/inventory/godown-transfer';
import { GradeInterface } from '../models/interfaces/inventory/grade';
import { BatchItemGodownInterface } from '../models/interfaces/inventory/item/batch/batch-item-godown';
import { ItemInterface } from '../models/interfaces/inventory/item/index';
import {
  PhysicalStockFilterType,
  PhysicalStockInterface,
} from '../models/interfaces/inventory/item/stock/physical-stock';
import { RevisionInterface } from '../models/interfaces/inventory/revision';
import { StockGroupInterface } from '../models/interfaces/inventory/stock-group';
import { erpV2Api } from './admin';
import { INVENTORY_SERVER_URI } from './server-uri';

// eslint-disable-next-line
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { bomService, purchaseOrderService } from './order';

export const godownService = new GenericHttpWithLogsRepo<
  Godown,
  GodownFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/godowns',
});

export const useGodownsTree = createUseGetAllGodown({
  useGodownService: () => godownService,
});

export const useCombinedGodown = createUseCombinedResource<
  Godown,
  GodownFilterType,
  GodownInterface
>({
  useService: () => godownService,
});

export const stockGroupService = new HttpStockGroupRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);

export const useCombinedStockGroup = createUseCombinedResource<
  StockGroup,
  StockGroupTempFilterType,
  StockGroupInterface
>({
  useService: () => stockGroupService,
});

export const itemV2Service = new HttpItemV2Repo(INVENTORY_SERVER_URI, erpV2Api);

export const useCombinedItem = createUseCombinedResource<
  ItemRes,
  ItemFilter,
  ItemInterface
>({
  useService: () => itemV2Service,
});

export const gradeService = new HttpGradeRepo(INVENTORY_SERVER_URI, erpV2Api);
export const useCombinedGrade = createUseCombinedResource<
  Grade,
  GradeFilter,
  GradeInterface
>({
  useService: () => gradeService,
});

export const itemCategoryService = new ItemCategoryHttpRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);

export const itemCategoryV2Service = new GenericHttpWithLogsRepo<
  ItemCategoryRes,
  ItemCategoryFilter
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/categories',
});

export const useCombinedItemCategory = createUseCombinedResource<
  ItemCategoryRes,
  ItemCategoryFilter,
  ItemCategoryV2Interface
>({
  useService: () => itemCategoryV2Service as any,
});

export const godownTransferService = new GenericHttpWithLogsRepo<
  GodownTransfer,
  GodownTransferFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/transactions',
});

export const useCombinedGodownTransfer = createUseCombinedResource<
  GodownTransfer,
  GodownTransferFilterType,
  GodownTransferInterface
>({
  useService: () => godownTransferService,
});

export const physicalStockService = new GenericHttpWithLogsRepo<
  PhysicalStockType,
  PhysicalStockFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/items/batches/physical-stock',
});

export const usePhysicalStock = createUseResource<
  PhysicalStockType,
  PhysicalStockFilterType,
  PhysicalStockInterface
>({
  useService: () => physicalStockService,
});

export const batchItemGodownService = new HttpBatchItemGodownRepo(
  INVENTORY_SERVER_URI,
  erpV2Api,
  bomService
);

export const useCombinedBatchItemGodown = createUseCombinedResource<
  ItemBatchGodown,
  BatchItemGodownFilterType,
  BatchItemGodownInterface
>({
  useService: () => batchItemGodownService,
});

export const ghsPrecautionaryStatementService = new GenericHttpWithLogsRepo<
  HazardPrecaution,
  HazardPrecautionFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/hazard-regulations/precaution-statements',
});

export const useCombinedPrecautionaryStatement = createUseCombinedResource<
  HazardPrecaution,
  HazardPrecautionFilterType,
  GhsPrecautionaryStatementInterface
>({
  useService: () => ghsPrecautionaryStatementService,
});

export const hazardCategoryService = new GenericHttpWithLogsRepo<
  HazardCategory,
  HazardCategoryFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/hazard-regulations/categories',
});

export const useCombinedHazardCategory = createUseCombinedResource<
  HazardCategory,
  HazardCategoryFilterType,
  HazardCategoryInterface
>({
  useService: () => hazardCategoryService,
});

export const hazardStatementService = new GenericHttpWithLogsRepo<
  HazardStatement,
  HazardStatementFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/hazard-regulations/hazard-statements',
});

export const useCombinedHazardStatement = createUseCombinedResource<
  HazardStatement,
  HazardStatementFilterType,
  HazardStatementInterface
>({
  useService: () => hazardStatementService,
});

export const hazardGhsClassService = new GenericHttpWithLogsRepo<
  HazardClass,
  HazardClassFilterType
>({
  erpv2Api: erpV2Api,
  baseURI: INVENTORY_SERVER_URI,
  resourceEndpoint: '/hazard-regulations/class',
});

export const useCombinedHazardGhsClass = createUseCombinedResource<
  HazardClass,
  HazardClassFilterType,
  HazardGhsClassInterface
>({
  useService: () => hazardGhsClassService,
});

export const useBatchGodownItemAllocate = createUseBatchItemAllocate({
  batchItemGodownService,
});

export const useMRS = createUseMRS({
  useBomService: () => bomService,
  useBatchItemGodownService: () => batchItemGodownService,
  usePurchaseOrderService: () => purchaseOrderService,
  useGodownTransferService: () => godownTransferService,
});

export const revisionService = new HttpRevisionRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);

export const useCombinedRevision = createUseCombinedResource<
  Revision,
  RevisionFilterType,
  RevisionInterface
>({
  useService: () => revisionService,
});
