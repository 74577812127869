import { ExcelDownload } from '@erp_core/erp-icons/icons/excel-download';
import {
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import { AttendanceInterface } from '../../../../../models/interfaces/hrd/attendance';

const pendingActionsMapper: XlsxDownloadMapper = [
  { columnName: 'employee', dataBinding: { property: 'employee' } },
  {
    columnName: 'date',
    dataBinding: { property: 'date' },
  },
  {
    columnName: 'resource',
    dataBinding: { property: 'resource' },
  },
  {
    columnName: 'pending-action',
    dataBinding: { property: 'pendingAction' },
  },
  {
    columnName: 'responsible-party',
    dataBinding: { property: 'actor' },
  },
];

export function PendingActionsReport({
  attendanceService,
  month,
}: {
  attendanceService: AttendanceInterface;
  month: string;
}): JSX.Element {
  const PendingActions = renderBulkDownload({
    mapperFun: async () => {
      const items = await attendanceService.getPendingActions(month);

      const result: {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[] = [];

      if (items.length !== 0) {
        const dynamicMapper: XlsxDownloadMapper = [...pendingActionsMapper];

        const fileName = 'pending-actions-report'; // File name based in stock group id

        result.push({ mapper: dynamicMapper, data: items, fileName });
      }
      return result;
    },
    downloadIcon: ExcelDownload,
  });

  return (
    <div className='mx-auto'>
      <div className='flex justify-center'>
        <span className='flex border border-green-500 rounded p-1'>
          <PendingActions /> Download Pending Actions
        </span>
      </div>
    </div>
  );
}
