import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  GatePassFilterType,
  GatePassType,
} from '@erp_core/erp-types/dist/types/modules/hrd/gatepass';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import { useEffect, useState } from 'react';

type BulkGatepassActionFormProps = {
  list: Array<GatePassType>;
  onClose: () => void;
  setGatePass: (s: GatePassType, options?: any) => Promise<GatePassType>;
  currentUser: CurrentUserType;
  getGatePasses: (filter?: GatePassFilterType) => void;
  date: string;
};

export function BulkGatepassActionForm({
  list,
  onClose,
  setGatePass,
  currentUser,
  getGatePasses,
  date,
}: BulkGatepassActionFormProps): JSX.Element {
  const pendingValidnCheck = list
    .map((li) => li.status === 'approved')
    .includes(true);

  const [count, setCount] = useState<number>(0);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setMessage(`${count} of ${list.length} approved...`);
    // eslint-disable-next-line
  }, [count]);

  async function bulkApprove() {
    for (let i = 0; i < list.length; i++) {
      const finalData = ({
        id: list[i].id,
        approvedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        status: 'approved',
      } as unknown) as GatePassType;
      await setGatePass(finalData, {
        enableLoading: false,
        enableResource: false,
      });
      setCount((c) => c + 1);
      await sleep(500);
    }
    await getGatePasses({ date });
    onClose();
  }

  if (pendingValidnCheck) {
    return (
      <div className='m-2 border border-gray-200 rounded-lg'>
        <div className='text-center text-red-600'>
          Seems like approved entries are selected. Kindly deselect them to
          perform this action!
        </div>
      </div>
    );
  }

  return (
    <div className='m-2 border border-gray-200 rounded-lg'>
      <div className='text-center'>
        <span className='text-gray-600 italic'>Entries: </span>
        <div className='flex'>
          {list.map((l) => (
            <div key={l.id} className='mx-1 border border-gray-200 rounded-md'>
              {l.employee.name}-{l.status}-{l.date}
            </div>
          ))}
        </div>
      </div>
      <div className='align-center m-1'>
        <div className='text-center font-semibold animate-pulse'>{message}</div>

        <LoadingButton
          text='Save'
          loadingStyle='text-red-600'
          behaviourParams={{}}
          behaviorFn={async () => {
            bulkApprove();
          }}
        ></LoadingButton>
      </div>
    </div>
  );
}
