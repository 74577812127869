import {
  SalaryBonus,
  SalaryBonusFilter,
} from '@erp_core/erp-types/dist/types/modules/hrd/salary-bonus';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { GenericHttpWithLogsRepo } from '../datalayer/repository/http/generic-resource-with-logs';
import { SalaryBonusInterface } from '../models/interfaces/hrd/salary-bonus';
import { erpV2Api } from './admin';
import { SALARY_ADVANCE_SERVER_URI } from './server-uri';

export const salaryBonusService = new GenericHttpWithLogsRepo<
  SalaryBonus,
  SalaryBonusFilter
>({
  erpv2Api: erpV2Api,
  baseURI: SALARY_ADVANCE_SERVER_URI,
  resourceEndpoint: '/salary-bonus',
});

export const useCombinedSalaryBonus = createUseCombinedResource<
  SalaryBonus,
  SalaryBonusFilter,
  SalaryBonusInterface
>({
  useService: () => salaryBonusService,
});
